// import BackButton from "components/Utils/Button/BackButton";
import ProductsPage from "components/More/ProductsPage/ProductsPage";
import { ProductDetails } from "components/Profile/ProductsTab/ProductDetails/ProductDetails";
import { ProductPage } from "components/Profile/ProductsTab/ProductPage/ProductPage";
import ServicesTree from "components/Profile/ServicesTab/ServicesComponent/ServicesTree";
import BackButton from "components/Utils/Button/BackButton";
import { featuresSection } from "components/Utils/General";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import useFetchProfile from "hooks/useFetchProfile";
import { useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "../Header.module.css";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const ProductsViews = () => {
	const navigate = useNavigate();
	const { pathname, state } = useLocation();

	const { filters } = state || {};
	const { userUrl, productId } = useParams();
	const isUncategorizedProducts = filters?.onlyUncategorizedProducts === true;

	let component;
	let headerTitle;
	let headerBtn;

	const [countryFlag, setCountryFlag] = useState();
	const productsRef = useRef(null);

	const { profileData: profile, isFetching, canEdit } = useFetchProfile();

	//! Handle Error Redirect
	if (!profile && pathname !== "/products") {
		return <LoadingPage fullPage={true} />;
	}

	const productAccordion = (
		<div style={{ padding: "20px" }}>
			<ServicesTree
				// data={profile?.uuid} //TODO devrait etre userId pas data en prop
				userId={profile?.uuid} // added instead of above
				showServicesWithProducts={true}
			/>
		</div>
	);

	switch (pathname) {
		case "/products":
			component = <ProductsPage ref={productsRef} onFlagChange={setCountryFlag} />;
			headerTitle = "Products";
			headerBtn = (
				<button onClick={() => productsRef.current.showModal()}>
					<img src={countryFlag} alt="" />
				</button>
			);
			break;

		case `/profile/${userUrl}/products`:
			component = (
				<ProductPage
					profile={profile}
					canEdit={canEdit}
					// productAccordion={productAccordion}
				/>
			);
			headerTitle = isUncategorizedProducts ? "Uncategorized Products" : "Products";
			break;

		case `/profile/${userUrl}/product/${productId}`:
			component = <ProductDetails profile={profile} canEdit={canEdit} />;
			// headerTitle = "Product Details";
			break;
		default:
			navigate("/profile");
			return null;
	}

	return (
		<DefaultPage
			headerRightContent={
				<>
					<div className={styles.header}>
						<BackButton onBack={() => navigate(-1)} />
						{headerTitle}
					</div>
					{headerBtn}
				</>
			}
			leftSideChildren={
				pathname === "/products" ? (
					<FeaturesSidebar active={featuresSection.products} />
				) : profile.productsHasServices ? (
					// <div style={{ padding: "20px" }}>
					// 	<ServicesTree
					// 		data={profile.uuid}
					// 		showServicesWithProducts={true}
					// 	/>
					// </div>
					productAccordion
				) : (
					<NavBar />
				)
			}
			rightSideChildren={component}
			rightSideBgColor={"#FFF"}
			rightSideContainerColor={"#FFF"}
		/>
	);
};
export default ProductsViews;
