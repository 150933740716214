import React from "react";
import styles from "./Language.module.scss";

function ProficiencyGuide() {
	return (
		<div className={styles.description_section}>
			<label className={styles.title}>Proficiency Guide</label>

			<label className={styles.pro_title}>0 – No Proficiency</label>
			<p className={styles.description}>
				At this lowest level, there is basically no knowledge of the language. The person
				may know a few words, but can’t form sentences or carry on any type of conversation.
				Phrased as: “No English” (example)
			</p>

			<label className={styles.pro_title}>1 – Elementary Proficiency</label>
			<p className={styles.description}>
				At this language proficiency level, a person can form basic sentences, including
				asking and answering simple questions. This is essentially the starting point of the
				language proficiency levels. This level reflects someone who is traveling to a new
				country and who has just begun to study a language. Phrased as: “Elementary Spanish”
			</p>

			<label className={styles.pro_title}>2 – Limited Working Proficiency</label>
			<p className={styles.description}>
				Someone at this level can handle basic work commands and social phrases. They can
				carry on limited casual conversations at the office and discuss their personal life.
				Someone at this level still needs help with more extensive conversations in the
				language. They can only operate independently in basic conversations. Phrased as:
				“Limited Working English”
			</p>

			<label className={styles.pro_title}>3 – Professional Working Proficiency</label>
			<p className={styles.description}>
				Someone at this language proficiency level can make contributions to office
				meetings, have conversations with clients, and carry out most work functions
				requested of them. A person at level 3 can speak at a normal speed in the language
				and has a fairly extensive vocabulary. They likely still have an accent at this
				level and probably require help understanding subtle and nuanced phrasing. Some
				employers consider this level or above as basically acceptable, depending on the
				specific job. Phrased as: “Professional Working Spanish”
			</p>

			<label className={styles.pro_title}>4 – Full Professional Proficiency</label>
			<p className={styles.description}>
				Full professional fluency is desired by most employers. Someone at this level can
				have advanced discussions on a wide range of topics about personal life, current
				events, and technical topics such as business and finance. People at this level may
				still have a minor accent and may occasionally misspeak or make minor mistakes.
				Their vocabulary is extensive and they can carry on conversations with ease. Most
				employers consider level 4 or above acceptable. Phrased as: “Full Professional
				Spanish”
			</p>

			<label className={styles.pro_title}>5 – Native / Bilingual Proficiency</label>
			<p className={styles.description}>
				Someone at this language proficiency level was either raised speaking the language
				as their native tongue or has been speaking it so long that they are completely
				fluent. They have little or no accent. Phrased as: “Native English”.
			</p>
		</div>
	);
}

export default ProficiencyGuide;
