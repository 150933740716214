import styles from "./Education.module.scss";
import { format } from "date-fns";
import Tag from "components/Utils/SubComs/Tags/Tag";
import ThumbnailContainer from "components/Utils/SubComs/ThumbnailContainer/ThumbnailContainer";
import RoundButton from "components/Utils/Button/RoundButton";

const EducationItem = ({ showControls = true, onEditClicked, onDelete, item }) => {
	const startDate = item.startDate > 0 ? format(new Date(item.startDate), "dd/MM/yyyy") : "";

	const endDate = item.currentlyWorking
		? "Currently pursuing"
		: item.completionDate > 0
		? format(new Date(item.completionDate), "dd/MM/yyyy")
		: "";

	return (
		<div key={item.uuid} className={styles.certificate_item}>
			<Tag customStyle={styles.certificate_name} itemName={item.educationType.name} />

			<div className={styles.certificate_title}>
				<span>{item.title}</span>
			</div>
			<div className={styles.institute_name}>
				{item.instituteDTO ? item.instituteDTO.name : item.instituteName}
			</div>
			<div className={styles.institute_website}>{item.website}</div>
			<div className={styles.institute_location}>{item.locationDTO?.countryDTO.name}</div>
			{item.comments && (
				<div className={styles.certificate_description}>
					<p>{item.comments}</p>
				</div>
			)}

			{item.files.length > 0 && <ThumbnailContainer items={item.files} />}

			{item.score && (
				<span className={styles.certificate_score}>{`${item.score} ${
					item.scoreOutOf ? `/ ${item.scoreOutOf}` : ""
				}`}</span>
			)}
			<div className={styles.certificate_dates}>
				{/* {item.startDate !== 0
                    ? `${format(
                          new Date(item.startDate),
                          "dd/MM/yyyy"
                      )} - ${format(
                          new Date(item.completionDate),
                          "dd/MM/yyyy"
                      )}`
                    : `${format(new Date(item.completionDate), "dd/MM/yyyy")}`} */}
				{`${startDate}
                    ${startDate && endDate ? " - " : ""}
                 ${endDate}`}
			</div>

			{item.validity !== 0 && (
				<div className={styles.certificate_validity}>{`Valid till ${format(
					new Date(item.validity),
					"dd/MM/yyyy"
				)}`}</div>
			)}

			{showControls && (
				<div className={styles.controls}>
					<RoundButton icon="edit" onClick={onEditClicked} />
					<RoundButton icon="delete" onClick={onDelete} />
				</div>
			)}

			{/* {showControls && (
				<div className={styles.certificate_controls}>
					<img src={editIcon} onClick={onEditClicked} alt="Edit" />
					<img src={binIcon} onClick={onDelete} alt="Delete" />
				</div>
			)} */}
		</div>
	);
};

export default EducationItem;
