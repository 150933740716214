import { useQueryClient } from "@tanstack/react-query";
import postApi from "api/post";
import routes from "components/Routing/routing-keys";
import FeedContainer from "components/Utils/FeedContainer/FeedContainer";
import FullScreenModal from "components/Utils/GaawkModal/FullScreenModal";
import ThreeColumnPage from "components/Utils/PageArchs/ThreeColumnPage/ThreeColumnPage";
import PostLoader from "components/Utils/SubComs/CustomLoader/PostLoader";
import useCurrentUser from "hooks/useCurrentUser";
import useGetPostById from "hooks/useGetPostById";
import useMutate from "hooks/useMutate";
import usePostViewer from "hooks/usePostViewer";
import useUpdateReaction from "hooks/useUpdateReaction";
import { postsKeys } from "queryKeys/posts-key-factory";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HomeRightSidebar from "./Components/HomeRightSidebar/HomeRightSidebar";
import CreatePost from "./CreatePost";
import EditPostModal from "./EditPostModal";
import PostFullScreen from "./PostFullScreen";
import PostItem from "./PostItem";
import styles from "./PostPreview.module.scss";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const PostPreview = () => {
	// const getPostApi = useApi(postApi.getPostById, true, true);

	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { postModal, handleItemClicked, handleCloseModal, subPostIndex, postId, subPostId } =
		usePostViewer();

	const queryKey = postsKeys.detail(postId);

	const { data: post, isLoading, isSuccess } = useGetPostById(postId);

	useEffect(() => {
		if (isSuccess) {
			trackEvent(eventsDictionary.POST.FULL_POST_DETAILS, {
				post_id: post.uuid,
			});
		}
	}, [isSuccess]);

	const updatePostReaction = useUpdateReaction();

	const handlePostUpdate = (reaction) => {
		updatePostReaction(queryKey, reaction);
	};

	const {
		action: { mutate: deletePost },
	} = useMutate(postApi.deletePost, () => navigate(routes.root));

	const { type, url, profileImage, firstName, lastName, name } = useCurrentUser();

	const [showModal, setShowModal] = useState(false);

	const hasRun = useRef(false);

	useEffect(() => {
		if (showModal && !hasRun.current) {
			trackEvent(eventsDictionary.POST.EDIT);

			hasRun.current = true;
		}
	}, [showModal]);

	// ! ========== POST SHARE ==========
	const postRef = useRef();

	const handleShare = (post) => {
		if (post.sharedPost) {
			postRef.current.sharePost(post.sharedPost);
		} else {
			postRef.current.sharePost(post);
		}
	};

	return (
		<>
			<CreatePost
				ref={postRef}
				name={type === "USER" ? `${firstName} ${lastName}` : name}
				profileImg={profileImage}
				type={type}
				onCreate={() => toast.success("Post successfully shared!")}
				// board={board}
			/>

			<ThreeColumnPage
				leftSideChildren={<NavBar />}
				centerChildren={
					<FeedContainer fixedContainer={true} className={styles.feed_container}>
						{isLoading ? (
							<PostLoader />
						) : (
							<PostItem
								post={post}
								canEditBoard={post.board?.moderator}
								isPinned={post.pinned}
								onItemClicked={handleItemClicked(post)}
								onDelete={() => deletePost(post.uuid)}
								onPostUpdate={handlePostUpdate}
								onEdit={() => {
									setShowModal(true);
								}}
								onShare={handleShare}
								// onTogglePin={() => handleTogglePin(post)}
							/>
						)}
					</FeedContainer>
				}
				rightSideChildren={<HomeRightSidebar />}
			/>

			<FullScreenModal
				show={postModal}
				onClose={() => {
					//ESC KEY WHEN IN THE MODAL TRIGGERS THIS
					handleCloseModal();
					window.history.pushState(null, "", `/post/${postId}`);
				}}
				fullWidth={true}
				children={
					<PostFullScreen
						post={post}
						initialSlideIndex={subPostIndex}
						postId={postId}
						subPostId={subPostId}
						isModal={true}
						onClose={handleCloseModal}
						onRefresh={() => {
							if (post.subPosts?.length > 1) {
								queryClient.invalidateQueries(queryKey);
							} else {
								deletePost(post.uuid);
							}
						}}
						onUpdateUrl={() => window.history.pushState(null, "", pathname)}
					/>
				}
			/>

			<EditPostModal
				existingPost={post}
				name={type === "USER" ? `${firstName} ${lastName}` : name}
				profileImg={profileImage}
				type={type}
				url={url}
				show={showModal}
				toggleShow={(bool) => setShowModal(bool)}
				onInvalidate={() => queryClient.invalidateQueries(queryKey)}
			/>
		</>
	);
};

export default PostPreview;
