import searchApi from "api/search";
import { formatFiltersValues } from "components/Utils/General";
import useApi from "hooks/useApi";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { searchKeys } from "queryKeys/search-key-factory";
import { useSelector } from "react-redux";
import ServicesComponent from "../SearchComponents/Services";
import styles from "./All.module.scss";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const PAGE_SIZE = 40;

const Services = ({ isMobile, onShowModal, tagsList, userCoordinates }) => {
	const { paramValue: inputValue } = useGetQueryParam("q");

	const servicesFiltersObject = useSelector((state) => state.filters.servicesFilters);

	const storedServicesFilters = {
		...servicesFiltersObject,
		q: inputValue,
		locationIds: [{ uuid: userCoordinates.id, value: userCoordinates.id }], //* formatting it like that (object in array) because of how the api request is structured and because of how formatFiltersValues() works
	};

	const searchServicesApi = useApi(searchApi.searchServices, true, true);

	const fetchServices = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, filters] = queryKey;
		const response = await searchServicesApi.request(
			pageParam,
			PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const { items, isFetchingNextPage, viewRef, isLoading } = useInfiniteScroll({
		queryKey: searchKeys.services(storedServicesFilters),
		queryFn: fetchServices,
		pageSize: PAGE_SIZE,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage = lastPage.list?.length === PAGE_SIZE ? pages.length : undefined;
				return nextPage;
			},
		},
	});

	const data = items?.flatMap((page) => page.list);

	const count = items?.[0]?.count;

	useEffect(() => {
		trackEvent(eventsDictionary.SEARCH.SERVICES);
	}, []);

	return (
		<div className={styles.section}>
			<div className={styles.sub_section}>
				<ServicesComponent
					viewRef={viewRef}
					data={data}
					count={count}
					isFetchingNextPage={isFetchingNextPage}
					isLoading={isLoading}
					isMobile={isMobile}
					onShowModal={onShowModal}
					tagsList={tagsList}
					isPaginated={true}
				/>
			</div>
		</div>
	);
};

export default Services;
