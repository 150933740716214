import { useState, useMemo } from "react";
import styles from "./PdfReader.module.css";
import { Document, Page, PasswordResponses, pdfjs } from "react-pdf";
import GaawkButton from "../Button/GaawkButton";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfReader = ({ file, loadingSpinner, parentDimensions }) => {
	const { width: wrapperWidth, height: wrapperHeight } = parentDimensions;

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pdfDimensions, setPdfDimensions] = useState({});

	const fitHorizontal = useMemo(() => {
		const wRatio = pdfDimensions.width / wrapperWidth;
		const hRatio = pdfDimensions.height / wrapperHeight;
		if (wRatio < hRatio) {
			return false;
		}
		return true;
	}, [pdfDimensions.height, pdfDimensions.width, wrapperHeight, wrapperWidth]);

	const onPdfLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const updatePdfPageNumber = (action = "") => {
		if (action === "INCREASE") {
			if (pageNumber < numPages) setPageNumber((prevState) => prevState + 1);
		} else {
			if (pageNumber > 1) setPageNumber((prevState) => prevState - 1);
		}
	};

	const [hidePdf, setHidePdf] = useState(false);

	function onPassword(callback, reason) {
		function callbackProxy(password) {
			// Cancel button handler
			if (password === null) {
				// Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
				setHidePdf(true);
				return;
			}

			callback(password);
		}

		switch (reason) {
			case PasswordResponses.NEED_PASSWORD: {
				const password = prompt("Enter the password to open this PDF file.");
				callbackProxy(password);
				break;
			}
			case PasswordResponses.INCORRECT_PASSWORD: {
				const password = prompt("Invalid password. Please try again.");
				callbackProxy(password);
				break;
			}
			default:
		}
	}

	if (hidePdf)
		return (
			<div className={styles.password_container}>
				<p>The pdf is password protected</p>
				<GaawkButton
					severity={"secondary"}
					text={"Enter Password"}
					onClick={() => setHidePdf(false)}
				/>
			</div>
		);

	return (
		<div className={styles.pdf_container}>
			<Document
				file={file.url}
				onLoadSuccess={onPdfLoadSuccess}
				// onLoadError={() => alert("Error while loading document! ")}
				loading={loadingSpinner}
				error={<p style={{ color: "white" }}>Failed to load pdf file.</p>}
				onPassword={onPassword}
				onLoadError={(error) => console.log("ERROR")}
				onError={(e) => console.log(e)}
				// TODO ADD THE HANDLER FOR PROTECTED PDFs with 'onPassword'
			>
				<Page
					width={fitHorizontal ? wrapperWidth : null}
					height={!fitHorizontal ? wrapperHeight : null}
					onLoadSuccess={({ width, height }) => setPdfDimensions({ width, height })}
					pageNumber={pageNumber}
					renderAnnotationLayer={false}
					renderTextLayer={false}
				/>
			</Document>
			{numPages && (
				<div className={styles.page_controls}>
					<button type="button" onClick={updatePdfPageNumber}>
						‹
					</button>
					<span>
						{pageNumber} of {numPages}
					</span>
					<button type="button" onClick={() => updatePdfPageNumber("INCREASE")}>
						›
					</button>
				</div>
			)}
		</div>
	);
};

export default PdfReader;
