// import { useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import styles from "./LeftSide.module.scss";
import NavBar from "../NavBar/NavBar";
import { useSelector } from "react-redux";

const LeftSide = ({
	children,
	hybridNavigation = false,
	// rightProiority,
	hide,
	enableCollapsedNavBar = false,
}) => {
	const isMobile = useSelector((state) => state.ui.isMobile);

	return (
		<div
			className={classNames(styles.container, {
				[styles.hybridNav]: hybridNavigation,
				[styles.hide]: isMobile && hide,
				[styles.isMobile]: isMobile,
			})}
		>
			{enableCollapsedNavBar && !isMobile && <NavBar collapsed />}
			<div
				className={classNames(styles.left_panel_main_wrapper, {
					[styles.isMobile]: isMobile,
					[styles.navBarEnabled]: enableCollapsedNavBar,
				})}
			>
				<div className={`${styles.middle_container} ${hybridNavigation && styles.chat}`}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default LeftSide;
