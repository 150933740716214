import Tag from "components/Utils/SubComs/Tags/Tag";
import styles from "./PlanItem.module.scss";
import { classNames } from "primereact/utils";
import popularIcon from "images/popular.svg";
import { forwardRef, useMemo } from "react";
import { ReactComponent as FeatureIcon } from "images/featureIcon.svg";
import { packageTypes } from "components/Utils/General";
import GaawkButton from "components/Utils/Button/GaawkButton";

const ctaLabels = {
	current: "Current Plan",
	upgrade: {
		premium: "Upgrade to Premium",
		aaa: "Upgrade to AAA",
		custom: "Contact Us",
	},
	downgrade: {
		free: "Downgrade to Free",
		aaa: "Downgrade to AAA",
		premium: "Downgrade to Premium",
	},
};

const PlanItem = forwardRef(({ item, currentPlan, isHighlighted, onPlanClick }, ref) => {
	const { paymentPackageType: currentPackage } = currentPlan;

	const { paymentPackageType, features, price, description, offer } = item;

	const isCurrentPlan = currentPackage === paymentPackageType;
	const isBasicPlan = paymentPackageType === packageTypes.free;
	const isPremiumPlan = paymentPackageType === packageTypes.premium;
	const isAaaPlan = paymentPackageType === packageTypes.aaa;
	const isCustomPlan = paymentPackageType === packageTypes.custom;

	const ctaText = useMemo(() => {
		switch (paymentPackageType) {
			case packageTypes.free:
				if (currentPackage === packageTypes.free) {
					return ctaLabels.current;
				} else {
					return ctaLabels.downgrade.free;
				}

			case packageTypes.premium:
				if (currentPackage === packageTypes.free) {
					return ctaLabels.upgrade.premium;
				} else if (currentPackage === packageTypes.premium) {
					return ctaLabels.current;
				} else {
					return ctaLabels.downgrade.premium;
				}

			case packageTypes.aaa:
				if (
					currentPackage === packageTypes.free ||
					currentPackage === packageTypes.premium
				) {
					return ctaLabels.upgrade.aaa;
				} else if (currentPackage === packageTypes.aaa) {
					return ctaLabels.current;
				} else {
					return ctaLabels.downgrade.aaa;
				}

			case packageTypes.custom:
				if (
					currentPackage === packageTypes.free ||
					currentPackage === packageTypes.premium ||
					currentPackage === packageTypes.aaa
				) {
					return ctaLabels.upgrade.custom;
				} else {
					return ctaLabels.current;
				}

			default:
				break;
		}
	}, [paymentPackageType, currentPackage]);

	const featuresList = useMemo(
		() =>
			features.map((feature) => {
				return (
					<li key={feature.id}>
						<FeatureIcon
							className={classNames({
								[styles.basic_color]: isBasicPlan,
								[styles.premium_color]: isPremiumPlan,
								[styles.aaa_color]: isAaaPlan,
								[styles.custom_color]: isCustomPlan,
							})}
						/>
						<p>{feature.feature}</p>
					</li>
				);
			}),
		[features, paymentPackageType]
	);

	return (
		<div
			ref={ref}
			className={classNames(styles.container, {
				[styles.currentPlan]: isCurrentPlan,
				[styles.highlighted]: isHighlighted,
			})}
		>
			<div className={styles.tag_container}>
				<Tag
					itemName={
						isBasicPlan
							? "Basic"
							: isCustomPlan
							? "More"
							: isPremiumPlan
							? "Premium"
							: "AAA"
					}
					customStyle={classNames(styles.tag, {
						[styles.basic]: isBasicPlan,
						[styles.premium]: isPremiumPlan,
						[styles.aaa]: isAaaPlan,
						[styles.custom]: isCustomPlan,
					})}
				/>

				{paymentPackageType === packageTypes.premium && (
					<div className={styles.most_popular}>
						<p>Most popular</p>
						<img src={popularIcon} alt="popular" />
					</div>
				)}
			</div>

			<div className={styles.price_container}>
				{price === 0 && <p>Free</p>}
				{price === -1 && <p>Call</p>}
				{price > 0 && (
					<p>
						{price} <span>AED</span>
					</p>
				)}
			</div>

			<div className={styles.description}>
				<p>{description}</p>
			</div>
			<div className={styles.offer}>
				{(paymentPackageType === packageTypes.premium ||
					paymentPackageType === packageTypes.aaa) && <p>Billed monthly</p>}
				<p>{offer}</p>
			</div>

			<GaawkButton
				className={classNames(styles.button, {
					[styles.currentPlan]: isCurrentPlan,
				})}
				severity={isCurrentPlan ? "tertiary" : "primary"}
				onClick={isCurrentPlan ? undefined : onPlanClick}
				type={"button"}
				text={ctaText}
			/>

			<hr className={styles.separator} />

			<div className={styles.features_container}>
				<h5>Includes:</h5>
				<ul className={styles.features_list}>{featuresList}</ul>
			</div>
		</div>
	);
});

export default PlanItem;
