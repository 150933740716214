import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { softwareUpdated } from "../../../../store/slices/user";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./SoftwareEntry.module.scss";
import SoftwareItem from "./SoftwareItem";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Index = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { softwareList } = useCurrentUser();

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(profileApi.deleteSoftware, (response) => {
			trackEvent(eventsDictionary.PROFILE.DELETE_SOFTWARE);
			dispatch(softwareUpdated(response.data));
		});

	//! usememo ?

	const userSoftwareList = softwareList.map((software) => (
		<SoftwareItem
			key={software.uuid}
			item={software}
			onEditClicked={() =>
				navigate(routes.editSoftware, {
					state: software,
				})
			}
			onDelete={() => handleRequestMutate(software)}
		/>
	));

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.SOFTWARE_LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.button_wrapper}>
				<GaawkButton
					severity={"secondary"}
					text="+ Add A New Software"
					onClick={() => navigate(routes.addSoftware)}
				/>
			</div>

			{softwareList.length > 0 && (
				<>
					<h4>Software you have added</h4>
					<div className={styles.software_section}>{userSoftwareList}</div>
				</>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmMutate(activeItem.uuid)}
			/>
		</div>
	);
};

export default Index;
