import { useQueryClient } from "@tanstack/react-query";
import { calendarKeys } from "queryKeys/calendar-key-factory";
import { useState } from "react";
import calendarApi from "../api/calendar";
import useApi from "./useApi";
import { useCustomQuery } from "./useCustomQuery";
import useMutate from "./useMutate";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const useCalendarEntries = (profileId, enabled = true) => {
	const queryClient = useQueryClient();
	const getEntriesApi = useApi(calendarApi.getEntries, true, true);

	const fetchCalendarEntries = async ({ signal, queryKey }) => {
		const [_, profileId, date] = queryKey;
		const response = await getEntriesApi.request(
			date.getMonth(),
			date.getFullYear(),
			profileId
		);
		return response.data;
	};

	const [date, setDate] = useState(() => new Date());

	const { data = [] } = useCustomQuery({
		queryKey: calendarKeys.entiresByDate(profileId, date),
		queryFn: fetchCalendarEntries,
		enabled,
	});

	const handleMonthChange = (date) => {
		setDate(date);
	};

	const { handleRequestMutate, handleConfirmMutate, activeItem, warningModal, setWarningModal } =
		useMutate(calendarApi.deleteEntry, () => {
			trackEvent(eventsDictionary.PROFILE.DELETE_CALENDAR_EVENT);
			queryClient.invalidateQueries(calendarKeys.entiresByDate(profileId, date));
		});

	return {
		data,
		handleMonthChange,
		handleRequestMutate,
		handleConfirmMutate,
		activeItem,
		warningModal,
		setWarningModal,
	};
};

export default useCalendarEntries;
