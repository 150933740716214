import { useEffect } from "react";
import styles from "./ProfileSection.module.css";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import GaawkButton from "components/Utils/Button/GaawkButton";
import RoundButton from "components/Utils/Button/RoundButton";

const ProfileSection = ({
	show,
	loading,
	canEdit,
	label,
	data,
	onEdit,
	onAdd,
	btnText,
	infoText,
	trackFn,
}) => {
	useEffect(() => {
		if (show && !loading && trackFn) {
			trackFn();
		}
	}, [show, loading]);

	return (
		<>
			{loading && (
				// <div className={styles.container}>
				<ProfileTabContainer minHeight={false}>
					<h3>{label}</h3>
					<LoadingSpinner />
				</ProfileTabContainer>
				//</div>
			)}

			{show && !loading && (
				// <div className={styles.container}>
				<ProfileTabContainer minHeight={false}>
					<div className={styles.item_header}>
						<h3>{label}</h3>
						{canEdit && <RoundButton icon="edit" onClick={onEdit} />}
					</div>

					{data}
				</ProfileTabContainer>
				// </div>
			)}

			{!show && canEdit && !loading && (
				// <div className={styles.emptyContainer}>
				<ProfileTabContainer minHeight={false}>
					<h3>{label}</h3>

					{infoText && <div className={styles.emptyText}>{infoText}</div>}
					<div className={styles.emptyBtn}>
						<GaawkButton
							severity={"secondary"}
							onClick={onAdd}
							text={`+ Add ${btnText ?? label}`}
							className={styles.custom_btn}
						/>
					</div>
				</ProfileTabContainer>
				// </div>
			)}
		</>
	);
};

export default ProfileSection;
