import { useQueryClient } from "@tanstack/react-query";
import profileApi from "api/profile";
import { formatBytes, getStorageWarningText, showStorageWarning } from "components/Utils/General";
import StorageBar from "components/Utils/StorageBar/StorageBar";
import CustomCheckbox from "components/Utils/SubComs/Inputs/CustomCheckbox/CustomCheckbox";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useMutate from "hooks/useMutate";
import useVaultStorage from "hooks/useVaultStorage";
// import { classNames } from "primereact/utils";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { useState } from "react";
import { toast } from "react-toastify";
import styles from "./Storage.module.scss";
import StorageCard from "./StorageComponents/StorageCard";
import GaawkButton from "components/Utils/Button/GaawkButton";
// import { useNavigate } from "react-router-dom";
// import routes from "components/Routing/routing-keys";

const Storage = () => {
	const queryClient = useQueryClient();

	const {
		data,
		isFetching,
		totalStorage,
		systemFolderSpace,
		gallerySpace,
		vaultSpace,
		usedSpaceInPercentage,
	} = useVaultStorage();

	const {
		galleryImage,
		galleryPDF,
		galleryVideo,

		vaultAudio,
		vaultFiles,
		vaultImage,
		vaultPDF,
		vaultVideo,
	} = data;

	const initialValues = {
		gallery: {
			video: false,
			photo: false,
			pdf: false,
		},
		vault: {
			video: false,
			photo: false,
			files: false,
		},
	};

	const [checkboxValues, setCheckboxValues] = useState(initialValues);

	const hasAtLeastOneTrueValue = Object.values(checkboxValues).some((section) =>
		Object.values(section).some((value) => value === true)
	);

	const [isLoading, setIsLoading] = useState(false);

	//* GALLERY MUTATIONS
	const {
		action: { mutateAsync: cleanGalleryVideos },
	} = useMutate(profileApi.deleteGalleryVideoStorage);
	const {
		action: { mutateAsync: cleanGalleryImages },
	} = useMutate(profileApi.deleteGalleryImageStorage);
	const {
		action: { mutateAsync: cleanGalleryPdfs },
	} = useMutate(profileApi.deleteGalleryPdfStorage);

	//* VAULT MUTATIONS
	const {
		action: { mutateAsync: cleanVaultVideos },
	} = useMutate(profileApi.deleteVaultVideoStorage);
	const {
		action: { mutateAsync: cleanVaultImages },
	} = useMutate(profileApi.deleteVaultImageStorage);
	const {
		action: { mutateAsync: cleanVaultFiles },
	} = useMutate(profileApi.deleteVaultFileStorage);

	const handleCleanStorage = async () => {
		setIsLoading(true);

		try {
			await Promise.all(
				[
					checkboxValues.gallery.video ? cleanGalleryVideos() : null,
					checkboxValues.gallery.photo ? cleanGalleryImages() : null,
					checkboxValues.gallery.pdf ? cleanGalleryPdfs() : null,
					checkboxValues.vault.video ? cleanVaultVideos() : null,
					checkboxValues.vault.photo ? cleanVaultImages() : null,
					checkboxValues.vault.files ? cleanVaultFiles() : null,
				].filter(Boolean)
			);
		} catch (error) {
			//* Will simply show a toast notification if any error occurs in all mutations above
			toast.error("Something Wrong Happened");
		} finally {
			setIsLoading(false);
			queryClient.invalidateQueries(vaultKeys.storage());
			setCheckboxValues(initialValues);
		}
	};

	return (
		<div className={styles.container}>
			{isFetching ? (
				<LoadingSpinner customStyle={styles.loading_container} />
			) : (
				<>
					<StorageCard
						text={getStorageWarningText("homeSidebar", usedSpaceInPercentage, data)}
						showCleanBtn={false}
						className={styles.mb_10}
					/>

					<h4>Manage Storage</h4>
					<div className={styles.info}>“System folder” can't be managed from here.</div>

					<div className={styles.bar_container}>
						<StorageBar
							total={totalStorage}
							system={systemFolderSpace}
							gallery={gallerySpace}
							vault={vaultSpace}
						/>
					</div>

					<div className={styles.info}>
						**Note: “System folder” is storage for your product and service’s media, so
						it can’t be managed from here.
					</div>

					<div className={styles.spaces_container}>
						<h4>Clean up your spaces:</h4>

						<div className={styles.bordered_container}>
							<h4>Gallery</h4>
							{/* //* GALLERY */}
							<div className={styles.section_container}>
								<div className={styles.radio_button_wrapper}>
									<CustomCheckbox
										checked={checkboxValues.gallery.video}
										onChange={({ target }) => {
											setCheckboxValues((prevState) => ({
												vault: prevState.vault,
												gallery: {
													...prevState.gallery,
													video: target.checked,
												},
											}));
										}}
										id="videoGallery"
										customBackgroundColor={"gaawk"}
										marginRight="0px"
										isBorder={true}
									/>

									<label htmlFor="videoGallery">
										<span>Video</span>

										<span>{formatBytes(galleryVideo)}</span>
									</label>
								</div>

								<div className={styles.radio_button_wrapper}>
									<CustomCheckbox
										checked={checkboxValues.gallery.photo}
										onChange={({ target }) => {
											setCheckboxValues((prevState) => ({
												vault: prevState.vault,
												gallery: {
													...prevState.gallery,
													photo: target.checked,
												},
											}));
										}}
										id="imagesGallery"
										customBackgroundColor={"gaawk"}
										marginRight="0px"
										isBorder={true}
									/>

									<label htmlFor="imagesGallery">
										<span>Photos</span>

										<span>{formatBytes(galleryImage)}</span>
									</label>
								</div>

								<div className={styles.radio_button_wrapper}>
									<CustomCheckbox
										checked={checkboxValues.gallery.pdf}
										onChange={({ target }) => {
											setCheckboxValues((prevState) => ({
												vault: prevState.vault,
												gallery: {
													...prevState.gallery,
													pdf: target.checked,
												},
											}));
										}}
										id="pdfGallery"
										customBackgroundColor={"gaawk"}
										marginRight="0px"
										isBorder={true}
									/>

									<label htmlFor="pdfGallery">
										<span>PDFs</span>

										<span>{formatBytes(galleryPDF)}</span>
									</label>
								</div>
							</div>

							<h4>Vault</h4>
							{/* //* VAULT */}
							<div className={styles.section_container}>
								<div className={styles.radio_button_wrapper}>
									<CustomCheckbox
										checked={checkboxValues.vault.video}
										onChange={({ target }) => {
											setCheckboxValues((prevState) => ({
												vault: {
													...prevState.vault,
													video: target.checked,
												},
												gallery: prevState.gallery,
											}));
										}}
										id="videoVault"
										customBackgroundColor={"gaawk"}
										marginRight="0px"
										isBorder={true}
									/>

									<label htmlFor="videoVault">
										<span>Video</span>

										<span>{formatBytes(vaultVideo)}</span>
									</label>
								</div>

								<div className={styles.radio_button_wrapper}>
									<CustomCheckbox
										checked={checkboxValues.vault.photo}
										onChange={({ target }) => {
											setCheckboxValues((prevState) => ({
												vault: {
													...prevState.vault,
													photo: target.checked,
												},
												gallery: prevState.gallery,
											}));
										}}
										id="imagesVault"
										customBackgroundColor={"gaawk"}
										marginRight="0px"
										isBorder={true}
									/>

									<label htmlFor="imagesVault">
										<span>Photos</span>

										<span>{formatBytes(vaultImage)}</span>
									</label>
								</div>

								<div className={styles.radio_button_wrapper}>
									<CustomCheckbox
										checked={checkboxValues.vault.files}
										onChange={({ target }) => {
											setCheckboxValues((prevState) => ({
												vault: {
													...prevState.vault,
													files: target.checked,
												},
												gallery: prevState.gallery,
											}));
										}}
										id="vaultFiles"
										customBackgroundColor={"gaawk"}
										marginRight="0px"
										isBorder={true}
									/>

									<label htmlFor="vaultFiles">
										<span>Files</span>

										<span>
											{formatBytes(vaultAudio + vaultFiles + vaultPDF)}
										</span>
									</label>
								</div>
							</div>

							<GaawkButton
								text={"Clean Up"}
								className={styles.fullwidth}
								disabled={!hasAtLeastOneTrueValue}
								onClick={handleCleanStorage}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Storage;
