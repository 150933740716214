import profileApi from "api/profile";
import ProfilePic from "components/Home/ProfilePic";
import routes from "components/Routing/routing-keys";
import { format } from "date-fns";
import useMutate from "hooks/useMutate";
import verifiedIcon from "images/verified.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { experienceUpdated } from "../../../../store/slices/user";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./ExperienceItem.module.css";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import RoundButton from "components/Utils/Button/RoundButton";

const ExperienceItem = ({ item, showControls = true }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleEdit = (experienceItem, role) => () => {
		navigate(routes.editExperience, {
			state: {
				experienceItem: experienceItem,
				role: role,
			},
		});
	};

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(profileApi.deleteExperience, (response) => {
			trackEvent(eventsDictionary.PROFILE.DELETE_EXPERIENCE);
			dispatch(experienceUpdated(response.data));
		});

	const experienceRoles = item.roleList.map((role) => (
		<div key={role.uuid} className={styles.outer}>
			<div className={styles.experience_card}>
				<div className={styles.position_wrapper}>
					<div className={styles.position_info}>
						<span className={styles.position_title}>
							{role.title ? role.title : role.role.name}
						</span>

						<span className={styles.subData}>
							{`${role.locationDTO?.name}, ${role.locationDTO?.countryDTO.name}`}
						</span>

						<span className={styles.subData}>
							{`${format(new Date(role.startDate), "MMMM yyyy")} - ${
								role.endDate === 0 || role.currentPosition
									? "Present"
									: format(new Date(role.endDate), "MMMM yyyy")
							}`}
						</span>
					</div>

					{role.verified && (
						<img src={verifiedIcon} className={styles.verified_icon} alt="verified" />
					)}
				</div>
				{role.description && <p>{role.description}</p>}
				{showControls && (
					<div className={styles.controls}>
						<RoundButton icon="edit" onClick={handleEdit(item, role)} />
						<RoundButton
							icon="delete"
							onClick={() => handleRequestMutate({ uuid: item.uuid, role })}
						/>
					</div>
				)}
			</div>
		</div>
	));

	const companyName = item.companyName ? item.companyName : item.company ? item.company.name : "";

	return (
		<>
			<div className={styles.experience_item}>
				<div className={styles.experience_header}>
					{/* {item.company && item.company.profileImage ? (
						<img
							src={item.company.profileImage.image}
							className={styles.company_logo}
							alt=""
						/>
					) : (
						<div className={styles.experience_icon}></div>
					)} */}
					<ProfilePic
						type="COMPANY"
						thumbnail={item.company?.profileImage?.image}
						name={companyName}
						enableName={false}
					/>

					<div className={styles.company_details}>
						<span className={styles.company_position}>{companyName}</span>
						{/* <span className={styles.company_location}>
                            {item.company && item.company.city.name}
                        </span> */}
					</div>
				</div>
				<div className={styles.position_details}>{experienceRoles}</div>
			</div>

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						experienceId: activeItem.uuid,
						roleId: activeItem.role.uuid,
					})
				}
			/>
		</>
	);
};

export default ExperienceItem;
