import { useQuery } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useGetQueryParam from "hooks/useGetQueryParam";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import specialProfileApi from "../../api/special-profile";
import SpecialProfile from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfile";
import AdditionalDetailsEntry from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfileForms/AdditionalDetailsEntry/AdditionalDetailsEntry";
import GeneralDetailsEntry from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfileForms/GeneralDetailsEntry/GeneralDetailsEntry";
import MediaEntry from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfileForms/MediaEntry/MediaEntry";
import PhysicalDetailsEntry from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfileForms/PhysicalDetailsEntry/PhysicalDetailsEntry";
import SpecialBaseEntry from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfileForms/SpecialBaseEntry/SpecialBaseEntry";
import VocalDetailsEntry from "../../components/Profile/ProfileEdit/SpecialProfile/SpecialProfileForms/VocalDetailsEntry/VocalDetailsEntry";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import LoadingPage from "../../components/Utils/SubComs/CustomLoader/LoadingPage";
import useApi from "../../hooks/useApi";
import styles from "../Header.module.css";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const SpecialProfileView = () => {
	const navigate = useNavigate();
	const { userUrl } = useParams();
	const { pathname } = useLocation();

	const { uuid } = useCurrentUser();

	const getSpecialUrlApi = useApi(specialProfileApi.getSpecialByUrl, true, true);

	// const [searchParams, setSearchParams] = useSearchParams();
	// const agencyId = searchParams.get("agency") || "";
	const { paramValue } = useGetQueryParam("agency");

	const [canEdit, setCanEdit] = useState(undefined);

	const fetchSpecialProfile = async () => {
		const response = await getSpecialUrlApi.request(userUrl, paramValue);
		return response.data;
	};

	const {
		isLoading,
		isError,
		data: specialProfile = {},
		isFetched,
	} = useQuery({
		queryKey: specialProfileKeys.detail(userUrl), //TODO >> amend query to accept agencyId ?
		queryFn: fetchSpecialProfile,

		// staleTime: 300000, // 5 minutes
	});

	useEffect(() => {
		if (isFetched && !isLoading) {
			setCanEdit(specialProfile?.owner?.uuid === uuid);
		}
	}, [specialProfile, uuid, isLoading, isFetched]);

	const protectedRoutes = [
		routes.specialProfile(userUrl, "/edit"),
		routes.specialProfile(userUrl, "/edit/more"),
		routes.specialProfile(userUrl, "/edit/vocal"),
		routes.specialProfile(userUrl, "/edit/media"),
		routes.specialProfile(userUrl, "/edit/physical"),
		routes.specialProfile(userUrl, "/edit/general"),
	];

	let component;
	let headerTitle;

	switch (pathname) {
		case routes.specialProfile(userUrl):
			component = <SpecialProfile profile={specialProfile} canEdit={canEdit} />;
			headerTitle = "Special Profile";
			break;
		case routes.specialProfile(userUrl, "/edit"):
			component = <SpecialBaseEntry editMode={true} profile={specialProfile} />;
			headerTitle = "Edit Special Profile";
			break;
		case routes.specialProfile(userUrl, "/edit/general"):
			component = <GeneralDetailsEntry profile={specialProfile} />;
			headerTitle = "General Details";
			break;
		case routes.specialProfile(userUrl, "/edit/physical"):
			component = <PhysicalDetailsEntry profile={specialProfile} />;
			headerTitle = "Physical Details";
			break;
		case routes.specialProfile(userUrl, "/edit/more"):
			component = <AdditionalDetailsEntry profile={specialProfile} />;
			headerTitle = "Additional Details";
			break;
		case routes.specialProfile(userUrl, "/edit/vocal"):
			component = <VocalDetailsEntry profile={specialProfile} />;
			headerTitle = "Vocal Details";
			break;
		case routes.specialProfile(userUrl, "/edit/media"):
			component = <MediaEntry profile={specialProfile} />;
			headerTitle = "Media";
			break;

		default:
			break;
	}

	if (isLoading) return <LoadingPage fullPage={true} />;

	if (isError) return <Navigate to="/profile" />;

	if (protectedRoutes.includes(pathname) && canEdit === false)
		return <Navigate to={routes.specialProfile(userUrl)} />;

	if (
		(protectedRoutes.includes(pathname) && canEdit === true) ||
		!protectedRoutes.includes(pathname)
	)
		return (
			<DefaultPage
				headerRightContent={
					<div className={styles.header}>
						<BackButton onBack={() => navigate(-1)} />
						{headerTitle}
					</div>
				}
				leftSideChildren={<NavBar />}
				rightSideChildren={component}
				rightSideBgColor={"#FFF"}
			/>
		);
};

export default SpecialProfileView;
