import {
	Link,
	//  Navigate,
	useNavigate,
	//   useParams
} from "react-router-dom";
import { copyToClipboard } from "../../../Utils/General";
import { breadcrumbTrail } from "./breadCrumbTrail";
import styles from "./ServiceDetails.module.scss";
import {
	//  useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import ServiceItem from "../ServicesComponent/ServiceItem";
import { useDispatch } from "react-redux";
import WhiteOptions from "images/three-dots-white-5-x-20.svg";
import LazyImage from "../../../Utils/LazyImage/LazyImage";
import servicesApi from "../../../../api/services";
import ReadMore from "../../../Utils/SubComs/ReadMore/ReadMore";
import postApi from "../../../../api/post";
import ForwardIcon from "images/icon-navigate-forward@3x.png";
import { useRef, useState, useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import ProductItem from "../ServicesComponent/ProductItem";
// import MediaItem from "../../../Utils/MediaItem/MediaItem";
import useEditSubPost from "../../../../hooks/useEditSubPost";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import EditMediaModal from "../../../Home/EditMediaModal";
import CreatePost from "../../../Home/CreatePost";
import FileSaver from "file-saver";
import ServiceEntry from "../ServiceAdd/ServiceEntry";
import PostViewer from "../../GalleryTab/PostViewer";
// import { replaceItemInArray } from "../../../Utils/General";
import {
	setService,
	setStep,
	setModal,
	// setEditMode,
} from "../../../../store/slices/service";
import useDirectMessage from "../../../../hooks/useDirectMessage";
import useApi from "../../../../hooks/useApi";
import useMutate from "../../../../hooks/useMutate";
import { servicesKeys } from "queryKeys/services-key-factory";
import { useDeleteProduct } from "components/Profile/ProductsTab/hooks/useDeleteProduct";
import { setModalProduct, setProduct } from "store/slices/products";
import { BackdropLoading } from "components/Profile/ProductsTab/ProductsComponents/BackDropLoading";
// import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
// import useUpdateReaction from "hooks/useUpdateReaction";
import { produce } from "immer";
import ServicesTree from "../ServicesComponent/ServicesTree";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import GalleryContainer from "components/Utils/SubComs/GalleryContainer/GalleryContainer";
import ServiceProductMediaItem from "components/Utils/MediaItem/ServiceProductMediaItem";
import ProductEntry from "components/Profile/ProductsTab/ProductEntry/ProductEntry";
import useCurrentUser from "hooks/useCurrentUser";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import RoundButton from "components/Utils/Button/RoundButton";

const PAGE_MEDIA = 0;
const MEDIA_SIZE = 14;

const ServiceDetails = ({ data }) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const postRef = useRef(null);
	const reportRef = useRef();

	// const popoverRef = useRef(null);

	const [serviceToDelete, setServiceToDelete] = useState(undefined);

	const [showSlider, setShowSlider] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	//TODO >> replace with useCurrentUser() custom hook;
	// const companyId = useCompanyId();
	// const { uuid: userId, ...profile } = useSelector((state) => {
	// 	if (companyId) {
	// 		return state.company.companyInfo;
	// 	} else {
	// 		return state.user.profileInfo;
	// 	}
	// });

	const { uuid: userId, ...profile } = useCurrentUser();

	const {
		headerStyle,
		image: { file: { customName: serviceFileName } = {} } = {},
		parents,
		name: serviceName,
		tag: serviceTag,
		description: serviceDescription,
		minifiedProfile: { uuid: ownerId } = {},
		minifiedProfile,
		tagMedia,
		tagProducts,
		products,
		uuid: serviceId,
		level,
		hasChildren,
		locations,
		worldWide,
	} = data;

	useEffect(() => {
		trackEvent(eventsDictionary.SERVICE.DETAILS, {
			serviceId,
		});
	}, [serviceId]);

	const locationNames = locations
		?.map((location) => (location.name ? location?.name : location?.countryDTO?.name))
		.join(", ");

	const isHorizontal = headerStyle === "HORIZONTAL";

	const breadCrumb = breadcrumbTrail(parents);

	const { handleDirectMessage } = useDirectMessage();

	const [showWarningModal, setShowWarningModal] = useState(false);

	const [subServices, setSubServices] = useState();

	const ContentButtons = () => {
		return ownerId !== userId ? (
			<div className={styles.enquire_btn_wrapper}>
				<GaawkButton
					text={"Enquire This Service"}
					className={styles.update_btn}
					//! Take the user to messages
					onClick={() => {
						trackEvent(eventsDictionary.SERVICE.ENQUIRE, { serviceId });
						handleDirectMessage(minifiedProfile);
					}}
				/>
			</div>
		) : (
			<div className={styles.enquire_btn_wrapper}>
				<RoundButton
					icon={"edit"}
					onClick={() => {
						dispatch(setService(data));
						dispatch(setStep(2));
						dispatch(setModal(true));
						// dispatch(setEditMode(true));
					}}
				/>
				<RoundButton
					icon="delete"
					onClick={() => {
						setShowWarningModal(true);
					}}
				/>
			</div>
		);
	};

	const {
		handleConfirmMutate: handleConfirmDeleteProduct,
		handleRequestMutate: handleRequestDeleteProduct,
		warningModal: warningModalProduct,
		setWarningModal: setWarningModalProduct,
		isLoading: isLoadingProductDelete,
		activeItem: activeProductItem,
	} = useDeleteProduct(false);

	const handleEditProduct = (product) => {
		dispatch(setProduct(product));
		dispatch(setModalProduct(true));
	};

	const handleDeleteService = (service) => {
		setServiceToDelete(service);
		setShowWarningModal(true);
	};

	const handleShareMedia = (media) => {
		postRef.current.sharePost(media);
	};

	const handleCopyLink = (type, id) => {
		copyToClipboard(`${window.location.origin}/${type}/${id}`);
		toast.success("Link copied !");
	};

	const handleDownload = (media = undefined) => {
		const postMedia = media ? media : filesArray[activeSlideIndex];
		FileSaver.saveAs(`${postMedia.url}`, `${postMedia.originalName}`);
	};
	const handleShowSlider = (media) => {
		const fileIndex = serviceMedias.findIndex((item) => item === media);
		setInitialSlideIndex(fileIndex);
		setShowSlider(true);
		window.history.pushState(null, "", `/post/${media.uuid}`);
	};

	const handleCloseSlider = () => {
		setShowSlider(false);
		window.history.replaceState(null, "", `/services/${serviceId}`);
	};

	const handlePostUpdate = (reaction) => {
		const queryKey = servicesKeys.countableMedias(serviceId);

		const previousState = queryClient.getQueryData(queryKey);

		const updatedData = produce(previousState, (draftData) => {
			draftData[0]["reactionStats"] = reaction.reactionStats;
			draftData[0]["profileReactionType"] = reaction.profileReactionType;
		});

		queryClient.setQueryData(queryKey, updatedData);
	};

	const memoizedProductItems = useMemo(() => {
		return products?.map((product) => (
			<ProductItem
				optionsIcon={WhiteOptions}
				key={product.uuid}
				isOwner={userId === ownerId}
				onDelete={() => handleRequestDeleteProduct(product)}
				onEdit={() => handleEditProduct(product)}
				onMessage={() => handleDirectMessage(product.owner)}
				onCopy={() => handleCopyLink("product", product.uuid)}
				item={product}
			/>
		));
	}, [products, userId, ownerId]);

	// ! ===== GET MEDIA ======

	const getServiceMediaApi = useApi(postApi.getServiceMedia, true, true);
	const fetchServiceMedia = async ({ queryKey }) => {
		const [, , , serviceUuid] = queryKey;
		const response = await getServiceMediaApi.request(serviceUuid, PAGE_MEDIA, MEDIA_SIZE);
		return response.data;
	};
	const { data: serviceMedias, isFetched: isFetchedMedias } = useQuery({
		queryKey: servicesKeys.countableMedias(serviceId),
		queryFn: fetchServiceMedia,
		enabled: !!data,
	});

	const filesArray = useMemo(() => serviceMedias?.map((item) => item.file), [serviceMedias]);

	useEffect(() => {
		if (showSlider && activeSlideIndex > -1) {
			window.history.replaceState(null, "", `/post/${serviceMedias[activeSlideIndex].uuid}`);
		} else {
			handleCloseSlider();
		}
	}, [activeSlideIndex, serviceMedias]);

	//! ==== DELETE SERVICE ====
	// const deleteServiceApi = useApi(servicesApi.deleteService, true, true);

	// const deleteService = useMutation({
	// 	mutationFn: () => {
	// 		return deleteServiceApi.request(serviceId);
	// 	},
	// 	onSuccess: () => {
	// 		if (data.level === 3) {
	// 			queryClient.invalidateQueries(
	// 				servicesKeys.serviceLvl(3, ownerId, data.parents.p2.uuid)
	// 			);
	// 		} else if (data.level === 2) {
	// 			queryClient.invalidateQueries(
	// 				servicesKeys.serviceLvl(2, ownerId, data.parents.p3.uuid)
	// 			);
	// 		} else if (data.level === 1) {
	// 			queryClient.invalidateQueries(
	// 				servicesKeys.serviceLvl(1, ownerId)
	// 			);
	// 		}

	// 		queryClient.removeQueries(servicesKeys.detail(serviceId));

	// 		navigate("/profile", { replace: true });
	// 	},
	// 	onError: () => toast.error("Failed to delete the service"),
	// });

	const {
		action: { mutate: deleteService },
	} = useMutate(
		servicesApi.deleteService,
		() => {
			trackEvent(eventsDictionary.SERVICE.DELETE_SERVICE);

			if (serviceToDelete) {
				if (serviceToDelete.level === 2) {
					queryClient.invalidateQueries(
						servicesKeys.serviceLvl(2, ownerId, serviceToDelete.parents.p3.uuid, false)
					);
				} else if (serviceToDelete.level === 3) {
					queryClient.invalidateQueries(
						servicesKeys.serviceLvl(3, ownerId, serviceToDelete.parents.p2.uuid, false)
					);
				}

				queryClient.invalidateQueries(servicesKeys.detail(serviceId));
				setServiceToDelete(undefined);
				setShowWarningModal(false);
			} else {
				if (data.level === 3) {
					queryClient.invalidateQueries(
						servicesKeys.serviceLvl(3, ownerId, data.parents.p2.uuid, false)
					);
				} else if (data.level === 2) {
					queryClient.invalidateQueries(
						servicesKeys.serviceLvl(2, ownerId, data.parents.p3.uuid, false)
					);
				} else if (data.level === 1) {
					queryClient.invalidateQueries(servicesKeys.serviceLvl(1, ownerId, "", false));
				}

				queryClient.removeQueries(servicesKeys.detail(serviceId));
				navigate("/profile", { replace: true });
			}
		},
		undefined,
		{
			meta: {
				successMessage: "Service deleted successfully",
				errorMessage: "Failed to delete the service",
			},
		}
	);

	// !=========== DELETE MEDIA ===============

	const invalidateMedias = () => {
		queryClient.invalidateQueries(servicesKeys.countableMedias(serviceId));
	};

	const handleDeleteMediaSuccess = () => {
		invalidateMedias();
		if (showSlider && serviceMedias.length === activeSlideIndex + 1) {
			//* decrement activeSlideIndex by 1 when you're deleting the last subPost
			setActiveSlideIndex((prevState) => prevState - 1);
		}
	};

	const { warningModal, setWarningModal, handleRequestMutate, activeItem, handleConfirmMutate } =
		useMutate(postApi.deletePost, handleDeleteMediaSuccess);

	// ! edit media ================

	// const updateMediaApi = useApi(postApi.updateMedia, true);

	const [showEditMediaModal, setShowEditMediaModal] = useState(false);
	const [editItem, setEditItem] = useState("");

	const handleShowEditMediaModal = (media) => {
		setShowEditMediaModal(true);
		setEditItem(media);
	};

	const { handleEdit } = useEditSubPost(postApi.updateMedia, invalidateMedias);

	const servicesListFullView = subServices?.pages.map((page) =>
		page.map((service, index) => {
			return (
				<Link
					key={service.uuid}
					className={styles.service_full_view_item}
					to={`/services/${service.uuid}`}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<ServiceItem
						item={service}
						onDelete={() => handleDeleteService(service)}
						canEdit={userId === ownerId}
					/>
				</Link>
			);
		})
	);

	return (
		<>
			<div className={styles.header_wrapper}>
				<div
					className={`${
						isHorizontal ? styles.header_container : styles.header_container_vertical
					}`}
				>
					{serviceFileName && (
						<div className={isHorizontal ? styles.image_container : undefined}>
							<LazyImage
								image={<img src={serviceFileName} alt={serviceFileName} />}
							/>
							{isHorizontal && level !== 1 && (
								<div className={styles.breadcrumb_BG}>
									<span className={styles.breadcrumb_container}>
										{breadCrumb}
									</span>
								</div>
							)}
						</div>
					)}
					{!isHorizontal && level !== 1 && (
						<div className={styles.breadcrumb_BG}>
							<span className={styles.breadcrumb_container}>{breadCrumb}</span>
						</div>
					)}

					<div
						className={`${
							isHorizontal ? styles.content_wrapper : styles.content_wrapper_vertical
						}`}
					>
						<span className={styles.service_name}>{serviceName}</span>
						<div className={styles.tag_container}>
							<span className={styles.tag}>+{serviceTag}</span>
						</div>
						<ReadMore customStyle={styles.description} lines={2}>
							{serviceDescription}
						</ReadMore>

						{worldWide && (
							<span className={styles.locations_wrapper}>Available Worldwide</span>
						)}

						{locations?.length > 0 && (
							<span className={styles.locations_wrapper}>
								Available in: {locationNames}
							</span>
						)}
					</div>
				</div>
				<ContentButtons />
			</div>
			<div className={styles.products_medias_wrapper}>
				{tagProducts && products?.length > 0 && (
					<div className={styles.section}>
						<h3>Products ({products?.length})</h3>
						<GalleryContainer>{memoizedProductItems}</GalleryContainer>
						{/* <div className={styles.items_wrapper}></div> */}

						<Link
							to={`/services/${serviceId}/products`}
							className={styles.container_link}
						>
							<span className={styles.view_all}>View All</span>
							<img
								src={ForwardIcon}
								className={styles.forward_icon}
								alt="All Products"
							/>
						</Link>
					</div>
				)}
				{isFetchedMedias && serviceMedias.length > 0 && tagMedia && (
					<div className={styles.section}>
						<h3>Tagged Posts</h3>
						<GalleryContainer>
							{serviceMedias?.map((media) => (
								<ServiceProductMediaItem
									key={media.uuid}
									media={media}
									onClick={() => handleShowSlider(media)}
									onEdit={() => handleShowEditMediaModal(media)}
									// onDownload={() =>
									// 	handleDownload(media.file)
									// }
									onCopy={() => handleCopyLink("post", media.uuid)}
									onShare={() => handleShareMedia(media)}
									onDelete={() => handleRequestMutate(media)}
									onReport={() => reportRef.current.showReportModal(media.uuid)}
								/>
							))}
						</GalleryContainer>

						<Link
							to={`/services/${serviceId}/medias`}
							className={styles.container_link}
						>
							<span className={styles.view_all}>View All</span>
							<img
								src={ForwardIcon}
								className={styles.forward_icon}
								alt="All Medias"
							/>
						</Link>
					</div>
				)}

				{hasChildren && (
					<>
						<div className={styles.section}>
							<h3
								className={styles.sub_services_txt}
							>{`Sub-Services in ${serviceName}`}</h3>

							<ServicesTree
								serviceDetailsView={true}
								data={data}
								parentId={serviceId}
								serviceLvl={level + 1}
								onServicesLoad={setSubServices}
							/>
						</div>

						<div className={styles.services_container}>{servicesListFullView}</div>
					</>
				)}
			</div>
			<CreatePost
				ref={postRef}
				name={
					profile.type === "COMPANY"
						? profile.name
						: `${profile.firstName} ${profile.lastName}`
				}
				profileImg={profile.profileImage}
				type={profile.type}
				onCreate={() => toast.success("Post successfully shared!")}
			/>
			{serviceMedias && (
				<PostViewer
					show={showSlider}
					onClose={handleCloseSlider}
					post={serviceMedias[activeSlideIndex]}
					files={filesArray}
					isServiceDetails={true}
					postName={serviceName}
					initialSlideIndex={initialSlideIndex}
					onActiveSlide={(index) => {
						setActiveSlideIndex(index);
					}}
					onPostUpdate={handlePostUpdate}
					isOwner={userId === serviceMedias[activeSlideIndex]?.owner.uuid}
					onEdit={(post) => handleShowEditMediaModal(post)}
					onDelete={(post) => handleRequestMutate(post)}
					onShare={(post) => handleShareMedia(post)}
					onDownload={handleDownload}
				/>
			)}
			<WarningModal
				show={showWarningModal}
				modalOnTop={false}
				headerText="Are you sure you want to delete this Service? All associated sub-services will be deleted as well."
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setShowWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					deleteService(serviceToDelete ? serviceToDelete.uuid : serviceId)
				}
			/>
			<WarningModal
				show={warningModal}
				modalOnTop={false}
				headerText="Are you sure you want to delete this post?"
				warningText={
					<>
						Deleting this post will also be deleted from its parent post.
						<br />
						<br />
						This action cannot be undone.
					</>
				}
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmMutate(activeItem.uuid)}
			/>
			<WarningModal
				show={warningModalProduct}
				modalOnTop={true}
				headerText="Are you sure you want to delete this product?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModalProduct(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmDeleteProduct(activeProductItem.uuid)}
			/>
			<EditMediaModal
				show={showEditMediaModal}
				onClose={() => setShowEditMediaModal(false)}
				onReset={() => setEditItem("")}
				item={editItem}
				onConfirm={handleEdit}
				onToggle={(bool) => setShowEditMediaModal(bool)}
				// isEditing={true}
			/>

			<ServiceEntry />

			<ProductEntry />
			<BackdropLoading isVisible={isLoadingProductDelete} />
			<ReportModal ref={reportRef} category="post" />
		</>
	);
};

export default ServiceDetails;
