import { entryTypes } from "../../Utils/General";
import styles from "./EventItem.module.css";
import { format } from "date-fns";
import RoundButton from "components/Utils/Button/RoundButton";

const EventItem = ({ event, onEdit, onDelete }) => {
	return (
		<div className={styles.container}>
			<div className={styles.title}>
				<div
					className={styles.color_pin}
					style={{
						backgroundColor:
							event.type === "Booked"
								? "#ffad5c"
								: event.type === "Penciled"
								? "#83d1ad"
								: "#a6b1bc",
					}}
				/>
				<h4>{entryTypes.find((entry) => entry.value === event.type).label}</h4>
			</div>
			<div className={styles.date_container}>
				{format(new Date(event.startTime), "dd MMM. yyyy")} -{" "}
				{format(new Date(event.endTime), "dd MMM. yyyy")}
			</div>
			<div className={styles.label_container}>{event.label}</div>
			<div className={styles.notes_container}>{event.notes}</div>
			<div className={styles.button_container}>
				<RoundButton icon={"edit"} onClick={onEdit} />
				<RoundButton icon={"delete"} onClick={onDelete} />
			</div>
		</div>
	);
};

export default EventItem;
