import styles from "./SearchInput.module.css";
import ClearIcon from "images/cross-grey-14-x-14@3x.png";
// import Flag from "images/flag-search-uae-46-x-25@3x.png";
import Search from "images/magnifyingGrey.svg";
import { classNames } from "primereact/utils";

// import useWindowSize from "../../../../../hooks/useWindowSize";

const SearchInput = ({
	value,
	onChange,
	onKeyUp,
	onClearClicked,
	// onFlagClicked,
	onSubmitSearch,
	// isAdvanced = false,
	showIcons,
	placeholder = "Search...",
	border = true,
	borderRadius,
	searchRef,
	// flag,
	classNameInput,

	// isServiceTab = false,
}) => {
	return (
		<>
			{/* ${
					isAdvanced
						? styles.search_wrapper
						: `${styles.search_wrapper} ${styles.chat}` */}
			<div
				className={classNames(styles.search_wrapper, {
					[styles.with_controls]: showIcons,
				})}
			>
				{/* {value === "" && isAdvanced && (
					<label htmlFor="search-bar">
						Search <span>gaawk</span>
					</label>
				)} */}
				<input
					id="search-bar"
					ref={searchRef}
					onChange={onChange}
					onKeyUp={onKeyUp}
					value={value}
					type="text"
					className={`${styles.input} ${classNameInput}`}
					placeholder={placeholder}
					style={{
						border: `${!border && "none"}`,
						borderRadius: borderRadius ? `${borderRadius}` : undefined,
					}}
				/>
				{showIcons && (
					<>
						{value && (
							<div className={styles.clear_wrapper}>
								<img src={ClearIcon} alt="Clear" onClick={onClearClicked} />
							</div>
						)}

						<div className={styles.search_wrapper_icon}>
							<img src={Search} onClick={onSubmitSearch} alt="Search" />
						</div>
					</>
				)}
			</div>
			{/* {isAdvanced && (
				<div className={styles.advanced_search}>

					{width < 640 && (
						<>

							<img
								src={flag}
								className={styles.flag}
								onClick={onFlagClicked}
								alt="Flag"
							/>

						</>
					)}
				</div>
			)} */}
		</>
	);
};

export default SearchInput;
