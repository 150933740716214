import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addressUpdated as companyAddressUpdated } from "../../../../../store/slices/company";
import { addressUpdated } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Address.module.scss";
import AddressItem from "./AddressItem";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Address = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { addresses, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(
			editCompany ? profileApi.deleteCompanyAddress : profileApi.deleteAddress,
			(response, variables) => {
				trackEvent(eventsDictionary.PROFILE.DELETE_ADDRESS);
				const { companyId } = variables || {};
				dispatch(
					companyId ? companyAddressUpdated(response.data) : addressUpdated(response.data)
				);
			}
		);

	const addressesList = useMemo(
		() =>
			addresses.map((address) => (
				<AddressItem
					key={address.uuid}
					item={address}
					onEditClicked={() =>
						navigate(routes.editAddress, {
							state: address,
						})
					}
					onDelete={() => handleRequestMutate(address)}
				/>
			)),
		[addresses]
	);

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.ADDRESS_LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.button_wrapper}>
				<GaawkButton
					severity={"secondary"}
					text="+ Add A New Address"
					onClick={() => navigate(routes.addAddress)}
				/>
			</div>

			{addresses.length > 0 && (
				<>
					<h4>Your Addresses</h4>
					<div className={styles.addresses_section}>{addressesList}</div>
				</>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						...(editCompany && { companyId }),
						addressId: activeItem.uuid,
					})
				}
			/>
		</div>
	);
};

export default Address;
