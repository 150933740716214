import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import useCurrentUser from "hooks/useCurrentUser";
import useFetchProfile from "hooks/useFetchProfile";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateProfileTab } from "store/slices/activeTab";
import DefaultPage from "../Utils/PageArchs/DefaultPage/DefaultPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import ServicesTree from "./ServicesTab/ServicesComponent/ServicesTree";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";
import routes from "components/Routing/routing-keys";

const Index = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const activeTab = useSelector((state) => state.activeTab.profileTab);

	const { uuid: profileId } = useCurrentUser();
	const { profileData: profile, isFetching, canEdit, isProfileSuccess } = useFetchProfile();

	const tabClicked = (e) => {
		dispatch(updateProfileTab(e.target?.innerHTML || e));
	};

	useEffect(() => {
		if (isProfileSuccess) {
			trackEvent(eventsDictionary.PROFILE.DETAIL, {
				isOwnProfile: profile.uuid === profileId,
				requested_profile_url: profile.url,
				requested_profile_id: profile.uuid,
				requested_profile_type: profile.type,
				requested_profile_name:
					profile.type === "company"
						? profile.name
						: `${profile.firstName} ${profile.lastName}`,
			});
		}
	}, [isProfileSuccess]);

	const leftSidePanel = useMemo(() => {
		if (activeTab === "services" && profile?.hasServices) {
			return (
				<div style={{ padding: "20px" }}>
					<ServicesTree userId={profile?.uuid} enabled={true} />
				</div>
			);
		} else if (activeTab === "products" && profile?.productsHasServices) {
			return (
				<div style={{ padding: "20px" }}>
					<ServicesTree
						showServicesWithProducts={true}
						userId={profile?.uuid}
						enabled={true}
					/>
				</div>
			);
		} else {
			return <NavBar />;
		}
	}, [activeTab, profile?.hasServices, profile?.productsHasServices, profile?.uuid]);

	return (
		<DefaultPage
			forceShowLeftScroll={true}
			leftSideChildren={leftSidePanel}
			rightSideChildren={
				profile && (
					<ProfilePage
						userInfo={profile}
						onItemClicked={tabClicked}
						canEdit={canEdit}
						onClickEditProfilePic={() => navigate(routes.editProfilePic)}
						onClickEditCoverPic={() => navigate(routes.editCoverPic)}
						onClickEdit={() => navigate(routes.editProfile)}
					/>
				)
			}
		/>
	);
};

export default Index;
