import { useNavigate } from "react-router-dom";
import styles from "./Experience.module.scss";
import ExperienceItem from "./ExperienceItem";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import { useEffect } from "react";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Index = () => {
	const navigate = useNavigate();

	const { experiences } = useCurrentUser();

	const userExperiences = experiences.map((exp) => <ExperienceItem key={exp.uuid} item={exp} />);

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.EXPERIENCE_LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.button_wrapper}>
				<GaawkButton
					severity={"secondary"}
					text="+ Add A New Experience"
					onClick={() => navigate(routes.addExperience)}
				/>
			</div>

			{experiences.length > 0 && (
				<>
					<h4>Experiences you have added</h4>
					<div className={styles.experience_section}>{userExperiences}</div>
				</>
			)}
		</div>
	);
};

export default Index;
