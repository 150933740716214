import { useLocation, useNavigate } from "react-router-dom";
import CalendarEntry from "../../components/Profile/WorkStatusTab/CalendarEntry";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "../Header.module.css";
import routes from "components/Routing/routing-keys";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const CalendarEntryView = () => {
	const navigate = useNavigate();

	const { pathname } = useLocation();

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} />
					{pathname === routes.calendarAdd ? "Add to Calendar" : "Edit Calendar"}
				</div>
			}
			leftSideChildren={<NavBar />}
			rightSideChildren={<CalendarEntry />}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default CalendarEntryView;
