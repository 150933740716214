import { useNavigate } from "react-router-dom";
import EditCalendar from "../../components/Profile/WorkStatusTab/EditCalendar";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "../Header.module.css";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import { useEffect } from "react";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const CalendarView = () => {
	const navigate = useNavigate();

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.CALENDAR_DETAILS);
	}, []);

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> Calendar
				</div>
			}
			leftSideChildren={<NavBar />}
			rightSideBgColor={"#FFFFFF"}
			rightSideChildren={<EditCalendar />}
		/>
	);
};

export default CalendarView;
