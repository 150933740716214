import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import workStatusApi from "../../../api/workStatus";
import useApi from "../../../hooks/useApi";
import ProfileTabContainer from "../../Utils/SubComs/ProfileTab/ProfileTabContainer";
import MyCalendar from "./MyCalendar/MyCalendar";
import styles from "./WorkStatus.module.css";
import WorkStatusItem from "./WorkStatusItem/WorkStatusItem";
import openCalendar from "images/open-calendar.svg";
import { employementTypes } from "../../Utils/General";
import useCalendarEntries from "../../../hooks/useCalendarEntries";
import routes from "components/Routing/routing-keys";
import { useCustomQuery } from "hooks/useCustomQuery";
import { workStatusKeys } from "queryKeys/workStatus-key-factory";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const WorkStatus = ({ canEdit, userInfo }) => {
	const navigate = useNavigate();

	const { uuid: profileId, calendarEnabled } = userInfo;

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.WORK_STATUS_TAB);
	}, []);

	const getWorkStatusApi = useApi(workStatusApi.getWorkStatus, true, true);

	const fetchWorkStatus = async ({ queryKey }) => {
		const [_, profileId] = queryKey;
		const response = await getWorkStatusApi.request(profileId);
		return response.data;
	};

	const { data: workStatus, isLoading } = useCustomQuery({
		queryKey: workStatusKeys.getWorkStatus(profileId),
		queryFn: fetchWorkStatus,
	});

	const {
		available,
		roles = [],
		types = [],
		locations = [],
		legalCountries = [],
		workplace_office,
		workplace_remote,
		workplace_hybrid,
	} = workStatus || {};

	// ! =========== LISTS =============

	const roleItems = useMemo(
		() => roles.map((role) => (role.roleDTO ? role.roleDTO.name : role.roleName)),
		[roles]
	);

	const workplaceItems = [
		workplace_office && "On-site",
		workplace_remote && "Remote",
		workplace_hybrid && "Hybrid",
	].filter(Boolean);

	const jobItems = useMemo(
		() => types.map((type) => employementTypes.find((jobType) => jobType.value === type).label),
		[types]
	);

	const locationsItems = useMemo(
		() => locations.map((location) => `${location.name}, ${location.countryDTO.name}`),
		[locations]
	);

	const countriesItems = useMemo(
		() => legalCountries.map((country) => country.countryDTO.name),
		[legalCountries]
	);

	// !=== calendar entries ===

	const {
		data: calendarEntries,
		handleMonthChange,
		handleRequestMutate,
		handleConfirmMutate,
		activeItem,
		warningModal,
		setWarningModal,
	} = useCalendarEntries(profileId, calendarEnabled || canEdit);

	const noData =
		roleItems.length === 0 &&
		workplaceItems.length === 0 &&
		locationsItems.length === 0 &&
		jobItems.length === 0 &&
		countriesItems.length === 0;

	return (
		<>
			{available && (
				<div className={styles.work_availability_container}>
					<img src={openCalendar} alt="Open to work" />
					Open to work
				</div>
			)}

			<ProfileTabContainer
				customStyle={styles.calendar_container}
				disabled={!calendarEnabled && !canEdit}
				marginTop={!available}
				minHeight={false}
			>
				<MyCalendar
					userInfo={userInfo}
					calendarEntries={calendarEntries}
					onMonthChange={handleMonthChange}
					canEdit={canEdit}
					onDeleteEntry={handleRequestMutate}
				/>
			</ProfileTabContainer>

			{isLoading && (
				<ProfileTabContainer minHeight={false} customStyle={styles.work_status_container}>
					<LoadingSpinner />
				</ProfileTabContainer>
			)}

			{(!noData || canEdit) && !isLoading && (
				<ProfileTabContainer minHeight={false} customStyle={styles.work_status_container}>
					{canEdit && (
						<div className={styles.btn_wrapper}>
							<GaawkButton
								severity={"tertiary"}
								text={"Update Work Status"}
								onClick={() => navigate(routes.updateWorkStatus)}
							/>
						</div>
					)}
					{roleItems.length > 0 && <WorkStatusItem title={"Roles"} items={roleItems} />}
					{workplaceItems.length > 0 && (
						<WorkStatusItem title={"Workplace"} items={workplaceItems} />
					)}
					{locationsItems.length > 0 && (
						<WorkStatusItem title={"Locations"} items={locationsItems} />
					)}
					{jobItems.length > 0 && <WorkStatusItem title={"Job type"} items={jobItems} />}
					{countriesItems.length > 0 && (
						<WorkStatusItem title={"Legal to work in"} items={countriesItems} />
					)}
				</ProfileTabContainer>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this calendar entry?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmMutate(activeItem.uuid)}
			/>
		</>
	);
};

export default WorkStatus;
