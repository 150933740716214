import { useState, useEffect, useMemo } from "react";

import styles from "./ChatRightSide.module.scss";
import Message from "./Message";
import SystemMessage from "./SystemMessage";
import { useDispatch, useSelector } from "react-redux";

import GaawkModal from "../Utils/GaawkModal/GaawkModal";

import { getMessageState } from "./Conversation";
import ProfilePage from "../Profile/ProfilePage/ProfilePage";
import GroupInfo from "./GroupInfo";
import gaawkChat from "images/gaawk-logos/gaawk-chat-grey.svg";

import receivedIcon from "images/doubletick-lightgray.svg";
import readIcon from "images/doubletick-blue.svg";
import MessageInfoUser from "./MessageInfoUser";

import chatApi from "../../api/chat";
import useApi from "../../hooks/useApi";

import { List } from "react-content-loader";

import { format, getTime } from "date-fns";
import useCompanyId from "../../hooks/useCompanyId";
import LoadingPage from "../Utils/SubComs/CustomLoader/LoadingPage";
import LoadingSpinner from "../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import { updateProfileTab } from "store/slices/activeTab";
import { classNames } from "primereact/utils";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const ChatRightSide = ({
	convoHeader,
	userProfile,
	messages,
	msgRef,
	hasNextPageMsg,
	conversation,
	loading,
	loadingProfile,
	onReply,
	onDelete,
	onForward,
	onSelect,
	selectedMessages,
	replyHeight,
	selectActive,
	onRefetch,
	onReport,
	onReportGrp,
	onClear,
	onExport,
	onExit,
	onMute,
	onUnmute,
	onUpdateCount,
	onUpdateGroupInfo,
	onDirectMsg,
	onShowSlider,
	isInfoOpened,
}) => {
	const getMessageDetailsApi = useApi(chatApi.getMessageDetails);

	/**
	 * Selectors
	 */
	const dispatch = useDispatch();

	const companyId = useCompanyId();

	const { uuid: profileId } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const chatFooterHeight = useSelector((state) => state.ui.chatFooterHeight);

	/**
	 * States
	 */
	const activeTab = useSelector((state) => state.activeTab.profileTab);

	const tabClicked = (e) => {
		dispatch(updateProfileTab(e.target?.innerHTML || e));
	};

	//* ================== MESSAGE INFO MODAL HANDLER ==================

	const [msgInfoModal, setMsgInfoModal] = useState(false);
	const [msgInfo, setMsgInfo] = useState("");
	const [msgData, setMsgData] = useState("");

	const LoadingView = () => (
		<div className={styles.loadingContainer}>
			<List />
		</div>
	);

	const handleShowInfo = (messageInfo) => {
		getMessageDetailsApi.request(messageInfo.messageId);
		setMsgInfo(messageInfo);
		setMsgInfoModal(true);
		trackEvent(eventsDictionary.CHAT.MESSAGE_INFO);
	};

	useEffect(() => {
		if (
			!getMessageDetailsApi.loading &&
			getMessageDetailsApi.data &&
			getMessageDetailsApi.data.length > 0
		) {
			setMsgData(getMessageDetailsApi.data);
		}
	}, [getMessageDetailsApi.data]);

	const groupedDays = (messages) => {
		return messages.reduce((acc, el, i) => {
			const messageDay = format(new Date(el?.messageTime), "EEEE do MMMM yyyy");
			if (acc[messageDay]) {
				return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
			}
			return { ...acc, [messageDay]: [el] };
		}, {});
	};

	const generateItems = (messages) => {
		const days = groupedDays(messages);

		const sortedDays = Object.keys(days).sort(
			(x, y) => getTime(new Date(y)) - getTime(new Date(x))
		);

		const items = sortedDays.reduce((acc, date) => {
			const sortedMessages = days[date].sort(
				(x, y) => new Date(y.messageTime) - new Date(x.messageTime)
			);

			return acc.concat([...sortedMessages, { type: "day", date, id: date }]);
		}, []);
		return items;
	};

	const flattenMessagesArray = messages?.pages.flat();

	const replyIdToFocus = useSelector((state) => state.chat.replyId);

	const messagesList = useMemo(
		() =>
			generateItems(flattenMessagesArray).map((message) => {
				if (message?.type === "day") {
					return <SystemMessage key={message.id} message={message.date} />;
				}

				return (
					<div
						className={classNames({
							[styles.msg_wrapper_focused]: message.messageId === replyIdToFocus,
						})}
						key={message.messageId}
					>
						<Message
							key={message.messageId}
							item={message}
							profileId={profileId}
							conversation={conversation}
							onShowInfo={handleShowInfo}
							onReply={onReply}
							onSelect={onSelect}
							onForward={onForward}
							onDelete={onDelete}
							isSelected={selectedMessages
								.map((item) => item.messageId)
								.includes(message.messageId)}
							selectActive={selectActive}
							onShowSlider={() => onShowSlider(message)}
						/>
					</div>
				);
			}),
		[
			replyIdToFocus,
			messages?.pages,
			conversation,
			selectActive,
			selectedMessages,
			onShowSlider,
			// handleShowInfo,
			// msgRef,
			// onDelete,
			// onForward,
			// onReply,
			// onSelect,
			// profileId,
		]
	);

	/**
	 * JSX
	 */

	if (loadingProfile) return <LoadingPage />;

	return (
		<>
			{/* {loadingProfile &&
                !userProfile &&
                conversation.conversationType === "Default" && (
                    <div className={styles.loading_container}>
                        <Loader
                            type="Oval"
                            color="#6cc5d1"
                            height={80}
                            width={80}
                        />
                    </div>
                )} */}
			{convoHeader}

			{isInfoOpened ? (
				// !loadingProfile &&
				userProfile && conversation.conversationType === "Default" ? (
					<div className={`${styles.container} ${styles.info_opened}`}>
						<ProfilePage
							conversation={conversation}
							userInfo={userProfile}
							activeTab={activeTab}
							onItemClicked={tabClicked}
							canEdit={false}
							isEditing={false}
							// onBlock={onBlock}
							onReport={onReport}
							onClear={onClear}
							onExport={onExport}
							onMute={onMute}
							onUnmute={onUnmute}
							onUpdateFav={onRefetch}
							onUpdateFollow={onRefetch}
							onUpdateFriendStatus={onRefetch}
							onUpdateWork={onRefetch}
							onUnblock={onRefetch}
							onUpdateBlock={onRefetch}
						/>
					</div>
				) : (
					<div className={`${styles.container} ${styles.info_opened}`}>
						<GroupInfo
							info={conversation}
							onReportGrp={onReportGrp}
							onExit={onExit}
							onClear={onClear}
							onExport={onExport}
							onMute={onMute}
							onUnmute={onUnmute}
							onUpdateCount={onUpdateCount}
							onUpdateGroupInfo={onUpdateGroupInfo}
							onDirectMsg={onDirectMsg}
						/>
					</div>
				)
			) : conversation ? (
				<>
					{/* <ConvoHeader /> */}
					<div className={styles.container}>
						<div
							className={styles.wrapper}
							style={
								replyHeight
									? {
											height: `calc(100dvh - 55px - 65px - ${chatFooterHeight}px - ${
												replyHeight + 4
											}px)`,
									  }
									: {
											height: `calc(100dvh - 55px - 65px - ${chatFooterHeight}px`,
									  }
							}
						>
							{messagesList}
							{/* <LoadingSpinner
                                visible={loading}
                                customStyle={styles.msg_loading_spinner}
                            /> */}
							<div
								ref={msgRef}
								style={{
									display: hasNextPageMsg ? "block" : "none",
								}}
							>
								<LoadingSpinner
									visible={loading}
									customStyle={styles.msg_loading_spinner}
								/>
							</div>
						</div>
					</div>
					{/* //* MESSAGE DETAILS MODAL */}
					<GaawkModal
						show={msgInfoModal}
						handleClose={() => {
							setMsgInfo("");
							setMsgData("");
							setMsgInfoModal(false);
						}}
						defaultModal={false}
						showHeader={true}
						title={"Message Info"}
						closeAlign={"right"}
						children={
							<div className={styles.messageInfo_container}>
								<div className={styles.messageInfo_top}>
									<SystemMessage
										message={msgInfo.messageTime}
										isMessageInfo={true}
									/>

									<div className={styles.messageInfo_content}>
										<Message
											key={msgInfo.messageId}
											item={msgInfo}
											profileId={profileId}
											conversation={conversation}
											isInfoModal={true}
										/>
									</div>
								</div>

								{msgInfo.isGroup ? (
									<>
										{getMessageDetailsApi.loading && !msgData && (
											<LoadingView />
										)}
										{msgData && (
											<>
												<div className={styles.messageInfo_status}>
													<div className={styles.group_header_status}>
														<img src={readIcon} alt="read" />
														<span>Read by</span>
													</div>

													<div className={styles.gray_container}>
														{msgData.map(
															(data) =>
																data.read && (
																	<MessageInfoUser
																		thumbnail={
																			data.profile
																				.profileImage?.image
																		}
																		name={data.profile.name}
																		type={data.profile.type}
																		time={data.readTime}
																	/>
																)
														)}
													</div>
												</div>

												<div className={styles.messageInfo_status}>
													<div className={styles.group_header_status}>
														<img src={receivedIcon} alt="received" />
														<span>Delivered to</span>
													</div>

													<div className={styles.gray_container}>
														{msgData.map(
															(data) =>
																data.sent &&
																!data.read && (
																	<MessageInfoUser
																		thumbnail={
																			data.profile
																				.profileImage?.image
																		}
																		name={data.profile.name}
																		type={data.profile.type}
																		time={data.sentTime}
																	/>
																)
														)}
													</div>
												</div>
											</>
										)}
									</>
								) : (
									<>
										{getMessageDetailsApi.loading && !msgData && (
											<LoadingView />
										)}
										{msgData && (
											<div
												className={`${styles.messageInfo_status} ${styles.gray_container}`}
											>
												<div className={styles.messageInfo_status_wrapper}>
													<div
														className={
															styles.messageInfo_status_content
														}
													>
														<img
															src={getMessageState("READ")}
															alt="status"
														/>
														Read
													</div>

													<div className={styles.messageInfo_status_time}>
														{msgData[0].readTime !== 0
															? format(
																	new Date(msgData[0].readTime),
																	"dd MMMM yyyy, HH:mm"
															  )
															: "-"}
													</div>
												</div>

												<div className={styles.messageInfo_status_wrapper}>
													<div
														className={
															styles.messageInfo_status_content
														}
													>
														<img
															src={getMessageState("RECEIVED")}
															alt="status"
														/>
														Received
													</div>

													<div className={styles.messageInfo_status_time}>
														{msgData[0].receiveTime !== 0
															? format(
																	new Date(
																		msgData[0].receiveTime
																	),
																	"dd MMMM yyyy, HH:mm"
															  )
															: "-"}
													</div>
												</div>

												{/* <div
													className={
														styles.messageInfo_status_wrapper
													}
												>
													<div
														className={
															styles.messageInfo_status_content
														}
													>
														<img
															src={getMessageState(
																"SENT"
															)}
															alt="status"
														/>
														Sent
													</div>

													<div
														className={
															styles.messageInfo_status_time
														}
													>
														{msgData[0].sentTime !==
														0
															? format(
																	new Date(
																		msgData[0].sentTime
																	),
																	"dd MMMM yyyy, HH:mm"
															  )
															: "-"}
													</div>
												</div> */}
											</div>
										)}
									</>
								)}
							</div>
						}
					/>
				</>
			) : (
				<div className={styles.gaawkchat_logo_container}>
					<img src={gaawkChat} alt="gaawk chat" />
				</div>
			)}
		</>
	);
};

export default ChatRightSide;
