import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { otherUpdated as companyOtherUpdated } from "../../../../../store/slices/company";
import { otherUpdated } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Other.module.scss";
import OtherItem from "./OtherItem";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Other = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { others, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(
			editCompany ? profileApi.deleteCompanyOther : profileApi.deleteOther,
			(response, variables) => {
				trackEvent(eventsDictionary.PROFILE.DELETE_OTHERS);
				const { companyId } = variables || {};
				dispatch(
					companyId ? companyOtherUpdated(response.data) : otherUpdated(response.data)
				);
			}
		);

	const otherList = others.map((other) => (
		<OtherItem
			key={other.uuid}
			item={other}
			onEditClicked={() =>
				navigate(routes.editOther, {
					state: other,
				})
			}
			onDelete={() => handleRequestMutate(other)}
		/>
	));

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.OTHERS_LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.button_wrapper}>
				<GaawkButton
					severity={"secondary"}
					text="+ Add New Other Info"
					onClick={() => navigate(routes.addOther)}
				/>
			</div>

			{others.length > 0 && (
				<>
					<h4>Your other contact info</h4>
					<div className={styles.other_section}>{otherList}</div>
				</>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						...(editCompany && { companyId }),
						otherUuid: activeItem.uuid,
					})
				}
			/>
		</div>
	);
};

export default Other;
