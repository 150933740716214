// import useWindowSize from "hooks/useWindowSize";
import styles from "./NewBottomNavBar.module.scss";
import { ReactComponent as Boards } from "images/board.svg";
import { ReactComponent as Jobs } from "images/job.svg";
import { ReactComponent as Home } from "images/navbar-icons/homepage.svg";
import { ReactComponent as Search } from "images/navbar-icons/search.svg";
import { ReactComponent as Services } from "images/services.svg";
import GaawkLink from "../GaawkLink/GaawkLink";
import { classNames } from "primereact/utils";
import routes from "components/Routing/routing-keys";

const NewBottomNavBar = ({ hidden = false, fluid = false }) => {
	return (
		<div
			className={classNames(
				styles.container
				//     {
				// 	[styles.hide]: hidden,
				// 	[styles.fluid]: fluid,
				// }
			)}
		>
			<GaawkLink to={routes.root}>
				<Home />
			</GaawkLink>
			<GaawkLink to={routes.search}>
				<Search />
			</GaawkLink>
			<GaawkLink
			// to={routes.} //TODO route for products & services
			>
				<Services />
			</GaawkLink>
			<GaawkLink to={routes.jobs}>
				<Jobs />
			</GaawkLink>
			<GaawkLink to={routes.boards}>
				<Boards />
			</GaawkLink>
		</div>
	);
};

export default NewBottomNavBar;
