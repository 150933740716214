import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { siteUpdated as companySiteUpdated } from "../../../../../store/slices/company";
import { siteUpdated } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Email.module.scss";
import EmailItem from "./EmailItem";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Email = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { sites, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(
			editCompany ? profileApi.deleteCompanySite : profileApi.deleteSite,
			(response, variables) => {
				trackEvent(eventsDictionary.PROFILE.DELETE_EMAIL);
				const { companyId } = variables || {};
				dispatch(
					companyId ? companySiteUpdated(response.data) : siteUpdated(response.data)
				);
			}
		);

	const sitesList = sites.map((site) => (
		<EmailItem
			key={site.uuid}
			item={site}
			onEditClicked={() =>
				navigate(routes.editEmail, {
					state: site,
				})
			}
			onDelete={() => handleRequestMutate(site)}
		/>
	));

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.EMAIL_LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.button_wrapper}>
				<GaawkButton
					severity={"secondary"}
					text="+ Add A New Web Address"
					onClick={() => navigate(routes.addEmail)}
				/>
			</div>

			{sites.length > 0 && (
				<>
					<h4>Your Email / Website</h4>
					<div>{sitesList}</div>
				</>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						...(editCompany && { companyId }),
						siteId: activeItem.uuid,
					})
				}
			/>
		</div>
	);
};

export default Email;
