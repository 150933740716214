import { useRef, useState } from "react";
import styles from "../Header.module.css";
import ServicesPage from "../../components/More/ServicesPage/ServicesPage";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import BackButton from "../../components/Utils/Button/BackButton";
import ServicesNearby from "../../components/More/ServicesPage/ServicesNearby";
import ServicesConnections from "../../components/More/ServicesPage/ServicesConnections";
import ServiceMedias from "../../components/Profile/ServicesTab/ServiceMedias/ServiceMedias";
import ServiceProducts from "../../components/Profile/ServicesTab/ServiceProducts/ServiceProducts";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import { featuresSection } from "components/Utils/General";
import { useGetServiceById } from "hooks/useGetServiceById";
import ServicesTree from "components/Profile/ServicesTab/ServicesComponent/ServicesTree";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import ServiceDetails from "components/Profile/ServicesTab/ServiceDetails/ServiceDetails";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";

const ServicesView = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { serviceId } = useParams();

	const shouldDisable =
		pathname === "/services" || //TODO >> add to routing-keys.js
		pathname === "/services/nearby" || //TODO >> add to routing-keys.js
		pathname === "/services/connections"; //TODO >> add to routing-keys.js

	const { data, isFetching } = useGetServiceById(serviceId, undefined, !shouldDisable);

	const { minifiedProfile: { uuid: ownerId } = {}, name: serviceName } = data || {};

	const [countryFlag, setCountryFlag] = useState();
	const servicesRef = useRef(null);

	let component;
	let headerBtn;
	let headerTitle;

	switch (pathname) {
		case "/services":
			component = <ServicesPage ref={servicesRef} onFlagChange={setCountryFlag} />;
			headerBtn = (
				<button onClick={() => servicesRef.current.showModal()}>
					<img src={countryFlag} alt="" />
				</button>
			);
			headerTitle = "Services";
			break;

		case "/services/nearby":
			component = <ServicesNearby />;
			headerTitle = "Services in your area";
			break;

		case `/services/${serviceId}/medias`:
			component = <ServiceMedias />;
			headerTitle = `${serviceName} Medias`;
			break;

		case `/services/${serviceId}/products`:
			component = ownerId && <ServiceProducts ownerId={ownerId} serviceName={serviceName} />;
			headerTitle = ` ${ownerId ? serviceName + " Products" : ""} `;
			break;

		case "/services/connections":
			component = <ServicesConnections />;
			headerTitle = "Services by your connections";
			break;

		case `/services/${serviceId}`:
			//TODO >> need to move the fetching logic because invalidating makes the whole page refreshing
			component = isFetching ? <LoadingPage /> : <ServiceDetails data={data} />;
			headerTitle = "Services Details";
			break;
		default:
			navigate("/profile");
			return null;
	}

	const isProducts = pathname === `/services/${serviceId}/products`; //TODO >> add to routing-keys.js
	const isMedias = pathname === `/services/${serviceId}/medias`; //TODO >> add to routing-keys.js
	const isServiceDetails = pathname === `/services/${serviceId}`; //TODO >> add to routing-keys.js

	return (
		<DefaultPage
			forceShowLeftScroll={true}
			headerRightContent={
				<>
					<div className={styles.header}>
						<BackButton onBack={() => navigate(-1)} />
						{headerTitle}
					</div>
					{headerBtn}
				</>
			}
			rightSideBgColor={"#FFF"}
			rightSideContainerColor={"#FFF"}
			leftSideChildren={
				<>
					{/* //TODO >> need to move the fetching logic because invalidating makes the whole page refreshing */}
					{isProducts || isMedias || isServiceDetails ? (
						isFetching ? (
							<LoadingSpinner />
						) : (
							<div style={{ padding: "20px" }}>
								<ServicesTree data={data} showServicesWithProducts={isProducts} />
							</div>
						)
					) : (
						<FeaturesSidebar active={featuresSection.services} />
					)}
				</>
			}
			rightSideChildren={component}
		/>
	);
};

export default ServicesView;
