import GaawkButton from "components/Utils/Button/GaawkButton";
import styles from "./DeleteAccount.module.scss";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import profileApi from "api/profile";
import { logout } from "components/Utils/General";
import useMutate from "hooks/useMutate";
import WarningModal from "components/Utils/GaawkModal/WarningModal";

const DeleteAccount = () => {
	const { warningModal, setWarningModal, handleRequestMutate, handleConfirmMutate } = useMutate(
		profileApi.deleteAccount,
		() => {
			trackEvent(eventsDictionary.SETTINGS.DELETE_USER);
			logout();
		}
	);

	return (
		<div className={styles.container}>
			By deleting your password and accepting all terms, you acknowledge and agree to reset
			your account settings.
			<br />
			<br />
			This action will remove your saved password, accept all associated terms and conditions,
			and may result in losing certain stored data, such as preferences or unsaved progress.
			Make sure to back up any important information before proceeding, as this action cannot
			be undone.
			<br />
			<br />
			You will not be able to delete your account if you own a company. If you have any
			company associated with your account, you must either delete the company or transfer
			ownership to another user before proceeding to delete your account.
			{/* <br />
			<br />
			By entering your password and accepting all terms, you confirm your decision to delete
			your account. */}
			<div className={styles.button_container}>
				<GaawkButton
					text="DELETE ACCOUNT"
					onClick={() => handleRequestMutate()}
					severity={"danger"}
				/>
			</div>
			<WarningModal
				show={warningModal}
				modalOnTop={false}
				headerText="Are you sure you want to delete your account?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmMutate()}
			/>
		</div>
	);
};

export default DeleteAccount;
