import styles from "./PhoneChange.module.scss";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import { useState } from "react";
import profileApi from "../../api/profile";
import PhoneOTP from "./PhoneOTP";
import useMutate from "../../hooks/useMutate";
import { toast } from "react-toastify";
import { components } from "react-select";
import { dialCodeFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import useFetchLocation from "hooks/useFetchLocation";
import { locationKeys } from "queryKeys/location-key-factory";
import { useDispatch } from "react-redux";
import { userLoginUpdated } from "store/slices/user";
import GaawkButton from "components/Utils/Button/GaawkButton";

const itemsPerPage = 20;

const PhoneChange = () => {
	const dispatch = useDispatch();

	const schema = Joi.object({
		mobile: Joi.string()
			.pattern(/^[0-9]+$/)
			.required()
			.label("Mobile")
			.messages({
				"string.empty": "Mobile can't be empty",
				"string.pattern.base": "Mobile can only contain numbers",
			}),
		countryCode: Joi.required().messages({
			"any.required": "Dial code is required",
		}),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
		watch,
	} = useForm({ resolver: joiResolver(schema), mode: "onBlur" });

	const codeWatcher = watch("countryCode");
	const mobileWatcher = watch("mobile");
	const fullMobile = `${codeWatcher?.value.replace(" ", "")}${mobileWatcher}`;

	const [otpToken, setOtpToken] = useState("");

	const {
		action: { mutate: sendOtp },
	} = useMutate(profileApi.sendOtp, (response) => setOtpToken(response.data.otp));

	const handleSendOtp = () => {
		sendOtp({
			email: undefined,
			mobile: fullMobile,
		});
	};

	const handleUpdateSuccess = (response) => {
		setOtpToken("");
		toast.success("Phone number updated!");
		dispatch(userLoginUpdated(response.data));
	};

	const fetchCountries = useFetchLocation(false, itemsPerPage);

	return (
		<>
			{otpToken ? (
				<PhoneOTP
					countryCode={codeWatcher?.value}
					mobile={mobileWatcher}
					otpToken={otpToken}
					onResendOtp={() => sendOtp({ email: undefined, fullMobile })}
					onSuccess={handleUpdateSuccess}
				/>
			) : (
				<form onSubmit={handleSubmit((data) => handleSendOtp(data))}>
					<p className={styles.info}>
						Enter a mobile number which will be linked to your account. This number will
						not be published on your profile unless you choose to make it visible.
					</p>

					<div className={styles.form_input_wrapper}>
						<label>
							Mobile Number<span className="required">*</span>
						</label>

						<div className={styles.phone_input_wrapper}>
							<InfiniteSearchInput
								queryName={locationKeys.countries}
								queryFn={fetchCountries}
								itemsPerPage={itemsPerPage}
								formatter={dialCodeFormatter}
								error={errors?.countryCode}
								control={control}
								name="countryCode"
								customStyle={styles.dialCode_input}
								errorStyle={styles.hide}
								openMenuOnClick={true}
								option={{
									ValueContainer: ({ children, ...props }) => {
										const { value } = props.selectProps;

										return (
											<components.ValueContainer {...props}>
												{value?.value || children}
											</components.ValueContainer>
										);
									},
								}}
							/>

							<TextInput
								{...register("mobile")}
								placeholder="Enter your mobile number"
								error={!!errors.mobile}
							/>
						</div>

						{errors?.countryCode && (
							<p className={`${styles.error_message} ${styles.countryCode}`}>
								{errors?.countryCode?.message}
							</p>
						)}
						{errors?.mobile && (
							<p className={`${styles.error_message} ${styles.mobile}`}>
								{errors?.mobile?.message}
							</p>
						)}
					</div>

					<div className={styles.button_container}>
						<GaawkButton type={"submit"} text={"Send OTP"} />
					</div>
				</form>
			)}
		</>
	);
};

export default PhoneChange;
