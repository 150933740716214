import { useState } from "react";
import { specialProfileTypes } from "./special-types";
import KeyPeopleItem from "../KeyPeople/KeyPeopleItem";
import styles from "./SpecialProfileItem.module.css";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import RoundButton from "components/Utils/Button/RoundButton";

const SpecialProfileItem = ({
	item,
	showUrl = false,
	canEdit,
	onDelete,
	redirect = true,
	agencyId = "",
}) => {
	const navigate = useNavigate();

	const [showWarning, setShowWarning] = useState(false);

	return (
		<div className={styles.profile_container}>
			<KeyPeopleItem
				key={item.uuid}
				title={item?.alias || item.name}
				subtitle={specialProfileTypes.find((type) => type.value === item.type).label}
				image={item?.profilePic.file.customName}
				showControls={false}
				onClick={() => redirect && navigate(routes.specialProfile(item.url))}
				cursor={"pointer"}
			/>

			{showUrl && (
				<div className={styles.url_container}>
					{`${window.location.origin}${routes.specialProfile(item.url)}${
						agencyId ? `?agency=${agencyId}` : ""
					}`}
				</div>
			)}

			{canEdit && (
				<div className={styles.button_container}>
					<RoundButton
						icon="edit"
						onClick={() =>
							navigate(routes.specialProfile(item.url, "/edit"), {
								state: { agencyId },
							})
						}
					/>

					<RoundButton icon="delete" onClick={() => setShowWarning(true)} />
				</div>
			)}

			<WarningModal
				show={showWarning}
				modalOnTop={true}
				headerText="Are you sure you want to delete this special profile?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setShowWarning(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => {
					setShowWarning(false);
					onDelete();
				}}
			/>
		</div>
	);
};

export default SpecialProfileItem;
