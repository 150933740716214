import { Navigate, useSearchParams } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import Feeds from "./Feeds";
import FeedContainer from "components/Utils/FeedContainer/FeedContainer";
import ThreeColumnPage from "components/Utils/PageArchs/ThreeColumnPage/ThreeColumnPage";
import HomeRightSidebar from "./Components/HomeRightSidebar/HomeRightSidebar";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const FeedByHashtag = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const hashtagValue = searchParams.get("hashtag");
	const hasParam = searchParams.has("hashtag");

	if (!hasParam || !hashtagValue) return <Navigate to={routes.root} />;

	return (
		<ThreeColumnPage
			leftSideChildren={<NavBar />}
			centerChildren={
				<FeedContainer fixedContainer={true}>
					<h4>results for #{hashtagValue}</h4>
					<Feeds hashtagValue={hashtagValue} />
				</FeedContainer>
			}
			rightSideChildren={<HomeRightSidebar />}
		/>
	);
};

export default FeedByHashtag;
