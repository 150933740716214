import {
	init,
	track,
	setUserId,
	identify,
	Identify,
	reset,
	// add,
} from "@amplitude/analytics-browser";

// const enrichPageUrlPlugin = () => {
// 	const getQueryParamsAsObject = (search) => {
// 		const params = new URLSearchParams(search);
// 		const queryParams = {};
// 		params.forEach((value, key) => {
// 			queryParams[key] = value;
// 		});
// 		return queryParams;
// 	};

// 	return {
// 		execute: async (event) => {
// 			const queryParams = getQueryParamsAsObject(window.location.search);
// 			// for example if the event is a page_view with the path "/profile" add a property "isOwnProfile" with a value of true or false
// 			event.event_properties = {
// 				...event.event_properties,
// 				...queryParams,
// 				//TODO >> add some properties based on the event type
// 			};
// 			return event;
// 		},
// 	};
// };

const getQueryParamsAsObject = (search) => {
	const params = new URLSearchParams(search);
	const queryParams = {};
	params.forEach((value, key) => {
		queryParams[key] = value;
	});
	return queryParams;
};

const initAmplitude = () => {
	// add(enrichPageUrlPlugin());

	init(process.env.REACT_APP_AMPLITUDE_KEY, {
		serverZone: "EU",
		autocapture: {
			attribution: false,
			pageViews: false,
			sessions: true,
			formInteractions: false,
			fileDownloads: false,
			elementInteractions: false,
		},
		// logLevel: process.env.NODE_ENV === "development" ? "debug" : "none",
	});
};

const trackEvent = (eventName, eventProperties = {}) => {
	const queryParams = getQueryParamsAsObject(window.location.search);
	track(eventName, { ...eventProperties, path: document.location.pathname, ...queryParams });
};

const awaitableTrackEvent = async (eventName, eventProperties = {}) => {
	await track(eventName, { ...eventProperties, path: document.location.pathname }).promise;
};

const setUserProperties = (properties) => {
	const identifyObj = new Identify();

	Object.entries(properties).forEach(([key, value]) => {
		identifyObj.set(key, value);
	});

	identify(identifyObj);
};

const identifyUser = (userId, userProperties = {}) => {
	setUserId(userId);

	if (Object.keys(userProperties).length > 0) {
		setUserProperties(userProperties);
	}
};

const resetAmplitude = () => {
	reset();
};

export {
	initAmplitude,
	trackEvent,
	identifyUser,
	resetAmplitude,
	setUserProperties,
	awaitableTrackEvent,
};
