import { joiResolver } from "@hookform/resolvers/joi";
import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import { defaultFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import useCurrentUser from "hooks/useCurrentUser";
import { useCustomQuery } from "hooks/useCustomQuery";
import useMutate from "hooks/useMutate";
import Joi from "joi";
import { socialKeys } from "queryKeys/social-key-factory";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import socialApi from "../../../../../api/social";
import useApi from "../../../../../hooks/useApi";
import { socialUpdated as companySocialUpdated } from "../../../../../store/slices/company";
import { socialUpdated } from "../../../../../store/slices/user";
import CustomSelect from "../../../../Utils/SubComs/CustomSelect/CustomSelect";
import TextInput from "../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import VisibilitySelector from "../../../../Utils/Visibility/VisibilitySelector";
import styles from "./Social.module.scss";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const SocialEntry = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const { pathname, state } = useLocation();

	const editMode = pathname === routes.editSocial;

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.ADD_SOCIAL_MEDIA, { isEditing: editMode });
	}, [editMode]);

	const { uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { uuid: profileSocialId, link, socialDTO, visibility: currentVisibility } = state || {};

	const schema = Joi.object({
		platform: Joi.object({
			label: Joi.string(),
			value: Joi.string(),
		})
			.required()
			.messages({
				"any.required": "Platform can't be empty",
				"object.base": "Platform can't be empty",
			})
			.label("Platform"),

		link: Joi.string()
			.required()
			.messages({ "string.empty": "Link can't be empty" })
			.label("Link"),
	});

	const {
		register,
		formState: { errors, isDirty, isSubmitSuccessful },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					platform: {
						label: socialDTO?.name,
						value: socialDTO?.uuid,
					},
					link,
			  }
			: {
					platform: null,
					link: "",
			  },
	});

	//! SOCIAL HANDLERS ----------------------------------------------

	//TODO >> This endpoint is not paginated, so can't use <InfiniteSearchInput /> component like the other endpoints

	const socialMediaApi = useApi(socialApi.getSocialMedia, true, true);

	const fetchSocialMedias = async () => {
		const response = await socialMediaApi.request();
		return response.data;
	};

	const { data: social, isFetching } = useCustomQuery({
		queryKey: socialKeys.all,
		queryFn: fetchSocialMedias,
		options: {
			staleTime: 30000,
		},
	});
	// !=============================

	const [visibility, setVisibility] = useState(currentVisibility ?? "WORK_CIRCLE_FRIENDS");

	const handleSuccess = (response, variables) => {
		const { companyId } = variables || {};
		dispatch(companyId ? companySocialUpdated(response.data) : socialUpdated(response.data));
		navigate(routes.social, { replace: true });
	};

	const {
		action: { mutate: addSocial, isLoading: isAdding },
	} = useMutate(editCompany ? profileApi.addCompanySocial : profileApi.addSocial, (response) => {
		trackEvent(eventsDictionary.PROFILE.ADD_SOCIAL_MEDIA_DONE);
		handleSuccess(response);
	});

	const {
		action: { mutate: updateSocial, isLoading: isUpdating },
	} = useMutate(
		editCompany ? profileApi.updateCompanySocial : profileApi.updateSocial,
		(response) => {
			trackEvent(eventsDictionary.PROFILE.ADD_SOCIAL_MEDIA_DONE, { isEditing: editMode });
			handleSuccess(response);
		}
	);

	const handleSave = (data) => {
		const {
			link,
			platform: { value: socialId },
		} = data;

		const body = {
			...(editMode && { profileSocialId }),
			socialId,
			link,
			visibility,
		};

		if (editMode) {
			updateSocial({ ...(editCompany && { companyId }), body });
		} else {
			addSocial({ ...(editCompany && { companyId }), body });
		}
	};

	const handleVisibilityChange = (value) => () => {
		setVisibility(value);
	};

	if (editMode && !state) {
		return <Navigate to={routes.social} />;
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.contact_section}>
					<form
						className={styles.add_form}
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<InputWrapper
							label="Platform"
							required={true}
							error={errors?.platform}
							className={styles.no_margin}
							component={
								<CustomSelect
									fontSize="14px"
									height="35px"
									options={social?.map(defaultFormatter)}
									control={control}
									name="platform"
									isLoading={isFetching}
								/>
							}
						/>

						<InputWrapper
							label="Link"
							required={true}
							error={errors?.link}
							component={<TextInput {...register("link")} placeholder="Enter Link" />}
						/>

						<div className={styles.visibility_wrapper}>
							<label className={styles.visibility_label}>
								Visibility
								<span className="required">*</span>
							</label>

							<VisibilitySelector
								value={visibility}
								onValueChange={handleVisibilityChange}
							/>
						</div>

						<div className={styles.button_container}>
							<GaawkButton
								type={"submit"}
								text={"Save"}
								isLoading={isAdding || isUpdating}
							/>
						</div>
					</form>
				</div>
			</div>

			<PromptModal when={isDirty && !isSubmitSuccessful} />
		</>
	);
};

export default SocialEntry;
