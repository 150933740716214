import { router } from "AppRoutes";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import { ReactComponent as FullscreenIcon } from "images/fullscreen-icon-white.svg";
import { useEffect, useRef } from "react";
import { NavigationType, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PostActions from "../../Home/PostActions";
import PostItem from "../../Home/PostItem";
import FileViewer from "../../Utils/FileViewer/FileViewer";
import ViewerHeader from "../../Utils/FileViewer/ViewerHeader";
import FullScreenModal from "../../Utils/GaawkModal/FullScreenModal";
import { copyToClipboard } from "../../Utils/General";
import DefaultPage from "../../Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "./PostViewer.module.css";
// import AlbumActions from "./AlbumActions";
// import { copyPostLink } from "../../Utils/General";

const PostViewer = ({
	show,
	post,
	files,
	onClose,
	onShare,
	postName,
	onLoadMore,
	initialSlideIndex,
	onActiveSlide,
	isLoading,
	onPostUpdate,
	isOwner,
	onEdit,
	onDelete,
	onDownload,
}) => {
	const navigate = useNavigate();

	const fileViewerRef = useRef(null);
	const reportRef = useRef();

	useEffect(() => {
		let unlisten;
		if (show) {
			unlisten = router.subscribe((state) => {
				if (state.historyAction === NavigationType.Pop) {
					onClose();
				}
			});
		}

		return () => unlisten && unlisten();
	}, [show]);

	const handleCopyLink = () => {
		copyToClipboard(
			`${window.location.origin}/post/${post.sharedPost ? post.sharedPost.uuid : post.uuid}`
		);
		toast.success("Link copied !");
	};

	return (
		<>
			<FullScreenModal
				show={show}
				onClose={() => navigate(-1)}
				fullWidth={true}
				children={
					<DefaultPage
						showFooter={false}
						leftSideChildren={
							post && (
								<PostItem
									sm={true}
									post={post}
									visibleByDefault={true}
									onPostUpdate={onPostUpdate}
									onShare={onShare}
									isModal={true}
									onClose={onClose}
								/>
							)
						}
						headerRightContent={
							<ViewerHeader
								onClose={() => navigate(-1)}
								leftSide={<div className={styles.header}>{postName}</div>}
								rightSide={
									<div className={styles.post_actions_wrapper}>
										<button
											onClick={() => fileViewerRef.current.enterFullscreen()}
										>
											<FullscreenIcon width={20} />
										</button>

										<PostActions
											whiteButton={true}
											postOwner={post?.owner?.name}
											isOwner={isOwner}
											isPublic={post?.postVisibility === "PUBLIC"}
											onEdit={() => onEdit(post)}
											onDelete={() => onDelete(post)}
											onCopy={handleCopyLink}
											onShare={() => onShare(post)}
											onDownload={onDownload}
											onReport={() =>
												reportRef.current.showReportModal(post?.uuid)
											}
										/>
									</div>
								}
							/>
						}
						rightSideChildren={
							<div className={styles.fileviewer_container}>
								<FileViewer
									ref={fileViewerRef}
									files={files}
									initialSlide={initialSlideIndex}
									onLoadMore={onLoadMore}
									isLoading={isLoading}
									onActiveSlide={onActiveSlide}
									inModal={false}
									deletableItems={true}
								/>
								<div className={styles.mobile_post_container}>
									{post && (
										<PostItem
											sm={true}
											post={post}
											visibleByDefault={true}
											onPostUpdate={onPostUpdate}
											onShare={onShare}
											onClose={onClose}
										/>
									)}
								</div>
							</div>
						}
						rightSideFullWidth={true}
					/>
				}
			/>

			<ReportModal ref={reportRef} category="post" />
		</>
	);
};

export default PostViewer;
