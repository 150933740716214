import { getFileIcon, getVideoFrame } from "../../Utils/General";
import LazyImage from "../../Utils/LazyImage/LazyImage";
import styles from "./GalleryFileItem.module.css";
import { ReactComponent as CrossIcon } from "images/cross-icon.svg";
import { ReactComponent as PlayIcon } from "images/play-button.svg";
import { useEffect, useState } from "react";
import RoundButton from "components/Utils/Button/RoundButton";

const GalleryFileItem = ({ file, onRemove, onEdit, className }) => {
	const isImage = file.multipartFile.type.startsWith("image");
	const isVideo = file.multipartFile.type.startsWith("video");

	const [objectUrl, setObjectUrl] = useState(null);

	useEffect(() => {
		const loadFile = async () => {
			if (!file) return;

			let url;
			if (file.multipartFile.type.startsWith("image")) {
				url = URL.createObjectURL(file.multipartFile);
			} else if (file.multipartFile.type.startsWith("video")) {
				url = await getVideoFrame(file.multipartFile);
			} else {
				url = getFileIcon(file.multipartFile, file.multipartFile.name).icon;
			}

			setObjectUrl(url);
		};

		loadFile();

		// Cleanup function
		return () => {
			if (objectUrl) {
				URL.revokeObjectURL(objectUrl);
			}
		};
	}, [file]);

	return (
		<div className={`${styles.container} ${className}`}>
			<div
				className={`${styles.image_container} ${
					isImage || isVideo ? styles.photo : undefined
				}`}
			>
				<button
					className={styles.remove_button}
					onClick={() => {
						onRemove();
						URL.revokeObjectURL(objectUrl);
					}}
				>
					<CrossIcon width={12} />
				</button>

				{onEdit && (
					<div className={styles.edit_button}>
						<RoundButton icon="edit" onClick={onEdit} />
					</div>
				)}

				{isImage ? (
					<LazyImage image={<img src={objectUrl} alt={""} />} />
				) : isVideo ? (
					<div className={styles.video_container}>
						<PlayIcon className={styles.icon} />
						<LazyImage image={<img src={objectUrl} alt={""} />} />
					</div>
				) : (
					<>
						<div className={styles.icon_container}>
							<img src={objectUrl} alt={""} />
						</div>
						<div className={styles.filename_container}>{file.multipartFile.name}</div>
					</>
				)}
			</div>
		</div>
	);
};

export default GalleryFileItem;
