import { useEffect, useMemo, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import SearchInput from "../Utils/SubComs/Inputs/SearchInput/SearchInput";
import styles from "./ServicesTagModal.module.scss";
import useApi from "../../hooks/useApi";
import servicesApi from "../../api/services";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import useCompanyId from "../../hooks/useCompanyId";
import { useSelector } from "react-redux";
import Tag from "../Utils/SubComs/Tags/Tag";
import ProfilePic from "./ProfilePic";
import { ReactComponent as ServiceIcon } from "images/services.svg";
import LoadingSpinner from "../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import GaawkButton from "components/Utils/Button/GaawkButton";

const itemsPerPage = 50;

const ServicesTagModal = ({ show, handleClose, onConfirm, currentTags }) => {
	const queryClient = useQueryClient();

	const searchServicesApi = useApi(servicesApi.searchServices, true, true);

	const companyId = useCompanyId();

	const { uuid: profileId } = useSelector((state) => {
		if (companyId) {
			return state.company.companyInfo;
		} else {
			return state.user.profileInfo;
		}
	});

	const { ref: viewRef, inView } = useInView();

	const [searchInput, setSearchInput] = useState("");
	const debouncedSearch = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const fetchSearch = async ({ pageParam = 0, signal }) => {
		const response = await searchServicesApi.request(
			pageParam,
			itemsPerPage,
			debouncedSearch,
			profileId
		);
		return response.data;
	};

	const { data, hasNextPage, fetchNextPage, isLoading, isFetching } = useInfiniteQuery({
		queryKey: ["searchServicesTag", debouncedSearch],
		queryFn: fetchSearch,
		getNextPageParam: (lastPage, pages) => {
			const nextPage = lastPage.length === itemsPerPage ? pages.length : undefined;
			return nextPage;
		},

		enabled: show,
		staleTime: 0,
		cacheTime: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, fetchNextPage, isFetching]);

	// console.log("%c THE DATA >>", "color: yellow; font-weight: bolder;", data);

	const [selectedServices, setSelectedServices] = useState([]);

	useEffect(() => {
		// if (currentTags) {
		setSelectedServices(currentTags ? currentTags : []);
		// }
	}, [currentTags]);

	const handleRemoveService = (service) => {
		setSelectedServices((prevState) => prevState.filter((item) => item.uuid !== service.uuid));
		queryClient.invalidateQueries({
			queryKey: ["searchServicesTag", debouncedSearch],
		});
	};

	const selectedTagsList = useMemo(
		() =>
			selectedServices.map((service) => (
				<Tag
					key={service.uuid}
					itemName={service.name}
					onRemove={() => handleRemoveService(service)}
				/>
			)),
		[selectedServices]
	);

	const formattedSearchServices = useMemo(
		() =>
			data?.pages?.map((page) =>
				page
					.filter(
						(service) =>
							!selectedServices
								.map((selectedService) => selectedService.uuid)
								.includes(service.uuid)
					)
					.map((service, i) => (
						<button
							key={i}
							ref={page.length === i + 1 ? viewRef : null}
							className={styles.service_item_wrapper}
							onClick={() => handleSelectedTag(service)}
						>
							<div className={styles.top}>
								<ProfilePic
									type={service.minifiedProfile.type}
									thumbnail={service.minifiedProfile?.profileImage?.image}
								/>
								<div className={styles.service_name}>
									<h4>+{service.name}</h4>
									<span>{service.tag}</span>
								</div>
							</div>

							{service.parents && Object.keys(service.parents).length > 0 && (
								<div className={styles.breadcrumb_wrapper}>
									<span>
										{/* <img src={servicesIcon} alt="" />{" "} */}
										<ServiceIcon width={20} fill={"#000"} />
										{`+${service.parents?.p3?.name}`}
									</span>

									{service.parents.p2 && (
										<>
											{` > `}
											<span>
												{/* <img
														src={servicesIcon}
														alt=""
													/>{" "} */}
												<ServiceIcon width={20} fill={"#000"} />
												{`+${service.parents.p2.name}`}
											</span>
										</>
									)}
								</div>
							)}
							<div className={styles.border_bottom}></div>
						</button>
					))
			),
		[data, selectedServices]
	);

	const handleSelectedTag = (service) => {
		setSelectedServices((prevState) => [...prevState, service]);
		queryClient.invalidateQueries({
			queryKey: ["searchServicesTag", debouncedSearch],
		});
	};

	const handleCloseModal = () => {
		handleClose();
		setSearchInput("");
		queryClient.removeQueries({
			queryKey: ["searchServicesTag", debouncedSearch],
		});
		setSelectedServices([]);
	};

	const handleConfirm = () => {
		onConfirm(selectedServices);
		handleCloseModal();
	};

	return (
		<GaawkModal
			show={show}
			handleClose={handleCloseModal}
			defaultModal={false}
			showHeader={true}
			title={"Services Tags"}
			closeAlign={"left"}
			modalOnTop={true}
			children={
				<>
					{isLoading ? (
						<LoadingSpinner customStyle={styles.custom_loading_spinner} />
					) : (
						<>
							<div className={styles.search_input_wrapper}>
								<SearchInput
									onChange={handleSearchInputChange}
									value={searchInput}
									showIcons={true}
									onClearClicked={() => setSearchInput("")}
									border={false}
									placeholder={"Search services"}
								/>
							</div>

							{selectedServices.length > 0 && (
								<div className={styles.selected_tags_container}>
									{selectedTagsList}
								</div>
							)}

							<NoResults
								isSearch={debouncedSearch}
								visible={data?.pages?.flat()?.length === 0}
								text={
									debouncedSearch
										? "No results related to your search"
										: "No results"
								}
							/>

							<div className={styles.services_items_container}>
								{formattedSearchServices}
							</div>
							<div className={styles.button_wrapper}>
								<GaawkButton onClick={handleConfirm} text="Done" />
							</div>
						</>
					)}
				</>
			}
		/>
	);
};

export default ServicesTagModal;
