import styles from "./NoData.module.css";
import GaawkButton from "components/Utils/Button/GaawkButton";

const NoData = ({ text, onClick }) => {
	return (
		<div className={styles.no_data}>
			<p>{text}</p>
			<GaawkButton className={styles.message_btn} text={"Send A Message"} onClick={onClick} />
		</div>
	);
};

export default NoData;
