import styles from "./AddMembersModal.module.css";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import ContactSearch from "./ContactSearch";
import LoadingSpinner from "../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import GaawkButton from "components/Utils/Button/GaawkButton";

const AddMembersModal = ({
	show,
	onClose,
	onCancel,
	closeAlign,
	searchInput,
	onInputChange,
	onClear,
	participantsList,
	contactList,
	onConfirm,
	title = "Add members",
	buttonText = "Create Group",
	isLoading,
	disabled = false,
}) => {
	// console.log("🚀 ~ contactList >>", contactList);
	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={title}
			closeAlign={closeAlign}
			children={
				<>
					<div className={styles.group_search_wrapper}>
						<ContactSearch
							searchInput={searchInput}
							onSearchInputChange={onInputChange}
							onClear={onClear}
						/>
					</div>
					<div className={styles.add_group_container}>
						{participantsList?.length > 0 && (
							<div className={styles.group_participants_wrapper}>
								{participantsList}
							</div>
						)}

						<div className={styles.contact_list_wrapper}>
							{contactList}
							<LoadingSpinner visible={isLoading} />

							<NoResults
								isSearch={true}
								text={"No results related to your search"}
								visible={contactList?.length === 0 && !isLoading}
							/>
						</div>

						<div className={styles.form_controls}>
							<GaawkButton
								severity={"tertiary"}
								text="Cancel"
								type="button"
								onClick={onCancel}
							/>

							<GaawkButton
								isLoading={disabled}
								onClick={onConfirm}
								text={buttonText}
								type="button"
							/>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default AddMembersModal;
