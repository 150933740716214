import { useState, useRef, useEffect, useMemo } from "react";
import styles from "./CreatePost.module.css";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import EditMediaButtons from "./EditMediaButtons";
import LocationModal from "./LocationModal";
import locationIcon from "images/location_icon.svg";
import { ReactComponent as CalendarIcon } from "images/calendar.svg";
import greyClose from "images/cancel-btn-gray.svg";
import ProfilePic from "../Home/ProfilePic";
import WarningModal from "../Utils/GaawkModal/WarningModal";
// import CropModal from "./CropModal";
import CropModal from "../Utils/CropModal/CropModal";
import MediaItem from "./MediaList";
import { format } from "date-fns";
import ServicesTagModal from "./ServicesTagModal";
import Tag from "../Utils/SubComs/Tags/Tag";
import {
	getFileIcon,
	getLocationType,
	getVideoFrame,
	maxPostLength,
} from "components/Utils/General";
import useUrlPreview from "hooks/useUrlPreview";
import CustomMentions from "components/Utils/Mentions/CustomMentions";
import UserListModal from "components/Utils/UserListModal/UserListModal";
import profileApi from "api/profile";
import { profileKeys } from "queryKeys/profile-key-factory";
import GroupMember from "components/Chat/GroupMember";
import Tooltip from "components/Utils/Tooltip/Tooltip";
import GaawkButton from "components/Utils/Button/GaawkButton";

const itemsPerPage = 20;

const EditMediaModal = ({
	show,
	onClose,
	item,
	// isEditing = false,
	onToggle,
	onConfirm,
	onReset,
}) => {
	const [newItem, setNewItem] = useState("");

	useEffect(() => {
		if (item && (item.file || item.subPosts?.length > 0 || item.multipartFile)) {
			if (!item.newFile) {
				setNewItem({
					...item,
					caption: item.postText || "",
					taggedIds: item.taggedProfiles,
				});
			} else {
				setNewItem(item);
			}
		}
	}, [item]);

	const ref = useRef();

	//! =========== caption Handler =============

	const handleCaptionChange = (text) => {
		setNewItem((prevState) => ({ ...prevState, caption: text }));
	};

	//! =========== Location Handler =============

	const [showLocationModal, setShowLocationModal] = useState(false);

	const handleShowLocationModal = () => {
		onToggle(false);
		setShowLocationModal(true);
	};

	const handleCloseLocationModal = () => {
		setShowLocationModal(false);
		onToggle(true);
	};

	const handleLocationClicked = (place) => {
		getLocationType(place.types);
		// onLocationChange(place);

		setNewItem((prevState) => ({
			...prevState,
			location: {
				latitude: place.geometry.location.lat,
				longitude: place.geometry.location.lng,
				title: place.name,
				placeId: place.place_id,
				type: getLocationType(place.types),
			},
		}));
		handleCloseLocationModal();
	};

	const handleRemoveLocation = () => {
		setNewItem((prevState) => ({ ...prevState, location: null }));
	};

	//! =========== Profile tag Handler =============
	const [showTagModal, setShowTagModal] = useState(false);
	const [mediaTags, setMediaTags] = useState([]);

	const handleShowTagModal = () => {
		onToggle(false);
		setShowTagModal(true);
		setMediaTags(newItem.taggedIds || []);
	};

	const handleHideTagModal = () => {
		setShowTagModal(false);
		onToggle(true);
		setMediaTags([]);
	};

	const fetchTags = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, query] = queryKey;
		const response = await profileApi.searchTags(pageParam, itemsPerPage, query);
		return response.data;
	};

	const handleConfirmTags = () => {
		setNewItem((prevState) => ({ ...prevState, taggedIds: mediaTags }));
		handleHideTagModal();
	};

	const handleRemoveTag = (tag) => {
		setMediaTags((prevTags) => prevTags.filter((t) => t.uuid !== tag.uuid));
	};

	const handleQuickRemove = (tag) => {
		setNewItem((prevState) => ({
			...prevState,
			taggedIds: prevState.taggedIds.filter((t) => t.uuid !== tag.uuid),
		}));
	};

	const renderTag = ({ user: tag, ref }) => {
		const isSelected = mediaTags.some((t) => t.uuid === tag.uuid);

		return (
			<GroupMember
				ref={ref}
				key={tag.uuid}
				redirect={false}
				onClick={() => {
					if (!isSelected) {
						setMediaTags((prevState) => [...prevState, tag]);
					}
				}}
				disabled={isSelected}
				data={tag}
			/>
		);
	};

	const taggedProfiles = useMemo(
		() =>
			newItem?.taggedIds?.map((profile) => {
				return (
					<Tooltip label={profile.name} key={profile.uuid} placement={"bottom"}>
						<div className={styles.taggedItem}>
							<ProfilePic
								type={profile.type}
								thumbnail={profile?.profileImage?.image}
								w={40}
								h={40}
								name={profile.name}
								enableName={false}
								noMarginRight={true}
							/>
							<button className={styles.close_btn}>
								<img
									src={greyClose}
									onClick={() => handleQuickRemove(profile)}
									alt="remove"
								/>
							</button>
						</div>
					</Tooltip>
				);
			}),
		[newItem]
	);

	//! =========== Datepicker Handler =============

	// const [showDatePicker, setShowDatePicker] = useState(false);

	const handleDatePicker = (date) => {
		setNewItem((prevState) => ({ ...prevState, date: date.getTime() }));

		// setShowDatePicker((prevState) => !prevState);
	};

	// const handleSelectDate = (dateInMs) => {
	//     setShowDatePicker(false);
	//     // onSelectDate(dateInMs);
	//     setNewItem((prevState) => ({ ...prevState, date: dateInMs }));
	// };

	const handleRemoveMediaDate = () => {
		// onRemoveDate();
		const newItemCopy = { ...newItem };
		delete newItemCopy.date;
		setNewItem(newItemCopy);
		ref.current.resetDate();
	};

	//! =========== CROP Handler ================

	const [showCropModal, setShowCropModal] = useState(false);

	const handleShowCropModal = () => {
		onToggle(false);
		setShowCropModal(true);
	};

	const handleHideCropModal = () => {
		setShowCropModal(false);
		onToggle(true);
	};

	const handleCrop = (blob) => {
		blob.fileName = item.multipartFile.name;
		setNewItem((prevState) => ({ ...prevState, multipartFile: blob }));
	};

	//! =========== Services Handler =============

	const [showServicesModal, setShowServicesModal] = useState(false);

	const handleShowServicesModal = () => {
		onToggle(false);
		setShowServicesModal(true);
	};

	const handleHideServicesModal = () => {
		setShowServicesModal(false);
		onToggle(true);
	};

	const handleServicesChange = (serviceTags) => {
		setNewItem((prevState) => ({
			...prevState,
			services: serviceTags,
		}));
	};

	const handleRemoveTaggedService = (service) => {
		const newItemCopy = { ...newItem };
		const taggedServices = newItemCopy["services"];
		const updatedTaggedServices = taggedServices.filter((x) => x.uuid !== service.uuid);
		setNewItem((prevState) => ({
			...prevState,
			services: updatedTaggedServices,
		}));
	};

	const taggedServices = newItem.services?.map((service) => {
		return (
			<Tag
				key={service.uuid}
				itemName={service.name}
				onRemove={() => handleRemoveTaggedService(service)}
			/>
		);
	});

	//! =========== BUTTONS Handler =============

	const [showWarning, setShowWarning] = useState(false);

	const clearModal = () => {
		onClose();
		newItem.newFile && cleanupObjectUrls();
		onReset && onReset();
	};

	const handleWarningDiscard = () => {
		setShowWarning(false);
		clearModal();
	};

	const handleConfirmEditMedia = () => {
		onConfirm(newItem);
		clearModal();
	};

	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();

	useEffect(() => {
		// Create object URLs when postMedia changes

		const loadItem = async (media) => {
			let url;
			if (media.multipartFile.type.startsWith("image")) {
				url = URL.createObjectURL(media.multipartFile);
			} else if (media.multipartFile.type.startsWith("video")) {
				url = await getVideoFrame(media.multipartFile);
			} else {
				url = getFileIcon(media, media.name).icon;
			}

			setObjectUrls([url]);
		};

		if (newItem.newFile && show) {
			// const newUrl = URL.createObjectURL(newItem.multipartFile);
			// setObjectUrls([newUrl]);
			loadItem(newItem);
		}

		// Cleanup function
		//* >>  cleanup when pressing back or 'save'
	}, [newItem, show]);

	return (
		<>
			{show && (
				<GaawkModal
					show={show}
					handleClose={() => {
						//TODO: enhancement >> only open warning if changes detected
						setShowWarning(true);
					}}
					defaultModal={false}
					showHeader={true}
					title={"Edit details"}
					closeAlign={"left"}
					children={
						<div
							className={`${styles.create_post_modal_container} ${styles.scrollable_modal}`}
						>
							<MediaItem
								mediaType={newItem.newFile ? newItem.type : newItem.file?.mediaType}
								url={
									newItem.newFile
										? objectUrls[0] // URL.createObjectURL(newItem.multipartFile)
										: newItem.file?.url
								}
								fileName={
									newItem.newFile
										? newItem.multipartFile.name
										: newItem.file?.originalName
								}
								text={newItem.newFile ? newItem.caption : newItem.postText}
								singleMedia={true}
							/>

							<EditMediaButtons
								ref={ref}
								mediaType={newItem.type}
								// isEditing={isEditing}
								onLocation={handleShowLocationModal}
								onTag={handleShowTagModal}
								onDatePicker={handleDatePicker}
								onCrop={handleShowCropModal}
								onServices={handleShowServicesModal}
								isNewFile={newItem.newFile}
							/>

							<div className={styles.form_input_wrapper}>
								<label htmlFor="group-description">Description</label>

								<CustomMentions
									maxLength={maxPostLength}
									placeholder={"Description"}
									value={newItem.caption || ""}
									onChange={handleCaptionChange}
									showEmojiButton={false}
									fixedHeight={true}
									h={96}
								/>
							</div>

							{newItem.location && (
								<div
									className={`${styles.form_input_wrapper} ${styles.media_edit}`}
								>
									<label htmlFor="group-description">Location</label>
									<div className={styles.media_data}>
										<div>
											<img src={locationIcon} alt="location" />
											{newItem.location.title}
										</div>
										<img
											src={greyClose}
											className={styles.delete_data}
											onClick={handleRemoveLocation}
											alt="close"
										/>
									</div>
								</div>
							)}

							{newItem.date !== undefined && newItem.date !== 0 && (
								<div
									className={`${styles.form_input_wrapper} ${styles.media_edit}`}
								>
									<label htmlFor="group-description">Date</label>
									<div className={styles.media_data}>
										<div>
											{/* <img
													src={calendarIcon}
													alt="Date"
												/> */}
											<CalendarIcon />

											{format(new Date(newItem.date), "dd/MM/yyyy")}
										</div>
										<img
											src={greyClose}
											className={styles.delete_data}
											onClick={handleRemoveMediaDate}
											alt="close"
										/>
									</div>
								</div>
							)}

							{taggedProfiles && taggedProfiles.length > 0 && (
								<div
									className={`${styles.form_input_wrapper} ${styles.media_edit}`}
								>
									<label htmlFor="group-description">Tagged profiles</label>
									<div className={styles.taggedProfiles_container}>
										{taggedProfiles}
									</div>
								</div>
							)}

							{taggedServices && taggedServices.length > 0 && (
								<div
									className={`${styles.form_input_wrapper} ${styles.media_edit}`}
								>
									<label htmlFor="group-description">Tagged services</label>
									<div className={styles.selected_tags_container}>
										{taggedServices}
									</div>
								</div>
							)}

							<div className={styles.button_container}>
								<GaawkButton text={"Save"} onClick={handleConfirmEditMedia} />
							</div>
						</div>
					}
				/>
			)}

			{newItem?.newFile && (
				<CropModal
					image={objectUrls[0]}
					show={showCropModal}
					onClose={handleHideCropModal}
					onCrop={(cropped) => {
						handleCrop(cropped);
						setShowCropModal(false);
						handleHideCropModal();
					}}
					returnBlob={true}
				/>
			)}

			<LocationModal
				show={showLocationModal}
				onClose={handleCloseLocationModal}
				onLocationClicked={handleLocationClicked}
			/>

			<UserListModal
				show={showTagModal}
				onClose={handleHideTagModal}
				title="Tag People / Companies"
				fetchUsers={fetchTags}
				renderUser={renderTag}
				hasSearch={true}
				queryKey={(query) => profileKeys.searchTags(query)}
				closeAlign={"left"}
				showSelectedContainer={true}
				selectedUsers={mediaTags}
				onConfirm={handleConfirmTags}
				onRemove={handleRemoveTag}
				pageSize={itemsPerPage}
			/>

			<ServicesTagModal
				show={showServicesModal}
				handleClose={handleHideServicesModal}
				onConfirm={handleServicesChange}
				currentTags={newItem.services}
			/>

			<WarningModal
				show={showWarning}
				modalOnTop={true}
				headerText="Are you sure you want to discard your changes?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setShowWarning(false)}
				submitButtonText={"DISCARD"}
				onSubmitButtonClicked={handleWarningDiscard}
			/>
		</>
	);
};

export default EditMediaModal;
