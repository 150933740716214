import Modal from "react-bootstrap/Modal";

import styles from "./FullScreenModal.module.scss";
// import BottomNavBar from "../SubComs/bottomNavBar/BottomNavBar";
// import {showChatProfile} from "../../Chat/RightSideHeader";
// import ShowChatProfile from "../../Chat/ShowChatProfile";

// import backIcon from "images/back-arrow-white-20-x-17.svg";
// import closeIcon from "images/close-icon-white-20-x-20.svg";
// import optionsIcon from "images/three-dots-white-5-x-20.svg";

// import FileViewer from "../FileViewer/FileViewer";

const FullScreenModal = ({
	show,
	escKeyboard = true,
	children,
	header,
	onClose,
	customStyle,
	fullWidth = false,
	fullscreenEnabled = false,
}) => {
	return (
		<Modal
			show={show}
			animation={false}
			// onHide={onClose}
			backdrop={true}
			className={`${styles.modal_container} ${customStyle} ${
				fullWidth ? styles.full_width_modal : undefined
			}`}
			backdropClassName={styles.backdrop}
			dialogClassName={styles.dialog}
			contentClassName={styles.content}
			keyboard={escKeyboard}
			onEscapeKeyDown={onClose}
			// onBackdropClick={onClose} // not working
		>
			{header && (
				<Modal.Header className={styles.header}>
					{header}
					{/* <div className={styles.header_left_container}>
                    <img src={backIcon} alt={"back"} className={styles.back_arrow_img} onClick={handleClose} />
                    <ShowChatProfile data={profile} handleProfileClick={onProfileClick} />
                </div>

                <div className={styles.header_right_container}>
                    <img src={closeIcon} alt={"close"} className={styles.close_img} onClick={handleClose} />
                    <img src={optionsIcon} alt={"options"} className={styles.option_img} />
                </div> */}
				</Modal.Header>
			)}

			{/* <Modal.Body className={styles.body}>
                <div className={styles.body_container}>{children}</div>
            </Modal.Body>

            <Modal.Footer className={styles.footer}>
                <BottomNavBar fluid={true} />
            </Modal.Footer> */}
			{children}
		</Modal>
	);
};

export default FullScreenModal;
