import { joiResolver } from "@hookform/resolvers/joi";
import routes from "components/Routing/routing-keys";
import useApi from "hooks/useApi";
import { useCustomQuery } from "hooks/useCustomQuery";
import useMutate from "hooks/useMutate";
import Joi from "joi";
import { calendarKeys } from "queryKeys/calendar-key-factory";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { components } from "react-select";
import { toast } from "react-toastify";
import calendarApi from "../../../api/calendar";
import { entryTypes } from "../../Utils/General";
import CustomSelect from "../../Utils/SubComs/CustomSelect/CustomSelect";
import DayPicker from "../../Utils/SubComs/DayPicker/DayPicker";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import styles from "./CalendarEntry.module.css";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";

const CalendarEntry = () => {
	const navigate = useNavigate();

	const { id } = useParams();

	const editMode = !!id;

	const getCalendarEntryApi = useApi(calendarApi.getEntry, true, true);

	const fetchEntry = async ({ queryKey }) => {
		const [_, entryId] = queryKey;
		const response = await getCalendarEntryApi.request(entryId);
		return response.data;
	};

	const { data, isSuccess } = useCustomQuery({
		queryKey: calendarKeys.entryById(id),
		queryFn: fetchEntry,
		enabled: editMode,
	});

	useEffect(() => {
		if (isSuccess) {
			trackEvent(eventsDictionary.PROFILE.ADD_CALENDAR_EVENT, {
				isEditing: true,
			});
		} else {
			trackEvent(eventsDictionary.PROFILE.ADD_CALENDAR_EVENT);
		}
	}, [isSuccess]);

	const { label, notes, startTime, endTime, type: entryType, uuid: entryId } = data || {};

	const schema = Joi.object({
		startDate: Joi.date().required().label("Start Date").messages({
			"any.required": "Start Date should not be empty",
			"date.base": "Start Date should be a valid date",
		}),
		endDate: Joi.date().required().label("End Date").min(Joi.ref("startDate")).messages({
			"any.required": "End Date should not be empty",
			"date.base": "End Date should be a valid date",
			"date.min": "End date should be greater or equal to Start date",
		}),
		entryType: Joi.object({
			value: Joi.string(),
			label: Joi.string(),
		})
			.required()
			.messages({
				"any.required": "Entry type can't be empty",
				"object.base": "Entry type can't be empty",
			})
			.label("Entry type"),
		label: Joi.string().allow("").label("Label"),
		notes: Joi.string().allow("").label("Notes"),
	});

	const {
		formState: { errors },
		reset,
		register,
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: {
			startDate: null,
			endDate: null,
			entryType: null,
			label: "",
			notes: "",
		},
	});

	useEffect(() => {
		if (data) {
			reset({
				startDate: new Date(startTime),
				endDate: new Date(endTime),
				entryType: entryTypes.find((entry) => entry.value === entryType),
				label,
				notes,
			});
		}
	}, [data]);

	// ! SAVE ========================================================

	const handleSuccess = () => {
		navigate(routes.calendar);
	};

	const handleError = (error) => {
		if (error.code === 406) toast.error("You already have a calendar entry during these dates");
	};

	const {
		action: { mutate: addEntry, isLoading: isLoadingAdd },
	} = useMutate(
		calendarApi.addEntry,
		() => {
			trackEvent(eventsDictionary.PROFILE.ADD_CALENDAR_EVENT_DONE);
			handleSuccess();
		},
		handleError,
		{
			meta: {
				enableError: false,
			},
		}
	);
	const {
		action: { mutate: updateEntry, isLoading: isLoadingEdit },
	} = useMutate(
		calendarApi.updateEntry,
		() => {
			trackEvent(eventsDictionary.PROFILE.ADD_CALENDAR_EVENT_DONE, {
				isEditing: true,
			});
			handleSuccess();
		},
		handleError,
		{
			meta: {
				enableError: false,
			},
		}
	);

	const handleSave = (data) => {
		const {
			label,
			notes,
			startDate,
			endDate,
			entryType: { value: type },
		} = data;

		const body = {
			...(editMode && { entryId }),
			startDate: startDate.getTime(),
			endDate: endDate.getTime(),
			label,
			notes,
			type,
		};

		if (editMode) updateEntry({ body });
		else addEntry({ body });
	};

	return (
		<form
			className={styles.calendar_form}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			<div className={styles.form_input_wrapper}>
				<label className={styles.input_label}>
					Entry Type<span className="required">*</span>
				</label>
				<CustomSelect
					options={entryTypes}
					placeholder="Select an entry"
					isSearchable={false}
					components={{
						Option: (props) => {
							const label = props.data.label;
							return (
								<components.Option {...props}>
									<div className={styles.option_item}>
										<div
											className={styles.color_pin}
											style={{
												backgroundColor:
													label === "Booked"
														? "#ffad5c"
														: label === "Penciled"
														? "#83d1ad"
														: "#a6b1bc",
											}}
										/>
										{label}
									</div>
								</components.Option>
							);
						},
						Control: ({ children, ...props }) => {
							const label = props.getValue()[0]?.label;
							return (
								<components.Control {...props}>
									{label !== undefined && (
										<div
											className={`${styles.color_pin} ${styles.control}`}
											style={{
												backgroundColor:
													label === "Booked"
														? "#ffad5c"
														: label === "Penciled"
														? "#83d1ad"
														: "#a6b1bc",
											}}
										/>
									)}
									{children}
								</components.Control>
							);
						},
					}}
					height={35}
					control={control}
					name="entryType"
					error={!!errors.entryType}
				/>
				{errors?.entryType?.message && (
					<p className={styles.error_message}>{errors?.entryType?.message}</p>
				)}
			</div>

			<div className={styles.datePickerWrapper}>
				<div className={styles.datePickerItem}>
					<label>
						Start Date
						<span className="required">*</span>
					</label>
					<DayPicker
						className={`form-start-date ${!!errors.startDate ? "error" : undefined}`}
						control={control}
						name="startDate"
					/>
					{errors?.startDate?.message && (
						<p className={styles.error_message}>{errors?.startDate?.message}</p>
					)}
				</div>
				<div className={styles.datePickerItem}>
					<label>
						End Date
						<span className="required">*</span>
					</label>
					<DayPicker
						className={`form-end-date ${!!errors.endDate ? "error" : undefined}`}
						control={control}
						name="endDate"
					/>
					{errors?.endDate?.message && (
						<p className={styles.error_message}>{errors?.endDate?.message}</p>
					)}
				</div>
			</div>

			<div className={styles.form_input_wrapper}>
				<label className={styles.input_label}>Label</label>
				<TextInput {...register("label")} placeholder={"Add label"} />
			</div>

			<Controller
				name={"notes"}
				control={control}
				render={({ field }) => (
					<TextArea
						className={styles.textarea_wrapper}
						label="Notes"
						error={errors.notes}
						{...field}
						placeholder="Enter notes"
					/>
				)}
			/>

			<div className={styles.button_wrapper}>
				<GaawkButton
					type="submit"
					isLoading={isLoadingAdd || isLoadingEdit}
					text={"Save"}
					className={styles.button}
				/>
			</div>
		</form>
	);
};

export default CalendarEntry;
