import { useRef, useState } from "react";
// import SocketSubscription from "./components/Chat/SocketSubscription";
import { ToastContainer, toast } from "react-toastify";
// import NotificationChecker from "./components/Notifications/NotificationChecker";
import { RouterProvider } from "react-router-dom";
// import { useSelector } from "react-redux";
// import {
// 	messaging,
// 	//  onMessageListener,
// } from "./firebase";
import getNotificationDetails from "components/Utils/Notifications/notification-details";
import NotificationBubble from "components/Utils/Notifications/NotificationBubble";
import { router } from "./AppRoutes";
import useSpecialAction from "components/Utils/Notifications/useSpecialAction";
// import useCompanyId from "hooks/useCompanyId";
import useProfileSwitch from "hooks/useProfileSwitch";
// import styles from "react-day-picker/dist/style.css";
import ProfileSwitchModal from "components/Utils/Notifications/ProfileSwitchModal";
import { specialNotificationActions } from "components/Utils/Notifications/special-notification-actions";
import useCurrentUser from "hooks/useCurrentUser";
import { getMessaging, onMessage } from "firebase/messaging";
import usePageLeave from "hooks/usePageLeave";
import { logout } from "components/Utils/General";

const AppEntryPoint = () => {
	// onMessageListener()
	// 	.then((notification) => {
	// 		// console.log("🚀 ~ FOREGROUND NOTIFICATION >>", notification);
	// 		const parsedNotification = JSON.parse(notification.data.payload);
	// 		// console.log("🚀 ~ parsedNotification >>", parsedNotification);

	// 		const { body, title, url } =
	// 			getNotificationDetails(parsedNotification);

	// 		toastId.current = toast(
	// 			<div>
	// 				<NotificationBubble
	// 					notification={parsedNotification}
	// 					body={body}
	// 					title={title}
	// 					onClick={() => {
	// 						if (profileId !== parsedNotification.to.uuid) {
	// 							handleSwitchModal({
	// 								...parsedNotification,
	// 								url,
	// 							});
	// 						} else {
	// 							handleNotification({
	// 								...parsedNotification,
	// 								url,
	// 							});
	// 						}

	// 						dismiss();
	// 					}}
	// 				/>
	// 			</div>,
	// 			{
	// 				position: "top-right",
	// 				autoClose: 5000,
	// 				hideProgressBar: true,
	// 				closeOnClick: false,
	// 				pauseOnHover: true,
	// 				draggable: true,
	// 				progress: undefined,
	// 				theme: "light",
	// 			}
	// 		);
	// 	})
	// 	.catch((err) => console.log("failed: ", err));

	return (
		<>
			{/* {uuid && <SocketSubscription />} */}
			{/* <ToastContainer className="toast_container" /> */}
			<RouterProvider router={router} />

			{/* <NotificationChecker /> */}
			{/* <ProfileSwitchModal
				show={switchModal}
				onClose={() => setSwitchModal(false)}
				data={notification?.to}
				onConfirm={() => {
					notification?.to.type === "COMPANY"
						? switchToCompany(notification?.to.uuid)
						: switchToProfile(notification?.to.uuid);
				}}
			/> */}
		</>
	);
};

export default AppEntryPoint;
