import { classNames } from "primereact/utils";
import styles from "./GaawkButton.module.scss";
import LoadingSpinner from "../SubComs/LoadingSpinner/LoadingSpinner";
import { forwardRef } from "react";

const GaawkButton = forwardRef(
	(
		{
			type = "button",
			onClick,
			disabled = false,
			isLoading = false,
			className,
			text,
			severity = "primary", //* other values can be: "secondary", "tertiary", "quaternary", "black", "danger"
			size = "normal", //* other value can be "small"
		},
		ref
	) => {
		return (
			<button
				ref={ref}
				type={type}
				onClick={onClick}
				disabled={disabled || isLoading}
				className={classNames(styles.gaawk_button, className, {
					[styles.disabled]: disabled,
					[styles.loading]: isLoading,
					[styles.small]: size === "small",
					[styles.primary]: severity === "primary",
					[styles.secondary]: severity === "secondary",
					[styles.tertiary]: severity === "tertiary",
					[styles.quaternary]: severity === "quaternary",
					[styles.black]: severity === "black",
					[styles.blackSecondary]: severity === "black-secondary",
					[styles.danger]: severity === "danger",
				})}
			>
				{isLoading ? (
					<LoadingSpinner
						visible={isLoading}
						color={"#FFFFFF"}
						size={20}
						customStyle={styles.custom_loader}
					/>
				) : (
					text
				)}
			</button>
		);
	}
);

export default GaawkButton;
