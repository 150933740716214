import { useState, useRef, useMemo } from "react";
import styles from "./VaultItemViewer.module.css";
import { useParams } from "react-router-dom";
import vaultApi from "../../api/vault";
import useApi from "../../hooks/useApi";
import FileViewer from "../Utils/FileViewer/FileViewer";
import ViewerHeader from "../Utils/FileViewer/ViewerHeader";
import { featuresSection, viewerMapper } from "../Utils/General";
import VaultActions from "./VaultActions";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import DefaultPage from "../Utils/PageArchs/DefaultPage/DefaultPage";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import { useNavigate } from "react-router-dom";
import VaultShareModal from "./VaultShareModal";
import routes from "components/Routing/routing-keys";
import { useCustomQuery } from "hooks/useCustomQuery";
import { vaultKeys } from "queryKeys/vault-key-factory";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import useMutate from "hooks/useMutate";
import { useQueryClient } from "@tanstack/react-query";
// import useMutate from "hooks/useMutate";

const VaultItemViewer = () => {
	const queryClient = useQueryClient();

	const navigate = useNavigate();

	const getVaultItemApi = useApi(vaultApi.getItemById, true, true);

	const fileviewerRef = useRef();

	const { id } = useParams();

	const [shareModal, setShareModal] = useState(false);

	const fetchItem = async ({ signal, queryKey }) => {
		const [_, __, id] = queryKey;
		const response = await getVaultItemApi.request(id);
		return response.data;
	};

	const { data: item, isLoading } = useCustomQuery({
		queryKey: vaultKeys.vaultItem(id),
		queryFn: fetchItem,
	});

	const formattedFile = useMemo(() => item && viewerMapper([item]), [item]);

	const handleDownload = () => {
		FileSaver.saveAs(item.file.customName, item.file.originalName);
	};

	const {
		action: { mutate: makeFavorite },
	} = useMutate(vaultApi.updateFavorite, () => {
		queryClient.setQueryData(vaultKeys.vaultItem(id), (oldData) => {
			return {
				...oldData,
				favorite: !oldData.favorite,
			};
		});
	});

	if (isLoading) return <LoadingPage fullPage />;

	return (
		<>
			<DefaultPage
				leftSideChildren={<FeaturesSidebar active={featuresSection.vault} />}
				headerRightContent={
					<ViewerHeader
						onClose={() => navigate(routes.vaultFolder())}
						leftSide={<div className={styles.parentFolderName}>Your Vault</div>}
						rightSide={
							<VaultActions
								isFavorite={item?.favorite}
								onFavorite={() =>
									makeFavorite({
										itemId: item.uuid,
										status: !item.favorite,
									})
								}
								onDownload={handleDownload}
								onShare={() => {
									setShareModal(true);
								}}
								onFullscreen={() => fileviewerRef.current.enterFullscreen()}
							/>
						}
					/>
				}
				rightSideChildren={
					<FileViewer
						ref={fileviewerRef}
						files={formattedFile}
						isLoading={isLoading}
						text={item?.file?.originalName}
					/>
				}
				rightSideFullWidth={true}
			/>

			<VaultShareModal
				show={shareModal}
				onClose={() => {
					setShareModal(false);
				}}
				items={item}
			/>
		</>
	);
};

export default VaultItemViewer;
