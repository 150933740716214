import { useQuery, useQueryClient } from "@tanstack/react-query";
import jobApi from "api/job";
import useGetQueryParam from "hooks/useGetQueryParam";
import useMutate from "hooks/useMutate";
import { searchKeys } from "queryKeys/search-key-factory";
import searchApi from "../../../api/search";
import useApi from "../../../hooks/useApi";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import Jobs from "../SearchComponents/Jobs";
import PeopleAndCompanies from "../SearchComponents/PeopleAndCompanies";
import Posts from "../SearchComponents/Posts";
import Products from "../SearchComponents/Products";
import Services from "../SearchComponents/Services";
import styles from "./All.module.scss";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const All = ({ onViewMore, userCoordinates }) => {
	const { paramValue: inputValue } = useGetQueryParam("q");

	const queryClient = useQueryClient();

	const searchAllApi = useApi(searchApi.searchAll, true, true);

	const searchAll = async ({ queryKey }) => {
		const [_, __, searchInput, locationId] = queryKey;
		const response = await searchAllApi.request(searchInput, locationId);
		return response.data;
	};

	const queryKey = searchKeys.searchAll(inputValue, userCoordinates.id);

	const { data = {}, isLoading } = useQuery({
		queryKey,
		queryFn: searchAll,
		enabled: !!userCoordinates.id,
	});

	const handleInvalidateQuery = () => {
		queryClient.invalidateQueries(queryKey);
	};

	const {
		action: { mutate: toggleSave },
	} = useMutate(jobApi.toggleSaveJob, handleInvalidateQuery);

	useEffect(() => {
		trackEvent(eventsDictionary.SEARCH.ALL);
	}, []);

	if (isLoading) return <LoadingSpinner />;

	return (
		<>
			{data.users?.count > 0 && (
				<div className={styles.section}>
					<div className={styles.sub_section}>
						<PeopleAndCompanies
							count={data.users?.count}
							data={data.users?.list}
							onClick={() => onViewMore("people")}
						/>
					</div>
				</div>
			)}

			{data.companies?.count > 0 && (
				<div className={styles.section}>
					<div className={styles.sub_section}>
						<PeopleAndCompanies
							count={data.companies?.count}
							data={data.companies?.list}
							isCompany={true}
							onClick={() => onViewMore("companies")}
						/>
					</div>
				</div>
			)}

			{data.products?.count > 0 && (
				<div className={styles.section}>
					<div className={styles.sub_section}>
						<Products
							count={data.products?.count}
							data={data.products?.list}
							onClick={() => onViewMore("products")}
						/>
					</div>
				</div>
			)}

			{data.services?.count > 0 && (
				<div className={styles.section}>
					<div className={styles.sub_section}>
						<Services
							count={data.services?.count}
							data={data.services?.list}
							onClick={() => onViewMore("services")}
						/>
					</div>
				</div>
			)}

			{data.jobs?.count > 0 && (
				<div className={styles.section}>
					<div className={styles.sub_section}>
						<Jobs
							count={data.jobs?.count}
							data={data.jobs?.list}
							onClick={() => onViewMore("jobs")}
							onToggleSave={toggleSave}
						/>
					</div>
				</div>
			)}

			{data.posts?.count > 0 && (
				<div className={styles.section}>
					<div className={`${styles.sub_section} ${styles.posts_container}`}>
						<Posts
							count={data.posts?.count}
							data={data.posts?.list}
							onRefresh={handleInvalidateQuery}
							onClick={() => onViewMore("posts")}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default All;
