import BackButton from "../../../components/Utils/Button/BackButton";
import GaawkModal from "../../../components/Utils/GaawkModal/GaawkModal";
import DefaultPage from "../../../components/Utils/PageArchs/DefaultPage/DefaultPage";

import styles from "./Filter.module.css";
import { useNavigate } from "react-router-dom";

const Filter = ({
	title,
	leftSideChildren,
	rightSideChildren,
	isMobile,
	onCloseModal,
	hiddenMode,
	headerButton,
}) => {
	const navigate = useNavigate();

	return (
		<>
			<DefaultPage
				headerRightContent={
					<>
						<div className={styles.header}>
							<BackButton onBack={() => navigate(-1)} />
							{title}
						</div>
						{headerButton}
					</>
				}
				leftSideChildren={!isMobile && leftSideChildren}
				rightSideChildren={rightSideChildren}
				rightSideBgColor={"#FFF"}
			/>

			<GaawkModal
				show={isMobile}
				handleClose={onCloseModal}
				defaultModal={false}
				showHeader={true}
				title={title}
				closeAlign={"right"}
				hiddenMode={hiddenMode}
				children={leftSideChildren}
			/>
		</>
	);
};

export default Filter;
