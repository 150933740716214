import useCurrentUser from "hooks/useCurrentUser";
import styles from "./Invoice.module.scss";
import { format } from "date-fns";
import blueTick from "images/blue-tick-35-x-35.svg";
import { useNavigate } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Invoice = ({ plan }) => {
	const navigate = useNavigate();
	const { type, firstName, lastName, name } = useCurrentUser();

	const {
		uuid,
		registrationDate,
		//  price,
		//   discount
	} = plan;
	return (
		<div className={styles.container}>
			<div className={styles.invoice_container}>
				<div className={styles.icon_container}>
					<img src={blueTick} alt="" />
				</div>
				<h1 className={styles.title}>Successful Payment !</h1>
				<p className={styles.subtitle}>Subscription successful! You're all set.</p>

				<div className={styles.totalPayment}>
					<p className={styles.subtitle}>Total payment</p>
					<span className={styles.amount}>
						{/* //* Currently hardcoded to 0 */}
						{/* {price - discount} */} {"0.00"}
					</span>
					<span className={styles.currency}>AED</span>
				</div>

				<div className={styles.detailsGrid}>
					<div className={styles.detailItem}>
						<span className={styles.detailLabel}>Ref Number</span>
						<span className={styles.detailValue}>{uuid}</span>
					</div>
					<div className={styles.detailItem}>
						<span className={styles.detailLabel}>Payment Time</span>
						<span className={styles.detailValue}>
							{format(new Date(registrationDate), "dd/MM/yyyy")}
						</span>
					</div>
					<div className={styles.detailItem}>
						<span className={styles.detailLabel}>Payment Method</span>
						<span className={styles.detailValue}>{"--"}</span>
					</div>
					<div className={styles.detailItem}>
						<span className={styles.detailLabel}>Sender Name</span>
						<span className={styles.detailValue}>
							{type === "USER" ? `${firstName} ${lastName}` : name}
						</span>
					</div>
					<div className={styles.detailItem}>
						<span className={styles.detailLabel}>VAT Number</span>
						<span className={styles.detailValue}>{"--"}</span>
					</div>
				</div>
			</div>

			<div className={styles.button_container}>
				<GaawkButton
					text={"Back To Your Dashboard"}
					onClick={() => navigate(routes.plans)}
				/>
			</div>
		</div>
	);
};

export default Invoice;
