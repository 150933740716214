import { useQuery, useQueryClient } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import useApi from "hooks/useApi";
import useMutate from "hooks/useMutate";
import useProfileSwitch from "hooks/useProfileSwitch";
import { classNames } from "primereact/utils";
import { profileKeys } from "queryKeys/profile-key-factory";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import profileApi from "../../api/profile";
import CompanyItem from "../Companies/CompanyItem";
import ProfilePic from "../Home/ProfilePic";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import AdminRepEdit from "./AdminRepEdit";
import styles from "./CompanyManage.module.scss";
import ManageSection from "./ManageSection";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const CompanyManage = ({ companyProfile, isLoadingProfile }) => {
	const navigate = useNavigate();

	const {
		uuid: companyId,
		owner,
		locationDTO,
		companyUserType,
		agency,
		url,
		profileImage,
		name,
	} = companyProfile || {};

	useEffect(() => {
		trackEvent(eventsDictionary.COMPANY.MANAGE);
	}, []);

	const getCompanyUsersApi = useApi(profileApi.getCompanyUsers, true, true);

	const fetchCompanyUsers = async ({ queryKey }) => {
		const [_, companyId, userType] = queryKey;
		const response = await getCompanyUsersApi.request(companyId, userType);
		return response.data;
	};

	//! FETCHING EXISTING ADMINS =================

	const { data: admins = [] } = useQuery({
		queryKey: profileKeys.companyUsersType(companyId, "ADMIN"),
		queryFn: fetchCompanyUsers,
		enabled: !isLoadingProfile,
	});

	const adminsIds = admins?.map((admin) => admin.uuid);

	//! FETCHING EXISTING REPRESENTATIVES =================

	const { data: reps = [] } = useQuery({
		queryKey: profileKeys.companyUsersType(companyId, "REPRESENTATIVE"),
		queryFn: fetchCompanyUsers,
		enabled: !isLoadingProfile,
	});

	const repsIds = reps?.map((rep) => rep.uuid);

	// ! ================ DELETE COMPANY USERS ==============================

	const {
		action: { mutate: deleteCompanyUser },
	} = useMutate(profileApi.deleteCompanyUser, (_, variables) => {
		const { userType } = variables;
		queryClient.invalidateQueries(profileKeys.companyUsersType(companyId, userType));
	});

	// ! ================ ADD COMPANY USERS ==============================

	const queryClient = useQueryClient();

	const {
		action: { mutate: addCompanyUser },
	} = useMutate(profileApi.addCompanyUser, (_, variables) => {
		const { userType, invalidateAll } = variables;

		queryClient.invalidateQueries(
			invalidateAll
				? profileKeys.companyUsers(companyId)
				: profileKeys.companyUsersType(companyId, userType)
		);
	});

	const [selectedUser, setSelectedUser] = useState("");
	const [alertModal, setAlertModal] = useState("");

	const handleSelected = (user, mode) => {
		const { label: name, value: uuid, ...userProps } = user;
		const formattedUserObject = { name, uuid, ...userProps };

		setSelectedUser(formattedUserObject);

		if (mode === "admin") {
			if (repsIds.includes(uuid)) {
				setAlertModal(mode);
			} else {
				addCompanyUser({ companyId, uuid, userType: "ADMIN" });
			}
		} else {
			if (adminsIds.includes(uuid)) {
				setAlertModal(mode);
			} else {
				addCompanyUser({ companyId, uuid, userType: "REPRESENTATIVE" });
			}
		}
	};

	const handleConfirmSwitch = () => {
		addCompanyUser({
			companyId,
			profileId: selectedUser.uuid,
			userType: alertModal === "admin" ? "ADMIN" : "REPRESENTATIVE",
			invalidateAll: true,
		});

		setAlertModal("");
	};

	// !======================== DELETE COMPANY =========================

	const { uuid: profileId } = useSelector((state) => state.user.profileInfo);

	const { switchToProfile } = useProfileSwitch(false, () => navigate(routes.root));

	const {
		action: { mutate: deleteCompany },
	} = useMutate(profileApi.deleteCompany, () => {
		//* >> IF YOU ARE LOGGED AS THE COMPANY YOU'RE TRYING TO DELETE
		//* >> THEN SWITCH BACK TO YOUR MAIN PROFILE
		trackEvent(eventsDictionary.COMPANY.MANAGE_DELETE_DONE);
		switchToProfile(profileId);
	});

	const [deleteModal, setDeleteModal] = useState(false);

	// !=================================================================

	return (
		<div className={styles.container}>
			<div className={styles.company_item}>
				<CompanyItem
					item={companyProfile}
					subdata={`${locationDTO.name}, ${locationDTO.countryDTO.name}`}
				/>
			</div>

			{companyUserType === "OWNER" && (
				<ManageSection
					title="Become Agency"
					text={
						<>
							By becoming an agency you can represent and manage talents, people with
							their talent profiles can send you requests to be on your talent page.
							{agency && (
								<span className={styles.agency_message}>
									Congrats! You are one of our agency now!
								</span>
							)}
						</>
					}
					btnText={agency ? "Cancel Agency Subscription" : "Become Agency"}
					severity={agency ? "tertiary" : "primary"}
					onClick={() => navigate(`/profile/${url}/manage/agency`)} //TODO >> to be added to routing-keys.js file
				/>
			)}

			<AdminRepEdit
				mode="admin"
				people={admins}
				onSelect={(user) => handleSelected(user, "admin")}
				company={companyProfile}
				onRemove={(profileId) =>
					deleteCompanyUser({
						companyId,
						profileId,
						userType: "ADMIN",
					})
				}
			/>

			<AdminRepEdit
				mode="representative"
				people={reps}
				onSelect={(user) => handleSelected(user, "representative")}
				company={companyProfile}
				onRemove={(profileId) =>
					deleteCompanyUser({
						companyId,
						profileId,
						userType: "REPRESENTATIVE",
					})
				}
			/>

			<div
				className={classNames(styles.owner_details_wrapper, {
					[styles.bordered]: companyUserType === "OWNER",
				})}
			>
				<h4>Company Owner</h4>

				<ProfilePic
					thumbnail={owner?.profileImage?.image}
					w={36}
					h={36}
					type={owner.type}
					name={owner.name}
					createMode={true}
					url={owner.url}
					subdata={owner.tagLine}
				/>
			</div>

			{companyUserType === "OWNER" && (
				<ManageSection
					title="Company Ownership"
					text="You are the owner of this company on Gaawk. You can transfer the ownership to someone else."
					btnText="Transfer Ownership"
					onClick={() => navigate(`/profile/${url}/manage/transfer`)} //TODO >> to be added to routing-keys.js file
				/>
			)}

			{companyUserType === "OWNER" && (
				<div className={styles.button_container}>
					<GaawkButton
						text={"DELETE COMPANY"}
						severity={"danger"}
						onClick={() => {
							setDeleteModal(true);
							trackEvent(eventsDictionary.COMPANY.MANAGE_DELETE_INITIATE);
						}}
					/>
				</div>
			)}

			<GaawkModal
				show={alertModal}
				handleClose={() => setAlertModal("")}
				defaultModal={false}
				showHeader={true}
				title={`Add new ${alertModal}`}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.warning_wrapper}>
							<div className={styles.image_wrapper}>
								<ProfilePic
									thumbnail={selectedUser.profileImage?.image}
									w={36}
									h={36}
									type={"USER"}
								/>
							</div>

							<div className={styles.warning_text}>
								{`${selectedUser.name} is ${
									alertModal === "admin" ? "a representative" : "an admin"
								}.
                                If you add them to ${
									alertModal === "admin" ? "admins" : "representatives"
								}, they will be removed from ${
									alertModal === "admin" ? "representatives" : "admins"
								}.
                                Are you sure you want to do this?`}
							</div>
						</div>
						<div className={styles.modal_controls}>
							<GaawkButton
								text={"Cancel"}
								severity={"tertiary"}
								onClick={() => setAlertModal("")}
							/>
							<GaawkButton
								text={"Confirm"}
								severity={"danger"}
								onClick={handleConfirmSwitch}
							/>
						</div>
					</div>
				}
			/>

			<GaawkModal
				show={deleteModal}
				handleClose={() => setDeleteModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"Delete Company"}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.warning_wrapper}>
							<div className={styles.image_wrapper}>
								<ProfilePic
									thumbnail={profileImage?.originalImageURL}
									w={36}
									h={36}
									type={"COMPANY"}
								/>
							</div>

							<div className={styles.warning_text}>
								Once you delete the company, all the data and people related to this
								company will be deleted. Are you sure you want to do this action?
							</div>
						</div>
						<div className={styles.modal_controls}>
							<GaawkButton
								text={"Cancel"}
								severity={"tertiary"}
								onClick={() => setDeleteModal(false)}
							/>
							<GaawkButton
								text={"Delete"}
								severity={"danger"}
								onClick={() => deleteCompany(companyId)}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default CompanyManage;
