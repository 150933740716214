import { useQuery } from "@tanstack/react-query";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import { profileKeys } from "queryKeys/profile-key-factory";
import profileApi from "../../api/profile";
import useApi from "../../hooks/useApi";
import styles from "./CompanyList.module.css";
import UserCompanies from "./UserCompanies";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const CompanyList = () => {
	const getUserCompaniesApi = useApi(profileApi.getUserCompany, true, true);

	const fetchUserCompanies = async ({ queryKey }) => {
		const response = await getUserCompaniesApi.request();
		return response.data;
	};

	const { data: userCompanies, isFetching } = useQuery({
		queryKey: profileKeys.userCompanies(),
		queryFn: fetchUserCompanies,
	});

	const { owned, admins, reps } = userCompanies || {};

	useEffect(() => {
		trackEvent(eventsDictionary.COMPANY.MY_COMPANIES);
	}, []);

	if (isFetching) return <LoadingPage />;

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<UserCompanies
					title={owned.length > 0 ? "Companies you own" : "You don't have companies yet!"}
					text={
						owned.length > 0
							? `The followings are companies that you have created. You own these companies,
                                        but you can transfer the ownership to another person from Manage company
                                        menu.`
							: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro officia
                                        voluptatem reprehenderit voluptatibus. Facere delectus, fuga voluptatem ipsa
                                        quos eum accusamus alias possimus sint doloribus repudiandae eligendi
                                        quaerat numquam voluptatibus!`
					}
					items={owned}
					owned={true}
				/>
			</div>
			{(admins.length > 0 || reps.length > 0) && (
				<div className={styles.other_companies}>
					{admins.length > 0 && (
						<UserCompanies
							title={"Companies you're Admin in"}
							text={"You are admin in the following companies"}
							items={admins}
						/>
					)}
					{reps.length > 0 && (
						<UserCompanies
							title={"Companies you represent"}
							text={"You are a representative in the following companies"}
							items={reps}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default CompanyList;
