import { useNavigate, useLocation } from "react-router-dom";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import CompanyList from "../../components/Companies/CompanyList";
import CompanyEntry from "../../components/Companies/CompanyEntry";
import routes from "components/Routing/routing-keys";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const CompanyView = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const isCompanyAdd = pathname === routes.addCompany;

	return (
		<DefaultPage
			headerRightContent={<BackButton onBack={() => navigate(-1)} />}
			leftSideChildren={<NavBar />}
			rightSideChildren={isCompanyAdd ? <CompanyEntry /> : <CompanyList />}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default CompanyView;
