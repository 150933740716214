import GaawkModal from "../../Utils/GaawkModal/GaawkModal";
import styles from "./DeleteItemModal.module.css";
import GaawkButton from "../Button/GaawkButton";

const DeleteItemModal = ({ show, onClose, text, title, onConfirm, isLoading }) => {
	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={title}
			closeAlign={"right"}
			children={
				<div className={styles.container}>
					<div className={styles.warning_container}>{text}</div>

					<div className={styles.button_container}>
						<GaawkButton
							severity={"tertiary"}
							text={"Cancel"}
							type="button"
							onClick={onClose}
						/>
						<GaawkButton
							severity={"danger"}
							text={"Remove"}
							type="button"
							onClick={onConfirm}
							isLoading={isLoading}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default DeleteItemModal;
