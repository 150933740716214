import ProfilePic from "components/Home/ProfilePic";
import styles from "./NotificationItem.module.scss";
import getNotificationDetails from "./notification-details";
import { format, isToday } from "date-fns";
import Popover from "../Popover/Popover";
import Options from "images/three-dots-black-5-x-20.svg";
import { ReactComponent as ClearIcon } from "images/clear-icon-gray.svg";
import { ReactComponent as MarkAsRead } from "images/mark-as-read.svg";
import { useRef } from "react";
// import { useNavigate } from "react-router";
import useSpecialAction from "./useSpecialAction";
import { specialNotificationActions } from "./special-notification-actions";
import { router } from "AppRoutes";

const NotificationItem = ({ item, onRead, onDelete, onClose }) => {
	const popoverRef = useRef(null);

	const { read, from, notificationTime, action } = item;

	// const navigate = useNavigate();

	const { url, body, title } = getNotificationDetails(item);

	const handleSpecialAction = useSpecialAction();

	return (
		<button
			className={`${styles.notification_item} ${read ? styles.read : undefined}`}
			onClick={() => {
				onRead();
				specialNotificationActions.includes(action)
					? handleSpecialAction({ ...item, url })
					: router.navigate(url);
				onClose && onClose();
			}}
		>
			<div className={styles.notification_details}>
				<div className={styles.left_side}>
					<ProfilePic
						w={36}
						h={36}
						name={from.name}
						enableName={false}
						thumbnail={from?.profileImage?.image}
						type={from.type}
					/>

					<div className={styles.notification_body}>
						<h4>{title}</h4>
						<p>{body}</p>
					</div>
				</div>

				<Popover
					allow={["left"]}
					// crossAxis={15}
					ref={popoverRef}
					render={
						<div className="popover_container">
							{!read && (
								<button
									tabIndex={0}
									className={`popover_item ${styles.menu_item}`}
									onClick={(e) => {
										e.stopPropagation();
										onRead();
										popoverRef.current.closePopover();
									}}
								>
									<div className={styles.icon_wrapper}>
										<MarkAsRead width={16} />
									</div>
									<span>Mark as read</span>
								</button>
							)}
							<button
								tabIndex={0}
								className={`popover_item ${styles.menu_item}`}
								onClick={(e) => {
									e.stopPropagation();
									onDelete();
									popoverRef.current.closePopover();
								}}
							>
								<div className={styles.icon_wrapper}>
									<ClearIcon fill="#000" width={16} />
								</div>

								<span>Clear notification</span>
							</button>
						</div>
					}
				>
					<div
						tabIndex={0}
						role="button"
						className={styles.more_button}
						onClick={(e) => e.stopPropagation()}
					>
						<img src={Options} alt="options" />
					</div>
				</Popover>
			</div>

			<div className={styles.notification_time}>
				{format(
					new Date(notificationTime),
					isToday(new Date(notificationTime)) ? "h:mm aaa" : "dd/MM/yyyy"
				)}
			</div>
		</button>
	);
};

export default NotificationItem;
