import { Marker, StaticGoogleMap } from "react-static-google-map";

function StaticMap({ lat, lng, title, zoom = "13", customStyle, onClick }) {
	return (
		<StaticGoogleMap
			width={"100% !important"}
			height={"100% !important"}
			className={`img-fluid ${customStyle}`}
			size="1000x1000"
			title={title}
			zoom={zoom}
			apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
			onClick={onClick}
		>
			<Marker
				location={`${lat},${lng}`}
				label={title}
				iconURL={`https://gigshack.s3.ap-southeast-1.amazonaws.com/maps_marker.png`}
			/>
		</StaticGoogleMap>
	);
}

export default StaticMap;
