import { joiResolver } from "@hookform/resolvers/joi";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import useCurrentUser from "hooks/useCurrentUser";
import useFetchRoles from "hooks/useFetchRoles";
import useMutate from "hooks/useMutate";
import useSearchUsers from "hooks/useSearchUsers";
import { profileKeys } from "queryKeys/profile-key-factory";
import { rolesKeys } from "queryKeys/roles-key-factory";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { components } from "react-select";
import profileApi from "../../../../api/profile";
import { updateKeyPeople } from "../../../../store/slices/company";
import ProfilePic from "../../../Home/ProfilePic";
import {
	defaultFormatter,
	peopleFormatter,
} from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import keyPeopleSchema from "./keyPeople-schema";
import styles from "./KeyPeopleEntry.module.css";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import { useEffect } from "react";
import GaawkButton from "components/Utils/Button/GaawkButton";

const itemsPerPage = 20;

const KeyPeopleEntry = () => {
	const dispatch = useDispatch();

	const { uuid: companyId } = useCurrentUser();

	const {
		formState: { errors, isDirty, isSubmitSuccessful },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(keyPeopleSchema),
		mode: "onSubmit",
		defaultValues: {
			title: "",
			person: null,
		},
	});

	const navigate = useNavigate();

	const {
		action: { mutate: addKeyPeople, isLoading },
	} = useMutate(profileApi.addKeyPeople, (response) => {
		trackEvent(eventsDictionary.PROFILE.ADD_KEY_PEOPLE_DONE);
		dispatch(updateKeyPeople(response.data));
		navigate(routes.keyPeople, { replace: true });
	});

	const handleSave = (data) => {
		const {
			person: { value: profileId },
			title: { value: role },
		} = data;

		addKeyPeople({ companyId, profileId, role });
	};

	const fetchRoles = useFetchRoles();
	const fetchUsers = useSearchUsers();

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.ADD_KEY_PEOPLE);
	}, []);

	return (
		<>
			<form
				className={styles.form}
				onSubmit={handleSubmit((data) => handleSave(data))}
				noValidate
			>
				<InfiniteSearchInput
					label={"Person's name"}
					queryName={profileKeys.search}
					required={true}
					error={errors?.person}
					control={control}
					name="person"
					queryFn={fetchUsers}
					itemsPerPage={itemsPerPage}
					formatter={peopleFormatter}
					option={{
						Option: (props) => {
							const user = props.data;
							return (
								<components.Option {...props}>
									<div className={styles.member_item}>
										<ProfilePic
											thumbnail={user?.profileImage?.image}
											w={36}
											h={36}
											type={user.type}
											name={user.label}
											createMode={true}
											subdata={user.tagLine}
										/>
									</div>
								</components.Option>
							);
						},
					}}
				/>

				<InfiniteSearchInput
					label={"Title / Designation"}
					required={true}
					queryName={rolesKeys.search}
					queryFn={fetchRoles}
					itemsPerPage={itemsPerPage}
					formatter={defaultFormatter}
					error={errors?.title}
					control={control}
					name="title"
					creatable={true}
				/>

				<div className={styles.button_container}>
					<GaawkButton type={"submit"} text={"Save"} isLoading={isLoading} />
				</div>
			</form>

			<PromptModal when={isDirty && !isSubmitSuccessful} />
		</>
	);
};

export default KeyPeopleEntry;
