import { useMutation } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import galleryApi from "../../../api/gallery";
import EditMediaModal from "../../Home/EditMediaModal";
import SimpleDropZone from "../../Utils/SubComs/CustomDropZone/SimpleDropZone";
import LoadingBar from "../../Utils/SubComs/LoadingBar/LoadingBar";
import GalleryFileItem from "./GalleryFileItem";
import styles from "./GalleryFileUploader.module.css";
import GaawkButton from "components/Utils/Button/GaawkButton";

const uploadSingleFile = async ({ albumId, file, onUploadProgress }) => {
	const formData = new FormData();

	formData.append("albumId", albumId);
	formData.append(
		"multipartFile",
		file.multipartFile,
		file.multipartFile instanceof Blob ? file.multipartFile.fileName : undefined
	);
	formData.append("caption", file.caption || "");

	if (file.date) {
		formData.append("date", file.date);
	}

	if (file.location) {
		formData.append("location.latitude", file.location.latitude);
		formData.append("location.longitude", file.location.longitude);
		formData.append("location.type", file.location.type);
		formData.append("location.title", file.location.title);
	}

	if (file.taggedIds && file.taggedIds.length) {
		formData.append(
			"taggedIds",
			file.taggedIds.map((profile) => profile.uuid)
		);
	}

	const response = await galleryApi.addAlbumFiles(formData, onUploadProgress);

	if (!response.ok) {
		// throw new Error("File upload failed");
		throw response.data;
	}

	return response.data;
};

const GalleryFileUploader = () => {
	const navigate = useNavigate();
	const { albumId } = useParams();

	const [droppedFiles, setDroppedFiles] = useState([]);
	const [overallProgress, setOverallProgress] = useState(0);

	const uploadMutation = useMutation(uploadSingleFile);

	const handleUploadFiles = async () => {
		// Disable button during upload
		if (uploadMutation.isLoading) return;

		// Reset overall progress
		setOverallProgress(0);

		// Sequential uploads to ensure one file at a time
		for (let i = 0; i < droppedFiles.length; i++) {
			const file = droppedFiles[i];
			try {
				await uploadMutation.mutateAsync({
					albumId,
					file,
					onUploadProgress: (progress) => {
						// Calculate overall progress across all files
						const baseProgress = (i / droppedFiles.length) * 100;
						const currentFileProgress = (progress / 100) * (100 / droppedFiles.length);
						setOverallProgress(Math.round(baseProgress + currentFileProgress));
					},
				});
			} catch (error) {
				console.error("Upload batch stopped due to error:", error);
				break;
			}
		}

		// Navigate after all uploads complete
		navigate(routes.album(albumId), { replace: true });
	};

	/**
	 * Callbacks
	 */
	const onDropFiles = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles.length > 0) {
				const formattedFiles = [];
				for (let i = 0; i < acceptedFiles.length; i++) {
					formattedFiles.push({
						caption: "",
						multipartFile: acceptedFiles[i],
						type: acceptedFiles[i].type.includes("video")
							? "VIDEO"
							: acceptedFiles[i].type.includes("image")
							? "IMAGE"
							: "PDF",
						taggedIds: [],
						newFile: true,
					});
				}
				setDroppedFiles([...droppedFiles, ...formattedFiles]);
			}
		},
		[droppedFiles]
	);

	/**
	 * functions
	 */

	const removeFile = (index) => () => {
		setDroppedFiles(droppedFiles.filter((_, i) => i !== index));
	};

	// !=========== EDIT FILE =============
	const [showEditModal, setShowEditModal] = useState(false);
	const [editItem, setEditItem] = useState("");
	const [editItemIndex, setEditItemIndex] = useState("");

	const handleShowEditModal = (file, index) => {
		setEditItem(file);
		setEditItemIndex(index);
		setShowEditModal(true);
	};

	const handleHideEditModal = () => {
		setShowEditModal(false);
	};

	const handleConfirmEdit = (newItem) => {
		const droppedFilesCopy = [...droppedFiles];
		droppedFilesCopy[editItemIndex] = newItem;
		setDroppedFiles(droppedFilesCopy);
	};

	/**
	 * Mappers
	 */

	const mappedFiles = useMemo(
		() =>
			droppedFiles.map((file, index) => {
				return (
					<GalleryFileItem
						key={index}
						file={file}
						onRemove={removeFile(index)}
						onEdit={() => handleShowEditModal(file, index)}
					/>
				);
			}),
		[droppedFiles]
	);

	return (
		<div className={styles.container}>
			<div className={styles.upload_header}>
				<div>
					<h4>Upload Files</h4>
					<p>
						You need to add all of your media here, then press “Upload all files” to
						upload all your files into the album. Only *.jpeg *.jpg *.png *.pdf and
						video files will be accepted.
					</p>
				</div>
				{droppedFiles.length > 0 && (
					<GaawkButton
						onClick={handleUploadFiles}
						disabled={uploadMutation.isLoading}
						text={"Upload"}
					/>
				)}
			</div>

			<SimpleDropZone
				onDrop={onDropFiles}
				acceptedFiles={["image/png, image/jpg, image/jpeg, application/pdf, video/*"]}
			/>

			{uploadMutation.isLoading && droppedFiles.length > 0 && (
				<LoadingBar progress={overallProgress} />
			)}

			{mappedFiles.length > 0 && (
				<div className={styles.items_container}>
					<div className={styles.items_wrapper}>{mappedFiles}</div>
				</div>
			)}

			<EditMediaModal
				show={showEditModal}
				onClose={handleHideEditModal}
				onReset={() => setEditItem("")}
				item={editItem}
				onConfirm={handleConfirmEdit}
				onToggle={(bool) => setShowEditModal(bool)}
			/>
		</div>
	);
};

export default GalleryFileUploader;
