import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateKeyPeople } from "../../../../store/slices/company";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./KeyPeople.module.css";
import KeyPeopleItem from "./KeyPeopleItem";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import { useEffect } from "react";
import GaawkButton from "components/Utils/Button/GaawkButton";

const KeyPeople = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { keyPeople, uuid } = useCurrentUser();

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(profileApi.deleteKeyPeople, (response) => {
			trackEvent(eventsDictionary.PROFILE.DELETE_KEY_PEOPLE);
			dispatch(updateKeyPeople(response.data));
		});

	const formattedKeyPeople = useMemo(
		() =>
			keyPeople.map((item) => (
				<div key={item.profile.uuid} className={styles.key_profile}>
					<KeyPeopleItem
						title={item.profile.name}
						subtitle={item.customRole ?? item.roleDTO.name}
						onDelete={() => handleRequestMutate(item.profile)}
						image={item.profile?.profileImage?.image}
						showControls={true}
					/>
				</div>
			)),
		[keyPeople]
	);

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.KEY_PEOPLE_LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.button_wrapper}>
				<GaawkButton
					severity={"secondary"}
					text="+ Add Key People"
					onClick={() => navigate(routes.addKeyPeople)}
				/>
			</div>

			{keyPeople.length > 0 && (
				<>
					<h4>Key people you have added</h4>

					<div
						className={`${styles.key_special_profiles} ${
							keyPeople.length > 2 ? styles.align_left : undefined
						}`}
					>
						{formattedKeyPeople}
					</div>
				</>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						companyId: uuid,
						profileId: activeItem.uuid,
					})
				}
			/>
		</div>
	);
};

export default KeyPeople;
