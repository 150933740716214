import { useQuery } from "@tanstack/react-query";
import searchApi from "api/search";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useApi from "hooks/useApi";
import useDebounce from "hooks/useDebounce";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { filterKeys } from "queryKeys/filters-key-factory";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetProductsFilters, updateProductsFilters } from "store/slices/filters";
import { formatFiltersValues, measurementUnits, weightUnits } from "../../Utils/General";
import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import CustomSelect from "../../Utils/SubComs/CustomSelect/CustomSelect";
import { defaultFormatter } from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import ConnectionFilter from "./ConnectionFilter";
import styles from "./ProductsFilter.module.scss";
import useFilterAndSort from "./useFilterAndSort";
import GaawkButton from "components/Utils/Button/GaawkButton";

const FILTERS_PAGE_SIZE = 100;

const ProductsFilter = forwardRef(({ userCoordinates, companyId }, ref) => {
	const { paramValue: inputValue } = useGetQueryParam("q");

	const dispatch = useDispatch();

	const productsFiltersObject = useSelector((state) => state.filters.productsFilters);

	const storedProductsFilters = {
		...productsFiltersObject,
		q: inputValue,
		locationIds: [{ uuid: userCoordinates.id, value: userCoordinates.id }], //* formatting it like that (object in array) because of how the api request is structured and because of how formatFiltersValues() works
	};

	useEffect(() => {
		return () => {
			//CLEARS PRODUCTS SEARCH FILTERS WHEN COMPONENT UNMOUNTS
			dispatch(resetProductsFilters());
		};
	}, []);

	// ! ========= FETCHING OF DEFAULT PRODUCTS FILTERS ============

	const productsFiltersApi = useApi(searchApi.productsFilters, true, true);

	const fetchProductsFilters = async ({ queryKey }) => {
		const [_, __, filters] = queryKey;
		const response = await productsFiltersApi.request(formatFiltersValues(filters));
		return response.data;
	};

	const {
		data: productsFilters,
		// isLoading, //TODO >> loading spinner for 1st render
		isFetching,
		// isError,
	} = useQuery({
		queryKey: filterKeys.products(storedProductsFilters),
		queryFn: fetchProductsFilters,
	});

	// !========================================================

	const getProductsDynamicFilterApi = useApi(searchApi.productsDynamicFilters, true, true);

	const fetchDynamicFilters = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, type, filters] = queryKey;
		const response = await getProductsDynamicFilterApi.request(
			type,
			pageParam,
			FILTERS_PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const {
		items: dynamicColors,
		hasNextPage: hasNextPageColors,
		refetch: refetchColors,
		loadMore: loadMoreColors,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicProductsFilter("colors", storedProductsFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const {
		items: dynamicMaterials,
		hasNextPage: hasNextPageMaterials,
		refetch: refetchMaterials,
		loadMore: loadMoreMaterials,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicProductsFilter("materials", storedProductsFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const {
		items: dynamicOwners,
		hasNextPage: hasNextPageOwners,
		refetch: refetchOwners,
		loadMore: loadMoreOwners,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicProductsFilter("owners", storedProductsFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const {
		items: dynamicServices,
		hasNextPage: hasNextPageServices,
		refetch: refetchServices,
		loadMore: loadMoreServices,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicProductsFilter("services", storedProductsFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const {
		colors: colorsList = [],
		materials: materialsList = [],
		services = [],
		owners = [],
	} = productsFilters || {};

	const colorRef = useRef();
	const ownersRef = useRef();
	const materialRef = useRef();
	const serviceRef = useRef();

	const {
		colors = [],
		materials = [],
		serviceTags = [],
		ownerIds = [],
		minLength: storedMinLength = "",
		maxLength: storedMaxLength = "",
		lengthUnit,
		minWidth: storedMinWidth = "",
		maxWidth: storedMaxWidth = "",
		widthUnit,
		minHeight: storedMinHeight = "",
		maxHeight: storedMaxHeight = "",
		heightUnit,
		minWeight: storedMinWeight = "",
		maxWeight: storedMaxWeight = "",
		weightUnit,
		friends,
		inMyCircle,
		inTheirCircle,
	} = storedProductsFilters || {};

	//! debouncing minLength ==========================

	const [minLength, setMinLength] = useState(storedMinLength);
	const debouncedMinLength = useDebounce(minLength);

	useEffect(() => {
		dispatch(
			updateProductsFilters({
				minLength: debouncedMinLength,
			})
		);
	}, [debouncedMinLength]);

	//! debouncing maxLength ==========================

	const [maxLength, setMaxLength] = useState(storedMaxLength);
	const debouncedMaxLength = useDebounce(maxLength);

	useEffect(() => {
		dispatch(
			updateProductsFilters({
				maxLength: debouncedMaxLength,
			})
		);
	}, [debouncedMaxLength]);

	//! debouncing minWidth ==========================

	const [minWidth, setMinWidth] = useState(storedMinWidth);
	const debouncedMinWidth = useDebounce(minWidth);

	useEffect(() => {
		dispatch(
			updateProductsFilters({
				minWidth: debouncedMinWidth,
			})
		);
	}, [debouncedMinWidth]);

	//! debouncing maxWidth ==========================

	const [maxWidth, setMaxWidth] = useState(storedMaxWidth);
	const debouncedMaxWidth = useDebounce(maxWidth);

	useEffect(() => {
		dispatch(
			updateProductsFilters({
				maxWidth: debouncedMaxWidth,
			})
		);
	}, [debouncedMaxWidth]);

	//! debouncing minHeight ==========================

	const [minHeight, setMinHeight] = useState(storedMinHeight);
	const debouncedMinHeight = useDebounce(minHeight);

	useEffect(() => {
		dispatch(
			updateProductsFilters({
				minHeight: debouncedMinHeight,
			})
		);
	}, [debouncedMinHeight]);

	//! debouncing maxHeight ==========================

	const [maxHeight, setMaxHeight] = useState(storedMaxHeight);
	const debouncedMaxHeight = useDebounce(maxHeight);

	useEffect(() => {
		dispatch(
			updateProductsFilters({
				maxHeight: debouncedMaxHeight,
			})
		);
	}, [debouncedMaxHeight]);

	//! debouncing minWeight ==========================

	const [minWeight, setMinWeight] = useState(storedMinWeight);
	const debouncedMinWeight = useDebounce(minWeight);

	useEffect(() => {
		dispatch(
			updateProductsFilters({
				minWeight: debouncedMinWeight,
			})
		);
	}, [debouncedMinWeight]);

	//! debouncing maxWeight ==========================

	const [maxWeight, setMaxWeight] = useState(storedMaxWeight);
	const debouncedMaxWeight = useDebounce(maxWeight);

	useEffect(() => {
		dispatch(
			updateProductsFilters({
				maxWeight: debouncedMaxWeight,
			})
		);
	}, [debouncedMaxWeight]);

	// !===============================================

	const filteredColors = useFilterAndSort(
		colors,
		dynamicColors,
		colorsList,
		dynamicColors.length > 0
	);

	const filteredMaterials = useFilterAndSort(
		materials,
		dynamicMaterials,
		materialsList,
		dynamicMaterials.length > 0
	);

	const filteredOwners = useFilterAndSort(
		ownerIds,
		dynamicOwners,
		owners,
		dynamicOwners.length > 0
	);

	const filteredServices = useFilterAndSort(
		serviceTags,
		dynamicServices,
		services,
		dynamicServices.length > 0
	);

	useImperativeHandle(ref, () => ({
		updateDynamicFilters(key, tag) {
			switch (key) {
				case "colors":
					colorRef.current.removeItem(tag);
					break;
				case "materials":
					materialRef.current.removeItem(tag);
					break;
				case "serviceTags":
					serviceRef.current.removeItem(tag);
					break;
				case "ownerIds":
					ownersRef.current.removeItem(tag);
					break;
				default:
					break;
			}
		},
		//TODO >> no need to refetch
		// refetch() {
		// 	handleRefetch();
		// },
	}));

	if (isFetching) return <LoadingSpinner customStyle={styles.loading_spinner} />;

	return (
		<div className={styles.container}>
			{owners?.length > 0 && (
				<div className={styles.multicheckbox_container}>
					<MultiCheckbox
						ref={ownersRef}
						title={"Listed by"}
						options={filteredOwners.map((item) => defaultFormatter(item))}
						onSelect={(owners) => {
							dispatch(
								updateProductsFilters({
									ownerIds: owners,
								})
							);
						}}
						selected={ownerIds}
						perRow="2, 150px"
					/>
					{((dynamicOwners?.length === 0 && owners.length === 4) ||
						(dynamicOwners?.length > 0 && hasNextPageOwners)) && (
						<SeeMore
							onClick={dynamicOwners?.length === 0 ? refetchOwners : loadMoreOwners}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			{colorsList?.length > 0 && (
				<div className={styles.multicheckbox_container}>
					<MultiCheckbox
						ref={colorRef}
						title={"Colors"}
						options={filteredColors.map((item) => defaultFormatter(item))}
						onSelect={(colors) => {
							dispatch(
								updateProductsFilters({
									colors,
								})
							);
						}}
						selected={colors}
						perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>
					{((dynamicColors?.length === 0 && colorsList.length === 4) ||
						(dynamicColors?.length > 0 && hasNextPageColors)) && (
						<SeeMore
							onClick={dynamicColors?.length === 0 ? refetchColors : loadMoreColors}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			{materialsList?.length > 0 && (
				<div className={styles.multicheckbox_container}>
					<MultiCheckbox
						ref={materialRef}
						title={"Materials"}
						options={filteredMaterials.map((item) => defaultFormatter(item))}
						onSelect={(materials) =>
							dispatch(
								updateProductsFilters({
									materials,
								})
							)
						}
						selected={materials}
						perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>
					{((dynamicMaterials?.length === 0 && materialsList.length === 4) ||
						(dynamicMaterials?.length > 0 && hasNextPageMaterials)) && (
						<SeeMore
							onClick={
								dynamicMaterials?.length === 0
									? refetchMaterials
									: loadMoreMaterials
							}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			{services?.length > 0 && (
				<div className={styles.multicheckbox_container}>
					<MultiCheckbox
						ref={serviceRef}
						title={"Service Tags"}
						options={filteredServices.map((item) => defaultFormatter(item))}
						onSelect={(serviceTags) => {
							dispatch(
								updateProductsFilters({
									serviceTags,
								})
							);
						}}
						selected={serviceTags}
						perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>
					{((dynamicServices?.length === 0 && services.length === 4) ||
						(dynamicServices?.length > 0 && hasNextPageServices)) && (
						<SeeMore
							onClick={
								dynamicServices?.length === 0 ? refetchServices : loadMoreServices
							}
							className={styles.margin_top}
						/>
					)}
				</div>
			)}

			<div className={styles.multicheckbox_container}>
				<label className={styles.mb_8}>Length</label>
				<div className={styles.input_wrapper}>
					<TextInput
						onChange={({ target }) => setMinLength(target.value)}
						value={minLength}
						placeholder="min"
						cursor="text"
						type="number"
					/>
					<TextInput
						onChange={({ target }) => setMaxLength(target.value)}
						value={maxLength}
						placeholder="max"
						cursor="text"
						type="number"
					/>
					<div className={styles.dropdown_container}>
						<CustomSelect
							onChange={(e) =>
								dispatch(updateProductsFilters({ lengthUnit: e.value }))
							}
							value={measurementUnits.find((item) => item.value === lengthUnit)}
							fontSize="14px"
							placeholder="Unit"
							options={measurementUnits}
							isSearchable={false}
							defaultValue={{
								label: "Meters",
								value: "METER",
							}}
						/>
					</div>
				</div>
			</div>

			<div className={styles.multicheckbox_container}>
				<label className={styles.mb_8}>Width</label>
				<div className={styles.input_wrapper}>
					<TextInput
						onChange={({ target }) => setMinWidth(target.value)}
						value={minWidth}
						placeholder="min"
						cursor="text"
						type="number"
					/>
					<TextInput
						onChange={({ target }) => setMaxWidth(target.value)}
						value={maxWidth}
						placeholder="max"
						cursor="text"
						type="number"
					/>
					<div className={styles.dropdown_container}>
						<CustomSelect
							onChange={(e) =>
								dispatch(updateProductsFilters({ widthUnit: e.value }))
							}
							value={measurementUnits.find((item) => item.value === widthUnit)}
							fontSize="14px"
							placeholder="Unit"
							options={measurementUnits}
							isSearchable={false}
							defaultValue={{
								label: "Meters",
								value: "METER",
							}}
						/>
					</div>
				</div>
			</div>

			<div className={styles.multicheckbox_container}>
				<label className={styles.mb_8}>Height</label>
				<div className={styles.input_wrapper}>
					<TextInput
						onChange={({ target }) => setMinHeight(target.value)}
						value={minHeight}
						placeholder="min"
						cursor="text"
						type="number"
					/>
					<TextInput
						onChange={({ target }) => setMaxHeight(target.value)}
						value={maxHeight}
						placeholder="max"
						cursor="text"
						type="number"
					/>
					<div className={styles.dropdown_container}>
						<CustomSelect
							onChange={(e) =>
								dispatch(updateProductsFilters({ heightUnit: e.value }))
							}
							value={measurementUnits.find((item) => item.value === heightUnit)}
							fontSize="14px"
							placeholder="Unit"
							options={measurementUnits}
							isSearchable={false}
							defaultValue={{
								label: "Meters",
								value: "METER",
							}}
						/>
					</div>
				</div>
			</div>

			<div className={styles.multicheckbox_container}>
				<label className={styles.mb_8}>Weight</label>
				<div className={styles.input_wrapper}>
					<TextInput
						onChange={({ target }) => setMinWeight(target.value)}
						value={minWeight}
						placeholder="min"
						cursor="text"
						type="number"
					/>
					<TextInput
						onChange={({ target }) => setMaxWeight(target.value)}
						value={maxWeight}
						placeholder="max"
						cursor="text"
						type="number"
					/>
					<div className={styles.dropdown_container}>
						<CustomSelect
							onChange={(e) =>
								dispatch(updateProductsFilters({ weightUnit: e.value }))
							}
							value={weightUnits.find((item) => item.value === weightUnit)}
							fontSize="14px"
							placeholder="Unit"
							options={weightUnits}
							isSearchable={false}
							defaultValue={{
								label: "Grams",
								value: "GRAM",
							}}
						/>
					</div>
				</div>
			</div>

			<ConnectionFilter
				friends={friends}
				friendLabel="From my friends"
				onFriendChange={
					!companyId && ((value) => dispatch(updateProductsFilters({ friends: value })))
				}
				inMyCircle={inMyCircle}
				myCircleLabel="From my work circle"
				onMyWorkChange={(value) => dispatch(updateProductsFilters({ inMyCircle: value }))}
				inTheirCircle={inTheirCircle}
				theirCircleLabel="From accounts who added me to their work circle"
				onTheirWorkChange={(value) =>
					dispatch(updateProductsFilters({ inTheirCircle: value }))
				}
			/>

			<div className={styles.button_container}>
				<GaawkButton
					severity={"tertiary"}
					text={"Clear Filters"}
					className={styles.btn}
					onClick={() => dispatch(resetProductsFilters())}
				/>
			</div>
		</div>
	);
});

export default ProductsFilter;
