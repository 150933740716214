import { useState, useEffect, useRef, cloneElement, forwardRef, useImperativeHandle } from "react";
import {
	useFloating,
	useInteractions,
	useClick,
	useDismiss,
	autoPlacement,
	FloatingPortal,
	FloatingFocusManager,
	shift,
	offset,
	autoUpdate,
	arrow,
	hide,
} from "@floating-ui/react-dom-interactions";
import "./Popover.css";

const Popover = forwardRef(({ children, render, onOpen, allow, crossAxis = 0 }, ref) => {
	// const refArrow = useRef();
	const [open, setOpen] = useState(false);

	useImperativeHandle(ref, () => ({
		closePopover() {
			setOpen(false);
		},
	}));

	const { x, y, reference, floating, strategy, context, middlewareData, placement } = useFloating(
		{
			open,
			onOpenChange: setOpen,
			middleware: [
				offset({
					mainAxis: 10,
					crossAxis: crossAxis,
				}),
				shift(),
				autoPlacement({
					autoAlignment: true,
					...(allow && { allowedPlacements: allow }),
				}),
				// arrow({
				// 	element: refArrow,
				// }),
				hide(),
			],
			whileElementsMounted: autoUpdate,
		}
	);

	const {
		// arrow: { x: arrowX, y: arrowY } = {},
		hide: { referenceHidden } = {},
	} = middlewareData;

	// const staticSide = {
	// 	top: "bottom",
	// 	right: "left",
	// 	bottom: "top",
	// 	left: "right",
	// }[placement.split("-")[0]];

	const { getReferenceProps, getFloatingProps } = useInteractions([
		useClick(context),
		useDismiss(context),
	]);

	useEffect(() => {
		onOpen && onOpen(open);
	}, [open]);

	return (
		<>
			{cloneElement(children, getReferenceProps({ ref: reference, ...children.props }))}

			{render && (
				<FloatingPortal>
					{open && (
						<FloatingFocusManager order={["floating", "content"]} context={context}>
							<div
								{...getFloatingProps({
									className: "container",
									ref: floating,
									style: {
										position: strategy,
										top: y ?? 0,
										left: x ?? 0,
										display: referenceHidden ? "none" : "block",
									},
								})}
							>
								{render}
								{/* <div
									ref={refArrow}
									style={{
										left: arrowX != null ? `${arrowX}px` : "",
										top: arrowY != null ? `${arrowY}px` : "",
										right: "",
										bottom: "",
										[staticSide]: "-4px",
										display: referenceHidden ? "none" : "block",
									}}
									id="arrow"
								/>
								<div
									style={{
										left: arrowX != null ? `${arrowX}px` : "",
										top: arrowY != null ? `${arrowY}px` : "",
										right: "",
										bottom: "",
										[staticSide]: "-4px",
										display: referenceHidden ? "none" : "block",
									}}
									id="arrow_shadow"
								/> */}
							</div>
						</FloatingFocusManager>
					)}
				</FloatingPortal>
			)}
		</>
	);
});

export default Popover;
