import styles from "./Social.module.scss";
import { getVisibility, getClickableLink } from "../../../../Utils/General";
import RoundButton from "components/Utils/Button/RoundButton";

const SocialItem = ({ item, onEditClicked, onDelete, showControls = true }) => {
	return (
		<div className={styles.item_container}>
			<div className={styles.description}>
				{item.socialDTO.icon ? (
					<span className={styles.item_icon}>
						<img src={item.socialDTO.icon} alt={item.socialDTO.name} />
					</span>
				) : (
					<span className={`${styles.item_icon} ${styles.empty}`} />
				)}

				<div style={{ overflow: "hidden" }}>
					<div className={styles.site_header}>
						<p>{item.socialDTO.name}</p>
						<div className={styles.visibility}>{getVisibility(item.visibility)}</div>
					</div>
					<div className={styles.site_wrapper}>
						<a href={getClickableLink(item.link)} target="_blank" rel="noreferrer">
							{item.link}
						</a>
					</div>
				</div>
			</div>

			{showControls && (
				<div className={styles.controls}>
					<RoundButton icon="edit" onClick={onEditClicked} />
					<RoundButton icon="delete" onClick={onDelete} />
				</div>
			)}
		</div>
	);
};

export default SocialItem;
