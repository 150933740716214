// import HeaderBar from "../../SubComs/HeaderBar/HeaderBar";
// import BottomNavBar from "../../SubComs/bottomNavBar/BottomNavBar";
import styles from "../DefaultPage/DefaultPage.module.scss";
import LeftSide from "../../SubComs/Left-Side/LeftSide";
import RightSide from "../../SubComs/RightSide/RightSide";
import NewBottomNavBar from "components/Utils/SubComs/bottomNavBar/NewBottomNavBar";
import HeaderBar from "components/Utils/SubComs/HeaderBar/HeaderBar";

const ChatPage = ({
	leftSideChildren,
	rightSideChildren,
	footerContent,
	// rightProiority,
	// headerLeftContent,
	// headerRightContent,
	showConvoList,
	inConvo,
	isInfoOpened,
}) => {
	//TODO >> maybe can merge this with <DefaultPage /> (same usage)
	return (
		<>
			<HeaderBar />

			<div className={styles.container}>
				<LeftSide
					hide={inConvo}
					// rightProiority={rightProiority}
					hybridNavigation={true}
					children={leftSideChildren}
					enableCollapsedNavBar
				/>

				<RightSide
					isInfoOpened={isInfoOpened}
					hide={showConvoList}
					// rightProiority={rightProiority}
					chat={true}
					footerContent={footerContent}
					children={
						<div className={styles.right_side_container}>{rightSideChildren}</div>
					}
				/>
			</div>

			{!inConvo && (
				<div className={styles.bottomNavBar}>
					<NewBottomNavBar />
				</div>
			)}

			{/* <BottomNavBar /> */}
		</>
	);
};

export default ChatPage;
