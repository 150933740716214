import { useQueryClient } from "@tanstack/react-query";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import useFetchProfile from "hooks/useFetchProfile";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useMutate from "hooks/useMutate";
import moreIcon from "images/3dot-grey.svg";
import chatIcon from "images/comment-black.svg";
import deleteIcon from "images/delete-black.svg";
import editIcon from "images/edit-black.svg";
import { filterKeys } from "queryKeys/filters-key-factory";
import { searchKeys } from "queryKeys/search-key-factory";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetTalentFilters, updateTalentFilters } from "store/slices/filters";
import profileApi from "../../api/profile";
import specialProfileApi from "../../api/special-profile";
import AliasModal from "../../components/Profile/TalentTab/AliasModal";
import FilterLeftSide from "../../components/Profile/TalentTab/FilterLeftSide";
import FilterRightSide from "../../components/Profile/TalentTab/FilterRightSide";
import PhysicalFilters from "../../components/Profile/TalentTab/Filters/PhysicalFilters";
import TalentDynamicFilters from "../../components/Profile/TalentTab/Filters/TalentDynamicFilters";
import VocalFilters from "../../components/Profile/TalentTab/Filters/VocalFilters";
import TalentItem from "../../components/Profile/TalentTab/TalentItem";
import DeleteItemModal from "../../components/Utils/DeleteItemModal/DeleteItemModal";
import Filter from "../../components/Utils/Filter/Filter";
import styles from "../../components/Utils/Filter/Filter.module.css";
import { formatFiltersValues, specialProfileTypes } from "../../components/Utils/General";
import MultiCheckbox from "../../components/Utils/MultiCheckbox/MultiCheckbox";
import Popover from "../../components/Utils/Popover/Popover";
import Tag from "../../components/Utils/SubComs/Tags/Tag";
import useApi from "../../hooks/useApi";
import useDirectMessage from "../../hooks/useDirectMessage";

const filtersPageSize = 4;
const talentsPageSize = 20;

//* COMPONENT USED IN JOBS AND IN AGENCY TALENT TAB (hence the `isGeneralSearch`)

//TODO >> clean the filter redux store when leaving the page resetTalentFilters()

const TalentFilterView = ({ isGeneralSearch = false }) => {
	const leftSideRef = useRef();
	const didMountRef = useRef(false);

	const queryClient = useQueryClient();

	const navigate = useNavigate();

	const isMobile = useSelector((state) => state.ui.isMobile);

	const [filterModal, setFilterModal] = useState();

	useEffect(() => {
		// closes the filter modal if opened in mobile,
		// and then switching to a desktop breakpoint
		if (filterModal && !isMobile) setFilterModal(false);
	}, [isMobile, filterModal]);

	const { profileData: profile, canEdit } = useFetchProfile(isGeneralSearch ? false : true);

	const agencyId = isGeneralSearch ? "" : profile?.uuid;

	//TODO >> throw a 401 unauthorized instead ?
	if (profile && !profile.agency && !isGeneralSearch) navigate(`/profile/${profile.url}`);

	const cat = useLocation().state?.category || "ACTOR_ACTRESS";

	const [category, setCategory] = useState(() =>
		specialProfileTypes.find((type) => type.value === cat)
	);

	// !=========== TALENT FILTERS ===========

	const dispatch = useDispatch();

	const storedTalentFilters = useSelector((state) => state.filters.talentFilters);
	const talentFilters = { typeIds: [category], ...storedTalentFilters };

	const handleSelectFilters = (selectedFilters) => {
		//* `typeIds: [category]` WILL BE part of selectedFilters because it's passed in filters components
		//* however it will not be by default in the redux store at the 1st render (not useful for now), but the initial state is passed below to queryKey
		dispatch(updateTalentFilters(selectedFilters));
	};

	const talentsFiltersApi = useApi(specialProfileApi.searchFilters, true, true);

	const fetchTalentFilters = async ({ queryKey, signal, pageParam = 0 }) => {
		const [_, __, agencyId, filters, agenciesOnly] = queryKey;
		const response = await talentsFiltersApi.request(
			pageParam,
			filtersPageSize,
			agencyId,
			formatFiltersValues(filters),
			agenciesOnly
		);
		return response.data;
	};

	const { items: filtersData, isLoading: isLoadingFilters } = useInfiniteScroll({
		queryKey: filterKeys.talent(
			agencyId,
			talentFilters,
			!isGeneralSearch //TODO >> need to rename this passed prop to `agenciesOnly` (naming clarity)
		),
		queryFn: fetchTalentFilters,
		pageSize: filtersPageSize,
		enabled: !isGeneralSearch ? !!agencyId : true, // if in talent tab, will only enable if the agencyId is there
	});

	// !=========== TALENT SEARCH ==============

	const searchTalentsApi = useApi(specialProfileApi.search, true, true);

	const fetchTalents = async ({ pageParam = 0, queryKey, signal }) => {
		const [_, __, agencyId, filters, agenciesOnly] = queryKey;

		const response = await searchTalentsApi.request(
			pageParam,
			talentsPageSize,
			agencyId,
			formatFiltersValues(filters),
			agenciesOnly
		);
		return response.data;
	};

	const {
		items: talents,
		viewRef,
		isFetchingNextPage,
		isLoading: isLoadingTalents,
	} = useInfiniteScroll({
		queryKey: searchKeys.talent(
			agencyId,
			talentFilters,
			!isGeneralSearch //TODO >> need to rename this passed prop to `agenciesOnly` (naming clarity)
		),
		queryFn: fetchTalents,
		pageSize: talentsPageSize,
		enabled: !isGeneralSearch ? !!agencyId : true, // if in talent tab, will only enable if the agencyId is there
	});

	// ! =======

	useEffect(() => {
		if (didMountRef.current) {
			leftSideRef.current.handleClearFields();
			dispatch(resetTalentFilters());
		}
		didMountRef.current = true;
	}, [category]);

	useEffect(() => {
		return () => {
			//CLEARS TALENT SEARCH FILTERS WHEN COMPONENT UNMOUNTS
			dispatch(resetTalentFilters());
		};
	}, []);

	const popoverRef = useRef(null);

	const { handleDirectMessage } = useDirectMessage();

	const [selectedTalent, setSelectedTalent] = useState();
	const [aliasModal, setAliasModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);

	const talentsList = useMemo(
		() =>
			talents.map((talent, index) => {
				const isLastItem = index === talents.length - 1;

				return (
					<Fragment key={talent.uuid}>
						<TalentItem
							ref={isLastItem ? viewRef : null}
							data={talent}
							rightAction={
								<Popover
									ref={popoverRef}
									render={
										<div className="popover_container">
											<button
												className="popover_item"
												onClick={() => {
													handleDirectMessage(talent.owner);
												}}
											>
												<img src={chatIcon} alt="" />
												<span>Message Talent</span>
											</button>
											{canEdit && !isGeneralSearch && (
												<>
													<button
														className="popover_item"
														onClick={() => {
															popoverRef.current.closePopover();
															setSelectedTalent(talent);
															setAliasModal(true);
														}}
													>
														<img src={editIcon} alt="" />
														<span>Rename Alias</span>
													</button>
													<button
														className="popover_item"
														onClick={() => {
															popoverRef.current.closePopover();
															setSelectedTalent(talent);
															setDeleteModal(true);
														}}
													>
														<img src={deleteIcon} alt="" />
														<span>Remove Talent</span>
													</button>
												</>
											)}
										</div>
									}
								>
									<button onClick={(e) => e.stopPropagation()}>
										<img src={moreIcon} alt="options" />
									</button>
								</Popover>
							}
						/>
						{isLastItem && isFetchingNextPage && <ContactLoader />}
					</Fragment>
				);
			}),
		[talents]
	);

	const {
		action: { mutate: unlink },
	} = useMutate(
		profileApi.unlinkAgency,
		() => {
			queryClient.invalidateQueries(
				searchKeys.talent(
					agencyId,
					talentFilters,
					!isGeneralSearch //TODO >> need to rename this passed prop to `agenciesOnly` (naming clarity)
				)
			);
		},
		undefined,
		{
			meta: {
				successMessage: "Talent deleted successfully!",
			},
		}
	);

	const handleDeleteTag = (key, tag) => {
		leftSideRef.current.updateDynamicFilters(key, tag);
	};

	// ! doesn't work if item is not an array (for example waist, height, etc... numerical values are not tags anyway)
	const tagsList = useMemo(() => {
		const valuesList = [];
		// * excluding profile type from tags
		const { typeIds, ...talentsWithoutProfileTypes } = talentFilters;

		for (const [k, v] of Object.entries(talentsWithoutProfileTypes)) {
			if (Array.isArray(v) && v.length > 0) {
				v.map((item) =>
					valuesList.push(
						<Tag
							key={item.label}
							itemName={`${item.label}`}
							onRemove={() => handleDeleteTag(k, item)}
							customStyle={styles.filter_tag}
						/>
					)
				);
			}
		}
		return valuesList;
	}, [talentFilters]);

	//* STATIC FILTERS BASED ON SELECTED PROFILE TYPE (VOCAL OR PHYSICAL FILTERS)
	const activeFilter = useMemo(() => {
		switch (true) {
			case category.value === "ACTOR_ACTRESS" ||
				category.value === "MODEL" ||
				category.value === "ENTERTAINER" ||
				category.value === "DANCER":
				return (
					<PhysicalFilters
						id="staticFilter" //required for the child component
						selectedStaticFilters={talentFilters}
						onChange={handleSelectFilters}
					/>
				);

			case category.value === "VOICE_OVER_ARTIST" || category.value === "SINGER_SONGWRITER":
				return (
					<VocalFilters
						id="staticFilter" //required for the child component
						selectedStaticFilters={talentFilters}
						onChange={handleSelectFilters}
					/>
				);

			default:
				break;
		}
	}, [category, talentFilters]);

	return (
		<>
			<Filter
				title="Talents"
				isMobile={isMobile}
				onCloseModal={() => setFilterModal(false)}
				hiddenMode={!filterModal}
				leftSideChildren={
					<FilterLeftSide
						ref={leftSideRef}
						isLoading={isLoadingFilters}
						onResetFilter={() => dispatch(resetTalentFilters())}
						leftSideChildren={[
							<MultiCheckbox
								id="otherFilter" //required for FilterLeftSide component
								title={"Profile Type"}
								options={specialProfileTypes}
								onSelect={(type) => {
									setCategory(type[0]);
								}}
								selected={[category]}
								perRow="2, 150px"
								type="radio"
							/>,
							activeFilter, //* STATIC FILTERS
							<TalentDynamicFilters
								id="dynamicFilter" //required for FilterLeftSide component
								filters={filtersData[0] || {}}
								onChange={handleSelectFilters}
								selectedDynamicFilters={talentFilters}
								pageSize={filtersPageSize}
								agencyId={agencyId}
								agenciesOnly={!isGeneralSearch}
							/>,
						]}
					/>
				}
				rightSideChildren={
					<FilterRightSide
						data={talentsList}
						title="Talents"
						tagsList={tagsList}
						isMobile={isMobile}
						onShowModal={() => setFilterModal(true)}
						loading={isLoadingTalents}
					/>
				}
			/>

			{aliasModal && (
				<AliasModal
					show={aliasModal}
					onClose={() => setAliasModal(false)}
					data={selectedTalent}
					onUpdate={() =>
						queryClient.invalidateQueries(
							searchKeys.talent(
								agencyId,
								talentFilters,
								!isGeneralSearch //TODO >> need to rename this passed prop to `agenciesOnly` (naming clarity)
							)
						)
					}
				/>
			)}

			<DeleteItemModal
				show={deleteModal}
				onClose={() => setDeleteModal(false)}
				title="Remove Talent"
				text={`You are about to remove the talent ${
					selectedTalent?.alias || selectedTalent?.name
				}. This action can NOT be undone.`}
				onConfirm={() => {
					setDeleteModal(false);
					unlink({
						agencyId: agencyId,
						specialProfileId: selectedTalent.uuid,
					});
				}}
			/>
		</>
	);
};

export default TalentFilterView;
