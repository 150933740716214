import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useConnectionsCount from "hooks/useConnectionsCount";
import Blocked from "./Blocked";
import styles from "./ConnectionTab.module.css";
import Favorites from "./Favorites";
import Follow from "./Follow";
import Friends from "./Friends";
import Work from "./Work";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const ConnectionTab = ({ userInfo, canEdit }) => {
	const { data: connectionCount, isFetching } = useConnectionsCount(userInfo?.uuid);

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.CONNECTIONS_TAB);
	}, []);

	if (isFetching) return <LoadingSpinner />;

	return (
		<div className={styles.container}>
			{userInfo && (
				<>
					<Friends
						profile={userInfo}
						canEdit={canEdit}
						connectionCount={connectionCount}
					/>

					<Work profile={userInfo} canEdit={canEdit} connectionCount={connectionCount} />

					<Follow
						profile={userInfo}
						canEdit={canEdit}
						connectionCount={connectionCount}
					/>

					<Favorites
						profile={userInfo}
						canEdit={canEdit}
						connectionCount={connectionCount}
					/>

					<Blocked
						profile={userInfo}
						canEdit={canEdit}
						connectionCount={connectionCount}
					/>
				</>
			)}
		</div>
	);
};

export default ConnectionTab;
