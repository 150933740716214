import React from "react";
import { useNavigate } from "react-router-dom";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import VaultFolderRightSide from "../../components/Vault/VaultFolderRightSide";
import BackButton from "../../components/Utils/Button/BackButton";
import FeaturesSidebar from "components/Utils/PageArchs/FeaturesSidebar/FeaturesSidebar";
import { featuresSection } from "components/Utils/General";
import styles from "../Header.module.css";

const VaultFolder = () => {
	const navigate = useNavigate();

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> Vault
				</div>
			}
			// headerContent={<BackButton />}

			leftSideChildren={<FeaturesSidebar active={featuresSection.vault} />}
			rightSideBgColor={"#FFFFFF"}
			rightSideChildren={<VaultFolderRightSide />}
		/>
	);
};

export default VaultFolder;
