import { useLocation, useNavigate } from "react-router-dom";
import styles from "../Header.module.css";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import BackButton from "components/Utils/Button/BackButton";
import SettingsMenu from "components/Settings/SettingsComponents/SettingsMenu";
import { useEffect, useState } from "react";
import routes from "components/Routing/routing-keys";
import { useSelector } from "react-redux";
import Section from "components/Settings/SettingsComponents/Section";
import ManageAccount from "components/Settings/ManageAccount/ManageAccount";
import PasswordChange from "components/Settings/PasswordChange";
import PhoneChange from "components/Settings/PhoneChange";
import DeleteAccount from "components/Settings/DeleteAccount/DeleteAccount";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import ManageSubscription from "components/Settings/ManageSubscription/ManageSubscription";
import Visibility from "components/Settings/Visibility/Visibility";
import Blocked from "components/Settings/Blocked/Blocked";

const SettingsView = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const isMobile = useSelector((state) => state.ui.isMobile);

	const [hideLeftSide, setHideLeftSide] = useState(pathname !== routes.settings);

	useEffect(() => {
		setHideLeftSide(pathname !== routes.settings);
	}, [pathname]);

	useEffect(() => {
		if (pathname === routes.settings) trackEvent(eventsDictionary.SETTINGS.LANDING);
	}, []);

	let component;
	let headerTitle = "";

	switch (pathname) {
		case "/settings/account":
			headerTitle = "Manage Account";
			component = <ManageAccount />;
			break;

		case "/settings/account/change-password":
			headerTitle = "Change Password";
			component = <PasswordChange />;
			break;

		case "/settings/account/change-phone":
			headerTitle = "Change Phone";
			component = <PhoneChange />;
			break;

		case "/settings/subscription":
			headerTitle = "Manage Subscription";
			component = <ManageSubscription />; //TODO
			break;

		case "/settings/delete":
			headerTitle = "Delete Account";
			component = <DeleteAccount />;
			break;

		case "/settings/visibility":
			headerTitle = "Visibility";
			component = <Visibility />;
			break;

		case "/settings/blocked":
			headerTitle = "Block List";
			component = <Blocked />;
			break;

		case "/settings":
			headerTitle = isMobile ? "Settings" : "Manage Account";
			component = <ManageAccount />;
			break;

		// case "/settings/faq":
		// 	headerTitle = "FAQ";
		// 	component = "FAQ";
		// 	break;

		// case "/settings/privacy":
		// 	headerTitle = "Privacy Policy";
		// 	component = "Privacy Policy";
		// 	break;

		// case "/settings/help":
		// 	headerTitle = "Help & Support";
		// 	component = "Help & Support";
		// 	break;

		default:
			throw new Response("", {
				status: 404,
			});
	}

	return (
		<DefaultPage
			hideLeftSide={hideLeftSide}
			hideRightSide={!hideLeftSide}
			hybridNavigation
			enableCollapsedNavBar
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} />
					{headerTitle}
				</div>
			}
			leftSideChildren={<SettingsMenu pathname={pathname} />}
			rightSideChildren={<Section>{component}</Section>}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default SettingsView;
