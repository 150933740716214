// import React, {
// 	useState,
// 	useMemo,
// 	useEffect,
// 	forwardRef,
// 	useImperativeHandle,
// 	useRef,
// } from "react";
// import styles from "./MultiCheckbox.module.css";
// // import RectangleCheckbox from "../../Utils/SubComs/Inputs/Checkbox/RectangleCheckbox";
// // import CustomRadioButton from "../SubComs/CustomRadioButton/CustomRadioButton";
// import { Controller } from "react-hook-form";
// import GaawkRadioButton from "../SubComs/GaawkRadioButton/GaawkRadioButton";
// // import CustomCheckbox from "../SubComs/Inputs/CustomCheckbox/CustomCheckbox";
// import Checkbox from "../SubComs/Inputs/Checkbox/Checkbox";

// const MultiCheckbox = forwardRef(
// 	(
// 		{
// 			options,
// 			title,
// 			infoText,
// 			perRow,
// 			canSelectAll = false,
// 			onSelect,
// 			selected,
// 			type = "checkbox",
// 			control,
// 			name,
// 			onChangeAlt,
// 			error,
// 			required = false,
// 			customStyle,
// 		},
// 		ref
// 	) => {
// 		const didMountRef = useRef(false);

// 		const [selectedItems, setSelectedItems] = useState(selected || []);

// 		// Use a comparison to prevent unnecessary updates
// 		useEffect(() => {
// 			// Only update if the arrays are different
// 			const areArraysEqual = (arr1, arr2) => {
// 				if (arr1.length !== arr2.length) return false;
// 				return arr1.every((item, index) => item.value === arr2[index]?.value);
// 			};

// 			if (!areArraysEqual(selected, selectedItems)) {
// 				setSelectedItems(selected || []);
// 			}
// 		}, [selected]);

// 		const [allowEffect, setAllowEffect] = useState(true);

// 		useImperativeHandle(ref, () => ({
// 			clear() {
// 				setAllowEffect(false);
// 				setSelectedItems([]);
// 			},
// 			set(value) {
// 				setSelectedItems([value]);
// 			},
// 			removeItem(item) {
// 				setSelectedItems((prevState) => prevState.filter((x) => x.value !== item.value));
// 			},
// 		}));

// 		const handleSelectAll = () => {
// 			if (selectedItems.length === options.length) setSelectedItems([]);
// 			else setSelectedItems(options);
// 		};

// 		const handleCheckboxChange = (option) => {
// 			if (selectedItems.map((item) => item.value).includes(option.value)) {
// 				setSelectedItems((prevState) =>
// 					prevState.filter((item) => item.value !== option.value)
// 				);
// 			} else {
// 				setSelectedItems((prevState) => [...prevState, option]);
// 			}
// 		};

// 		const handleRadioChange = (option) => {
// 			setSelectedItems([option]);
// 		};

// 		const optionsList = useMemo(
// 			() =>
// 				options.map((option, index) =>
// 					control ? (
// 						<Controller
// 							key={index}
// 							name={name}
// 							control={control}
// 							render={({ field: { onChange, value } }) => (
// 								<div key={index} className={styles.input_wrapper}>
// 									{type === "radio" ? (
// 										<>
// 											<GaawkRadioButton
// 												key={index}
// 												checked={value?.value === option.value}
// 												centered={true}
// 												onChange={() => onChange(option)}
// 												label={option.label}
// 											/>
// 										</>
// 									) : (
// 										<>
// 											<Checkbox
// 												key={index}
// 												className={styles.checkbox}
// 												w="14px"
// 												h="14px"
// 												checked={value
// 													?.map((item) => item.value)
// 													.includes(option.value)}
// 												onChange={() => onChangeAlt(option)}
// 												name={name}
// 												customBackgroundColor={"gaawk"}
// 											/>

// 											<span onClick={() => onChangeAlt(option)}>
// 												{option.label}
// 											</span>
// 										</>
// 									)}
// 								</div>
// 							)}
// 						/>
// 					) : (
// 						<div key={index} className={styles.input_wrapper}>
// 							{type === "radio" ? (
// 								<>
// 									<GaawkRadioButton
// 										key={index}
// 										checked={selectedItems
// 											?.map((item) => item.value)
// 											.includes(option.value)}
// 										onChange={() => handleRadioChange(option)}
// 										name={name}
// 										centered={true}
// 										label={option.label}
// 									/>
// 								</>
// 							) : (
// 								<>
// 									<Checkbox
// 										key={index}
// 										className={styles.checkbox}
// 										w="14px"
// 										h="14px"
// 										checked={selectedItems
// 											?.map((item) => item.value)
// 											.includes(option.value)}
// 										onChange={() => handleCheckboxChange(option)}
// 										name={name}
// 										customBackgroundColor={"gaawk"}
// 									/>
// 									<span onClick={() => handleCheckboxChange(option)}>
// 										{option.label}
// 									</span>
// 								</>
// 							)}
// 						</div>
// 					)
// 				),
// 			[options, selectedItems]
// 		);

// 		useEffect(() => {
// 			if (didMountRef.current && allowEffect) {
// 				onSelect && onSelect(selectedItems);
// 			}
// 			didMountRef.current = true;

// 			return () => setAllowEffect(true);
// 		}, [selectedItems]);

// 		return (
// 			<div className={customStyle}>
// 				<label className={styles.title}>
// 					{title}
// 					{required && <span className="required">*</span>}
// 				</label>
// 				<div
// 					className={styles.options_container}
// 					style={{
// 						gridTemplateColumns: perRow ? `repeat(${perRow})` : undefined,
// 					}}
// 				>
// 					{canSelectAll && (
// 						<div className={styles.input_wrapper}>
// 							<Checkbox
// 								className={styles.checkbox}
// 								w="14px"
// 								h="14px"
// 								checked={selectedItems.length === options.length}
// 								onChange={handleSelectAll}
// 								customBackgroundColor={"gaawk"}
// 							/>
// 							<span onClick={handleSelectAll}>Select All</span>
// 						</div>
// 					)}
// 					{optionsList}
// 				</div>
// 				{error && <p className={styles.error_message}>{error.message}</p>}

// 				{infoText && <span className={styles.info_text}>{infoText}</span>}
// 			</div>
// 		);
// 	}
// );

// export default MultiCheckbox;

import React, { useState, useMemo, useEffect, forwardRef, useImperativeHandle } from "react";
import styles from "./MultiCheckbox.module.css";
import { Controller } from "react-hook-form";
import GaawkRadioButton from "../SubComs/GaawkRadioButton/GaawkRadioButton";
import Checkbox from "../SubComs/Inputs/Checkbox/Checkbox";

const MultiCheckbox = forwardRef(
	(
		{
			options,
			title,
			infoText,
			perRow,
			canSelectAll = false,
			onSelect,
			selected,
			type = "checkbox",
			control,
			name,
			onChangeAlt,
			error,
			required = false,
			customStyle,
		},
		ref
	) => {
		const [selectedItems, setSelectedItems] = useState(selected || []);
		const isControlled = selected !== undefined;

		// Use a stable reference for comparison function
		const areArraysEqual = (arr1, arr2) => {
			if (!arr1 || !arr2) return false;
			if (arr1.length !== arr2.length) return false;
			return arr1.every((item, index) => item.value === arr2[index]?.value);
		};

		// Update internal state when controlled value changes
		useEffect(() => {
			if (isControlled && !areArraysEqual(selected, selectedItems)) {
				setSelectedItems(selected);
			}
		}, [selected, isControlled]);

		useImperativeHandle(ref, () => ({
			clear() {
				setSelectedItems([]);
			},
			set(value) {
				setSelectedItems([value]);
			},
			removeItem(item) {
				setSelectedItems((prevState) => prevState.filter((x) => x.value !== item.value));
			},
		}));

		const handleSelectAll = () => {
			const newItems = selectedItems.length === options.length ? [] : options;
			setSelectedItems(newItems);
			onSelect?.(newItems);
		};

		const handleCheckboxChange = (option) => {
			const newItems = selectedItems.map((item) => item.value).includes(option.value)
				? selectedItems.filter((item) => item.value !== option.value)
				: [...selectedItems, option];

			setSelectedItems(newItems);
			onSelect?.(newItems);
		};

		const handleRadioChange = (option) => {
			const newItems = [option];
			setSelectedItems(newItems);
			onSelect?.(newItems);
		};

		const optionsList = useMemo(
			() =>
				options.map((option, index) =>
					control ? (
						<Controller
							key={index}
							name={name}
							control={control}
							render={({ field: { onChange, value } }) => (
								<div key={index} className={styles.input_wrapper}>
									{type === "radio" ? (
										<GaawkRadioButton
											key={index}
											checked={value?.value === option.value}
											centered={true}
											onChange={() => onChange(option)}
											label={option.label}
										/>
									) : (
										<>
											<Checkbox
												key={index}
												className={styles.checkbox}
												w="14px"
												h="14px"
												checked={value
													?.map((item) => item.value)
													.includes(option.value)}
												onChange={() => onChangeAlt(option)}
												name={name}
												customBackgroundColor={"gaawk"}
											/>
											<span onClick={() => onChangeAlt(option)}>
												{option.label}
											</span>
										</>
									)}
								</div>
							)}
						/>
					) : (
						<div key={index} className={styles.input_wrapper}>
							{type === "radio" ? (
								<GaawkRadioButton
									key={index}
									checked={selectedItems
										?.map((item) => item.value)
										.includes(option.value)}
									onChange={() => handleRadioChange(option)}
									name={name}
									centered={true}
									label={option.label}
								/>
							) : (
								<>
									<Checkbox
										key={index}
										className={styles.checkbox}
										w="14px"
										h="14px"
										checked={selectedItems
											?.map((item) => item.value)
											.includes(option.value)}
										onChange={() => handleCheckboxChange(option)}
										name={name}
										customBackgroundColor={"gaawk"}
									/>
									<span onClick={() => handleCheckboxChange(option)}>
										{option.label}
									</span>
								</>
							)}
						</div>
					)
				),
			[options, selectedItems, control, type, name, onChangeAlt]
		);

		return (
			<div className={customStyle}>
				<label className={styles.title}>
					{title}
					{required && <span className="required">*</span>}
				</label>
				<div
					className={styles.options_container}
					style={{
						gridTemplateColumns: perRow ? `repeat(${perRow})` : undefined,
					}}
				>
					{canSelectAll && (
						<div className={styles.input_wrapper}>
							<Checkbox
								className={styles.checkbox}
								w="14px"
								h="14px"
								checked={selectedItems.length === options.length}
								onChange={handleSelectAll}
								customBackgroundColor={"gaawk"}
							/>
							<span onClick={handleSelectAll}>Select All</span>
						</div>
					)}
					{optionsList}
				</div>
				{error && <p className={styles.error_message}>{error.message}</p>}
				{infoText && <span className={styles.info_text}>{infoText}</span>}
			</div>
		);
	}
);

export default MultiCheckbox;
