import Footer from "components/Utils/Footer/Footer";
import HeaderBar from "../../SubComs/HeaderBar/HeaderBar";
import LeftSide from "../../SubComs/Left-Side/LeftSide";
import RightSide from "../../SubComs/RightSide/RightSide";
import styles from "./DefaultPage.module.scss";
import NewBottomNavBar from "components/Utils/SubComs/bottomNavBar/NewBottomNavBar";

const DefaultPage = ({
	leftSideChildren,
	rightSideChildren,
	onScroll,
	headerRightContent,
	rightSideBgColor,
	rightSideFullWidth = true,
	disableScrollbar = false,
	bannerComponent,
	showFooter = true,
	rightSideContainerColor = undefined,
	defaultBannerHeight = undefined,
	enableCollapsedNavBar = false,
	hybridNavigation = false,
	hideLeftSide,
	hideRightSide,
}) => {
	return (
		<>
			<HeaderBar headerRightContent={headerRightContent} />

			<div className={styles.main}>
				<div className={styles.container}>
					<LeftSide
						hybridNavigation={hybridNavigation}
						children={leftSideChildren}
						enableCollapsedNavBar={enableCollapsedNavBar}
						hide={hideLeftSide}
					/>

					<RightSide
						hide={hideRightSide}
						bannerComponent={bannerComponent}
						defaultBannerHeight={defaultBannerHeight}
						disableScrollbar={disableScrollbar}
						onScroll={onScroll}
						children={
							<div
								className={styles.right_side_container}
								style={{ backgroundColor: rightSideContainerColor }}
							>
								{rightSideChildren}
								{showFooter && <Footer />}
							</div>
						}
						rightSideBgColor={rightSideBgColor}
						rightSideFullWidth={rightSideFullWidth}
					/>
				</div>

				{/* Show only in mobile mode (below 748px) because bottomBar in left pannel will be hidden */}

				<div className={styles.bottomNavBar}>
					<NewBottomNavBar />
				</div>
			</div>
		</>
	);
};

export default DefaultPage;
