import { useMutation } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import FileContainer from "components/Utils/SubComs/FileContainer/FileContainer";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import vaultApi from "../../api/vault";
import SimpleDropZone from "../Utils/SubComs/CustomDropZone/SimpleDropZone";
import LoadingBar from "../Utils/SubComs/LoadingBar/LoadingBar";
import FileItem from "./FileItem";
import styles from "./UserVault.module.css";
import GaawkButton from "components/Utils/Button/GaawkButton";

const uploadSingleFile = async ({ file, parentId, onUploadProgress }) => {
	const response = await vaultApi.addFile(file, parentId, onUploadProgress);

	if (!response.ok) {
		// throw new Error("File upload failed");
		throw response.data;
	}

	return response.data;
};

const VaultFileUploader = () => {
	const navigate = useNavigate();
	const params = useParams();

	const [droppedFiles, setDroppedFiles] = useState([]);
	const [overallProgress, setOverallProgress] = useState(0);

	const uploadMutation = useMutation(uploadSingleFile);

	const handleUploadFiles = async () => {
		// Disable button during upload
		if (uploadMutation.isLoading) return;

		// Reset overall progress
		setOverallProgress(0);

		// Sequential uploads to ensure one file at a time
		for (let i = 0; i < droppedFiles.length; i++) {
			const file = droppedFiles[i];
			try {
				await uploadMutation.mutateAsync({
					file,
					parentId: params.id,
					onUploadProgress: (progress) => {
						// Calculate overall progress across all files
						const baseProgress = (i / droppedFiles.length) * 100;
						const currentFileProgress = (progress / 100) * (100 / droppedFiles.length);
						setOverallProgress(Math.round(baseProgress + currentFileProgress));
					},
				});
			} catch (error) {
				console.error("Upload batch stopped due to error:", error);
				break;
			}
		}

		// Navigate after all uploads complete
		navigate(routes.vaultFolder(params.id ?? ""), {
			replace: true,
		});
	};

	/**
	 * Callbacks
	 */
	const onDropFiles = useCallback(
		(acceptedFiles) => {
			setDroppedFiles([...droppedFiles, ...acceptedFiles]);
		},
		[droppedFiles]
	);

	/**
	 * functions
	 */

	const removeFile = (index) => () => {
		setDroppedFiles(droppedFiles.filter((_, i) => i !== index));
	};

	/**
	 * Mappers
	 */

	const mappedFiles =
		droppedFiles.length > 0 &&
		droppedFiles.map((file, index) => {
			return (
				<FileItem
					key={index}
					// name={file.name}
					// item={getFileIcon(file, file.name)}
					item={file}
					onRemove={removeFile(index)}
				/>
			);
		});

	return (
		<div className={styles.upload_container}>
			<div className={styles.upload_header}>
				<div>
					<h4>Upload Files</h4>
					<p>
						You need to add all of your media here, then press “Upload all files” to
						upload all your files into vault.
					</p>
				</div>
				{droppedFiles.length > 0 && (
					<GaawkButton
						onClick={handleUploadFiles}
						disabled={uploadMutation.isLoading}
						text={"Upload"}
					/>
				)}
			</div>

			<SimpleDropZone onDrop={onDropFiles} />

			{uploadMutation.isLoading && droppedFiles.length > 0 && (
				<LoadingBar progress={overallProgress} />
			)}

			<FileContainer>{mappedFiles}</FileContainer>
		</div>
	);
};

export default VaultFileUploader;
