import NavItem from "components/Utils/SubComs/NavBar/NavItem";
import styles from "./SettingsMenu.module.scss";
import ProfilePic from "components/Home/ProfilePic";
import useCurrentUser from "hooks/useCurrentUser";
import { useSelector } from "react-redux";
import routes from "components/Routing/routing-keys";

const SettingsMenu = ({ pathname }) => {
	const { type, url, prime, firstName, lastName, name, profileImage } = useCurrentUser();
	const { email, mobile, mobileCountryCode } = useSelector((state) => state.user.userLogin);

	const thumbnail = profileImage?.framedImageURL | profileImage?.originalImageURL;
	const isMobile = useSelector((state) => state.ui.isMobile);

	const accountItems = [
		{
			label: "Manage Account",
			path: routes.settingsAccount,
			isActive:
				pathname === routes.settingsAccount ||
				(pathname === routes.settings && !isMobile) ||
				pathname === routes.passwordChange ||
				pathname === routes.phoneChange,
		},
		{
			label: "Manage Subscription",
			path: routes.settingsSubscription,
			isActive: pathname === routes.settingsSubscription,
		},
		{
			label: `Delete ${type === "COMPANY" ? "Company " : " "}Account`,
			path: routes.settingsDelete,
			isActive: pathname === routes.settingsDelete,
		},
	];

	const privacyItems = [
		{
			label: "Visibility",
			path: routes.settingsVisibility,
			isActive: pathname === routes.settingsVisibility,
		},
		{
			label: "Block List",
			path: routes.settingsBlocked,
			isActive: pathname === routes.settingsBlocked,
		},
	];

	// const generalItems = [
	// 	{
	// 		label: "FAQ",
	// 		path: routes.settingsFaq,
	// 		isActive: pathname === routes.settingsFaq,
	// 	},
	// 	{
	// 		label: "Privacy Policy",
	// 		path: routes.settingsPrivacy,
	// 		isActive: pathname === routes.settingsPrivacy,
	// 	},
	// 	{
	// 		label: "Help & Support",
	// 		path: routes.settingsHelp,
	// 		isActive: pathname === routes.settingsHelp,
	// 	},
	// ];

	const userName = type === "USER" ? `${firstName} ${lastName}` : name;

	return (
		<div className={styles.container}>
			<div className={styles.active_profile_container}>
				<ProfilePic
					disableOverflow
					isPrime={prime}
					w={64}
					h={64}
					type={type}
					noMarginRight
					thumbnail={thumbnail}
					name={userName}
					letterSize={26}
					enableName={false}
					url={url}
					className={styles.disable_overflow}
				/>

				<div className={styles.user_info}>
					<h3>{userName}</h3>
					<p>{email}</p>
					<p>{`+${mobileCountryCode} ${mobile}`}</p>
				</div>
			</div>

			<div className={styles.nav_group}>
				<h3>Account</h3>
				<div className={styles.navigation}>
					{accountItems.map((item, index) => (
						<NavItem
							key={index}
							label={item.label}
							path={item.path}
							isActive={item.isActive}
							labelClassName={styles.label}
							enableToolTip={false}
						/>
					))}
				</div>
			</div>
			<div className={styles.nav_group}>
				<h3>Privacy</h3>
				<div className={styles.navigation}>
					{privacyItems.map((item, index) => (
						<NavItem
							key={index}
							label={item.label}
							path={item.path}
							isActive={item.isActive}
							labelClassName={styles.label}
							enableToolTip={false}
						/>
					))}
				</div>
			</div>
			{/* <div className={styles.nav_group}>
				<h3>General</h3>
				<div className={styles.navigation}>
					{generalItems.map((item, index) => (
						<NavItem
							key={index}
							label={item.label}
							path={item.path}
							isActive={item.isActive}
							labelClassName={styles.label}
							enableToolTip={false}
						/>
					))}
				</div>
			</div> */}

			{/* //TODO >> add FAQ , Privacy policy and help at the bottom of the navbar */}
		</div>
	);
};

export default SettingsMenu;
