import Feeds from "./Feeds";
// import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import HomeRightSidebar from "components/Home/Components/HomeRightSidebar/HomeRightSidebar";
import ThreeColumnPage from "components/Utils/PageArchs/ThreeColumnPage/ThreeColumnPage";
import FeedContainer from "components/Utils/FeedContainer/FeedContainer";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import { useEffect } from "react";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const Index = () => {
	useEffect(() => {
		trackEvent(eventsDictionary.HOME);
	}, []);

	return (
		<ThreeColumnPage
			leftSideChildren={<NavBar />}
			centerChildren={
				<FeedContainer fixedContainer={true}>
					<Feeds />
				</FeedContainer>
			}
			rightSideChildren={<HomeRightSidebar />}
		/>
	);
};

export default Index;
