import styles from "./ManageSubscription.module.scss";

const ManageSubscription = () => {
	return (
		<div className={styles.container}>
			<h4>Manage Subscription</h4>
		</div>
	);
};

export default ManageSubscription;
