import { useNavigate } from "react-router-dom";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import GalleryFileUploader from "../../components/Profile/GalleryTab/GalleryFileUploader";
import BackButton from "../../components/Utils/Button/BackButton";
import styles from "../Header.module.css";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const AlbumUploaderView = () => {
	const navigate = useNavigate();

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> Add Files
				</div>
			}
			leftSideChildren={<NavBar />}
			rightSideChildren={<GalleryFileUploader />}
		/>
	);
};

export default AlbumUploaderView;
