import styles from "./DefaultFullPage.module.scss";
import Footer from "components/Utils/Footer/Footer";
import NewBottomNavBar from "components/Utils/SubComs/bottomNavBar/NewBottomNavBar";
import HeaderBar from "components/Utils/SubComs/HeaderBar/HeaderBar";
import BackButton from "components/Utils/Button/BackButton";
import { useNavigate } from "react-router-dom";

const DefaultFullPage = ({ content, padding = true, showBackButton = false, backLabel = "" }) => {
	const navigate = useNavigate();
	return (
		<>
			<HeaderBar
				hideSeparator
				hideLeftSide
				headerRightContent={
					<div className={styles.back_container}>
						{showBackButton && <BackButton onBack={() => navigate(-1)} />}
						{backLabel && <p>{backLabel}</p>}
					</div>
				}
			/>

			<div className={styles.background}>
				<div className={`${styles.container} ${padding ? styles.with_padding : undefined}`}>
					{content}
				</div>
				<Footer />
			</div>

			<NewBottomNavBar />
		</>
	);
};

export default DefaultFullPage;
