import ProfileHeader from "../ProfileHeader/index";
import ProfileTab from "../ProfileTab/ProfileTab";
import ContactTab from "../ContactTab/ContactTab";
import ChatTab from "../ChatTab/ChatTab";
import ConnectionTab from "../ConnectionTab/ConnectionTab";
import PostsTab from "../PostsTab/PostsTab";
import GalleryTab from "../GalleryTab/GalleryTab";
import WorkStatus from "../WorkStatusTab/WorkStatus";
import TalentTab from "../TalentTab/TalentTab";
import ServicesTab from "../ServicesTab/ServicesTab";
import ProductsTab from "../ProductsTab/ProductsTab";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import routes from "components/Routing/routing-keys";
import styles from "react-day-picker/dist/style.css";
import BlockedContainer from "./BlockedContainer";

const ProfilePage = ({
	userInfo,
	onItemClicked,
	canEdit,
	onClickEdit,
	onClickEditProfilePic,
	onClickEditCoverPic,
	onReport,
	onClear,
	onExport,
	onMute,
	onUnmute,
	isMuted,
	conversation,
}) => {
	const {
		type,
		agency,
		hasServices,
		hasProducts,
		deleted,
		connection: { blocked, blocking } = {},
	} = userInfo;

	const isBlocked = blocking || blocked;

	const { profileTab: activeTab } = useSelector((state) => state.activeTab);
	const { pathname } = useLocation();
	const isChatPage = pathname === routes.chat;

	let displayTab;

	switch (true) {
		case activeTab === "posts":
			displayTab = <PostsTab canEdit={canEdit} userInfo={userInfo} />;
			break;
		case activeTab === "profile":
			displayTab = <ProfileTab canEdit={canEdit} userInfo={userInfo} />;
			break;
		case activeTab === "contacts":
			displayTab = <ContactTab canEdit={canEdit} userInfo={userInfo} />;
			break;
		case activeTab === "talent" && agency:
			displayTab = <TalentTab canEdit={canEdit} userInfo={userInfo} />;
			break;
		case activeTab === "connections":
			displayTab = <ConnectionTab canEdit={canEdit} userInfo={userInfo} />;
			break;
		case activeTab === "info" && isChatPage:
			displayTab = (
				<ChatTab
					conversation={conversation}
					onReport={onReport}
					onClear={onClear}
					onExport={onExport}
					onMute={onMute}
					onUnmute={onUnmute}
					isMuted={isMuted}
				/>
			);
			break;
		case activeTab === "gallery":
			displayTab = <GalleryTab canEdit={canEdit} userInfo={userInfo} />;
			break;
		case activeTab === "work status" && type === "USER":
			displayTab = <WorkStatus canEdit={canEdit} userInfo={userInfo} />;
			break;
		case activeTab === "services" && (hasServices || canEdit):
			displayTab = <ServicesTab canEdit={canEdit} userInfo={userInfo} />;
			break;
		case activeTab === "products" && (hasProducts || canEdit):
			displayTab = <ProductsTab canEdit={canEdit} userInfo={userInfo} />;
			break;
		default:
			onItemClicked("profile");
			break;
	}

	return (
		<>
			<ProfileHeader
				userInfo={userInfo}
				onItemClicked={onItemClicked}
				canEdit={canEdit}
				onClickEditProfilePic={onClickEditProfilePic}
				onClickEditCoverPic={onClickEditCoverPic}
				onClickEdit={onClickEdit}
			/>
			{!deleted && !isBlocked && userInfo && displayTab}
			{isBlocked && <BlockedContainer blocked={blocked} blocking={blocking} />}
		</>
	);
};

export default ProfilePage;
