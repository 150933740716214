import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { socialUpdated as companySocialUpdated } from "../../../../../store/slices/company";
import { socialUpdated } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Social.module.scss";
import SocialItem from "./SocialItem";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Social = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { socials, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(
			editCompany ? profileApi.deleteCompanySocial : profileApi.deleteSocial,
			(response, variables) => {
				trackEvent(eventsDictionary.PROFILE.DELETE_SOCIAL_MEDIA);
				const { companyId } = variables || {};
				dispatch(
					companyId ? companySocialUpdated(response.data) : socialUpdated(response.data)
				);
			}
		);

	const socialList = socials.map((social) => (
		<SocialItem
			key={social.uuid}
			item={social}
			onEditClicked={() =>
				navigate(routes.editSocial, {
					state: social,
				})
			}
			onDelete={() => handleRequestMutate(social)}
		/>
	));

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.SOCIAL_MEDIA_LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.button_wrapper}>
				<GaawkButton
					severity={"secondary"}
					text="+ Add New Social Media"
					onClick={() => navigate(routes.addSocial)}
				/>
			</div>

			{socials.length > 0 && (
				<>
					<h4>Your Social Medias</h4>
					<div>{socialList}</div>
				</>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						...(editCompany && { companyId }),
						userSocialId: activeItem.uuid,
					})
				}
			/>
		</div>
	);
};

export default Social;
