import { useState, useEffect, useMemo, useRef } from "react";
import useApi from "../../../hooks/useApi";
import profileApi from "../../../api/profile";
import ProfileTabContainer from "../../Utils/SubComs/ProfileTab/ProfileTabContainer";
import { talentCategories } from "./talent-categories";
import styles from "./TalentTab.module.css";
import PendingTalentModal from "./PendingTalentModal";
import specialProfileApi from "../../../api/special-profile";
import TalentItem from "./TalentItem";
import Popover from "../../Utils/Popover/Popover";
import moreIcon from "images/3dot-grey.svg";
import chatIcon from "images/comment-black.svg";
import editIcon from "images/edit-black.svg";
import deleteIcon from "images/delete-black.svg";
import AliasModal from "./AliasModal";
import {
	pluralize,
	// removeItemIdFromArray,
	// replaceItemInArray,
} from "../../Utils/General";
import useDirectMessage from "../../../hooks/useDirectMessage";
import { Link, useSearchParams } from "react-router-dom";
import NoResults from "../../Utils/SubComs/NoResults/NoResults";
// import LoadMoreButton from "../../Utils/Button/LoadMoreButton";
import DeleteItemModal from "../../Utils/DeleteItemModal/DeleteItemModal";
import usePaginatedResource from "hooks/usePaginatedResource";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useInView } from "react-intersection-observer";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useMutate from "hooks/useMutate";
import { profileKeys } from "queryKeys/profile-key-factory";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const itemsPerPage = 20;

const TalentTab = ({ canEdit, userInfo }) => {
	const queryClient = useQueryClient();

	const { agency, type, uuid, url } = userInfo;

	const [selectedTalent, setSelectedTalent] = useState();
	const [aliasModal, setAliasModal] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);

	const popoverRef = useRef(null);

	const getTalentsApi = useApi(specialProfileApi.getTalents, true, true);

	// const [pendingCount, setPendingCount] = useState(0);
	const [pendingModal, setPendingModal] = useState(false);

	const { handleDirectMessage } = useDirectMessage();

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.TALENT_TAB);
	}, []);

	//* using react-query =======

	const fetchTalents = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, uuid, status, type] = queryKey;

		const response = await getTalentsApi.request(uuid, pageParam, itemsPerPage, status, type);
		return response.data;
	};

	const {
		data: talents,
		isFetching,
		hasNextPage,
		fetchNextPage,
	} = usePaginatedResource(
		specialProfileKeys.getTalents(uuid),
		fetchTalents,
		itemsPerPage,
		type === "COMPANY" && agency
	);

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching]);

	// * ========================

	const invalidateTalents = () => {
		queryClient.invalidateQueries(specialProfileKeys.getTalents(uuid));
	};

	const [searchParams, setSearchParams] = useSearchParams();
	const param = searchParams.get("modal") === "true";

	useEffect(() => {
		setPendingModal(param);
	}, [param]);

	const removeQueryParams = () => {
		if (param) {
			searchParams.delete("modal");
			setSearchParams(searchParams);
		}
	};

	const categoriesList = talentCategories.map((cat) => (
		<Link
			key={cat.uuid}
			to={`/profile${url ? `/${url}` : ""}/talents`}
			state={{ category: cat.uuid }}
			className={styles.category_item}
		>
			<img src={cat.image} alt="" />
			<h4>{cat.name}</h4>
		</Link>
	));

	const talentsList = useMemo(
		() =>
			talents?.pages?.map((page) =>
				page.map((talent, i) => (
					<TalentItem
						key={talent.uuid}
						agencyId={uuid}
						ref={page.length === i + 1 ? viewRef : null}
						data={talent}
						rightAction={
							canEdit && (
								<Popover
									ref={popoverRef}
									render={
										<div className="popover_container">
											<button
												className="popover_item"
												onClick={() => {
													handleDirectMessage(talent.owner);
												}}
											>
												<img src={chatIcon} alt="" />
												<span>Message Talent</span>
											</button>
											<button
												className="popover_item"
												onClick={() => {
													popoverRef.current.closePopover();
													setSelectedTalent(talent);
													setAliasModal(true);
												}}
											>
												<img src={editIcon} alt="" />
												<span>Rename Alias</span>
											</button>
											<button
												className="popover_item"
												onClick={() => {
													popoverRef.current.closePopover();
													setSelectedTalent(talent);
													setDeleteModal(true);
												}}
											>
												<img src={deleteIcon} alt="" />
												<span>Remove Talent</span>
											</button>
										</div>
									}
								>
									<button onClick={(e) => e.stopPropagation()}>
										<img src={moreIcon} alt="options" />
									</button>
								</Popover>
							)
						}
					/>
				))
			),
		[talents]
	);

	const getPendingTalentApi = useApi(profileApi.getPendingTalent, true, true);

	const fetchPendingCount = async ({ queryKey }) => {
		const [_, profileId] = queryKey;
		const response = await getPendingTalentApi.request(profileId);
		return response.data;
	};

	const { data: pendingCount } = useQuery({
		queryKey: profileKeys.pendingTalents(uuid),
		queryFn: fetchPendingCount,
		enabled: canEdit,
	});

	const {
		action: { mutate: removeTalent },
	} = useMutate(profileApi.unlinkAgency, () => {
		invalidateTalents();
		setSelectedTalent(undefined);
		setDeleteModal(false);
	});

	const handleRemoveTalent = () => {
		removeTalent({ agencyId: uuid, talentId: selectedTalent.uuid });
	};

	return (
		<>
			<ProfileTabContainer minHeight={false}>
				<div className={styles.container}>
					<h3>Categories</h3>
					<div className={styles.categories_container}>{categoriesList}</div>
				</div>
			</ProfileTabContainer>

			<ProfileTabContainer>
				<div className={styles.container}>
					<h3>Talents</h3>
					{canEdit && pendingCount > 0 && (
						<button
							className={styles.pending_btn}
							onClick={() => {
								setPendingModal(true);
								trackEvent(eventsDictionary.PROFILE.TALENT_PENDING);
							}}
						>
							{pendingCount} {pluralize(pendingCount, "Talent")} want to be linked to
							your agency
						</button>
					)}

					<NoResults
						visible={talents?.pages[0].length === 0 && !isFetching}
						text={
							canEdit
								? `You don't have any talents listed in your agency yet.`
								: `${userInfo.name} doesn't have any talents listed yet`
						}
					/>

					{talents?.pages[0].length > 0 && (
						<div className={styles.talent_container}>{talentsList}</div>
					)}
				</div>
			</ProfileTabContainer>

			{pendingModal && (
				<PendingTalentModal
					show={pendingModal}
					onClose={() => {
						setPendingModal(false);
						removeQueryParams();
					}}
					agencyId={uuid}
					onUpdateCount={() =>
						queryClient.invalidateQueries(profileKeys.pendingTalents(uuid))
					}
					onUpdate={invalidateTalents}
				/>
			)}

			{aliasModal && (
				<AliasModal
					show={aliasModal}
					onClose={() => setAliasModal(false)}
					data={selectedTalent}
					onUpdate={invalidateTalents}
				/>
			)}

			<DeleteItemModal
				show={deleteModal}
				onClose={() => setDeleteModal(false)}
				title="Remove Talent"
				text={`You are about to remove the talent ${
					selectedTalent?.alias || selectedTalent?.name
				}. This action can NOT be undone.`}
				onConfirm={handleRemoveTalent}
			/>
		</>
	);
};

export default TalentTab;
