import { useState, useRef } from "react";
import styles from "./CropImage.module.css";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { imgPreview } from "./imgPreview";
import GaawkButton from "components/Utils/Button/GaawkButton";

const CropImage = ({ src, onCrop, returnBlob, isCircle = false, aspectMode }) => {
	const imgRef = useRef(null);

	const [crop, setCrop] = useState();

	const [completedCrop, setCompletedCrop] = useState();

	const [scale, setScale] = useState(1);
	const [rotate, setRotate] = useState(0);

	// const aspectMode =
	//     mode === "profile" || mode === "group"
	//         ? 1
	//         : mode === "cover"
	//         ? 25 / 7
	//         : mode === "project" || mode === "board"
	//         ? 2 / 1
	//         : undefined;

	const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
		return centerCrop(
			makeAspectCrop(
				{
					unit: "%",
					width: 90,
				},
				aspect,
				mediaWidth,
				mediaHeight
			),
			mediaWidth,
			mediaHeight
		);
	};

	const onImageLoad = (e) => {
		const { width, height } = e.currentTarget;
		setCrop(centerAspectCrop(width, height, aspectMode || 1));
	};

	const handleCrop = async () => {
		const preview = await imgPreview(imgRef.current, completedCrop, scale, rotate, returnBlob);

		onCrop(preview);
	};

	return (
		<>
			<ReactCrop
				crop={crop}
				aspect={aspectMode}
				onChange={(c) => setCrop(c)}
				minWidth={50}
				minHeight={50}
				onComplete={setCompletedCrop}
				keepSelection={true}
				circularCrop={isCircle}
				ruleOfThirds={true}
				className={styles.react_crop}
			>
				<img
					crossOrigin="anonymous"
					style={{
						transform: `scale(${scale}) rotate(${rotate}deg)`,
					}}
					ref={imgRef}
					src={src}
					alt=""
					onLoad={onImageLoad}
				/>
			</ReactCrop>

			<div className={styles.control}>
				<label htmlFor="scale-input">Scale</label>
				<input
					id="scale-input"
					type="range"
					step="0.1"
					max={10}
					min={1}
					value={scale}
					onChange={(e) => setScale(Number(e.target.value))}
				/>
			</div>

			<div className={styles.control}>
				<label htmlFor="rotate-input">Rotate</label>
				<input
					id="rotate-input"
					type="range"
					value={rotate}
					max={360}
					onChange={(e) =>
						setRotate(Math.min(360, Math.max(-360, Number(e.target.value))))
					}
				/>
			</div>

			<div className={styles.btn_container}>
				<GaawkButton text="Done" className={styles.btn} onClick={handleCrop} />
			</div>
		</>
	);
};

export default CropImage;
