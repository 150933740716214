import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import styles from "./Boards.module.scss";
import useApi from "../../../hooks/useApi";
// import useCompanyId from "../../../hooks/useCompanyId";
import boardsApi from "../../../api/boards";
import BoardSection from "../../Boards/BoardsComponents/BoardSection";
// import { useSelector } from "react-redux";
import useDebounce from "../../../hooks/useDebounce";
import CityModal from "../../Boards/BoardsComponents/CityModal";
import useUserLocation from "../../../hooks/useUserLocation";
import useGetBoards from "./useGetBoards";
import { boardsKeys } from "queryKeys/boards-key-factory";
import SuggestBoardModal from "./SuggestBoardModal";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
// import BoardEntry from "./BoardEntry";

const itemsPerPage = 20;

const Boards = forwardRef(({ onFlagChange }, ref) => {
	// const companyId = useCompanyId();

	// const { uuid } = useSelector((state) => {
	// 	if (companyId) {
	// 		return state.company.companyInfo;
	// 	} else {
	// 		return state.user.profileInfo;
	// 	}
	// });

	//! ========= GETTING USER COORDINATES ============
	const [showModal, setShowModal] = useState(false);

	useImperativeHandle(ref, () => ({
		showModal() {
			setShowModal(true);
		},
	}));

	const { userCoordinates, handleSelectedCity } = useUserLocation(onFlagChange);

	//! =========== SEARCH INPUT HANDLER =============

	const [searchInput, setSearchInput] = useState("");
	const debouncedSearch = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	//! ========================

	// const getMyBoardsApi = useApi(boardsApi.getMyBoards, true, true);

	// const fetchMyBoards = async ({ pageParam = 0, signal }) => {
	// 	const response = await getMyBoardsApi.request(pageParam, itemsPerPage);
	// 	return response.data;
	// };

	// const getFollowingBoardsApi = useApi(
	// 	boardsApi.getFollowingBoards,
	// 	true,
	// 	true
	// );

	// //* just adding "signal" as parameter allows to cancel the request if component unmounts
	// const fetchFollowing = async ({ pageParam = 0, signal }) => {
	// 	const response = await getFollowingBoardsApi.request(
	// 		uuid,
	// 		pageParam,
	// 		itemsPerPage
	// 	);
	// 	return response.data;
	// };

	const getSuggestedBoardsApi = useApi(boardsApi.getSuggestedBoards, true, true);

	const fetchSuggested = async ({ pageParam = 0, signal }) => {
		const response = await getSuggestedBoardsApi.request(
			pageParam,
			itemsPerPage,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const searchBoardsApi = useApi(boardsApi.searchBoards, true, true);

	const fetchSearch = async ({ pageParam = 0, signal }) => {
		const response = await searchBoardsApi.request(
			pageParam,
			itemsPerPage,
			debouncedSearch,
			userCoordinates.lat,
			userCoordinates.lng
		);
		return response.data;
	};

	const infiniteSearchData = useGetBoards(
		fetchSearch,
		boardsKeys.search(debouncedSearch, userCoordinates),
		true,
		undefined,
		debouncedSearch
	);

	// const myBoards = useGetBoards(fetchMyBoards, boardsKeys.list("myBoards"));

	// const infiniteFollowData = useGetBoards(
	// 	fetchFollowing,
	// 	boardsKeys.list("following")
	// );

	const infiniteSuggestedData = useGetBoards(
		fetchSuggested,
		boardsKeys.list("suggested", userCoordinates)
	);

	// const [showBoardModal, setShowBoardModal] = useState(false);

	// ! ======== SUGGEST MODAL ========

	const [showSuggestModal, setShowSuggestModal] = useState(false);

	useEffect(() => {
		if (showSuggestModal) trackEvent(eventsDictionary.BOARD.SUGGEST);
	}, [showSuggestModal]);

	useEffect(() => {
		trackEvent(eventsDictionary.BOARD.LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.search_input_wrapper}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					onClearClicked={() => setSearchInput("")}
					border={false}
					placeholder={"Search Boards"}
				/>
				{/* <button type={"button"} onClick={() => setShowBoardModal(true)}>
					+ Create board
				</button> */}
			</div>

			<div className={styles.boards_wrapper}>
				{searchInput ? (
					<BoardSection
						title="Search Results"
						searchInput={debouncedSearch}
						infiniteData={infiniteSearchData}
						showEmpty={true}
						showManageBoards={true}
					/>
				) : (
					<BoardSection
						title="Suggested Boards"
						infiniteData={infiniteSuggestedData}
						showEmpty={true}
						showManageBoards={true}
					/>
					// <>
					// 	<BoardSection
					// 		title="Your boards"
					// 		infiniteData={myBoards}
					// 	/>

					// 	<BoardSection
					// 		title="Boards you follow"
					// 		infiniteData={infiniteFollowData}
					// 	/>

					// 	<BoardSection
					// 		title="Suggested Boards"
					// 		infiniteData={infiniteSuggestedData}
					// 	/>
					// </>
				)}
			</div>

			<div className={styles.suggest_container}>
				Don't find what you were looking for?
				<button onClick={() => setShowSuggestModal(true)}>Suggest a board...</button>
			</div>

			<SuggestBoardModal show={showSuggestModal} onClose={() => setShowSuggestModal(false)} />

			{showModal && (
				<CityModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}

			{/* <BoardEntry
				show={showBoardModal}
				onClose={() => setShowBoardModal(false)}
			/> */}
		</div>
	);
});

export default Boards;
