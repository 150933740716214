import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import styles from "./SuggestBoardModal.module.scss";
import { ReactComponent as BoardIcon } from "images/board.svg";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import useMutate from "hooks/useMutate";
import boardsApi from "api/boards";
import { suggestedBoardSchema } from "./board-schema";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import GaawkButton from "components/Utils/Button/GaawkButton";

const SuggestBoardModal = ({ show, onClose }) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		control,
	} = useForm({
		resolver: joiResolver(suggestedBoardSchema),
		mode: "all",
	});

	const handleClose = () => {
		reset();
		onClose();
	};

	const {
		action: { mutate: suggestBoard, isLoading },
	} = useMutate(boardsApi.suggestBoard, () => {
		trackEvent(eventsDictionary.BOARD.SUGGEST_DONE);
		handleClose();
	});

	const handleSave = (data) => {
		const { boardName, description } = data;
		suggestBoard({ boardName, description });
	};

	return (
		<GaawkModal
			show={show}
			handleClose={handleClose}
			defaultModal={false}
			showHeader={true}
			title={"Suggest a board"}
			closeAlign={"right"}
			children={
				<div className={styles.container}>
					<div className={styles.info}>
						<BoardIcon fill="#000" className={styles.board_icon} />
						<p>
							Didn't find the board you were looking for? No problem! Please fill the
							fields below and we'll evaluate your suggestion and get back to you.
						</p>
					</div>
					<form onSubmit={handleSubmit((data) => handleSave(data))} noValidate>
						<InputWrapper
							label="Board Name"
							required={true}
							error={errors?.boardName}
							component={
								<TextInput
									{...register("boardName")}
									placeholder="Enter Board Name"
								/>
							}
						/>

						<Controller
							name={"description"}
							control={control}
							render={({ field }) => (
								<TextArea
									required={true}
									className={styles.textarea_wrapper}
									label="Description"
									error={errors.description}
									{...field}
								/>
							)}
						/>

						<div className={styles.button_container}>
							<GaawkButton
								className={styles.save_btn}
								text={"Save"}
								isLoading={isLoading}
							/>
						</div>
					</form>
				</div>
			}
		/>
	);
};

export default SuggestBoardModal;
