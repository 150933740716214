import NewBottomNavBar from "components/Utils/SubComs/bottomNavBar/NewBottomNavBar";

import styles from "./FullPage.module.css";

const FullPage = ({ content }) => {
	return (
		<>
			<div className={styles.container}>
				{content}
				<NewBottomNavBar />
			</div>
		</>
	);
};

export default FullPage;
