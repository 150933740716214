import { classNames } from "primereact/utils";
import styles from "./RoundButton.module.scss";
import { ReactComponent as DeleteIcon } from "images/delete.svg";
import { ReactComponent as EditIcon } from "images/edit.svg";

const RoundButton = ({
	type = "button",
	onClick,
	disabled = false,
	isLoading = false,
	className,
	icon,
}) => {
	let iconType;

	switch (icon) {
		case "edit":
			iconType = <EditIcon />;
			break;
		case "delete":
			iconType = <DeleteIcon />;
			break;

		//TODO >> add add, crop, tag, services,
		default:
			break;
	}

	return (
		<button
			type={type}
			onClick={onClick}
			disabled={disabled || isLoading}
			className={classNames(styles.round_button, className, {
				[styles.disabled]: disabled,
			})}
		>
			{iconType}
		</button>
	);
};

export default RoundButton;
