import GaawkButton from "components/Utils/Button/GaawkButton";
import styles from "./ManageAccount.module.scss";
import SettingsContainer from "../SettingsComponents/SettingsContainer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import routes from "components/Routing/routing-keys";

const ManageAccount = () => {
	const navigate = useNavigate();
	const { email, mobile, mobileCountryCode } = useSelector((state) => state.user.userLogin);

	return (
		<div className={styles.container}>
			<SettingsContainer className={styles.setting_item}>
				<div className={styles.details}>
					<p>Your Primary E-mail:</p>
					<b>{email}</b>
				</div>
				<GaawkButton
					text={"Change E-mail"}
					severity={"tertiary"}
					className={styles.manage_btn}
					onClick={() => alert("TODO")} //TODO
				/>
			</SettingsContainer>

			<SettingsContainer className={styles.setting_item}>
				<div className={styles.details}>
					<p>Your Primary phone number:</p>
					<b>{`+${mobileCountryCode} ${mobile}`}</b>
				</div>
				<GaawkButton
					text={"Change Number"}
					severity={"tertiary"}
					onClick={() => navigate(routes.phoneChange)}
					className={styles.manage_btn}
				/>
			</SettingsContainer>

			<SettingsContainer className={styles.setting_item}>
				<div className={styles.details}>
					<p>
						To change your password, check your email inbox and click on 'Change
						Password' to proceed.
					</p>
				</div>
				<GaawkButton
					text={"Change Password"}
					severity={"tertiary"}
					onClick={() => navigate(routes.passwordChange)}
					className={styles.manage_btn}
				/>
			</SettingsContainer>
		</div>
	);
};

export default ManageAccount;
