import styles from "./WarningChatModal.module.css";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import ProfilePic from "../Home/ProfilePic";
import cancelIcon from "images/cancel-btn-red.svg";
import reportIcon from "images/report-icon.svg";
import blockIcon from "images/block-icon.svg";
import clearIcon from "images/clear-icon-gray.svg";
import binIcon from "images/bin-icon-red.svg";
import binIconGrey from "images/bin-icon-gray.svg";
import GaawkButton from "components/Utils/Button/GaawkButton";

const ChatModal = ({ show, action, actionAlt = "", conversationItem, onConfirm, onClose }) => {
	const { conversationType, minifiedProfile, groupDTO } = conversationItem;

	// Possible actions :

	// ? Both Group and P2P:
	// * CLEAR
	// * DELETE_MSG ==> DONE
	// * DELETE_MSGS (multi select) ==> DONE
	// * DELETE_CONV

	// ? P2P:
	// * BLOCK
	// * REPORT

	// ? Group:
	// * LEAVE_GROUP
	// * REPORT_GROUP
	// * DELETE_GROUP
	// * DELETE_GROUP_IMG

	let icon = "";
	let title = "";
	let text = "";
	let btnText = "";
	let btnText2 = "";

	switch (action) {
		case "CLEAR":
			icon = clearIcon;
			title = "Clear Conversation";
			btnText = "Clear";
			text = (
				<div className={styles.text}>
					Are you sure you want to <span className={styles.red}>clear</span> this
					conversation? This action <b>CAN’T</b> be undone!
				</div>
			);
			break;
		case "DELETE_MSG":
			icon = binIconGrey;
			title = "Delete";
			btnText = "Delete For Me";
			if (actionAlt) {
				btnText2 = "Delete For Everyone";
			}
			text = (
				<div className={styles.text}>
					Are you sure you want to delete the selected message(s)? This action can not be
					undone!
				</div>
			);
			break;
		// case "DELETE_MSGS":
		//     icon = clearIcon;
		//     title = "Delete Messages";
		//     btnText = "delete";
		//     text = (
		//         <div className={styles.text}>
		//             Are you sure you want to <span className={styles.red}>delete</span> these messages?
		//         </div>
		//     );
		//     break;
		case "DELETE_CONV":
			icon = binIcon;
			title = "Delete Conversation";
			btnText = "Delete";
			text = (
				<div className={styles.text}>
					Are you sure you want to <span className={styles.red}>delete</span> this
					conversation?
				</div>
			);
			break;
		case "BLOCK":
			icon = blockIcon;
			title = "Block Connection";
			btnText = "Block";
			text = (
				<div className={styles.text}>
					Are you sure you want to <span className={styles.red}>block</span> this person?
					<br />
					You will not receive messages or notifications, and you'll not be connected.
				</div>
			);
			break;
		case "REPORT":
			icon = reportIcon;
			title = "Report Connection";
			btnText = "Report";
			text = (
				<div className={styles.text}>
					Are you sure you want to <span className={styles.red}>report</span> this person?
				</div>
			);
			break;
		case "LEAVE_GROUP":
			icon = cancelIcon;
			title = `Leave Group ${groupDTO?.name}`;
			btnText = "Leave";
			text = (
				<div className={styles.text}>
					Are you sure you want to <span className={styles.red}>leave</span> this group?
					<br />
					You will not receive messages or notifications.
				</div>
			);
			break;
		// case "REPORT_GROUP":
		// 	icon = reportIcon;
		// 	title = `Report Group ${groupDTO?.name}`;
		// 	btnText = "report";
		// 	text = (
		// 		<div className={styles.text}>
		// 			Are you sure you want to{" "}
		// 			<span className={styles.red}>report</span> this group?
		// 		</div>
		// 	);
		// 	break;
		case "DELETE_GROUP":
			icon = binIcon;
			title = `Delete Group ${groupDTO?.name}`;
			btnText = "Delete";
			text = (
				<div className={styles.text}>
					Are you sure you want to <span className={styles.red}>delete</span> this group?
					<br />
					You will <b>leave the group and will not receive new messages.</b> This action
					can NOT be undone.
				</div>
			);
			break;
		case "DELETE_GROUP_IMG":
			icon = cancelIcon;
			title = "Remove Group Image";
			btnText = "Remove";
			text = (
				<div className={styles.text}>
					Are you sure you want to <span className={styles.red}>remove</span> this group’s
					image?
				</div>
			);
			break;
		default:
			break;
	}

	let conversationImage = "";

	if (conversationType === "Default" && minifiedProfile.profileImage) {
		conversationImage = minifiedProfile.profileImage.thumbnail;
	} else if (conversationType === "Group" && groupDTO.image) {
		conversationImage = groupDTO.image.file.customName;
	}

	return (
		<div>
			<GaawkModal
				show={show}
				handleClose={onClose}
				showHeader={true}
				defaultModal={false}
				title={title}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.content_wrapper}>
							{conversationImage &&
							action !== "DELETE_MSGS" &&
							action !== "DELETE_MSG" &&
							action !== "CLEAR" ? (
								<ProfilePic
									type={"USER"}
									thumbnail={conversationImage}
									w={"52px"}
									h={"52px"}
									noMarginRight={true}
									icon={icon}
								/>
							) : (
								<img src={icon} className={styles.icon} alt={btnText} />
							)}
							{text}
						</div>

						<div className={styles.btn_container}>
							{!btnText2 && (
								<GaawkButton
									text={"Cancel"}
									severity={"tertiary"}
									className={styles.modal_btn}
									onClick={onClose}
								/>
							)}

							<GaawkButton
								severity={"danger"}
								text={btnText}
								className={styles.modal_btn}
								onClick={() => onConfirm(action)}
							/>

							{btnText2 && (
								<GaawkButton
									text={btnText2}
									severity={"danger"}
									className={styles.modal_btn}
									onClick={() => onConfirm(actionAlt)}
								/>
							)}
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default ChatModal;
