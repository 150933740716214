import styles from "../Results/Results.module.scss";
import TabMenu from "../../Utils/SubComs/TabMenu/TabMenu";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useSavedAppliedJobs from "hooks/useSavedAppliedJobs";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const SavedAppliedJobs = ({ endpoint, queryKey, infoText }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname === "/jobs/applied") {
			trackEvent(eventsDictionary.JOB.APPLIED);
		} else {
			trackEvent(eventsDictionary.JOB.SAVED);
		}
	}, [pathname]);

	const { data, isFetching, jobType, handleTabChange, jobsList } = useSavedAppliedJobs(
		endpoint,
		queryKey
	);

	return (
		<>
			<div className={styles.info_text}>{infoText}</div>
			<div className={styles.menu}>
				<TabMenu
					menuItems={{
						all: "All",
						long_term: "Jobs",
						freelance: "Freelance",
						casting: "Casting Calls",
					}}
					selectedTab={jobType}
					onSelectedTabChanged={handleTabChange}
					customStyle={styles.menu_button}
				/>
			</div>

			{data?.pages[0].length > 0 && <div className={styles.container}>{jobsList}</div>}
			<LoadingSpinner visible={isFetching} />

			<NoResults visible={data?.pages[0].length === 0} text={"No Results"} />
		</>
	);
};

export default SavedAppliedJobs;
