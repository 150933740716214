import routes from "components/Routing/routing-keys";
import ReactionsModal from "components/Utils/Reactions/ReactionsModal";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import Tag from "components/Utils/SubComs/Tags/Tag";
import { format } from "date-fns";
import FileSaver from "file-saver";
import useCurrentUser from "hooks/useCurrentUser";
import useGetPostById from "hooks/useGetPostById";
import useMutate from "hooks/useMutate";
import { ReactComponent as CalendarIcon } from "images/calendar.svg";
import blockIcon from "images/connection-icons/block.svg";
import commentIcon from "images/message.svg";
import share from "images/repost.svg";
import { forwardRef, useEffect, useRef, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import postApi from "../../api/post";
import BoardPostActions from "../../components/Boards/BoardsComponents/BoardPostActions";
import FormatSpecialText from "../../components/Utils/SubComs/FormatSpecialText/FormatSpecialText";
import GroupMember from "../Chat/GroupMember";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import WarningModal from "../Utils/GaawkModal/WarningModal";
import { copyToClipboard, getMapsLink, getReactionImg } from "../Utils/General";
import Reactions from "../Utils/Reactions/Reactions";
import ReactionsCounter from "../Utils/Reactions/ReactionsCounter";
import StaticMap from "../Utils/StaticMap/StaticMap";
import ReadMore from "../Utils/SubComs/ReadMore/ReadMore";
import Comments from "./Comments";
import PostDocuments from "./Components/PostDocuments";
import RepostModal from "./Components/RepostModal/RepostModal";
import styles from "./Feeds.module.scss";
import PostActions from "./PostActions";
import PostHeader from "./PostHeader";
import PostMedia from "./PostMedia";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const PostItem = forwardRef(
	(
		{
			post: postItem,
			sm,
			onItemClicked,
			onDelete,
			onEdit,
			onPostUpdate,
			onShare,
			inShareModal = false,
			isSharePost = false,
			visibleByDefault = false,
			showFooterOnly = false,
			activeSlideIndex,
			canEditBoard = false,
			isPinned = false,
			onTogglePin,
			isModal,
			onClose,
		},
		ref
	) => {
		// const { uuid: profileId } = useSelector((state) => state.user.profileInfo);

		const navigate = useNavigate();
		// const { pathname } = useLocation();
		const reportRef = useRef();

		const { uuid: profileId } = useCurrentUser();

		// const postRef = useRef(null);

		// const isFullscreenPost = useMemo(
		// 	() => pathname.startsWith("/post/") || isModal,
		// 	[pathname, isModal]
		// );

		useEffect(() => {
			setShowingComments(false);
		}, [activeSlideIndex]);

		const [mediaList, setMediaList] = useState([]);

		const [showingComments, setShowingComments] = useState(false);

		const [isShown, setIsShown] = useState(false);

		const [deleteWarning, setDeleteWarning] = useState(false);

		const [post, setPost] = useState(postItem);

		const [taggedModal, setTaggedModal] = useState(false);

		useEffect(() => {
			setPost(postItem);
		}, [postItem]);

		const {
			uuid: postId,
			owner,
			postText,
			// items,
			// documentList,
			file,
			taggedProfiles,
			reactionStats,
			commentsCount,
			totalCommentsCount,
			sharedCount,
			profileReactionType,
			// commentUniqueCount,
			hasSharedPost,
			sharedPost,
			postType,
			location,
			mediaList: mediaItemsList,
			pdfList: pdfItemsList,
			totalMediaCount,
			totalPDFCount,
			board: { name: boardName, feedImage, uuid: boardId } = {},
			board,
			title,
			services,
			date,
		} = post;

		useEffect(() => {
			if (showingComments) {
				trackEvent(eventsDictionary.POST.COMMENT_LIST, {
					post_id: postId,
				});
			}
		}, [showingComments]);

		const { data: fullPost, refetch } = useGetPostById(postId, false);

		const taggedProfilesList = taggedProfiles?.map((item) => (
			<GroupMember key={item.uuid} redirect={true} data={item} />
		));

		const { pathname } = useLocation();

		const servicesTags = services?.map((service) => (
			<Tag
				key={service.uuid}
				itemName={`+ ${service.name}`}
				customStyle={styles.tag_item}
				onClick={() =>
					pathname === routes.serviceDetails(service.uuid)
						? onClose()
						: navigate(routes.serviceDetails(service.uuid))
				}
			/>
		));

		useEffect(() => {
			if (file) {
				// fileArray.push(file);
				setMediaList([file]);
			}
			if (totalMediaCount > 0) {
				setMediaList(mediaItemsList.map((item) => item.file));

				// mediaItemsList.map((sub) => fileArray.push(sub.file));
				// setMediaList(fileArray);
			}

			// if (totalPDFCount > 0) {
			// 	setPdfList(pdfItemsList.map((item) => item.file));

			// }
		}, [file, pdfItemsList, mediaItemsList, totalMediaCount, totalPDFCount]);

		// !======= REACTION HANDLERS ==============

		const {
			action: { mutate: addReaction },
		} = useMutate(postApi.addPostReaction, (response) => onPostUpdate(response.data));

		const {
			action: { mutate: removeReaction },
		} = useMutate(postApi.removePostReaction, (response) => onPostUpdate(response.data));

		const [reactionTab, setReactionTab] = useState("All");
		const [showReactions, setShowReactions] = useState(false);
		const [delayHandler, setDelayHandler] = useState(null);

		const handleBtnEnter = () => {
			setDelayHandler(
				setTimeout(() => {
					setShowReactions(true);
				}, 500)
			);
		};

		const handleBtnLeave = () => {
			setDelayHandler(
				setTimeout(() => {
					setShowReactions(false);
				}, 700)
			);
		};

		useEffect(() => {
			return () => {
				clearTimeout(delayHandler);
			};
		}, [delayHandler]);

		const handleAddReaction = (reactionType) => {
			if (profileReactionType === reactionType) removeReaction(postId);
			else addReaction({ postId, reactionType });
			setShowReactions(false);
		};

		const handleReactionBtnClicked = () => {
			if (profileReactionType) removeReaction(postId);
			else addReaction({ postId, reactionType: "gaawk" });
			setShowReactions(false);
		};

		//! ================ repost modal ======================

		const [showRepostModal, setShowRepostModal] = useState(false);

		//! ================ reaction modal ====================

		const [showReactionsModal, setShowReactionsModal] = useState(false);

		// !==========  handleDownloadPicture  =================

		const handleDownloadPicture = () => {
			FileSaver.saveAs(`${post.file.customName}`, `${post.file.originalName}`);
		};

		// !====================================================

		const handleMapClick = (location) => () => {
			window.open(getMapsLink(location), "_blank");
		};

		const [warningObject, setWarningObject] = useState(undefined);

		const handleDeletePost = () => {
			if (post.postType === "PROFILE_PIC" || post.postType === "COVER_PIC") {
				setWarningObject({
					headerText: "Remove selected",
					warningText:
						"Are you sure you want to remove this post from feed and gallery? This will not delete your original post.",
					submitButtonText: "REMOVE",
				});
			} else {
				setWarningObject({
					headerText: "Are you sure you want to delete your post?",
					warningText: "This action cannot be undone.",
					submitButtonText: "DELETE",
				});
			}

			setDeleteWarning(true);
		};

		const confirmDeletePost = () => {
			onDelete(postId);
			setDeleteWarning(false);
		};

		// let comments = `${commentsCount} comment${commentsCount > 1 ? "s" : ""}`;

		// ! ============ comment increment handler ==================

		const handleUpdateCommentCount = (operation) => {
			if (operation === "INCREMENT") {
				setPost((prevState) => ({
					...prevState,
					commentsCount: prevState.commentsCount + 1,
					totalCommentsCount: prevState.totalCommentsCount + 1,
				}));
			} else {
				setPost((prevState) => ({
					...prevState,
					commentsCount: prevState.commentsCount - 1,
					totalCommentsCount: prevState.totalCommentsCount - 1,
				}));
			}
		};

		const handleUpdateTotalCommentCount = (operation) => {
			if (operation === "INCREMENT") {
				setPost((prevState) => ({
					...prevState,
					totalCommentsCount: prevState.totalCommentsCount + 1,
				}));
			} else {
				setPost((prevState) => ({
					...prevState,
					totalCommentsCount: prevState.totalCommentsCount - 1,
				}));
			}
		};

		const handleCopyLink = () => {
			copyToClipboard(
				`${window.location.origin}${routes.post(sharedPost ? sharedPost.uuid : postId)}`
			);
			toast.success("Link copied !");
		};

		const handleReport = () => reportRef.current.showReportModal(postId);

		return (
			<>
				<LazyLoadComponent visibleByDefault={visibleByDefault}>
					<div
						className={`${styles.post_item} ${sm ? styles.sm : undefined} ${
							isSharePost ? styles.share_post : undefined
						} ${inShareModal ? styles.inShareModal : undefined}`}
						onMouseLeave={() => isShown && setIsShown(false)}
						ref={ref}
					>
						{!showFooterOnly && (
							<div className={styles.post_header}>
								<PostHeader
									boardName={boardName}
									name={owner.name}
									img={
										board
											? feedImage?.file?.customName
											: owner?.profileImage?.image
									}
									icon={board ? owner?.profileImage?.image : undefined}
									emotion={post.emotion}
									location={post.location}
									tags={taggedProfiles}
									createMode={false}
									postTime={post.postTime}
									visibility={post.postVisibility}
									type={owner.type}
									url={owner.url}
									postType={board ? "BOARD" : postType}
									onOtherTagsClicked={(e) => {
										e.stopPropagation();
										setTaggedModal(true);
									}}
									onThumbnailClick={(e) => {
										e.stopPropagation();
										navigate(routes.board(boardId));
									}} //* >> only triggered if postType === "BOARD"
								/>

								{!isSharePost &&
									!isModal &&
									(board ? (
										<BoardPostActions
											isOwner={owner.uuid === profileId}
											canEditBoard={canEditBoard}
											isPinned={isPinned}
											onEdit={() => onEdit(post)}
											onTogglePin={onTogglePin}
											onShare={() => onShare(post)}
											onDelete={handleDeletePost}
											onCopy={handleCopyLink}
											onReport={handleReport}
										/>
									) : (
										<PostActions
											postOwner={owner.name}
											isOwner={owner.uuid === profileId}
											isPublic={post.postVisibility === "PUBLIC"}
											onEdit={() => onEdit(post)}
											onDelete={handleDeletePost}
											postType={post.postType}
											onCopy={handleCopyLink}
											onShare={() => onShare(post)}
											onReport={handleReport}
											onDownload={
												post.postType === "PROFILE_PIC" ||
												post.postType === "COVER_PIC"
													? handleDownloadPicture
													: undefined
											}
										/>
									))}
							</div>
						)}

						<div className={styles.post_container}>
							{!showFooterOnly && (
								<>
									{board && <h4 className={styles.board_post_title}>{title}</h4>}
									{postText && (
										<ReadMore>
											<FormatSpecialText
												text={postText}
												customStyle={styles.post_text}
											/>
										</ReadMore>
									)}
									{/* TODO >> add the date here  */}

									{sm && date > 0 && (
										<Tag
											customStyle={styles.tag_item}
											itemName={
												<div className={styles.date_container}>
													<CalendarIcon fill={"#FFF"} width={15} />

													{format(new Date(date), "dd/MM/yyyy")}
												</div>
											}
										/>
									)}

									{sm && services?.length > 0 && (
										<>
											<h5 className={styles.tags_title}>Services Tagged</h5>
											<div className={styles.services_tags_container}>
												{servicesTags}
											</div>
										</>
									)}
									{location && !file && totalMediaCount === 0 && (
										<div
											className={styles.post_location_wrapper}
											onClick={handleMapClick(location)}
										>
											<StaticMap
												lat={location.latitude}
												lng={location.longitude}
												title={location.title}
											/>
										</div>
									)}
									{(file || totalMediaCount > 0) && !sm && (
										<PostMedia
											post={post}
											mediaList={mediaList}
											onItemClicked={(index) => {
												if (file) {
													onItemClicked();
												} else {
													onItemClicked(mediaItemsList[index].uuid);
												}
											}}
											childPostsCount={totalMediaCount}
										/>
									)}
									{totalPDFCount > 0 && (
										<>
											<PostDocuments
												items={fullPost?.pdfList || pdfItemsList}
												onClick={(index) => {
													onItemClicked(
														fullPost?.pdfList[index].uuid ||
															pdfItemsList[index].uuid
													);
												}}
												isShared={isSharePost}
											/>

											{!fullPost?.pdfList &&
												pdfItemsList.length < totalPDFCount && (
													<SeeMore
														// onClick={() => setFullPostId(postId)}
														onClick={refetch}
														className={styles.view_all}
														text={`View All ${totalPDFCount} PDF Files`}
													/>
												)}
										</>
									)}
									{hasSharedPost && sharedPost && (
										<PostItem
											post={sharedPost}
											onItemClicked={onItemClicked}
											inShareModal={inShareModal}
											isSharePost={hasSharedPost}
										/>
									)}
									{hasSharedPost && !sharedPost && (
										<div
											className={`${styles.unavailable} ${styles.share_post}`}
										>
											<img src={blockIcon} alt="block" />
											<h4>Unavailable Post</h4>
											<p>
												This post is not available or deleted by the author.
											</p>
										</div>
									)}
								</>
							)}

							{!isSharePost && (
								<div className={styles.post_footer}>
									{(reactionStats.totalCount > 0 ||
										totalCommentsCount > 0 ||
										sharedCount > 0) && (
										<div className={styles.reaction_wrapper}>
											<ReactionsCounter
												reactionStats={reactionStats}
												commentsCount={totalCommentsCount}
												sharedCount={sharedCount}
												onShowReactions={() => setShowReactionsModal(true)}
												onShowRepost={() => setShowRepostModal(true)}
												onShowComment={() =>
													setShowingComments((prevState) => !prevState)
												}
												onClick={(reaction) => setReactionTab(reaction)}
											/>
										</div>
									)}

									<div className={styles.reaction_button_wrapper}>
										<div
											className={styles.react_button_wrapper}
											onMouseEnter={handleBtnEnter}
											onMouseLeave={handleBtnLeave}
										>
											{showReactions && (
												<div
													className={`${styles.reactions_container} ${
														isModal
															? styles.full_screen_post
															: undefined
													}`}
												>
													<Reactions
														onSelect={handleAddReaction}
														isFullscreenPost={isModal}
													/>
												</div>
											)}

											<button
												className={styles.reaction_button_icon}
												onClick={handleReactionBtnClicked}
											>
												<img
													src={getReactionImg(profileReactionType)}
													alt={profileReactionType ?? "React"}
												/>
												<label>{profileReactionType ?? "React"}</label>
											</button>
										</div>

										<button
											className={`${styles.reaction_button_icon} ${
												showingComments && styles.selected
											}`}
											onClick={() => setShowingComments(!showingComments)}
										>
											<img src={commentIcon} alt={"comment"} />
											<label>Comment</label>
										</button>

										{post.postVisibility === "PUBLIC" && !showFooterOnly && (
											<button
												className={styles.reaction_button_icon}
												onClick={() => onShare(post)}
											>
												<img src={share} alt={"share"} />
												<label>Repost</label>
											</button>
										)}
									</div>
								</div>
							)}
						</div>

						{showingComments && (
							<Comments
								visible={showingComments}
								postId={postId}
								commentsCount={commentsCount}
								onUpdateCommentCount={handleUpdateCommentCount}
								onUpdateTotalCommentCount={handleUpdateTotalCommentCount}
								isFullscreenPost={isModal}
								// onIncrementComment={handleIncrementComment}
								// onDecrementComment={handleDecrementComment}
								// commentUniqueCount={commentUniqueCount}
							/>
						)}
					</div>

					<GaawkModal
						show={taggedModal}
						handleClose={() => setTaggedModal(false)}
						defaultModal={false}
						showHeader={true}
						title={"Tagged people"}
						closeAlign={"right"}
						children={
							<div className={styles.tagged_container}>
								<div className={styles.overflow_container}>
									{taggedProfilesList}
								</div>
							</div>
						}
					/>

					<WarningModal
						show={deleteWarning}
						modalOnTop={false}
						headerText={warningObject?.headerText}
						warningText={warningObject?.warningText}
						cancelButtonText={"Cancel"}
						onCancelButtonClicked={() => setDeleteWarning(false)}
						submitButtonText={warningObject?.submitButtonText}
						onSubmitButtonClicked={confirmDeletePost}
					/>

					<ReactionsModal
						show={showReactionsModal}
						itemId={postId}
						type={"post"}
						onClose={() => setShowReactionsModal(false)}
						title="Reactions"
						activeTab={reactionTab}
						reactionStats={reactionStats}
					/>

					<RepostModal
						show={showRepostModal}
						onClose={() => setShowRepostModal(false)}
						postId={postId}
					/>

					<ReportModal ref={reportRef} category="post" />
				</LazyLoadComponent>
			</>
		);
	}
);

export default PostItem;
