// import { create } from "apisauce";
// import { differenceInHours, getTime } from "date-fns";

// const BASE_URL = process.env.REACT_APP_BASE_URL;
// const AUTH_USER = process.env.REACT_APP_AUTH_USER;
// const AUTH_PASS = process.env.REACT_APP_AUTH_PASSWORD;
// const expiryInHours = 12;

// const apiClient = create({
// 	baseURL: BASE_URL,
// });

// const getToken = async () => {
// 	// todo:: secure this IMPORTANT
// 	// check the local storage
// 	const savedToken = localStorage.getItem("app_session_id");
// 	const savedTime = localStorage.getItem("app_session_time");

// 	if (savedToken && savedTime && !isExpired(savedTime))
// 		return `Bearer ${savedToken}`;

// 	// call api to get a new token
// 	const client = create({ baseURL: BASE_URL });

// 	const result = await client.post("/authenticate", {
// 		password: AUTH_PASS,
// 		username: AUTH_USER,
// 	});

// 	if (!result.ok) return alert("Error connecting to server");

// 	const jwtToken = result.data.jwttoken;

// 	localStorage.setItem("app_session_id", jwtToken);
// 	localStorage.setItem("app_session_time", getTime(new Date()));

// 	return `Bearer ${jwtToken}`;
// };

// apiClient.addAsyncRequestTransform((request) => async () => {
// 	const companyId = localStorage.getItem("companyId");

// 	if (!request.headers.skipAuth) {
// 		const jwtToken = await getToken();
// 		request.headers["Authorization"] = jwtToken;
// 	}

// 	const profileId =
// 		companyId && !request.headers.useUserId
// 			? companyId
// 			: localStorage.getItem("uuid");
// 	const userId = localStorage.getItem("uuid");

// 	request.headers["profileId"] = profileId;
// 	request.headers["userId"] = userId;
// });

// const isExpired = (savedTime) => {
// 	return (
// 		differenceInHours(getTime(new Date()), parseInt(savedTime)) >=
// 		expiryInHours
// 	);
// };

// export default apiClient;

//* NEW VERSION OF THE CODE FROM CLAUDE

import { create } from "apisauce";
import { differenceInHours, getTime } from "date-fns";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_USER = process.env.REACT_APP_AUTH_USER;
const AUTH_PASS = process.env.REACT_APP_AUTH_PASSWORD;
const expiryInHours = 12;

// Flag to prevent multiple token refresh calls
let isRefreshing = false;
// Store pending requests
let failedQueue = [];
// Cache the token promise
let tokenPromise = null;

const processQueue = (error, token = null) => {
	failedQueue.forEach((prom) => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});

	failedQueue = [];
};

const apiClient = create({
	baseURL: BASE_URL,
});

const getToken = async () => {
	// If we already have a token request in progress, return that promise
	if (tokenPromise) return tokenPromise;

	const savedToken = localStorage.getItem("app_session_id");
	const savedTime = localStorage.getItem("app_session_time");

	if (savedToken && savedTime && !isExpired(savedTime)) {
		return `Bearer ${savedToken}`;
	}

	// Create a new token request
	tokenPromise = (async () => {
		const client = create({ baseURL: BASE_URL });

		try {
			const result = await client.post("/authenticate", {
				password: AUTH_PASS,
				username: AUTH_USER,
			});

			if (!result.ok) throw new Error("Authentication failed");

			const jwtToken = result.data.jwttoken;

			localStorage.setItem("app_session_id", jwtToken);
			localStorage.setItem("app_session_time", getTime(new Date()));

			return `Bearer ${jwtToken}`;
		} catch (error) {
			console.error("Error getting token:", error);
			throw error;
		} finally {
			// Clear the promise so future requests can try again
			tokenPromise = null;
		}
	})();

	return tokenPromise;
};

// Cache the current token value
let currentToken = null;

apiClient.addAsyncRequestTransform((request) => async () => {
	const companyId = localStorage.getItem("companyId");

	if (!request.headers.skipAuth) {
		// Only get a new token if we don't have one cached
		if (!currentToken) {
			currentToken = await getToken();
		}
		request.headers["Authorization"] = currentToken;
	}

	const profileId =
		companyId && !request.headers.useUserId ? companyId : localStorage.getItem("uuid");
	const userId = localStorage.getItem("uuid");

	request.headers["profileId"] = profileId;
	request.headers["userId"] = userId;
});

// Add response interceptor
apiClient.addMonitor(async (response) => {
	if (response.status === 401) {
		const originalRequest = response.config;

		if (isRefreshing) {
			return new Promise((resolve, reject) => {
				failedQueue.push({ resolve, reject });
			})
				.then((token) => {
					originalRequest.headers["Authorization"] = token;
					return apiClient.any(originalRequest);
				})
				.catch((err) => Promise.reject(err));
		}

		isRefreshing = true;

		// Clear existing token and cache
		localStorage.removeItem("app_session_id");
		localStorage.removeItem("app_session_time");
		currentToken = null;
		tokenPromise = null;

		try {
			const newToken = await getToken();
			currentToken = newToken;

			isRefreshing = false;

			processQueue(null, newToken);

			originalRequest.headers["Authorization"] = newToken;
			// return apiClient.any(originalRequest); //* replaced that with the below (refresh the browser window)
			window.location.reload();
			return;
		} catch (error) {
			isRefreshing = false;
			processQueue(error, null);
			return Promise.reject(error);
		}
	}

	return response;
});

const isExpired = (savedTime) => {
	return differenceInHours(getTime(new Date()), parseInt(savedTime)) >= expiryInHours;
};

export default apiClient;
