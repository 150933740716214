import routes from "components/Routing/routing-keys";
import useFetchRoles from "hooks/useFetchRoles";
import useMutate from "hooks/useMutate";
import publicIcon from "images/public_icon.svg";
import { locationKeys } from "queryKeys/location-key-factory";
import { rolesKeys } from "queryKeys/roles-key-factory";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateProfileTab } from "store/slices/activeTab";
import workStatusApi from "../../../api/workStatus";
import useFetchLocation from "../../../hooks/useFetchLocation";
import { employementTypes, workLocation as workLocationTypes } from "../../Utils/General";
import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import CustomCheckbox from "../../Utils/SubComs/Inputs/CustomCheckbox/CustomCheckbox";
import MultiSelectInput from "../../Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import {
	defaultFormatter,
	locationFormatter,
} from "../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import styles from "./UpdateWorkStatus.module.css";
import { useCustomQuery } from "hooks/useCustomQuery";
import useApi from "hooks/useApi";
import { workStatusKeys } from "queryKeys/workStatus-key-factory";
import useCurrentUser from "hooks/useCurrentUser";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const itemsLimit = 10;
const itemsPerPage = 20;

const UpdateWorkStatus = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { uuid } = useCurrentUser();
	const getWorkStatusApi = useApi(workStatusApi.getWorkStatus, true, true);

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.UPDATE_WORK_STATUS);
	}, []);

	const fetchWorkStatus = async ({ queryKey }) => {
		const [_, profileId] = queryKey;
		const response = await getWorkStatusApi.request(profileId);
		return response.data;
	};

	const { data, isLoading } = useCustomQuery({
		queryKey: workStatusKeys.getWorkStatus(uuid),
		queryFn: fetchWorkStatus,
		enabled: true,
		options: {
			select: (fetchedData) => {
				const { roles, locations, legalCountries, ...rest } = fetchedData;

				const formattedRoles = roles?.map((role) => ({
					label: role.roleDTO ? role.roleDTO.name : role.roleName,
					value: role.roleDTO ? role.roleDTO.uuid : role.roleName,
				}));

				return {
					...rest,
					locations: locations?.map((item) => locationFormatter(item)),
					legalCountries: legalCountries?.map((item) => locationFormatter(item)),
					roles: roles ? formattedRoles : [],
				};
			},
		},
	});

	const [workStatus, setWorkStatus] = useState({});

	useEffect(() => {
		if (data) {
			setWorkStatus(data);
		}
	}, [data]);

	const {
		available,
		roles,
		types = [],
		locations = [],
		legalCountries = [],
		workplace_hybrid,
		workplace_office,
		workplace_remote,
	} = workStatus;

	// ! ========== WORK AVAILABILITY ========

	const handleAvailability = (bool) => {
		setWorkStatus((prevState) => ({ ...prevState, available: bool }));
	};

	// ! ========== WORKPLACES ===============

	const selectedWorkplaceItems = [
		workplace_office && {
			label: "On Site",
			value: "OFFICE",
		},
		workplace_remote && {
			label: "Remote",
			value: "REMOTE",
		},
		workplace_hybrid && {
			label: "Hybrid",
			value: "HYBRID",
		},
	].filter(Boolean);

	const handleSelectWorkplace = (selectedWorkplaces) => {
		const workPlacesValues = selectedWorkplaces.map((item) => item.value);

		setWorkStatus((prevState) => ({
			...prevState,
			workplace_office: workPlacesValues.includes("OFFICE"),
			workplace_remote: workPlacesValues.includes("REMOTE"),
			workplace_hybrid: workPlacesValues.includes("HYBRID"),
		}));
	};

	// ! ========== JOB TYPES ================
	const handleSelectJob = (jobTypes) => {
		const formattedJobTypes = jobTypes?.map((type) => type.value);
		setWorkStatus((prevState) => ({
			...prevState,
			types: formattedJobTypes,
		}));
	};

	// ! ========== ROLES ====================
	const handleSelectedRole = (role) => {
		setWorkStatus((prevState) => ({
			...prevState,
			roles: [...prevState.roles, role],
		}));
	};

	const handleRemoveRole = (roleIndex) => {
		setWorkStatus((prevState) => ({
			...prevState,
			roles: prevState.roles.filter((_, index) => index !== roleIndex),
		}));
	};

	// ! ========== CITIES ===================
	const handleCities = (city) => {
		setWorkStatus((prevState) => ({
			...prevState,
			locations: [city, ...prevState.locations],
		}));
	};

	const handleRemoveCity = (cityIndex) => {
		setWorkStatus((prevState) => ({
			...prevState,
			locations: prevState.locations.filter((_, index) => index !== cityIndex),
		}));
	};

	// ! ========== COUNTRIES ================
	const handleCountries = (country) => {
		setWorkStatus((prevState) => ({
			...prevState,
			legalCountries: [country, ...prevState.legalCountries],
		}));
	};

	const handleRemoveCountry = (countryIndex) => {
		setWorkStatus((prevState) => ({
			...prevState,
			legalCountries: prevState.legalCountries.filter((_, index) => index !== countryIndex),
		}));
	};

	// ! ========== SUBMIT HANDLER ===========

	const {
		action: { mutate: updateWorkStatus },
	} = useMutate(workStatusApi.updateWorkStatus, () => {
		trackEvent(eventsDictionary.PROFILE.UPDATE_WORK_STATUS_DONE);
		dispatch(updateProfileTab("work status"));
		navigate(routes.profile());
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		const body = {
			available,
			locations: locations.map((location) => location.value),
			legalCountries: legalCountries.map((country) => country.value),
			jobTypes: types,
			roleIds: roles.map((role) => role.value),
			workplace_office,
			workplace_hybrid,
			workplace_remote,
		};

		updateWorkStatus({ body });
	};

	const fetchLocation = useFetchLocation(true, itemsPerPage);
	const fetchCountries = useFetchLocation(false, itemsPerPage);

	const fetchRoles = useFetchRoles();

	if (isLoading) return "Loading";

	return (
		<div className={styles.container}>
			<form onSubmit={handleSubmit}>
				<div className={styles.form_input_wrapper}>
					<label className={styles.input_label}>Work Status</label>
					<div className={styles.checkbox_options}>
						<div className={styles.checkbox_wrapper}>
							<CustomCheckbox
								checked={available === true}
								onChange={() => handleAvailability(true)}
								customBackgroundColor={"gaawk"}
								isCircle={true}
							/>
							<span onClick={() => handleAvailability(true)}>Available</span>
						</div>
						<div className={styles.checkbox_wrapper}>
							<CustomCheckbox
								checked={available === false}
								onChange={() => handleAvailability(false)}
								customBackgroundColor={"gaawk"}
								isCircle={true}
							/>
							<span onClick={() => handleAvailability(false)}>Not Available</span>
						</div>
					</div>
					<div className={styles.info_container}>
						<img src={publicIcon} alt="public" />
						Your work status is always public.
						<span className={styles.separator} />
					</div>
				</div>

				<MultiSelectInput
					creatable={true}
					queryName={rolesKeys.search}
					queryFn={fetchRoles}
					data={roles}
					itemsPerPage={itemsPerPage}
					formatter={defaultFormatter}
					label="Roles"
					limit={itemsLimit}
					onChange={handleSelectedRole}
					onRemoveItem={handleRemoveRole}
					infoText={`You can add up to ${itemsLimit} roles which outline your core
                    skill sets.`}
				/>

				<div className={`${styles.form_input_wrapper} ${styles.inline_checkbox_container}`}>
					<MultiCheckbox
						title={"Workplace"}
						infoText={
							"Workplace is your preference of working from home, office and / or hybrid."
						}
						options={workLocationTypes}
						perRow={"3, auto"}
						onSelect={handleSelectWorkplace}
						selected={selectedWorkplaceItems}
					/>
				</div>

				<MultiSelectInput
					queryName={locationKeys.cities}
					queryFn={fetchLocation}
					data={locations}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label="Preferred Locations"
					limit={itemsLimit}
					onChange={handleCities}
					onRemoveItem={handleRemoveCity}
					infoText={`You can add up to ${itemsLimit} locations where you want to work.`}
				/>

				<div className={styles.form_input_wrapper}>
					<MultiCheckbox
						title={"Job Type"}
						infoText={
							"What type of job you’re looking for? Full time, freelance, contract and more…"
						}
						options={employementTypes.flatMap((job) =>
							job.value !== "APPRENTICESHIP" && job.value !== "SELF_EMPLOYED"
								? job
								: []
						)}
						canSelectAll={true}
						onSelect={handleSelectJob}
						selected={types.map((type) =>
							employementTypes.find((jobType) => jobType.value === type)
						)}
					/>
				</div>

				<MultiSelectInput
					queryName={locationKeys.countries}
					queryFn={fetchCountries}
					data={legalCountries}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label="Legal to work in"
					limit={itemsLimit}
					onChange={handleCountries}
					onRemoveItem={handleRemoveCountry}
					infoText={`You can add up to ${itemsLimit} countries where you have / do not need a work permit to work in.`}
				/>

				<div className={styles.button_wrapper}>
					<GaawkButton
						type="submit"
						// disabled={editAlbumApi.loading}
						text={"Save"}
						className={styles.button}
					/>
				</div>
			</form>
		</div>
	);
};

export default UpdateWorkStatus;
