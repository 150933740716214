import { joiResolver } from "@hookform/resolvers/joi";
import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import { languageFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import useFetchLanguages from "hooks/useFetchLanguages";
import useMutate from "hooks/useMutate";
import { languagesKeys } from "queryKeys/languages-key-factory";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { languageUpdated } from "../../../../store/slices/user";
import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
import languageSchema from "./language-schema";
import styles from "./Language.module.scss";
import { getLanguageProficiencies, getLanguageProficiency } from "./LanguageProficiency";
import ProficiencyGuide from "./ProficiencyGuide";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const itemsPerPage = 20;

const LanguageEntry = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { pathname, state } = useLocation();

	const editMode = pathname === routes.editLanguage;

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.ADD_LANGUAGE, {
			isEditing: editMode,
		});
	}, [editMode]);

	const { uuid: userLanguageId, language, spoken, written } = state || {};

	const {
		formState: { errors, isDirty, isSubmitSuccessful },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(languageSchema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					language: { label: language?.name, value: language?.code },
					written: getLanguageProficiency(written),
					spoken: getLanguageProficiency(spoken),
			  }
			: {
					language: null,
					written: null,
					spoken: null,
			  },
	});

	const handleSuccess = (response) => {
		dispatch(languageUpdated(response.data));
		navigate(routes.language, { replace: true });
	};

	const {
		action: { mutate: addLanguage, isLoading: isAdding },
	} = useMutate(profileApi.addLanguage, (response) => {
		trackEvent(eventsDictionary.PROFILE.ADD_LANGUAGE_DONE);
		handleSuccess(response);
	});

	const {
		action: { mutate: updateLanguage, isLoading: isUpdating },
	} = useMutate(profileApi.updateLanguage, (response) => {
		trackEvent(eventsDictionary.PROFILE.ADD_LANGUAGE_DONE, {
			isEditing: editMode,
		});
		handleSuccess(response);
	});

	const handleSave = (data) => {
		const {
			language: { value: code },
			spoken: { value: spoken },
			written: { value: written },
		} = data;

		const body = {
			...(editMode && { userLanguageId }),
			code,
			spoken,
			written,
		};

		if (editMode) {
			updateLanguage({ body });
		} else {
			addLanguage({ body });
		}
	};

	const fetchLanguages = useFetchLanguages();

	if (editMode && !state) {
		return <Navigate to={routes.language} />;
	}

	return (
		<>
			<div className={`${styles.container} ${styles.new}`}>
				<div className={styles.form_wrapper}>
					<form
						className={styles.form}
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<InfiniteSearchInput
							disabled={editMode}
							label={"Language"}
							required={true}
							queryName={languagesKeys.search}
							queryFn={fetchLanguages}
							itemsPerPage={itemsPerPage}
							formatter={languageFormatter}
							error={errors?.language}
							control={control}
							name="language"
							customStyle={styles.language_wrapper}
							openMenuOnClick={true}
						/>

						<div className={styles.form_input_wrapper}>
							<label>
								Proficiency - Written
								<span className="required">*</span>
							</label>

							<CustomSelect
								placeholder="Select written proficiency"
								options={getLanguageProficiencies()}
								height="35px"
								error={!!errors.written}
								control={control}
								name="written"
							/>
							{errors?.written?.message && (
								<p className={styles.error_message}>{errors?.written?.message}</p>
							)}
						</div>

						<div className={styles.form_input_wrapper}>
							<label>
								Proficiency - Spoken
								<span className="required">*</span>
							</label>

							<CustomSelect
								placeholder="Select spoken proficiency"
								options={getLanguageProficiencies()}
								height="35px"
								error={!!errors.spoken}
								control={control}
								name="spoken"
							/>
							{errors?.spoken?.message && (
								<p className={styles.error_message}>{errors?.spoken?.message}</p>
							)}
						</div>

						<div className={styles.button_container}>
							<GaawkButton
								type={"submit"}
								isLoading={isAdding || isUpdating}
								text={"Save"}
							/>
						</div>
					</form>

					<ProficiencyGuide />
				</div>
			</div>

			<PromptModal when={isDirty && !isSubmitSuccessful} />
		</>
	);
};

export default LanguageEntry;
