import React, { useEffect, useState } from "react";
// import FullPage from "../Utils/PageArchs/FullPage/FullPage";
import SearchInput from "../Utils/SubComs/Inputs/SearchInput/SearchInput";

import styles from "./Search.module.scss";

import SearchFilterView from "../../views/search/SearchFilterView";
// import useQueryString from "../../hooks/useQueryString";
import useUserLocation from "../../hooks/useUserLocation";
import CityModal from "../Boards/BoardsComponents/CityModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import GaawkSearchIcon from "images/gaawk-logos/gaawk-search-black.svg";
import { ReactComponent as ForwardIcon } from "images/arrow-icon.svg";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const Index = () => {
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	const handleSubmit = (e) => {
		e.preventDefault();
		navigate(`/search?q=${searchInput}`);
	};

	const [searchInput, setSearchInput] = useState("");

	const handleInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const [showModal, setShowModal] = useState(false);

	const [countryFlag, setCountryFlag] = useState();

	const { userCoordinates, handleSelectedCity } = useUserLocation(setCountryFlag);

	const {
		name,
		countryDTO: { flagUrl, name: countryName },
	} = userCoordinates || {};

	useEffect(() => {
		trackEvent(eventsDictionary.SEARCH.LANDING);
	}, []);

	return (
		<>
			{!searchParams.has("q") && (
				<DefaultPage
					leftSideChildren={<NavBar />}
					rightSideFullWidth={true}
					rightSideBgColor={"#FFF"}
					rightSideChildren={
						<div className={styles.container}>
							<img
								src={GaawkSearchIcon}
								alt="gaawk search"
								className={styles.gaawk_logo}
							/>

							<p>
								Search anything from people &<br />
								companies to jobs, products, media and more…
							</p>

							<form onSubmit={handleSubmit}>
								<SearchInput
									showIcons={true}
									onChange={handleInputChange}
									value={searchInput}
									onClearClicked={() => setSearchInput("")}
									border={false}
									classNameInput={styles.search_input}
									onSubmitSearch={handleSubmit}
								/>
								<button
									className={styles.location_container}
									onClick={() => setShowModal(true)}
									type="button"
								>
									<div className={styles.location_wrapper}>
										<LazyImage
											image={<img src={flagUrl} alt="" />}
											width={"auto"}
											height={"auto"}
										/>
										<p>{`${name}, ${countryName}`}</p>
										<ForwardIcon className={styles.forward_icon} />
									</div>
								</button>
							</form>
						</div>
					}
				/>
			)}

			{searchParams.has("q") && (
				<SearchFilterView flag={countryFlag} userCoordinates={userCoordinates} />
			)}

			{showModal && (
				<CityModal
					show={showModal}
					onClose={() => setShowModal(false)}
					onSelectCity={handleSelectedCity}
				/>
			)}
		</>
	);
};

export default Index;
