import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { phoneUpdated as companyPhoneUpdated } from "../../../../../store/slices/company";
import { phoneUpdated } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Phone.module.scss";
import PhoneItem from "./PhoneItem";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import { useEffect } from "react";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Phone = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { contacts: contactList, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(
			editCompany ? profileApi.deleteCompanyContact : profileApi.deleteContact,
			(response, variables) => {
				trackEvent(eventsDictionary.PROFILE.DELETE_PHONE);
				const { companyId } = variables || {};
				dispatch(
					companyId ? companyPhoneUpdated(response.data) : phoneUpdated(response.data)
				);
			}
		);

	const contacts = contactList.map((contact) => (
		<PhoneItem
			key={contact.uuid}
			item={contact}
			onEditClicked={() =>
				navigate(routes.editPhone, {
					state: contact,
				})
			}
			onDelete={() => handleRequestMutate(contact)}
		/>
	));

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.PHONE_LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.button_wrapper}>
				<GaawkButton
					severity={"secondary"}
					text="+ Add A New Phone Number"
					onClick={() => navigate(routes.addPhone)}
				/>
			</div>

			{contactList.length > 0 && (
				<>
					<h4>Your Mobile / Landline</h4>
					<div className={styles.contact_section}>{contacts}</div>
				</>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						...(editCompany && { companyId }),
						contactId: activeItem.uuid,
					})
				}
			/>
		</div>
	);
};

export default Phone;
