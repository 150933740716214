import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./ProductDetails.module.scss";
import productStyles from "../ProductPage/ProductPage.module.scss";
import { useGetProductById, useGetProductMedia } from "components/Profile/ProductsTab/hooks";
// import FileSaver from "file-saver";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import GalleryItem from "components/Profile/GalleryTab/GalleryItem";
import Tag from "components/Utils/SubComs/Tags/Tag";
import LazyImage from "components/Utils/LazyImage/LazyImage";
import ReadMore from "components/Utils/SubComs/ReadMore/ReadMore";
import ScrollableMenu from "components/Profile/ScrollableMenu";
import { ReactComponent as FullscreenIcon } from "images/fullscreen-icon-white.svg";
import productIcon from "images/productImgs/layer-2@2x.png";
import modelIcon from "images/productImgs/g-3399@2x.png";
import colorIcon from "images/productImgs/g-1146@2x.png";
import materialsIcon from "images/productImgs/icon@2x.png";
import sizeIcon from "images/productImgs/membrane@2x.png";
import weightIcon from "images/productImgs/weight@2x.png";
import {
	measurementUnits,
	weightUnits,
	measurementUnitsCuts,
	viewerMapper,
} from "components/Utils/General";
import FileViewer from "components/Utils/FileViewer/FileViewer";
import FullScreenModal from "components/Utils/GaawkModal/FullScreenModal";
import ViewerHeader from "components/Utils/FileViewer/ViewerHeader";
import { ProductSpec } from "../ProductsComponents/ProductSpec";

// import Popover from "components/Utils/Popover/Popover";
// import optionIcon from "images/options-hor-dark-grey.svg";
// import downloadIcon from "images/download-black.svg";
// import copyIcon from "images/copy-icon-gray.svg";
import useDirectMessage from "hooks/useDirectMessage";
import { useDeleteProduct } from "components/Profile/ProductsTab/hooks/useDeleteProduct";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import { BackdropLoading } from "components/Profile/ProductsTab/ProductsComponents/BackDropLoading";
import { useDispatch } from "react-redux";
import { setModalProduct, setProduct } from "store/slices/products";
import { useGetSuggestedProducts } from "components/Profile/ProductsTab/hooks/useGetSuggestedProducts";
import { AllProductsComponent } from "components/Profile/ProductsTab/ProductsComponents/AllProducts";
import ProductEntry from "../ProductEntry/ProductEntry";
import useCurrentUser from "hooks/useCurrentUser";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import RoundButton from "components/Utils/Button/RoundButton";

export const ProductDetails = ({ profile, canEdit }) => {
	const fileviewerRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showSlider, setShowSlider] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const popoverRef = useRef(null); // * dont delete for the moment
	const [activeSlideIndex, setActiveSlideIndex] = useState(0); // * dont delete for the moment

	const { productId } = useParams();

	useEffect(() => {
		trackEvent(eventsDictionary.PRODUCT.DETAILS, {
			productId,
		});
	}, [productId]);

	const { product, isLoadingGetProductById, isErrorGetProductById } = useGetProductById(
		productId,
		true
	);

	const currentUser = useCurrentUser();

	// const editButtonRef = useRef(null);

	// const { state } = useLocation();

	// useEffect(() => {
	// 	if (state?.isEdit && !isLoadingGetProductById && Object.keys(product).length > 0) {
	// 		console.log("EDITING !!!!");
	// 		editButtonRef.current.click();
	// 	}
	// }, [state?.isEdit, product, isLoadingGetProductById]);

	const {
		suggestedProducts,
		isFetchingSuggestedProducts,
		ref: suggestedRef,
	} = useGetSuggestedProducts(productId, product?.owner?.uuid);

	const hasSuggested = suggestedProducts?.pages?.length > 0;

	const { handleDirectMessage } = useDirectMessage();

	const {
		isLoading: isLoadingDeleteProduct,
		warningModal,
		setWarningModal,
		handleConfirmMutate,
		handleRequestMutate,
		activeItem,
	} = useDeleteProduct(true);

	const {
		mainImage,
		description,
		name,
		brand,
		modelNumber,
		mediaCount,
		width,
		widthUnit,
		height,
		heightUnit,
		length,
		lengthUnit,
		weight,
		weightUnit,
		locations,
		materials,
		productColors,
		taggedServices,
		worldWide,
	} = product || {};

	const {
		// isMediaFetched,
		isFetching: isProductMediaFetching,
		data: productMedia,
		// viewRef,
		// fetchNextPage,
	} = useGetProductMedia(productId, mediaCount > 0);

	const flattenMedia = useMemo(() => productMedia?.pages?.flat(), [productMedia]);

	const mediaList = useMemo(() => viewerMapper(flattenMedia), [flattenMedia]);

	const handleShowSlider = (media, index) => {
		// const fileIndex = flattenMedia.findIndex((item) => item === media);
		// setInitialSlideIndex(fileIndex);
		setInitialSlideIndex(index);
		setShowSlider(true);
	};

	const formattedProductMedia = useMemo(
		() =>
			flattenMedia?.map((item, index) => (
				// <div key={item.uuid} ref={viewRef}>
				<GalleryItem
					key={item.uuid}
					custom_style={styles.item_container}
					item={item}
					option_white
					onClick={() => handleShowSlider(item, index)}
				/>
				// </div>
			)),
		[flattenMedia]
	);
	const colorsList = useMemo(
		() =>
			productColors?.map((item) => (
				<Tag key={item.uuid} customStyle={styles.tag} itemName={item.name} />
			)),
		[productColors]
	);

	const materialsList = useMemo(
		() =>
			materials?.map((item) => (
				<Tag key={item.uuid} customStyle={styles.tag} itemName={item.name} />
			)),
		[materials]
	);
	const generateUrl = (item) => {
		let baseUrl = `/services/${item.uuid}/${item.level}`;

		let params = [];
		if (item?.gaawkService?.serviceL1?.uuid) {
			params.push(`e1=${item?.uuid}`);
		}
		if (item?.gaawkService?.serviceL2?.uuid) {
			params.push(`e2=${item?.uuid}`);
		}

		return params.length ? `${baseUrl}?${params.join("&")}` : baseUrl;
	};
	const servicesList = useMemo(
		() =>
			taggedServices?.map((item) => (
				<Tag
					key={item.uuid}
					onClick={() => navigate(generateUrl(item))}
					customStyle={styles.services_tag_conatiner}
					itemName={`+${item.name}`}
				/>
			)),
		[taggedServices, navigate]
	);
	const specs = [
		{ icon: productIcon, title: "Brand", content: brand },
		{ icon: modelIcon, title: "Model Number", content: modelNumber },
		{
			icon: colorIcon,
			title: "Colors",
			content: colorsList,
			condition: productColors?.length > 0,
			hasTags: true,
		},
		{
			icon: materialsIcon,
			title: "Materials",
			content: materialsList,
			condition: materials?.length > 0,
			hasTags: true,
		},
		{
			icon: sizeIcon,
			title: "Size (LxWxH)",
			content:
				width && length && height
					? `${length} ${
							measurementUnitsCuts.find((item) => item.value === lengthUnit)?.label
					  } x  ${width} ${
							measurementUnitsCuts.find((item) => item.value === widthUnit)?.label
					  } x ${height} ${
							measurementUnits.find((item) => item.value === heightUnit)?.label
					  }`
					: "",
			condition: length > 0 || width > 0 || height > 0,
		},
		{
			icon: weightIcon,
			title: "Weight",
			content: weight
				? `${weight} ${weightUnits.find((item) => item.value === weightUnit)?.label}`
				: "",
			condition: weight > 0,
		},
	];

	if (isErrorGetProductById) return <Navigate to="/profile" />;

	return (
		<div className={styles.container}>
			<span className={styles.product_name_up}>{name}</span>
			<div className={styles.product_wrapper}>
				<div className={styles.left_wrapper}>
					<LazyImage
						image={<img src={mainImage?.file?.customName} alt="" />}
						inlineStyle={styles.main_image_wrapper}
					/>
					{flattenMedia?.length > 0 && (
						<ScrollableMenu
							customStyleSection={styles.scroll_container}
							children={formattedProductMedia}
							customStyle={styles.scroll_wrapper_media}
							//* >> no need for onLoadMore because fixed to 10 items (even though paginated api)
						/>
					)}
					{canEdit ? (
						<div className={styles.button_container}>
							{/* <EditButton
								ref={editButtonRef}
								onClick={() => {
									dispatch(setProduct(product));
									dispatch(setModalProduct(true));
								}}
							/> */}
							<RoundButton
								icon={"edit"}
								onClick={() => {
									dispatch(setProduct(product));
									dispatch(setModalProduct(true));
								}}
							/>

							<RoundButton
								icon={"delete"}
								onClick={() => handleRequestMutate(product)}
							/>
						</div>
					) : (
						<GaawkButton
							text={"Enquire this product"}
							onClick={() => {
								trackEvent(eventsDictionary.PRODUCT.ENQUIRE, { productId });
								handleDirectMessage(product.owner);
							}}
						/>
					)}
				</div>
				<div className={styles.info_conatiner}>
					<span className={styles.product_name}>{name}</span>
					<ReadMore customStyle={styles.description_product}>{description}</ReadMore>
					<div className={styles.specs_conatiner_row}>
						<ProductSpec
							locations={locations}
							servicesList={servicesList}
							specs={specs}
							taggedServices={taggedServices}
							isWorldWide={worldWide}
						/>
					</div>
				</div>
			</div>
			<div className={styles.specs_container}>
				<ProductSpec
					locations={locations}
					servicesList={servicesList}
					specs={specs}
					taggedServices={taggedServices}
					isWorldWide={worldWide}
				/>
			</div>
			<LoadingSpinner visible={isLoadingGetProductById || isProductMediaFetching} />
			{flattenMedia && (
				<FullScreenModal
					show={showSlider}
					fullWidth={true}
					onClose={() => setShowSlider(false)}
					header={
						<ViewerHeader
							onClose={() => setShowSlider(false)}
							leftSide={<></>}
							rightSide={
								<div className={styles.options_conatiner}>
									{/* <Popover
										ref={popoverRef}
										render={
											<div className="popover_container">
												<button
													className="popover_item"
													onClick={(e) => {
														e.stopPropagation();
														FileSaver.saveAs(
															`${flattenMedia[activeSlideIndex]?.file?.customName}`,
															`${flattenMedia[activeSlideIndex]?.file?.originalName}`
														);

														popoverRef.current.closePopover();
													}}
												>
													<img
														src={downloadIcon}
														alt="Download"
													/>
													<span>Download</span>
												</button>

												<button
													className="popover_item"
													onClick={(e) => {
														e.stopPropagation();
														// onShare();
														popoverRef.current.closePopover();
													}}
												>
													<img
														src={copyIcon}
														alt="Copy"
													/>
													<span>Copy</span>
												</button>
											</div>
										}
									>
										<button
											onClick={(e) => e.stopPropagation()}
										>
											<img
												src={optionIcon}
												className={
													styles.option_white_style
												}
												alt="options"
											/>
										</button>
									</Popover> */}

									<button onClick={() => fileviewerRef.current.enterFullscreen()}>
										<FullscreenIcon fill={"#FFF"} width={20} />
									</button>
								</div>
							} //TODO ADD 3 DOTS 'MORE OPTIONS'
						/>
					}
					children={
						<div className={styles.fileviewer_container}>
							<FileViewer
								ref={fileviewerRef}
								disableScrollToEnd={true}
								files={mediaList}
								// initialSlide={initialSlideIndexRef.current}
								initialSlide={initialSlideIndex}
								isLoading={false}
								// onActiveSlide={(index) =>
								// 	(activeSlideIndexRef.current = index)
								// }
								onActiveSlide={(index) => setActiveSlideIndex(index)}
								// onLoadMore={fetchNextPage}
								// text={
								// 	filesArray[activeSlideIndexRef.current]
								// 		?.message
								// }
							/>
						</div>
					}
				/>
			)}
			<WarningModal
				show={warningModal}
				modalOnTop={true}
				headerText="Are you sure you want to delete this product?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmMutate(activeItem.uuid)}
			/>

			<ProductEntry />

			{/* //* "Smiliar products" section */}
			{hasSuggested && (
				<>
					<AllProductsComponent
						ref={suggestedRef}
						visible={true}
						products={suggestedProducts}
						Customitems_wrapper={productStyles.items_wrapper}
						isViewAll={true}
						isSugg={true}
						userUrl={profile?.url}
						profileId={currentUser?.uuid}
						product_img_style={productStyles.product_img}
						canEdit={canEdit}
					/>
					<LoadingSpinner visible={isFetchingSuggestedProducts} />
				</>
			)}
			<BackdropLoading isVisible={isLoadingDeleteProduct} />
		</div>
	);
};
