import routes from "components/Routing/routing-keys";
import useProfileSwitch from "hooks/useProfileSwitch";
import { useEffect, useMemo } from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import profileApi from "../../../../api/profile";
import useApi from "../../../../hooks/useApi";
import useCompanyId from "../../../../hooks/useCompanyId";
import styles from "./ProfileSwitch.module.scss";
import ProfileSwitchItem from "./ProfileSwitchItem";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import ProfilePic from "components/Home/ProfilePic";
import useCurrentUser from "hooks/useCurrentUser";
import { ReactComponent as Logout } from "images/navbar-icons/logout.svg";
import { ReactComponent as Settings } from "images/navbar-icons/settings.svg";
import { ReactComponent as Building } from "images/building.svg";
import { ReactComponent as Switch } from "images/navbar-icons/switch.svg";
import useLogout from "hooks/useLogout";
import NavItem from "../NavBar/NavItem";
import GaawkButton from "components/Utils/Button/GaawkButton";

const ProfileSwitch = ({ isOpen, jobsModal = false }) => {
	const navigate = useNavigate();

	const getUserCompanyApi = useApi(profileApi.getUserCompany);

	const user = useSelector((state) => state.user.profileInfo);

	const currentUser = useCurrentUser();

	const companyId = useCompanyId();

	const { handleLogout } = useLogout();

	const { switchToProfile, switchToCompany, userCompanies, isLoading, refetch } =
		useProfileSwitch(false);

	useEffect(() => {
		if (isOpen) {
			refetch();
			trackEvent(eventsDictionary.PROFILE.SWITCH_PANEL);
		}
	}, [isOpen]);

	const companies = useMemo(
		() =>
			userCompanies.map((company) => (
				<ProfileSwitchItem
					key={company.uuid}
					data={company}
					isActive={company.uuid === companyId}
					onClick={() => switchToCompany(company.uuid)}
				/>
			)),
		[userCompanies, companyId]
	);

	const thumbnail = currentUser?.profileImage?.framedImageURL
		? currentUser.profileImage.framedImageURL
		: currentUser?.profileImage?.originalImageURL
		? currentUser.profileImage.originalImageURL
		: undefined;

	const menuItems = [
		{
			icon: <Building />,
			label: "Create/Manage Companies",
			path: routes.myCompanies,
		},
		{
			icon: <Settings />,
			label: "Settings",
			path: routes.settings,
		},
		{
			icon: <Switch />,
			label: "Switch Account",
			action: () => alert("TODO"),
		},
		{
			icon: <Logout />,
			label: "Log Out",
			action: () => handleLogout(),
		},
	];

	return (
		<div className={styles.container}>
			<div className={styles.active_profile_container}>
				<ProfilePic
					isPrime={currentUser.prime}
					w={64}
					h={64}
					type={currentUser.type}
					noMarginRight
					thumbnail={thumbnail}
					name={
						currentUser.type === "USER"
							? `${currentUser.firstName} ${currentUser.lastName}`
							: currentUser.name
					}
					letterSize={26}
					enableName={false}
					url={currentUser.url}
					className={styles.disable_overflow}
				/>
				<h3>
					{currentUser.type === "USER"
						? `${currentUser.firstName} ${currentUser.lastName}`
						: currentUser.name}
				</h3>

				<GaawkButton
					size={"small"}
					text={"View Profile"}
					severity={"tertiary"}
					onClick={() => navigate(routes.profile(currentUser.url))}
				/>
			</div>

			<div className={styles.info_text}>
				<h3>Switch Profiles</h3>
				{!jobsModal && (
					<p>
						Easily switch between your Profiles without logging out. Manage multiple
						profiles seamlessly in just a tap!
					</p>
				)}
			</div>

			{isLoading && (
				<div className={styles.loading_container}>
					<Loader type="Oval" color="#6cc5d1" height={25} width={25} />
				</div>
			)}

			{!getUserCompanyApi.loading && (
				<div className={styles.profiles_container}>
					{!jobsModal && (
						<ProfileSwitchItem
							data={user}
							isActive={!companyId}
							onClick={() => switchToProfile(user.uuid)}
						/>
					)}

					{companies}
				</div>
			)}

			<div className={styles.button_container}>
				<GaawkButton
					severity={"tertiary"}
					text={"Manage Companies"}
					className={styles.manage_companies_btn}
					onClick={() => navigate(routes.myCompanies)}
				/>
			</div>

			<div className={styles.navigation}>
				{menuItems.map((item, index) => (
					<NavItem
						key={index}
						label={item.label}
						icon={item.icon}
						action={item.action}
						path={item.path}
						labelClassName={styles.navItemLabel}
					/>
				))}
			</div>
		</div>
	);
};

export default ProfileSwitch;
