import ThreeColumnPage from "components/Utils/PageArchs/ThreeColumnPage/ThreeColumnPage";
import HomeRightSidebar from "components/Home/Components/HomeRightSidebar/HomeRightSidebar";
import ErrorPage from "./ErrorPage";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const RootBoundary = () => {
	return (
		<ThreeColumnPage
			leftSideChildren={<NavBar />}
			centerChildren={<ErrorPage />}
			rightSideChildren={<HomeRightSidebar />}
			bgColor={"#E2F3F6"}
		/>
	);
};

export default RootBoundary;
