import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { certificateUpdated as companyCertificateUpdated } from "../../../../store/slices/company";
import { certificateUpdated } from "../../../../store/slices/user";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./Certificate.module.scss";
import CertificateItem from "./CertificateItem";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Certificate = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { certificates, uuid, type } = useCurrentUser();

	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, activeItem, warningModal, setWarningModal } =
		useMutate(
			editCompany ? profileApi.deleteCompanyCertificate : profileApi.deleteCertificate,
			(response) => {
				trackEvent(eventsDictionary.PROFILE.DELETE_CERTIFICATE);
				dispatch(
					editCompany
						? companyCertificateUpdated(response.data)
						: certificateUpdated(response.data)
				);
			}
		);

	const formattedCertificates = useMemo(() => {
		return certificates?.map((item) => (
			<CertificateItem
				key={item.uuid}
				item={item}
				onEditClicked={() =>
					navigate(routes.editCertificate, {
						state: item,
					})
				}
				onDelete={() => handleRequestMutate(item)}
			/>
		));
	}, [certificates]);

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.CERTIFICATE_LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.button_wrapper}>
				<GaawkButton
					severity={"secondary"}
					text="+ Add A New Certificate"
					onClick={() => navigate(routes.addCertificate)}
				/>
			</div>

			{certificates?.length > 0 && (
				<>
					<h4>Your Certification & Courses</h4>
					<div className={styles.certificates_section}>{formattedCertificates}</div>
				</>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate(
						editCompany ? { certificationId: activeItem.uuid, uuid } : activeItem.uuid
					)
				}
			/>
		</div>
	);
};

export default Certificate;
