import DefaultPage from "components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "./Notifications.module.scss";
import BackButton from "components/Utils/Button/BackButton";
import { useNavigate } from "react-router-dom";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import { useMemo, useRef, useState } from "react";
import Popover from "components/Utils/Popover/Popover";
// import optionIcon from "images/three-dots-white-5-x-20.svg";
import optionIcon from "images/three-dots-black-5-x-20.svg";
import { ReactComponent as ClearIcon } from "images/clear-icon-gray.svg";
import { ReactComponent as MarkAsRead } from "images/mark-as-read.svg";
import { notificationCategory } from "components/Utils/General";
import useFetchNotifications from "hooks/useFetchNotifications";
import NotificationItem from "components/Utils/Notifications/NotificationItem";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useUrlParams from "hooks/useUrlParams";
import JobLoader from "components/Utils/SubComs/CustomLoader/JobLoader";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import { useEffect } from "react";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";
import GaawkButton from "components/Utils/Button/GaawkButton";
// import emptyIcon from "images/empty-box.svg";

const Notifications = () => {
	const popoverRef = useRef(null);
	const navigate = useNavigate();

	const [readModal, setReadModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);

	const paramConfigs = {
		tab: {
			validator: (tab) =>
				Object.keys(notificationCategory)
					.map((key) => key?.toLowerCase())
					.includes(tab?.toLowerCase()),
			defaultValue: "all",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);
	const { tab: activeTab } = params;

	const {
		query: { data, isFetching },
		viewRef,
		markAllAsRed,
		deleteAllNotification,
		markAsRead,
		deleteNotification,
	} = useFetchNotifications(true, activeTab.toUpperCase());

	const notificationsList = useMemo(
		() =>
			data?.pages?.map((page) =>
				page?.map((item, i) => (
					<div ref={page.length === i + 1 ? viewRef : null} key={item.uuid}>
						<NotificationItem
							item={item}
							onRead={() => markAsRead(item.uuid)}
							onDelete={() => deleteNotification(item.uuid)}
						/>
					</div>
				))
			),
		[data]
	);

	useEffect(() => {
		trackEvent(eventsDictionary.NOTIFICATION.LANDING);
	}, []);

	return (
		<>
			<DefaultPage
				headerRightContent={
					<>
						<BackButton onBack={() => navigate(-1)} />

						<Popover
							ref={popoverRef}
							render={
								<div className="popover_container">
									<h4 className={styles.menu_title}>
										{notificationCategory[activeTab.toUpperCase()]}{" "}
										notifications
									</h4>

									<button
										tabIndex={0}
										className={`popover_item ${styles.menu_item}`}
										onClick={(e) => {
											e.stopPropagation();
											setReadModal(true);
											popoverRef.current.closePopover();
										}}
									>
										<div className={styles.icon_wrapper}>
											<MarkAsRead width={16} />
										</div>
										<span>Mark all as read</span>
									</button>

									<button
										tabIndex={0}
										className={`popover_item ${styles.menu_item}`}
										onClick={(e) => {
											e.stopPropagation();
											setDeleteModal(true);
											popoverRef.current.closePopover();
										}}
									>
										<div className={styles.icon_wrapper}>
											<ClearIcon fill="#000" width={16} />
										</div>

										<span>Clear all notifications</span>
									</button>
								</div>
							}
						>
							<button className={styles.option_icon}>
								<img src={optionIcon} alt="options" />
							</button>
						</Popover>
					</>
				}
				leftSideChildren={<NavBar />}
				rightSideChildren={
					<div className={styles.container}>
						<div className={styles.tab_menu}>
							<TabMenu
								menuItems={notificationCategory}
								selectedTab={activeTab}
								onSelectedTabChanged={(tab) =>
									setParams({ tab: tab.toLowerCase() })
								}
								// customStyle={styles.menu_button}
								// isLoading={loading}
							/>
						</div>

						<div className={styles.notification_container}>
							{data?.pages[0].length > 0 && notificationsList}

							{isFetching && <JobLoader />}
							<NoResults
								// image={emptyIcon}
								// imageStyle={styles.empty_icon}
								// customStyle={styles.no_results}
								text={"You don't have any notifications"}
								visible={data?.pages[0].length === 0}
							/>
						</div>
					</div>
				}
				rightSideBgColor={"#FFF"}
			/>

			<GaawkModal
				show={deleteModal}
				title={"Clear Notifications"}
				closeAlign={"right"}
				handleClose={() => setDeleteModal(false)}
				defaultModal={false}
				showHeader={true}
				children={
					<div className={styles.modal_container}>
						Are you sure you want to clear all the notifications?
						<div className={styles.modal_button_wrapper}>
							<GaawkButton
								severity={"tertiary"}
								className={styles.action_btn}
								text={"Cancel"}
								onClick={() => setDeleteModal(false)}
							/>

							<GaawkButton
								severity={"danger"}
								className={styles.action_btn}
								text={"Confirm"}
								onClick={() => {
									deleteAllNotification();
									setDeleteModal(false);
								}}
							/>
						</div>
					</div>
				}
			/>

			<GaawkModal
				show={readModal}
				title={"Mark as read"}
				closeAlign={"right"}
				handleClose={() => setReadModal(false)}
				defaultModal={false}
				showHeader={true}
				children={
					<div className={styles.modal_container}>
						Are you sure you want to mark all the notifications as read?
						<div className={styles.modal_button_wrapper}>
							<GaawkButton
								severity={"tertiary"}
								className={styles.action_btn}
								text={"Cancel"}
								onClick={() => setReadModal(false)}
							/>

							<GaawkButton
								severity={"danger"}
								text={"Mark As Read"}
								className={styles.action_btn}
								onClick={() => {
									markAllAsRed();
									setReadModal(false);
								}}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default Notifications;
