import { useQuery } from "@tanstack/react-query";
import jobApi from "api/job";
import routes from "components/Routing/routing-keys";
import { formatFiltersValues } from "components/Utils/General";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useApi from "hooks/useApi";
import useUserLocation from "hooks/useUserLocation";
import { filterKeys } from "queryKeys/filters-key-factory";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetJobsFilters } from "store/slices/filters";
import useCompanyId from "../../../hooks/useCompanyId";
import styles from "./AdvancedSearch.module.css";
import CastingFilter from "./FormSections/CastingFilter";
import CompensationFilter from "./FormSections/CompensationFilter";
import FreelanceFilter from "./FormSections/FreelanceFilter";
import JobsFilter from "./FormSections/JobsFilter";
import SearchType from "./FormSections/SearchType";
import useGetQueryParam from "hooks/useGetQueryParam";
import GaawkButton from "components/Utils/Button/GaawkButton";
// import useGetQueryParam from "hooks/useGetQueryParam";
// import useJobInitialFilters from "../Landing/hooks/useJobInitialFilters";
// import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";

const AdvancedSearch = ({ data, filterMode = false }) => {
	const navigate = useNavigate();
	const companyId = useCompanyId();
	const dispatch = useDispatch();

	const [_, setSearchParams] = useSearchParams();

	const { userCoordinates } = useUserLocation();

	//TODO >> throw 401 unauthorized instead if you're logged as company ?
	useMemo(() => {
		if (companyId) navigate(routes.jobs);
	}, [companyId, navigate]);

	// ! ========= FETCHING OF DEFAULT JOBS FILTERS ============
	const { paramValue: queryParam } = useGetQueryParam("q");

	const jobsFiltersObject = useSelector((state) => state.filters.jobsFilters);

	const storedJobsFilters = {
		...jobsFiltersObject,
		q: queryParam || "",
		locationId: userCoordinates.id,
	};

	const jobsFiltersApi = useApi(jobApi.jobsFilters, true, true);

	const fetchPeopleFilters = async ({ queryKey }) => {
		const [_, __, filters] = queryKey;
		const response = await jobsFiltersApi.request(0, 4, formatFiltersValues(filters));
		return response.data;
	};

	const {
		data: jobsFilters,
		// isLoading, //TODO >> loading spinner for 1st render
		isFetching,
		// isError,
	} = useQuery({
		queryKey: filterKeys.jobs(storedJobsFilters),
		queryFn: fetchPeopleFilters,
	});

	const { languages: languagesList = [], skills: skillsList = [] } = jobsFilters || {};

	// !====================

	const { jobType } = storedJobsFilters || {};

	// !================

	const handleSubmit = (e) => {
		e.preventDefault();
		navigate(routes.jobsResults(queryParam));
	};

	if (isFetching) return <LoadingSpinner customStyle={styles.loading_spinner} />;

	return (
		<form className={styles.form} onSubmit={handleSubmit} noValidate>
			{/* {!filterMode && (
				<div className={styles.search_container}>
					<SearchInput
						onChange={(e) => setSearchInput(e.target.value)}
						value={searchInput}
						showIcons={true}
						onClearClicked={() => setSearchInput("")}
						border={false}
						placeholder={"Search Jobs"}
					/>
				</div>
			)} */}

			<SearchType />
			{jobType === 1 && <JobsFilter />}
			{jobType === 2 && <FreelanceFilter />}
			{jobType === 3 && (
				<CastingFilter languagesList={languagesList} locationId={userCoordinates.id} />
			)}
			<CompensationFilter skillsList={skillsList} locationId={userCoordinates.id} />

			<div className={styles.button_container}>
				<GaawkButton
					severity={"danger"}
					onClick={() => {
						dispatch(resetJobsFilters());
						setSearchParams(
							(prev) => {
								prev.set("q", "");
								return prev;
							},
							{ replace: true }
						);
					}}
					className={styles.clear_btn}
					text={"Clear Filters"}
				/>

				<GaawkButton type={"submit"} text={filterMode ? "Apply Filters" : "Search"} />
			</div>
		</form>
	);
};

export default AdvancedSearch;
