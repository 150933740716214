import { useRef, forwardRef, useImperativeHandle, cloneElement } from "react";
import styles from "./FilterLeftSide.module.css";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import GaawkButton from "components/Utils/Button/GaawkButton";

const FilterLeftSide = forwardRef(({ onResetFilter, leftSideChildren, isLoading = false }, ref) => {
	const categoryRef = useRef();
	const filterRef = useRef();
	const dynamicFilterRef = useRef();

	const otherElement = leftSideChildren.find((filter) => filter.props.id === "otherFilter");
	const otherFilter =
		otherElement &&
		cloneElement(otherElement, {
			ref: categoryRef,
		});

	const staticElement = leftSideChildren.find((filter) => filter.props.id === "staticFilter");
	const staticFilter =
		staticElement &&
		cloneElement(staticElement, {
			ref: filterRef,
		});

	const dynamicElement = leftSideChildren.find((filter) => filter.props.id === "dynamicFilter");
	const dynamicFilter =
		dynamicElement &&
		cloneElement(dynamicElement, {
			ref: dynamicFilterRef,
		});

	const handleClearFields = () => {
		dynamicFilterRef.current?.clear();
		filterRef.current?.clear();
	};

	useImperativeHandle(ref, () => ({
		handleClearFields,
		updateDynamicFilters(key, tag) {
			dynamicFilterRef.current?.updateDynamicFilters(key, tag);
			filterRef.current?.updateDynamicFilters(key, tag);
		},
	}));

	return (
		<div className={styles.leftside_container}>
			{otherFilter && (
				<div
					className={`${styles.type_container} ${
						dynamicFilter ? styles.no_bottom_padding : undefined
					}`}
				>
					{otherFilter}
				</div>
			)}

			{isLoading && <LoadingSpinner />}

			{dynamicFilter}

			{staticFilter}

			<div className={styles.button_container}>
				<GaawkButton
					severity={"tertiary"}
					text={"Clear Filters"}
					className={styles.btn}
					onClick={() => {
						handleClearFields();
						onResetFilter();
					}}
				/>
			</div>
		</div>
	);
});

export default FilterLeftSide;
