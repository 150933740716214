import { useEffect } from "react";
import styles from "./ChatTab.module.css";
// import SearchInput from "../../Utils/SubComs/Inputs/SearchInput/SearchInput";
import ConvActions from "../../Chat/ConvActions";
// import useWindowSize from "../../../hooks/useWindowSize";

import MediaContainer from "../../Chat/MediaContainer";
import ProfileTabContainer from "../../Utils/SubComs/ProfileTab/ProfileTabContainer";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const ChatTab = ({
	// onBlock,
	// onUnblock,
	onReport,
	onClear,
	onExport,
	onMute,
	onUnmute,
	conversation,
}) => {
	const {
		// uuid: convId,
		filesCount,
		muted,
		minifiedProfile: {
			connection: {
				//  blocked,
				blocking,
			} = {},
		},
		// isTemp = false,
	} = conversation;

	useEffect(() => {
		trackEvent(eventsDictionary.CHAT.CONVERSATION_DETAILS);
	}, []);

	return (
		<>
			<ProfileTabContainer minHeight={filesCount > 0 ? false : true}>
				{/* <div className={styles.container}> */}
				<div className={styles.option_container}>
					{/* <SearchInput placeholder="Search Conversation" showIcons={true} /> */}
					<ConvActions
						blocking={blocking}
						muted={muted}
						onExport={onExport}
						onClear={onClear}
						// onBlock={onBlock}
						// onUnblock={onUnblock}
						onReport={onReport}
						onMute={onMute}
						onUnmute={onUnmute}
					/>
				</div>
			</ProfileTabContainer>
			{filesCount > 0 && (
				<ProfileTabContainer>
					{/* {width >= 1166 && (
                        <MediaContainer
                            conversation={conversation}
                            filesCount={filesCount}
                        />
                    )} */}

					{/* {width < 1166 && ( */}
					<div className={styles.media_container}>
						<MediaContainer
							conversation={conversation}
							p2p={true}
							filesCount={filesCount}
						/>
					</div>
					{/* )} */}
				</ProfileTabContainer>
			)}
		</>
	);
};

export default ChatTab;
