import { useNavigate } from "react-router-dom";
import styles from "../../../views/Header.module.css";
import BackButton from "../../Utils/Button/BackButton";
import DefaultPage from "../../Utils/PageArchs/DefaultPage/DefaultPage";
import ConnectionDetails from "./ConnectionDetails";
import NavBar from "components/Utils/SubComs/NavBar/NavBar";

const ConnectionPage = () => {
	const navigate = useNavigate();

	return (
		<>
			<DefaultPage
				forceShowLeftScroll={true}
				leftSideChildren={<NavBar />}
				headerRightContent={
					<div className={styles.header}>
						<BackButton onBack={() => navigate(-1)} /> Connections
					</div>
				}
				rightSideContainerColor={"#FFF"}
				rightSideChildren={<ConnectionDetails />}
			/>
		</>
	);
};

export default ConnectionPage;
