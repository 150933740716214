import styles from "./VaultActions.module.css";

// import downloadIcon from "images/download-white.svg";
// import shareIcon from "images/share-white.svg";
// import starIcon from "images/star-white.svg";
// import starEmpty from "images/icon-metro-star-full.svg";
import { ReactComponent as StarIcon } from "images/star.svg";
import { ReactComponent as ShareIcon } from "images/share.svg";
import { ReactComponent as DownloadIcon } from "images/download.svg";
import { ReactComponent as FullscreenIcon } from "images/fullscreen-icon-white.svg";

const VaultActions = ({ onFavorite, onDownload, onShare, onFullscreen, isFavorite }) => {
	// const [popoverOpened, setPopoverOpened] = useState(false);

	return (
		<div className={styles.container}>
			<button onClick={onFavorite}>
				<StarIcon
					className={styles.icon}
					fill={isFavorite ? "#6cc5d1" : "none"}
					stroke={isFavorite ? "#6cc5d1" : "#fff"}
				/>
			</button>

			<button onClick={onDownload}>
				<DownloadIcon className={styles.icon} fill={"#FFF"} />
			</button>

			<button onClick={onShare}>
				<ShareIcon fill={"#FFF"} className={styles.icon} />
			</button>

			<button onClick={onFullscreen}>
				<FullscreenIcon fill={"#FFF"} width={20} />
			</button>

			{/* <CustomPopover
                isOpen={popoverOpened}
                onClickOutside={() => setPopoverOpened(false)}
                align={"end"}
                arrowEnabled={false}
                element={
                    <button onClick={() => setPopoverOpened((prevState) => !prevState)}>
                        <img src={optionIcon} alt="options" className={styles.headerIcons} />
                    </button>
                }
                children={
                    <div className="popover_container" onClick={() => setPopoverOpened((prevState) => !prevState)}>
                        <div className="popover_item" onClick={() => console.log("CLICKED")}>
                            <img src={downloadBlueIcon} alt="Download" />
                            <button>TO BE COMPLETED </button>
                        </div>
                    </div>
                }
            /> */}
		</div>
	);
};

export default VaultActions;
