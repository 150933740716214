import infoIcon from "images/info-black-icon.svg";
import styles from "./Other.module.scss";
import { getVisibility } from "../../../../Utils/General";
import RoundButton from "components/Utils/Button/RoundButton";

const OtherItem = ({ item, onEditClicked, onDelete, showControls = true }) => {
	return (
		<div key={item.uuid}>
			<div className={styles.data_section}>
				<div className={styles.item_description}>
					<span className={styles.item_icon}>
						<img src={infoIcon} alt="Info" />
					</span>

					<div className={styles.item_description_wrapper}>
						<label>{item.title}</label>
						<div className={styles.visibility_icon}>
							{getVisibility(item.visibility)}
						</div>
					</div>
				</div>
				<p className={styles.item_data}>{item.information}</p>
			</div>

			{showControls && (
				<div className={styles.controls}>
					<RoundButton icon="edit" onClick={onEditClicked} />
					<RoundButton icon="delete" onClick={onDelete} />
				</div>
			)}
		</div>
	);
};

export default OtherItem;
