import vaultApi from "api/vault";
import ContactSearch from "components/Chat/ContactSearch";
import GroupMember from "components/Chat/GroupMember";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import { unduplicateValue } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import Tag from "components/Utils/SubComs/Tags/Tag";
import TagContainer from "components/Utils/SubComs/Tags/TagContainer";
import useDebounce from "hooks/useDebounce";
import useMutate from "hooks/useMutate";
import useProfileSearch from "hooks/useProfileSearch";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./VaultShareModal.module.scss";
import GaawkButton from "components/Utils/Button/GaawkButton";

const VaultShareModal = ({ show, onClose, onReset, items }) => {
	const [searchInput, setSearchInput] = useState("");

	const debouncedSearch = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		const query = target.value;
		setSearchInput(query);
	};

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const [selectedUsers, setSelectedUsers] = useState([]);

	const { data, isFetching, fetchNextPage, hasNextPage } = useProfileSearch(
		debouncedSearch,
		show
	);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, inView]);

	const handleRemoveUser = (userIndex) => {
		setSelectedUsers((prevState) => prevState.filter((_, index) => index !== userIndex));
	};

	const selectedUsersList = useMemo(
		() =>
			selectedUsers?.map((item, index) => (
				<Tag
					key={item.uuid}
					itemName={item.name}
					onRemove={() => handleRemoveUser(index)}
				/>
			)),
		[selectedUsers]
	);

	const itemsList = useMemo(
		() =>
			data?.pages?.map((page) => {
				const unDuplicatedPage = unduplicateValue(selectedUsers, page, "uuid");

				return unDuplicatedPage.map((item, i) => (
					<button
						key={item.uuid}
						ref={unDuplicatedPage.length === i + 1 ? viewRef : null}
						className={styles.contact_item}
						onClick={() => setSelectedUsers((prevState) => [...prevState, item])}
					>
						<GroupMember
							data={item}
							isChatLeftSide={true}
							imgSize={40}
							disableDecoration={true}
						/>
					</button>
				));
			}),
		[data, viewRef, selectedUsers]
	);

	const handleClose = () => {
		onClose();
		setSelectedUsers([]);
	};

	const {
		action: { mutate: share, isLoading },
	} = useMutate(
		vaultApi.shareItems,
		() => {
			handleClose();
			onReset && onReset();
		},
		undefined,
		{
			meta: {
				successMessage: "Successfully shared!",
			},
		}
	);

	const handleShare = () => {
		const isArray = Array.isArray(items);
		share({
			items: isArray ? items : [items],
			selectedUsers,
		});
	};

	return (
		<GaawkModal
			show={show}
			handleClose={handleClose}
			defaultModal={false}
			showHeader={true}
			title={"Share"}
			closeAlign={"right"}
			children={
				<>
					<div className={styles.search_container}>
						<ContactSearch
							searchInput={searchInput}
							onSearchInputChange={handleSearchInputChange}
							onClear={() => setSearchInput("")}
						/>
					</div>

					<div className={styles.add_container}>
						{selectedUsers?.length > 0 && (
							<TagContainer
								label={"Share with"}
								items={selectedUsersList}
								containerClassName={styles.tags_container}
								border={false}
								scrollToBottomEnabled={true}
							/>
						)}

						<div className={styles.items_list_wrapper}>
							{itemsList}
							<LoadingSpinner visible={isFetching} />

							<NoResults
								isSearch={true}
								text={"No results related to your search"}
								visible={searchInput && itemsList?.[0].length === 0 && !isFetching}
							/>
						</div>

						<div className={styles.form_controls}>
							<GaawkButton
								disabled={selectedUsers.length === 0}
								isLoading={isLoading}
								onClick={handleShare}
								text={"Share"}
							/>
						</div>
					</div>
				</>
			}
		/>
	);
};

export default VaultShareModal;
