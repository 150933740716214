import styles from "./SpecialSection.module.css";
import GaawkButton from "components/Utils/Button/GaawkButton";
import RoundButton from "components/Utils/Button/RoundButton";

const SpecialSection = ({
	label,
	content,
	canEdit,
	onEdit,
	noBorder = false,
	hasContent,
	btnText = "",
	disableEdit = false,
	disabled = false,
	warningContent,
}) => {
	return (
		<div
			className={`${styles.section} ${noBorder ? styles.noBorder : undefined} ${
				!hasContent && !canEdit ? styles.hide : undefined
			}`}
		>
			{warningContent}

			<div className={`${styles.container} ${disabled ? styles.disabled : undefined}`}>
				{hasContent && (
					<>
						<div className={styles.item_header}>
							<h3 className={styles.header_title}>{label}</h3>
							{canEdit && !disableEdit && (
								<RoundButton icon="edit" onClick={onEdit} />
							)}
						</div>
						{content}
					</>
				)}

				{!hasContent && canEdit && (
					<div className={styles.emptyContainer}>
						<h2 className={styles.header_title}>{label}</h2>
						<div className={styles.emptyText}>
							Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia
							exercitationem, fuga dolorem nulla consequuntur enim obcaecati doloribus
							doloremque mollitia aspernatur. Ratione repellendus corrupti hic quo
							cumque dicta ducimus. Cupiditate, necessitatibus!
						</div>
						<div className={styles.emptyBtn}>
							<GaawkButton
								severity={"secondary"}
								className={styles.cta_button}
								onClick={onEdit}
								disabled={disabled}
								text={`${btnText ? btnText : `+ Add ${label}`}`}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SpecialSection;
