import useApi from "hooks/useApi";
import styles from "./Visibility.module.scss";
import settingsApi from "api/settings";
import { settingsKeys } from "queryKeys/settings-key-factory";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useMutate from "hooks/useMutate";
import MultiCheckbox from "components/Utils/MultiCheckbox/MultiCheckbox";
import { privacyOptions } from "components/Utils/General";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";

const Visibility = () => {
	const queryClient = useQueryClient();

	const getSettingsApi = useApi(settingsApi.getSettings, true, true);

	const fetchSettings = async () => {
		const response = await getSettingsApi.request();
		return response.data;
	};

	const { isLoading, data: settings } = useQuery({
		queryKey: settingsKeys.all,
		queryFn: fetchSettings,
	});

	const { messages, mentions, tags, groups } = settings || {};

	//! ====== MESSAGES ======

	const handleSuccess = (response) => {
		queryClient.setQueryData(settingsKeys.all, () => {
			return response.data;
		});
	};

	const {
		action: { mutate: mutateMessages },
	} = useMutate(settingsApi.updateMessagesSettings, handleSuccess, undefined, {
		meta: {
			successMessage: "Messages settings saved!",
		},
	});

	//! ====== GROUPS ========

	const {
		action: { mutate: mutateGroups },
	} = useMutate(settingsApi.updateGroupSettings, handleSuccess, undefined, {
		meta: {
			successMessage: "Groups settings saved!",
		},
	});

	//! ====== TAGS ==========

	const {
		action: { mutate: mutateTags },
	} = useMutate(settingsApi.updateTagsSettings, handleSuccess, undefined, {
		meta: {
			successMessage: "Tags settings saved!",
		},
	});

	//! ====== MENTIONS ======

	const {
		action: { mutate: mutateMentions },
	} = useMutate(settingsApi.updateMentionsSettings, handleSuccess, undefined, {
		meta: {
			successMessage: "Mentions settings saved!",
		},
	});

	if (isLoading) return <LoadingPage />;

	return (
		<div className={styles.container}>
			<MultiCheckbox
				title={"Who can send you messages"}
				options={privacyOptions}
				onSelect={(option) => mutateMessages(option?.[0]?.value)}
				selected={[privacyOptions.find((item) => item.value === messages)]}
				type={"radio"}
				perRow="1, auto"
				customStyle={styles.privacy_item}
				name={"messages"}
			/>
			<MultiCheckbox
				title={"Who can add you to the group"}
				options={privacyOptions}
				onSelect={(option) => mutateGroups(option?.[0]?.value)}
				selected={[privacyOptions.find((item) => item.value === groups)]}
				type={"radio"}
				perRow="1, auto"
				customStyle={styles.privacy_item}
				name={"groups"}
			/>
			<MultiCheckbox
				title={"Who can tag you in posts"}
				options={privacyOptions}
				onSelect={(option) => mutateTags(option?.[0]?.value)}
				selected={[privacyOptions.find((item) => item.value === tags)]}
				type={"radio"}
				perRow="1, auto"
				customStyle={styles.privacy_item}
				name={"tags"}
			/>
			<MultiCheckbox
				title={"Who can mention you in posts"}
				options={privacyOptions}
				onSelect={(option) => mutateMentions(option?.[0]?.value)}
				selected={[privacyOptions.find((item) => item.value === mentions)]}
				type={"radio"}
				perRow="1, auto"
				customStyle={styles.privacy_item}
				name={"mentions"}
			/>
		</div>
	);
};

export default Visibility;
