import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import styles from "./AdminLogin.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router";
import GaawkButton from "components/Utils/Button/GaawkButton";

const AdminLogin = () => {
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();

		if (id === "admin" && password === "admin1234") {
			localStorage.setItem("isAdminLogged", true);
			navigate("/");
		}
	};

	const [id, setId] = useState("");
	const [password, setPassword] = useState("");

	return (
		<form className={styles.login_form} onSubmit={handleSubmit}>
			<div className={styles.logo_wrapper}>
				<img src={require("images/gaawk-logos/gaawk-default-black.svg").default} alt="" />
			</div>

			<div className={styles.input_wrapper}>
				<TextInput onChange={({ target }) => setId(target.value)} placeholder="Enter ID" />
				<TextInput
					onChange={({ target }) => setPassword(target.value)}
					placeholder="Enter password"
					isPassword={true}
				/>
			</div>

			<GaawkButton type={"submit"} text={"Access"} className={styles.login_btn} />
		</form>
	);
};

export default AdminLogin;
