import { useQuery, useQueryClient } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useMutate from "hooks/useMutate";
import moreIcon from "images/3dot-grey.svg";
import chatIcon from "images/comment-black.svg";
import contactIcon from "images/contact-icon.svg";
import deleteIcon from "images/delete-black.svg";
import { profileKeys } from "queryKeys/profile-key-factory";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { updateIsInfoOpened } from "store/slices/ui";
import profileApi from "../../../api/profile";
import useApi from "../../../hooks/useApi";
import useDirectMessage from "../../../hooks/useDirectMessage";
import useSpecialProfiles from "../../../hooks/useSpecialProfiles";
import GroupMember from "../../Chat/GroupMember";
import CertificateItem from "../../Profile/ProfileEdit/Certificate/CertificateItem";
import EducationItem from "../../Profile/ProfileEdit/Education/EducationItem";
import ExperienceItem from "../../Profile/ProfileEdit/Experience/ExperienceItem";
import LanguageItem from "../../Profile/ProfileEdit/Language/LanguageItem";
import SoftwareItem from "../../Profile/ProfileEdit/Software/SoftwareItem";
import SpecialProfileItem from "../../Profile/ProfileEdit/SpecialProfile/SpecialProfileItem";
import DeleteItemModal from "../../Utils/DeleteItemModal/DeleteItemModal";
import { pluralize } from "../../Utils/General";
import Popover from "../../Utils/Popover/Popover";
import ProfileTabContainer from "../../Utils/SubComs/ProfileTab/ProfileTabContainer";
import Tag from "../../Utils/SubComs/Tags/Tag";
import KeyPeopleItem from "../ProfileEdit/KeyPeople/KeyPeopleItem";
import NoData from "../ProfilePage/NoData";
import styles from "../ProfileTab/ProfileTab.module.css";
import PendingEmployeeModal from "./PendingEmployeeModal";
import ProfileSection from "./ProfileSection";
import EmployeeModal from "./EmployeeModal";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const ProfileTab = ({ userInfo, canEdit }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {
		url,
		bio,
		uuid,
		type,
		lastName,
		firstName,
		certificates,
		name: companyName,
		roles = [],
		skills = [],
		languages = [],
		keyPeople = [],
		educations = [],
		experiences = [],
		softwareList = [],
		companyUserType,
		pendingEmployees: pendingCount,
		verifiedEmployees: verifiedCount,
	} = userInfo;

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.PROFILE_TAB);
	}, []);

	const [searchParams, setSearchParams] = useSearchParams();
	const param = searchParams.get("modal") === "true";

	useEffect(() => {
		setPendingModal(param);
	}, [param]);

	const removeQueryParams = () => {
		if (param) {
			searchParams.delete("modal");
			setSearchParams(searchParams);
		}
	};

	const { pathname } = useLocation();
	const { handleDirectMessage } = useDirectMessage();

	const handleSendMessage = () => {
		if (pathname === "/chat") {
			dispatch(updateIsInfoOpened(false));
		} else {
			handleDirectMessage(userInfo);
		}
	};

	const [employeeModal, setEmployeeModal] = useState(false);
	const [pendingModal, setPendingModal] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState();

	const popoverRef = useRef(null);

	const [pendingEmployees, setPendingEmployees] = useState(undefined);
	const [verifiedEmployees, setVerifiedEmployees] = useState(undefined);

	useEffect(() => {
		if (pendingCount > 0) {
			setPendingEmployees(pendingCount);
		} else {
			setPendingEmployees(undefined);
		}

		if (verifiedCount > 0) {
			setVerifiedEmployees(verifiedCount);
		} else {
			setVerifiedEmployees(undefined);
		}
	}, [userInfo]);

	// * ===================

	const getVerifiedEmployeesApi = useApi(profileApi.getVerifiedEmployees, true, true);

	const queryClient = useQueryClient();

	const invalidateEmployees = () => {
		queryClient.invalidateQueries(profileKeys.employees(uuid));
	};

	const {
		action: { mutate: removeEmployee, isLoading },
	} = useMutate(profileApi.deleteEmployee, () => {
		invalidateEmployees();
		setSelectedEmployee(undefined);
		setDeleteModal(false);
		setVerifiedEmployees((prevCount) => prevCount - 1);
	});

	const handleRemoveEmployee = () => {
		removeEmployee(selectedEmployee.uuid);
	};

	const fetchEmployees = async ({ queryKey }) => {
		const [_, companyId, __] = queryKey;
		const response = await getVerifiedEmployeesApi.request(0, 6, companyId);
		return response.data;
	};

	const { isFetching, data } = useQuery({
		queryKey: profileKeys.employees(uuid),
		queryFn: fetchEmployees,
		enabled: type === "COMPANY",
	});

	const verifiedEmployeesList = useMemo(
		() =>
			data?.map((employee) => (
				<GroupMember
					key={employee.uuid}
					redirect={true}
					data={employee}
					customStyle={styles.employee_item}
					rightSideAction={
						<Popover
							ref={popoverRef}
							render={
								<div className="popover_container">
									<button
										className="popover_item"
										onClick={() => {
											popoverRef.current.closePopover();
											navigate(routes.profile(employee.url));
										}}
									>
										<img src={contactIcon} alt="" />
										<span>View Profile</span>
									</button>

									<button
										className="popover_item"
										onClick={(e) => {
											e.preventDefault();
											handleDirectMessage(employee);
										}}
									>
										<img src={chatIcon} alt="" />
										<span>Message this person</span>
									</button>

									{canEdit && (
										<button
											className="popover_item"
											onClick={(e) => {
												e.preventDefault();
												popoverRef.current.closePopover();
												setSelectedEmployee(employee);
												setDeleteModal(true);
											}}
										>
											<img src={deleteIcon} alt="" />
											<span>Remove Employee</span>
										</button>
									)}
								</div>
							}
						>
							<button onClick={(e) => e.preventDefault()}>
								<img src={moreIcon} alt="options" />
							</button>
						</Popover>
					}
				/>
			)),
		[data]
	);

	const handleCountUpdate = (status) => {
		if (status === "ACCEPT") {
			setVerifiedEmployees((prevCount) => prevCount + 1);
		}
		setPendingEmployees((prevCount) => prevCount - 1);
	};

	const { data: specialProfiles, isLoading: specialProfileLoading } = useSpecialProfiles(uuid);

	const checkData = {
		bio,
		certificates,
		...(type === "USER" && { roles }),
		...(type === "USER" && { skills }),
		...(type === "USER" && { languages }),
		...(type === "USER" && { educations }),
		...(type === "USER" && { experiences }),
		...(type === "USER" && { softwareList }),
		...(type === "COMPANY" && { keyPeople }),
	};

	const noData = useMemo(() => {
		if (
			Object.values(checkData).every((data) => data?.length === 0) &&
			specialProfiles?.length === 0
		) {
			if (type === "USER") return `${firstName} ${lastName}`;
			return companyName;
		}
	}, [userInfo, specialProfiles]);

	const roleList = roles?.map((item) => (
		<Tag
			key={item.roleDTO ? item.roleDTO.uuid : item.roleName}
			itemName={item.roleName ? item.roleName : item.roleDTO.name}
		/>
	));

	const skillsList = skills?.map((skill) => (
		<Tag key={skill.uuid} itemName={skill.skillDTO.name} />
	));

	const software = softwareList?.map((software) => (
		<SoftwareItem key={software.uuid} item={software} showControls={false} />
	));

	const languageList = languages?.map((item) => (
		<LanguageItem key={item.uuid} item={item} showControls={false} />
	));

	const certificateList = certificates?.map((item) => (
		<CertificateItem key={item.uuid} item={item} showControls={false} />
	));

	const experiencesList = experiences?.map((exp) => (
		<ExperienceItem key={exp.uuid} item={exp} showControls={false} />
	));

	const educationsList = educations?.map((item) => (
		<EducationItem key={item.uuid} item={item} showControls={false} />
	));

	const keyPeopleList = keyPeople?.map((item) => (
		<div key={item.profile.uuid} className={styles.key_profile}>
			<KeyPeopleItem
				title={item.profile.name}
				subtitle={item.customRole ?? item.roleDTO.name}
				url={item.profile.url}
				image={item.profile?.profileImage?.image}
				showControls={false}
				onClick={() => navigate(`/profile/${item.profile.url}`)}
				cursor={"pointer"}
			/>
		</div>
	));

	const specialProfilesList = specialProfiles?.map((item) => (
		<SpecialProfileItem key={item.uuid} item={item} />
	));

	// *_____________________________________________________________

	return (
		<>
			{type === "COMPANY" &&
				canEdit &&
				(companyUserType === "OWNER" || companyUserType === "ADMIN") && (
					<ProfileTabContainer minHeight={false}>
						<div className={styles.manage_company}>
							<GaawkButton
								severity={"secondary"}
								text="Manage Company"
								onClick={() => navigate(`/profile/${url}/manage`)}
								className={styles.custom_btn}
							/>
						</div>
					</ProfileTabContainer>
				)}

			{noData && !canEdit && (
				<ProfileTabContainer>
					<NoData
						text={`${noData} did not add any information to their profile.`}
						onClick={handleSendMessage}
					/>
				</ProfileTabContainer>
			)}

			{/* SPECIAL PROFILE */}
			{type === "USER" && (
				<ProfileSection
					loading={specialProfileLoading}
					canEdit={canEdit}
					show={specialProfiles?.length > 0}
					label={"Special Profile"}
					infoText={"Info text TBD for special profile"}
					data={
						<div
							className={`${styles.key_special_profiles} ${
								specialProfiles?.length > 2 ? styles.align_left : undefined
							}`}
						>
							{specialProfilesList}
						</div>
					}
					onEdit={() => navigate(routes.specialProfilesList())}
					onAdd={() => navigate(routes.specialProfilesList("/add"))}
				/>
			)}

			{/* ROLES */}
			{type === "USER" && (
				<ProfileSection
					canEdit={canEdit}
					show={roles.length > 0}
					label={"Roles"}
					data={<div className={styles.items_wrapper_tags}>{roleList}</div>}
					onEdit={() => navigate(routes.role)}
					onAdd={() => navigate(routes.role)}
				/>
			)}

			{/* BIO */}
			<ProfileSection
				canEdit={canEdit}
				show={bio}
				label={"Bio"}
				data={<div className={styles.items_wrapper}>{bio}</div>}
				onEdit={() => navigate(routes.bio)}
				onAdd={() => navigate(routes.bio)}
			/>

			{/* SOFTWARE */}
			{type === "USER" && (
				<ProfileSection
					canEdit={canEdit}
					show={softwareList.length > 0}
					label={"Software"}
					data={<div className={styles.items_wrapper}>{software}</div>}
					onEdit={() => navigate("/profile/software")}
					onAdd={() => navigate("/profile/software/add")}
				/>
			)}

			{/* EXPERIENCE */}
			{type === "USER" && (
				<ProfileSection
					canEdit={canEdit}
					show={experiences.length > 0}
					label={"Experience"}
					data={<div className={styles.items_wrapper}>{experiencesList}</div>}
					onEdit={() => navigate("/profile/experience")}
					onAdd={() => navigate("/profile/experience/add")}
				/>
			)}

			{/* CERTIFICATION & AWARDS */}
			<ProfileSection
				canEdit={canEdit}
				label={"Certificates & Awards"}
				show={certificates?.length > 0}
				data={<div className={styles.items_wrapper}>{certificateList}</div>}
				onEdit={() => navigate("/profile/certificate")}
				onAdd={() => navigate("/profile/certificate/add")}
				btnText={"Certificates"}
			/>

			{/* LANGUAGES */}
			{type === "USER" && (
				<ProfileSection
					canEdit={canEdit}
					show={languages?.length > 0}
					label={"Languages"}
					data={<div className={styles.items_wrapper}>{languageList}</div>}
					onEdit={() => navigate("/profile/language")}
					onAdd={() => navigate("/profile/language/add")}
				/>
			)}

			{/* EDUCATION */}
			{type === "USER" && (
				<ProfileSection
					canEdit={canEdit}
					show={educations?.length > 0}
					label={"Education"}
					data={<div className={styles.items_wrapper}>{educationsList}</div>}
					onEdit={() => navigate("/profile/education")}
					onAdd={() => navigate("/profile/education/add")}
				/>
			)}

			{/* SKILLS */}
			{type === "USER" && (
				<ProfileSection
					canEdit={canEdit}
					show={skills?.length > 0}
					label={"Skills"}
					data={<div className={styles.items_wrapper_tags}>{skillsList}</div>}
					onEdit={() => navigate(routes.skills)}
					onAdd={() => navigate(routes.skills)}
				/>
			)}

			{/* KEY PEOPLE */}
			{type === "COMPANY" && (
				<ProfileSection
					canEdit={canEdit}
					show={keyPeople?.length > 0}
					label={"Key People"}
					data={
						<div
							className={`${styles.key_special_profiles} ${
								keyPeople?.length > 2 ? styles.align_left : undefined
							}`}
						>
							{keyPeopleList}
						</div>
					}
					onEdit={() => navigate("/profile/key-people")}
					onAdd={() => navigate("/profile/key-people/add")}
				/>
			)}

			{/* EMPLOYEES */}
			{type === "COMPANY" && (
				<ProfileSection
					canEdit={false}
					show={(canEdit && pendingEmployees > 0) || verifiedEmployees > 0}
					label={"Employees"}
					loading={isFetching}
					trackFn={() => trackEvent(eventsDictionary.PROFILE.EMPLOYEES_LIST)}
					data={
						<div className={styles.employees_container}>
							{canEdit && pendingEmployees > 0 && (
								<button
									className={styles.pending_btn}
									onClick={() => setPendingModal(true)}
								>
									Confirm {pendingEmployees} pending experience{" "}
									{pluralize(pendingEmployees, "role")}
								</button>
							)}

							{verifiedEmployeesList}

							{verifiedEmployees > 6 && (
								<SeeMore
									text={"View All Employees"}
									style={{ margin: "10px auto" }}
									onClick={() => setEmployeeModal(true)}
								/>
							)}
						</div>
					}
				/>
			)}

			{pendingModal && (
				<PendingEmployeeModal
					show={pendingModal}
					onClose={() => {
						setPendingModal(false);
						removeQueryParams();
					}}
					onUpdateCount={handleCountUpdate}
					onUpdate={invalidateEmployees}
				/>
			)}

			<EmployeeModal
				show={employeeModal}
				onClose={() => setEmployeeModal(false)}
				userInfo={userInfo}
				canEdit={canEdit}
				onUpdateCount={() => setVerifiedEmployees((prevState) => prevState - 1)}
			/>

			<DeleteItemModal
				show={deleteModal}
				onClose={() => setDeleteModal(false)}
				title="Remove Employee"
				text={`Are you sure you want to remove ${selectedEmployee?.name} from your list of employees? This action can NOT be undone.`}
				onConfirm={handleRemoveEmployee}
				isLoading={isLoading}
			/>
		</>
	);
};

export default ProfileTab;
