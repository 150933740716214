import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "ui",
	initialState: {
		isLoading: false,
		chatFooterHeight: undefined,
		chatTotalCount: 0,
		postModal: false,
		isInfoOpened: false,
		isMobile: window.innerWidth < 748,
	},

	reducers: {
		updateLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		updateChatFooterHeight: (state, action) => {
			state.chatFooterHeight = action.payload;
		},
		updateChatTotalCount: (state, action) => {
			state.chatTotalCount = action.payload;
		},
		updatePostModal: (state, action) => {
			state.postModal = action.payload;
		},
		updateIsInfoOpened: (state, action) => {
			state.isInfoOpened = action.payload;
		},
		updateIsMobile: (state, action) => {
			state.isMobile = action.payload;
		},
	},
});

export const {
	updateLoading,
	updateChatFooterHeight,
	updateChatTotalCount,
	updatePostModal,
	updateIsInfoOpened,
	updateIsMobile,
} = slice.actions;

export default slice.reducer;
