import locationIcon from "images/location_icon.svg";
import styles from "./Address.module.scss";
import { getVisibility } from "../../../../Utils/General";
import StaticMap from "components/Utils/StaticMap/StaticMap";
import RoundButton from "components/Utils/Button/RoundButton";

const AddressItem = ({ item, onEditClicked, onDelete, showControls = true }) => {
	const {
		building,
		label,
		visibility,
		latitude,
		longitude,
		locationDTO: {
			latitude: cityLat,
			longitude: cityLng,
			name: cityName,
			countryDTO: { name: countryName } = {},
		} = {},
	} = item;

	return (
		<div style={{ overflow: "hidden" }}>
			<div className={styles.leftside_header}>
				<span className={styles.item_icon}>
					<img src={locationIcon} alt="Address" />
				</span>
				<div className={styles.right_side}>
					<div className={styles.item_description}>
						<label>{label}</label>
						<div className={styles.visibility_icon}>{getVisibility(visibility)}</div>
					</div>
					<p>{`${building ? `${building},` : ""} ${cityName}, ${countryName}`}</p>
				</div>
			</div>

			{showControls && (
				<div className={styles.controls}>
					<RoundButton icon="edit" onClick={onEditClicked} />
					<RoundButton icon="delete" onClick={onDelete} />
				</div>
			)}

			<div>
				<StaticMap
					lat={latitude !== 0 && longitude !== 0 ? latitude : cityLat}
					lng={latitude !== 0 && longitude !== 0 ? longitude : cityLng}
					zoom="15"
					customStyle={styles.map}
				/>
			</div>
		</div>
	);
};

export default AddressItem;
