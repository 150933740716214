import { useQueryClient } from "@tanstack/react-query";
import profileApi from "api/profile";
import specialProfileApi from "api/special-profile";
import routes from "components/Routing/routing-keys";
import { copyToClipboard } from "components/Utils/General";
import ReportModal from "components/Utils/ReportModal/ReportModal";
import { differenceInYears } from "date-fns";
import useGetQueryParam from "hooks/useGetQueryParam";
import useMutate from "hooks/useMutate";
import copyIcon from "images/copy-icon.svg";
import { ReactComponent as ReportIcon } from "images/report.svg";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateSpecialProfileCounts } from "store/slices/user";
import Tag from "../../../Utils/SubComs/Tags/Tag";
import LinkToAgencyModal from "./LinkToAgencyModal";
import styles from "./SpecialProfile.module.scss";
import AgencyEntry from "./SpecialProfileForms/AgencyEntry/AgencyEntry";
import SpecialProfileItem from "./SpecialProfileItem";
import AdditionalDetails from "./SpecialProfileSections/AdditionalDetails";
import GeneralDetails from "./SpecialProfileSections/GeneralDetails";
import Media from "./SpecialProfileSections/Media";
import PhysicalDetails from "./SpecialProfileSections/PhysicalDetails";
import VocalDetails from "./SpecialProfileSections/VocalDetails";
import SpecialSection from "./SpecialSection";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const SpecialProfile = ({ profile, canEdit }) => {
	const { userUrl } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const reportRef = useRef();

	const { paramValue: agencyId } = useGetQueryParam("agency");

	const { uuid, specialProfileCounts } = useSelector((state) => state.user.profileInfo);

	const {
		uuid: specialProfileId,
		bio,
		accents = [],
		ethnicity,
		languages = [],
		nationalities = [],
		physicalDetails,
		imagesCount,
		videosCount,
		audiosCount,
		info,
		skills = [],
		vocalDetails,
		agencies = [],
		gender,
		customGender,
		age,
		type,
		url,
	} = profile || {};

	const canEditPhysical =
		type === "ACTOR_ACTRESS" || type === "MODEL" || type === "ENTERTAINER" || type === "DANCER";

	const canEditVoice = type === "SINGER_SONGWRITER" || type === "VOICE_OVER_ARTIST";

	const [agencyModal, setAgencyModal] = useState(false);

	const handleCopyLink = () => {
		copyToClipboard(`${window.location.origin}${routes.specialProfile(userUrl)}`);
		toast.success("Link copied !");
	};

	const {
		action: { mutate: unlinkAgency },
	} = useMutate(profileApi.unlinkAgency, () => {
		queryClient.invalidateQueries(specialProfileKeys.detail(url));
	});

	const {
		action: { mutate: deleteSpecialProfile },
	} = useMutate(specialProfileApi.deleteSpecialProfile, () => {
		queryClient.invalidateQueries(specialProfileKeys.lists(uuid));
		dispatch(updateSpecialProfileCounts(specialProfileCounts - 1));
		navigate(routes.specialProfilesList());
	});

	useEffect(() => {
		trackEvent(eventsDictionary.SPECIAL_PROFILE.DETAIL);
	}, []);

	return (
		<>
			{profile && (
				<div className={styles.image_container}>
					<SpecialProfileItem
						item={profile}
						showUrl={true}
						canEdit={canEdit}
						onDelete={() => deleteSpecialProfile(profile.uuid)}
						redirect={false}
						agencyId={agencyId}
					/>
				</div>
			)}

			<div className={styles.base_info}>
				<div className={styles.base_info_wrapper}>
					<Tag
						itemName={
							customGender
								? customGender?.[0]?.toUpperCase() +
								  customGender?.slice(1)?.toLowerCase()
								: gender?.[0]?.toUpperCase() + gender?.slice(1)?.toLowerCase()
						}
					/>
					<Tag itemName={`${differenceInYears(new Date(), new Date(age))} Years`} />
				</div>
			</div>

			<SpecialSection
				label={bio ? "Summary" : "General Details"}
				canEdit={canEdit}
				content={<GeneralDetails data={profile} />}
				hasContent={
					bio ||
					accents.length > 0 ||
					ethnicity ||
					languages.length > 0 ||
					nationalities.length > 0
						? true
						: false
				}
				noBorder={true}
				btnText="+ Add General Details"
				onEdit={() =>
					navigate(routes.specialProfile(userUrl, "/edit/general"), {
						state: { agencyId },
					})
				}
			/>

			{canEditPhysical && (
				<SpecialSection
					label={"Physical Details"}
					canEdit={canEdit}
					content={<PhysicalDetails data={physicalDetails} />}
					hasContent={
						physicalDetails &&
						(physicalDetails.chest > 0 ||
							physicalDetails.height > 0 ||
							physicalDetails.shoe > 0 ||
							physicalDetails.waist > 0 ||
							physicalDetails.weight > 0 ||
							physicalDetails.buildType ||
							physicalDetails.eyeColor ||
							physicalDetails.hairColor ||
							physicalDetails.hairLength ||
							physicalDetails.hairStyle)
					}
					onEdit={() =>
						navigate(routes.specialProfile(userUrl, "/edit/physical"), {
							state: { agencyId },
						})
					}
				/>
			)}

			<SpecialSection
				label={"Media"}
				canEdit={canEdit}
				content={<Media specialProfileId={specialProfileId} />}
				hasContent={imagesCount > 0 || videosCount > 0 || audiosCount > 0}
				onEdit={() =>
					navigate(routes.specialProfile(userUrl, "/edit/media"), {
						state: { agencyId },
					})
				}
			/>

			{canEditVoice && (
				<SpecialSection
					label={"Vocal Details"}
					canEdit={canEdit}
					content={<VocalDetails data={vocalDetails} />}
					hasContent={
						vocalDetails &&
						(vocalDetails.pitch !== "N_A" ||
							vocalDetails.vocalAge !== "N_A" ||
							vocalDetails.vocalRange !== "N_A" ||
							vocalDetails.recordingTypes.length > 0 ||
							vocalDetails.voiceCharacteristics.length > 0)
					}
					onEdit={() =>
						navigate(routes.specialProfile(userUrl, "/edit/vocal"), {
							state: { agencyId },
						})
					}
				/>
			)}

			<SpecialSection
				label={"Additional Details"}
				canEdit={canEdit}
				content={<AdditionalDetails skills={skills} info={info} />}
				hasContent={skills.length > 0 || info}
				onEdit={() =>
					navigate(routes.specialProfile(userUrl, "/edit/more"), {
						state: { agencyId },
					})
				}
			/>

			<SpecialSection
				label={"List with an Agency"}
				canEdit={canEdit}
				content={
					<AgencyEntry
						data={agencies}
						canEdit={canEdit}
						onClick={() => setAgencyModal(true)}
						onDelete={(agency) =>
							unlinkAgency({ agencyId: agency.uuid, specialProfileId })
						}
						disabled={profile?.privacy === "PRIVATE"}
					/>
				}
				hasContent={agencies.length > 0}
				btnText="Link with an Agency"
				onEdit={() => setAgencyModal(true)}
				disableEdit={true}
				disabled={profile?.privacy === "PRIVATE"}
				warningContent={
					profile?.privacy === "PRIVATE" && (
						<div className={styles.warning_info}>
							<img src={require("images/caution.svg").default} alt="" />
							<p>
								With a private profile, you can’t link with an agency. Please come
								back later to this option once you change privacy of your profile.
							</p>
						</div>
					)
				}
			/>

			<div className={styles.share_section}>
				<button className={styles.share_item} onClick={handleCopyLink}>
					<img className={styles.icon} src={copyIcon} alt="copy" />
					<span>Copy link</span>
				</button>

				<button
					className={styles.share_item}
					onClick={() => reportRef.current.showReportModal(specialProfileId)}
				>
					<ReportIcon className={styles.icon} fill={"#6cc5d1"} />
					<span>Report</span>
				</button>
			</div>

			{agencyModal && (
				<LinkToAgencyModal
					show={agencyModal}
					onClose={() => setAgencyModal(false)}
					specialProfileId={specialProfileId}
				/>
			)}

			<ReportModal ref={reportRef} category="specialProfile" />
		</>
	);
};

export default SpecialProfile;
