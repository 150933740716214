import routes from "components/Routing/routing-keys";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./JobConfirmation.module.css";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const JobConfirmation = ({ data }) => {
	const navigate = useNavigate();
	const { jobId } = useParams();

	useEffect(() => {
		if (data) trackEvent(eventsDictionary.JOB.APPLY_DONE);
		if (!data) navigate(`/jobs/${jobId}`);
	}, [data]);

	return (
		<div className={styles.container}>
			{data && (
				<div className={styles.content}>
					<h3 className={styles.title}>THANK YOU!</h3>
					<div className={styles.text_info}>
						Your application for the job has been submitted sucessfully.
					</div>

					<div className={styles.button_container}>
						<GaawkButton onClick={() => navigate(routes.jobs)} text="Keep Gaawking!" />
					</div>
				</div>
			)}
		</div>
	);
};

export default JobConfirmation;
