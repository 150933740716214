import LoadingSpinner from "../SubComs/LoadingSpinner/LoadingSpinner";
import styles from "./LoadMoreButton.module.css";
import SeeMore from "../SubComs/SeeMore/SeeMore";

const LoadMoreButton = ({
	hasMore,
	loading,
	text = "Load more",
	onLoad,
	customStyle,
	showSpinner = true,
}) => {
	if (hasMore && !loading) {
		return (
			<div className={`${styles.button_container} ${customStyle}`}>
				<SeeMore text={text} onClick={onLoad} />
			</div>
		);
	} else if (hasMore && loading && showSpinner) {
		return <LoadingSpinner visible={loading} customStyle={customStyle} />;
	} else return null;
};

export default LoadMoreButton;
