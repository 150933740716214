import styles from "./ProfilePic.module.css";
import { getVisibility } from "../Utils/General";
import LazyImage from "../Utils/LazyImage/LazyImage";
import { router } from "AppRoutes";
import routes from "components/Routing/routing-keys";
import { classNames } from "primereact/utils";
import { ReactComponent as PrimeIcon } from "images/prime.svg";

const ProfilePic = ({
	type,
	thumbnail,
	url,
	w,
	h,
	border = false,
	name,
	fontColorWhite = false,
	emotion,
	location,
	tags,
	createMode = true,
	diff,
	visibility,
	handleProfileClick,
	subdata,
	subdiv,
	icon,
	iconSize,
	noMarginRight = false,
	isPrime = false,
	isGaawkBoard = false,
	postTagLine,
	placeholder = undefined,
	postType,
	onThumbnailClick,
	subdataStyle,
	enableName = true,
	letterSize,
	fullWidth = false,
	nameClassName,
	className,
}) => {
	const handleClick = () => {
		if (url) {
			router.navigate(routes.profile(url));
		}
		if (handleProfileClick) handleProfileClick(); //This is for <ShowChatProfile /> to open infopage instead of profile
	};

	//TODO >> implement <Link /> instead of router.navigate

	return (
		<div
			onClick={handleClick}
			className={classNames(styles.profile_image_wrapper, className, {
				[styles.fullWidth]: fullWidth,
			})}
		>
			<div
				className={classNames(
					styles.profile_pic,
					type === "USER" && postType !== "BOARD" ? styles.user : styles.company,
					{
						[styles.border]: border,
						[styles.isClickable]: url || handleProfileClick,
						[styles.applyBgColor]: !thumbnail,
					}
				)}
				// onClick={handleClick}
				style={{
					width: w ? w : styles.profile_pic.width,
					height: h ? h : styles.profile_pic.height,
					marginRight: noMarginRight ? "0px" : styles.profile_pic.marginRight,
				}}
			>
				{thumbnail ? (
					<LazyImage
						image={<img src={thumbnail} alt={"user"} />}
						onClick={postType === "BOARD" ? onThumbnailClick : undefined}
						placeholder={placeholder} //! placeholder not working as per git library page
					/>
				) : (
					<span
						className={styles.initial_letter}
						style={letterSize ? { fontSize: letterSize } : undefined}
						id="initial_letter"
					>
						{name?.charAt(0)}
					</span>
				)}

				{isPrime && !icon && <PrimeIcon className={styles.prime_icon} />}
				{isGaawkBoard && (
					<span className={styles.gaawk_board}>
						<span className={styles.letter}>g</span>
					</span>
				)}
				{icon && (
					<img
						src={icon}
						style={{
							width: `${iconSize ? `${iconSize}px` : undefined}`,
							height: "auto",
						}}
						className={`${styles.thumbnail_icon} ${
							postType === "BOARD" && type === "USER" ? styles.user : undefined
						} ${
							postType === "BOARD" && type === "COMPANY" ? styles.company : undefined
						}`}
						alt="icon"
					/>
				)}
			</div>
			{name && enableName && (
				<div
					className={`${styles.header_profile_info_wrapper} ${
						url || handleProfileClick ? styles.isClickable : undefined
					}`}
					// onClick={(e) => {
					// 	e.stopPropagation();
					// 	handleClick();
					// }}
				>
					<div className={styles.header_text_wrapper}>
						{postType === "BOARD" && (
							<div className={styles.board_header} onClick={onThumbnailClick}>
								{postTagLine}
							</div>
						)}
						<p
							className={`${styles.post_owner_name} ${
								fontColorWhite ? styles.custom_font_color : undefined
							}`}
						>
							<span
								// onClick={(e) => {
								// 	e.stopPropagation();
								// 	handleClick();
								// }}
								className={classNames(styles.bold, nameClassName)}
							>
								{name}
							</span>
							{postTagLine && postType !== "BOARD" && postTagLine}
							{emotion && emotion}
							{location && location}
							{tags && tags}
						</p>
					</div>

					{!createMode && (
						<div className={styles.post_header_info}>
							<label className={styles.post_header_info_date}>{`${diff} -`}</label>
							<span className={styles.visibility_wrapper}>
								{getVisibility(visibility)}
							</span>
						</div>
					)}

					{subdata && (
						// <div className={styles.subdata_wrapper}>
						<div
							className={`${styles.subdata} ${subdataStyle} ${
								fontColorWhite ? styles.custom_font_color : undefined
							}`}
						>
							{subdata}
						</div>
						// </div>
					)}
					{subdiv && subdiv}
				</div>
			)}
		</div>
	);
};

export default ProfilePic;
