import { useQueryClient } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import StorageCard from "components/Storage/StorageComponents/StorageCard";
import GalleryContainer from "components/Utils/SubComs/GalleryContainer/GalleryContainer";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useMutate from "hooks/useMutate";
import useVaultStorage from "hooks/useVaultStorage";
import { galleryKeys } from "queryKeys/gallery-key-factory";
import { useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import galleryApi from "../../../api/gallery";
import useApi from "../../../hooks/useApi";
import useGalleryCount from "../../../hooks/useGalleryCount";
import CreatePost from "../../Home/CreatePost";
import WarningModal from "../../Utils/GaawkModal/WarningModal";
import { getStorageWarningText, showStorageWarning } from "../../Utils/General";
import GalleryLoader from "../../Utils/SubComs/CustomLoader/GalleryLoader";
import ProfileTabContainer from "../../Utils/SubComs/ProfileTab/ProfileTabContainer";
import AlbumItem from "./AlbumItem";
import styles from "./GalleryTab.module.css";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const GalleryTab = ({ canEdit, userInfo }) => {
	const navigate = useNavigate();

	const postRef = useRef(null);

	const {
		uuid: profileId,
		firstName,
		lastName,
		profileImage,
		type,
		// url,
	} = userInfo;

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.GALLERY_TAB);
	}, []);

	const { data, usedSpaceInPercentage } = useVaultStorage();

	const itemsPerView = useGalleryCount();

	const getAlbumsApi = useApi(galleryApi.getAlbums, true, true);

	const fetchAlbums = async ({ pageParam = 0, queryKey }) => {
		const [_, __, profileId] = queryKey;
		const response = await getAlbumsApi.request(profileId, pageParam, itemsPerView);
		return response.data;
	};

	const { items, hasNextPage, loadMore, isLoading, isFetching } = useInfiniteScroll({
		queryKey: galleryKeys.albums(profileId),
		queryFn: fetchAlbums,
		pageSize: itemsPerView,
	});

	// ! ========= SHARE ALBUM HANDLER =========
	// * KEEP FOR LATER
	// const handleShareAlbum = (post) => {
	//     if (post.sharedPost) {
	//         postRef.current.sharePost(post.sharedPost);
	//     } else {
	//         postRef.current.sharePost(post);
	//     }
	// };

	// ! ========= DELETE ALBUM HANDLER =========

	const queryClient = useQueryClient();
	const { warningModal, setWarningModal, handleRequestMutate, handleConfirmMutate, activeItem } =
		useMutate(
			galleryApi.deleteAlbum,
			() => {
				queryClient.invalidateQueries(galleryKeys.albums(profileId));
			},
			undefined,
			{
				meta: {
					successMessage: "Album deleted successfully",
				},
			}
		);

	// ! ========================================

	const albumsList = useMemo(
		() =>
			items.map((album) => (
				<AlbumItem
					key={album.uuid}
					onEdit={() => navigate(routes.albumEdit(album.uuid))}
					// onDownload={() => alert("DOWNLOAD ALBUM")}
					// onShare={() => handleShareAlbum(album.post)}
					onClick={() => navigate(routes.album(album.uuid))}
					onDelete={() => handleRequestMutate(album)}
					item={album}
				/>
			)),
		[items]
	);

	const loadingItems = [...Array(itemsPerView / 2)].map((_, index) => (
		<GalleryLoader key={index} />
	));

	return (
		<ProfileTabContainer minHeight={false} customStyle={styles.tab_container}>
			<div className={styles.button_container}>
				{canEdit && (
					<>
						<GaawkButton
							severity={"secondary"}
							text={"+ Add A New Album"}
							onClick={() => navigate(routes.albumAdd)}
							className={styles.add_button}
						/>

						{showStorageWarning(usedSpaceInPercentage, data) && (
							<StorageCard
								text={getStorageWarningText("default", usedSpaceInPercentage, data)}
							/>
						)}
					</>
				)}
			</div>

			<GalleryContainer>
				{isLoading && loadingItems}
				{albumsList}
				{isFetching && loadingItems}
			</GalleryContainer>

			{items?.length > 0 && hasNextPage && (
				<div className={styles.btn_container}>
					<SeeMore text="View More" onClick={loadMore} />
				</div>
			)}

			<NoResults
				visible={items?.length === 0 && !isLoading}
				text={"no results"}
				noPadding={true}
			/>

			<CreatePost
				ref={postRef}
				name={`${firstName} ${lastName}`}
				profileImg={profileImage}
				type={type}
				onCreate={() => toast.success("Post successfully shared!")}
			/>

			<WarningModal
				show={warningModal}
				modalOnTop={true}
				headerText="Are you sure you want to delete this album?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmMutate(activeItem.uuid)}
			/>
		</ProfileTabContainer>
	);
};

export default GalleryTab;
