import { notificationKeys } from "queryKeys/notifications-key-factory";
import usePaginatedResource from "./usePaginatedResource";
import useApi from "./useApi";
import notificationApi from "api/notification";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useMutate from "./useMutate";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const useFetchNotifications = (enabled = false, category = "ALL", itemsPerPage = 20) => {
	const queryClient = useQueryClient();

	const invalidateNotifications = () => {
		queryClient.invalidateQueries(notificationKeys.list(category));
	};

	const {
		action: { mutate: markAsRead },
	} = useMutate(notificationApi.markAsRead, invalidateNotifications);

	const {
		action: { mutate: markAllAsRed },
	} = useMutate(notificationApi.markAllAsRead, () => {
		invalidateNotifications();
		trackEvent(eventsDictionary.NOTIFICATION.MARK_ALL_READ);
	});

	const {
		action: { mutate: deleteNotification },
	} = useMutate(notificationApi.deleteNotification, invalidateNotifications);

	const {
		action: { mutate: deleteAllNotification },
	} = useMutate(notificationApi.deleteAllNotification, () => {
		invalidateNotifications();
		trackEvent(eventsDictionary.NOTIFICATION.DELETE_ALL);
	});

	const { ref: viewRef, inView } = useInView({ triggerOnce: true });

	const getNotificationsApi = useApi(notificationApi.getNotifications, true, true);

	const fetchNotifications = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const [_, __, category] = queryKey;

		const response = await getNotificationsApi.request(pageParam, itemsPerPage, category);
		return response.data;
	};

	const query = usePaginatedResource(
		notificationKeys.list(category),
		fetchNotifications,
		itemsPerPage,
		enabled
	);

	const { hasNextPage, fetchNextPage, isFetching } = query;

	useEffect(() => {
		if (inView && hasNextPage && !isFetching) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetching, fetchNextPage]);

	return {
		query,
		viewRef,
		markAsRead,
		markAllAsRed,
		deleteNotification,
		deleteAllNotification,
	};
};

export default useFetchNotifications;
