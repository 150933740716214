import styles from "../../Home/Feeds.module.scss";
import Feeds from "../../Home/Feeds";
import FeedContainer from "components/Utils/FeedContainer/FeedContainer";
import { useEffect } from "react";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";

const PostsTab = ({ userInfo }) => {
	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.POSTS_TAB);
	}, []);

	return (
		<div className={styles.container}>
			<FeedContainer>
				<Feeds profileId={userInfo.uuid} />
			</FeedContainer>
		</div>
	);
};

export default PostsTab;
