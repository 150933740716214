import profileApi from "api/profile";
import { router } from "AppRoutes";
import useMutate from "hooks/useMutate";
import { persistor } from "index";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { companyInfoUpdated } from "store/slices/company";
import { profileInfoUpdated } from "store/slices/user";
import useGetCompanies from "./useGetCompanies";
import { awaitableTrackEvent, setUserProperties } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import routes from "components/Routing/routing-keys";
import { useLocation } from "react-router";

const useProfileSwitch = (enabled = true, callback) => {
	const { pathname } = useLocation();

	const dispatch = useDispatch();

	const { data, isFetching, isLoading, refetch } = useGetCompanies(enabled);

	const userCompanies = useMemo(() => {
		const companies = [];

		data &&
			Object.values(data).forEach((category) => {
				companies.push(...category);
			});

		return companies;
	}, [data]);

	const updateLocalStorage = async (response) => {
		const data = response.data;
		const isUser = data.type === "USER";

		setUserProperties({
			profile_id: isUser ? "undefined" : data.uuid,
			profile_name: isUser ? "undefined" : data.name,
		});

		if (pathname === routes.chat) {
			await awaitableTrackEvent(eventsDictionary.CHAT.SWITCH_PROFILE_CHAT);
		} else {
			await awaitableTrackEvent(eventsDictionary.PROFILE.SWITCH_PROFILE);
		}

		if (isUser) localStorage.removeItem("companyId");
		else localStorage.setItem("companyId", data.uuid);

		dispatch(isUser ? profileInfoUpdated(data) : companyInfoUpdated(data));

		window.dispatchEvent(new Event("storage"));

		persistor.flush().then(() => {
			if (callback) {
				callback();
			} else {
				router.navigate(0); //refresh current page
			}
		});
	};

	const {
		action: { mutate: switchToProfile },
	} = useMutate(profileApi.getUserProfile, updateLocalStorage);

	const {
		action: { mutate: switchToCompany },
	} = useMutate(profileApi.getCompanyProfile, updateLocalStorage);

	return {
		switchToProfile,
		switchToCompany,
		userCompanies,
		isFetching,
		isLoading,
		refetch,
	};
};

export default useProfileSwitch;
