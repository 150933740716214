import { useQuery } from "@tanstack/react-query";
import jobApi from "api/job";
import {
	formatFiltersValues,
	frequencyTypes,
	genderTypes,
	jobTypeOptions,
	jobTypes,
	postedDateTypes,
	workLocation,
} from "components/Utils/General";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import { defaultFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useApi from "hooks/useApi";
import useCurrencies from "hooks/useCurrencies";
import useDebounce from "hooks/useDebounce";
import useGetQueryParam from "hooks/useGetQueryParam";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { filterKeys } from "queryKeys/filters-key-factory";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetJobsFilters, updateJobsFilters } from "store/slices/filters";
import MultiCheckbox from "../../Utils/MultiCheckbox/MultiCheckbox";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import styles from "./PeopleFilter.module.scss";
import CastingFilters from "./SubFilters/CastingFilters";
import FreelanceFilters from "./SubFilters/FreelanceFilters";
import LongTermFilters from "./SubFilters/LongTermFilters";
import useFilterAndSort from "./useFilterAndSort";
import GaawkButton from "components/Utils/Button/GaawkButton";

const FILTERS_PAGE_SIZE = 100;

const JobsFilter = forwardRef(({ userCoordinates }, ref) => {
	const { paramValue: inputValue } = useGetQueryParam("q");

	const dispatch = useDispatch();

	const jobsFiltersObject = useSelector((state) => state.filters.jobsFilters);

	const storedJobsFilters = {
		...jobsFiltersObject,
		q: inputValue,
		locationId: userCoordinates.id,
	};

	useEffect(() => {
		return () => {
			//CLEARS JOBS SEARCH FILTERS WHEN COMPONENT UNMOUNTS
			dispatch(resetJobsFilters());
		};
	}, []);

	const {
		jobType,
		compensation: storedCompensation = "",
		currencyId = "",
		frequency = "",
		jobRequest: { longTermJobTypeId, workingLocation, experience: storedExpLongTermJob },
		freelanceRequest: {
			workingLocation: freelanceWorkingLocation,
			experience: storedFreelanceExp = "",
			showBasedOnCalendar,
			startDate,
			endDate,
		},
		castingCallRequest: {
			gender,
			minAge: storedMinAge = "",
			maxAge: storedMaxAge = "",
			ethnicityObject = null,
			languageIds = [],
		},
		skillIds = [],
	} = storedJobsFilters || {};

	//! debouncing min compensation ==========================

	const [minCompensation, setMinCompensation] = useState(storedCompensation);
	const debouncedCompensation = useDebounce(minCompensation);

	useEffect(() => {
		dispatch(
			updateJobsFilters({
				compensation: debouncedCompensation,
			})
		);
	}, [debouncedCompensation]);

	//! debouncing long_term exp level ==========================

	const [expLvl, setExpLvl] = useState(storedExpLongTermJob);
	const debouncedExpLvl = useDebounce(expLvl);

	useEffect(() => {
		dispatch(
			updateJobsFilters({
				jobRequest: {
					...storedJobsFilters.jobRequest,
					experience: debouncedExpLvl,
				},
			})
		);
	}, [debouncedExpLvl]);

	//! debouncing freelance exp level ==========================

	const [freelanceExpLvl, setFreelanceExpLvl] = useState(storedFreelanceExp);
	const debouncedFreelanceExpLvl = useDebounce(freelanceExpLvl);

	useEffect(() => {
		dispatch(
			updateJobsFilters({
				freelanceRequest: {
					...storedJobsFilters.jobRequest,
					experience: debouncedFreelanceExpLvl,
				},
			})
		);
	}, [debouncedFreelanceExpLvl]);

	//! debouncing min age ==========================

	const [minAge, setMinAge] = useState(storedMinAge);
	const debouncedMinAge = useDebounce(minAge);

	useEffect(() => {
		dispatch(
			updateJobsFilters({
				castingCallRequest: {
					...storedJobsFilters.castingCallRequest,
					minAge: debouncedMinAge,
				},
			})
		);
	}, [debouncedMinAge]);

	//! debouncing max age ==========================

	const [maxAge, setMaxAge] = useState(storedMaxAge);
	const debouncedMaxAge = useDebounce(maxAge);

	useEffect(() => {
		dispatch(
			updateJobsFilters({
				castingCallRequest: {
					...storedJobsFilters.castingCallRequest,
					maxAge: debouncedMaxAge,
				},
			})
		);
	}, [debouncedMaxAge]);

	// ! ========= FETCHING OF DEFAULT JOBS FILTERS ============

	const jobsFiltersApi = useApi(jobApi.jobsFilters, true, true);

	const fetchPeopleFilters = async ({ queryKey }) => {
		const [_, __, filters] = queryKey;
		const response = await jobsFiltersApi.request(0, 4, formatFiltersValues(filters));
		return response.data;
	};

	const {
		data: jobsFilters,
		// isLoading, //TODO >> loading spinner for 1st render
		isFetching,
		// isError,
	} = useQuery({
		queryKey: filterKeys.jobs(storedJobsFilters),
		queryFn: fetchPeopleFilters,
	});

	// !====================

	const getJobsDynamicFilterApi = useApi(jobApi.jobsDynamicFilters, true, true);

	const fetchDynamicFilters = async ({ queryKey, pageParam = 0 }) => {
		const [_, __, type, filters] = queryKey;
		const response = await getJobsDynamicFilterApi.request(
			type,
			pageParam,
			FILTERS_PAGE_SIZE,
			formatFiltersValues(filters)
		);
		return response.data;
	};

	const [enableSkillsFilters, setEnableSkillsFilters] = useState(false);

	const {
		items: dynamicSkills,
		hasNextPage: hasNextPageSkills,
		refetch: refetchSkills,
		loadMore: loadMoreSkills,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicJobsFilter("skills", storedJobsFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: enableSkillsFilters,
	});

	const {
		items: dynamicLanguages,
		hasNextPage: hasNextPageLanguages,
		refetch: refetchLanguages,
		loadMore: loadMoreLanguages,
	} = useInfiniteScroll({
		queryKey: filterKeys.dynamicJobsFilter("languages", storedJobsFilters),
		queryFn: fetchDynamicFilters,
		pageSize: FILTERS_PAGE_SIZE,
		enabled: false,
	});

	const skillRef = useRef();
	const languageRef = useRef();

	const { data: currencies = [], isLoading: isLoadingCurrencies } = useCurrencies();

	//* below effect to dispatch default currencyId in jobFilters
	useEffect(() => {
		if (!currencyId && currencies?.length > 0 && !isLoadingCurrencies) {
			dispatch(
				updateJobsFilters({
					currencyId: currencies[0].uuid,
				})
			);
		}
	}, [currencies, isLoadingCurrencies, currencyId]);

	const { languages: languagesList = [], skills: skillsList = [] } = jobsFilters || {};

	const filteredSkills = useFilterAndSort(
		skillIds,
		dynamicSkills,
		skillsList,
		dynamicSkills.length > 0
	);

	const filteredLanguages = useFilterAndSort(
		languageIds,
		dynamicLanguages,
		languagesList,
		dynamicLanguages.length > 0,
		"code"
	);

	useImperativeHandle(ref, () => ({
		updateDynamicFilters(key, tag) {
			switch (key) {
				case "skillIds":
					skillRef.current?.removeItem(tag);
					break;
				case "languageIds":
					languageRef.current?.removeItem(tag);
					break;
				default:
					break;
			}
		},
	}));

	if (isFetching) return <LoadingSpinner customStyle={styles.loading_spinner} />;

	return (
		<>
			<div className={styles.container}>
				<MultiCheckbox
					title={"Type"}
					type="radio"
					options={jobTypeOptions}
					onSelect={
						(type) => dispatch(resetJobsFilters(type[0].value))

						// dispatch(
						// 	updateJobsFilters({
						// 		jobType: type[0].value,
						// 		// //* >> below will reset skills if job type is changed
						// 		// //* >> (skills should only work for jobType 0 (all) and 1 (jobs))
						// 		// ...(jobType !== type[0].value && {
						// 		// 	skillIds: [],
						// 		// }),
						// 	})
						// )
					}
					selected={[jobTypeOptions.find((item) => item.value === jobType)]}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/>

				{/* <MultiCheckbox
					title={"Date Posted"}
					type="radio"
					options={postedDateTypes}
					onSelect={(timeType) =>
						dispatch(updateJobsFilters({ time: timeType[0].value }))
					}
					selected={[postedDateTypes.find((item) => item.value === time)]}
					perRow="2, 150px"
					customStyle={styles.multicheckbox_container}
				/> */}
			</div>

			{/* //~ =============== FILTERS BASED ON JOB TYPE ==================== */}

			{jobType === jobTypes.long_term && (
				<LongTermFilters
					onEmployementSelect={(employementType) =>
						dispatch(
							updateJobsFilters({
								jobRequest: {
									...storedJobsFilters.jobRequest,
									longTermJobTypeId: employementType,
								},
							})
						)
					}
					employement={longTermJobTypeId}
					onWorkLocation={(workLocation) =>
						dispatch(
							updateJobsFilters({
								jobRequest: {
									...storedJobsFilters.jobRequest,
									workingLocation: workLocation[0].value,
								},
							})
						)
					}
					workLocation={[workLocation.find((item) => item.value === workingLocation)]}
					onExpLvl={({ target }) => setExpLvl(target.value)}
					expLvl={expLvl}
				/>
			)}
			{jobType === jobTypes.freelance && (
				<FreelanceFilters
					onWorkLocation={(workLocation) =>
						dispatch(
							updateJobsFilters({
								freelanceRequest: {
									...storedJobsFilters.freelanceRequest,
									workingLocation: workLocation[0].value,
								},
							})
						)
					}
					workLocation={[
						workLocation.find((item) => item.value === freelanceWorkingLocation),
					]}
					onExpLvl={({ target }) => setFreelanceExpLvl(target.value)}
					expLvl={freelanceExpLvl}
					onCalendarAvailability={(e) =>
						dispatch(
							updateJobsFilters({
								freelanceRequest: {
									...storedJobsFilters.freelanceRequest,
									showBasedOnCalendar: !showBasedOnCalendar,
								},
							})
						)
					}
					calendarAvailability={showBasedOnCalendar}
					onStartDate={(startDate) =>
						dispatch(
							updateJobsFilters({
								freelanceRequest: {
									...storedJobsFilters.freelanceRequest,
									startDate: startDate?.getTime() || null,
								},
							})
						)
					}
					onEndDate={(endDate) =>
						dispatch(
							updateJobsFilters({
								freelanceRequest: {
									...storedJobsFilters.freelanceRequest,
									endDate: endDate?.getTime() || null,
								},
							})
						)
					}
					startDate={startDate}
					endDate={endDate}
				/>
			)}
			{jobType === jobTypes.casting && (
				<CastingFilters
					ref={languageRef}
					onGenderChange={(gender) =>
						dispatch(
							updateJobsFilters({
								castingCallRequest: {
									...storedJobsFilters.castingCallRequest,
									gender: gender[0].value,
								},
							})
						)
					}
					gender={[genderTypes.find((item) => item.value === gender)]}
					onMinAge={({ target }) => setMinAge(target.value)}
					onMaxAge={({ target }) => setMaxAge(target.value)}
					minAge={minAge}
					maxAge={maxAge}
					onEthnicity={(ethnicity) =>
						dispatch(
							updateJobsFilters({
								castingCallRequest: {
									...storedJobsFilters.castingCallRequest,
									ethnicityId: ethnicity ? ethnicity.value : "",
									ethnicityObject: ethnicity,
								},
							})
						)
					}
					ethnicity={ethnicityObject}
					languagesList={filteredLanguages}
					onLanguage={(languages) =>
						dispatch(
							updateJobsFilters({
								castingCallRequest: {
									...storedJobsFilters.castingCallRequest,
									languageIds: languages,
								},
							})
						)
					}
					languages={languageIds}
					isFetchedLanguages={dynamicLanguages?.length > 0}
					hasNextPageLanguage={hasNextPageLanguages}
					onRefetch={refetchLanguages}
					onFetchNextPage={loadMoreLanguages}
					baseLanguagesCount={languagesList.length}
				/>
			)}

			{/* //~ ============================================================= */}

			<div
				className={`${styles.container} ${
					jobType === jobTypes.all ? styles.no_top_padding : undefined
				}`}
			>
				{skillsList?.length > 0 && (
					<MultiCheckbox
						ref={skillRef}
						title={"Skills"}
						options={filteredSkills.map((item) => defaultFormatter(item))}
						onSelect={(skills) =>
							dispatch(
								updateJobsFilters({
									skillIds: skills,
								})
							)
						}
						selected={skillIds}
						perRow="2, 150px"
						// customStyle={styles.multicheckbox_container}
					/>
				)}

				{((dynamicSkills?.length === 0 && skillsList.length === 4) ||
					(dynamicSkills?.length > 0 && hasNextPageSkills)) && (
					<SeeMore
						onClick={() => {
							if (dynamicSkills?.length === 0) {
								refetchSkills();
							} else {
								loadMoreSkills();
							}

							setEnableSkillsFilters(true);
						}}
						className={styles.margin_top}
					/>
				)}

				<InputWrapper
					label="Minimum compensation"
					component={
						<TextInput
							value={minCompensation}
							placeholder="Enter amount"
							onChange={({ target }) => setMinCompensation(target.value)}
							type="number"
						/>
					}
				/>

				<div className={styles.inline_input_wrapper}>
					<div className={styles.item}>
						<label>Currency</label>
						<CustomSelect
							isLoading={isLoadingCurrencies}
							options={currencies?.map((currency) => defaultFormatter(currency))}
							placeholder="Select"
							height="35px"
							// isClearable={true}
							onChange={(currency) =>
								dispatch(
									updateJobsFilters({
										currencyId: currency ? currency.value : "",
									})
								)
							}
							value={currencies
								?.map((currency) => defaultFormatter(currency))
								.find((item) => item.value === currencyId)}
						/>
					</div>
					<div className={styles.item}>
						<label>Frequency</label>
						<CustomSelect
							options={frequencyTypes}
							placeholder="Select"
							height="35px"
							// isClearable={true}
							onChange={(freq) =>
								dispatch(
									updateJobsFilters({
										frequency: freq ? freq.value : "",
									})
								)
							}
							value={frequencyTypes.find((item) => item.value === frequency)}
						/>
					</div>
				</div>

				<div className={styles.button_container}>
					<GaawkButton
						severity={"tertiary"}
						text={"Clear Filters"}
						className={styles.btn}
						onClick={() => dispatch(resetJobsFilters())}
					/>
				</div>
			</div>
		</>
	);
});

export default JobsFilter;
