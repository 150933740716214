import { joiResolver } from "@hookform/resolvers/joi";
import { useQuery } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import useFetchRoles from "hooks/useFetchRoles";
import useMutate from "hooks/useMutate";
import { locationKeys } from "queryKeys/location-key-factory";
import { profileKeys } from "queryKeys/profile-key-factory";
import { rolesKeys } from "queryKeys/roles-key-factory";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import profileApi from "../../../../api/profile";
import useApi from "../../../../hooks/useApi";
import useFetchLocation from "../../../../hooks/useFetchLocation";
import { experienceUpdated } from "../../../../store/slices/user";
import { employementTypes } from "../../../Utils/General";
import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
import DayPicker from "../../../Utils/SubComs/DayPicker/DayPicker";
import InfiniteSearchInput from "../../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import {
	defaultFormatter,
	locationFormatter,
} from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import CurrentPositionItem from "./CurrentPositionItem";
import experienceSchema from "./experience-schema";
import styles from "./ExperienceEntry.module.css";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";

const itemsPerPage = 20;

const ExperienceEntry = () => {
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { pathname, state } = useLocation();

	const editMode = pathname === routes.editExperience;

	const { uuid: experienceId, company, companyName: companyTitle } = state?.experienceItem || {};

	const {
		uuid: experienceRoleId,
		locationDTO: {
			name: cityName,
			countryDTO: { name: countryName } = {},
			id: locationId,
		} = {},
		currentPosition,
		description,
		title,
		role: roleObject,
		type,
		startDate,
		endDate,
	} = state?.role || {};

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.ADD_EXPERIENCE, {
			isEditing: editMode,
		});
	}, [editMode]);

	const {
		register,
		setValue,
		formState: { errors, isDirty, isSubmitSuccessful },
		watch,
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(experienceSchema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					role: roleObject
						? { label: roleObject.name, value: roleObject.uuid }
						: { label: title, value: title, __isNew__: true },
					employementType: {
						label: employementTypes.find((item) => item.value === type)?.label,
						value: type,
					},
					companyName: company
						? { label: company.name, value: company.uuid }
						: {
								label: companyTitle,
								value: companyTitle,
								__isNew__: true,
						  },
					city: {
						value: locationId,
						label: `${cityName}, ${countryName}`,
						//   countryCode: countryDTO.code,
					},
					workSwitch: currentPosition,
					headline: "",
					details: description,
					startDate: new Date(startDate),
					endDate: !currentPosition && endDate !== 0 ? new Date(endDate) : null,
			  }
			: {
					workSwitch: false,
					role: null,
					employementType: null,
					companyName: null,
					city: null,
					startDate: null,
					endDate: null,
					headline: "",
					details: "",
			  },
	});

	// ! FORM WATCHERS ========================================================

	const role = watch("role")?.label;
	const companyName = watch("companyName")?.label;
	const workSwitch = watch("workSwitch");

	// ! WORKING STATUS HANDLERS ==============================================

	const [currentlyWorking, setCurrentlyWorking] = useState(false);
	const handleWorkStatus = () => {
		setCurrentlyWorking((prevState) => !prevState);
		if (!currentlyWorking) setValue("endDate", null);
	};

	// ! HEADLINE HANDLERS ====================================================

	const [headlineSwitch, setHeadlineSwitch] = useState(false);

	const handleUpdateHeadline = () => {
		setHeadlineSwitch((prevState) => !prevState);
	};

	useEffect(() => {
		if (headlineSwitch && role && companyName) {
			setValue("headline", `${role.trim()} at ${companyName.trim()}`);
		} else {
			setValue("headline", "");
		}
	}, [role, headlineSwitch, companyName]);

	// ! CURRENT POSITIONS HANDLERS ===========================================

	const getCurrentPositionsApi = useApi(profileApi.getCurrentPositions, true, true);

	const fetchPositions = async ({ queryKey }) => {
		const response = await getCurrentPositionsApi.request();
		return response.data;
	};

	const { data: currentPositions, isFetching } = useQuery({
		queryKey: profileKeys.currentPositions(),
		queryFn: fetchPositions,
		enabled: !editMode,
	});

	const [selectedRoleIds, setSelectedRoleIds] = useState([]);

	const handleSelectedRole = useCallback(
		(roleId) => {
			if (selectedRoleIds.includes(roleId)) {
				setSelectedRoleIds((prevState) => prevState.filter((itemId) => itemId !== roleId));
			} else {
				setSelectedRoleIds((prevState) => [...prevState, roleId]);
			}
		},
		[selectedRoleIds]
	);

	const currentPositionsList = useMemo(
		() =>
			currentPositions?.map((position) => (
				<CurrentPositionItem
					key={position.uuid}
					item={position}
					onSelect={handleSelectedRole}
				/>
			)),
		[currentPositions, handleSelectedRole]
	);

	// ! SAVE HANDLER =========================================================

	const handleSuccess = (response) => {
		dispatch(experienceUpdated(response.data));
		navigate(routes.experience, { replace: true });
	};

	const {
		action: { mutate: addExperience, isLoading: isAdding },
	} = useMutate(profileApi.addExperience, (response) => {
		trackEvent(eventsDictionary.PROFILE.ADD_EXPERIENCE_DONE);
		handleSuccess(response);
	});

	const {
		action: { mutate: updateExperience, isLoading: isUpdating },
	} = useMutate(profileApi.updateExperience, (response) => {
		trackEvent(eventsDictionary.PROFILE.ADD_EXPERIENCE_DONE, { isEditing: editMode });
		handleSuccess(response);
	});

	const handleSave = (data) => {
		const {
			role,
			employementType: { value: type },
			companyName,
			city: { value: locationId },
			workSwitch,
			startDate,
			endDate,
			headline,
			details,
		} = data;

		const body = {
			...(editMode && { experienceId }),
			...(editMode && { experienceRoleId }),
			...(role.__isNew__ ? { title: role.value } : { roleId: role.value }),
			type,
			...(companyName.__isNew__
				? { companyName: companyName.value }
				: { companyId: companyName.value }),
			locationId,
			// countryId: countryCode,
			currentlyWorking: workSwitch,
			startDate: startDate.getTime(),
			...(!workSwitch && { endDate: endDate.getTime() }),
			...(headline && { headline }),
			...(details && { details }),
			...(selectedRoleIds.length > 0 && {
				endingRolesIds: selectedRoleIds,
			}),
		};

		if (editMode) {
			updateExperience({ body });
		} else {
			addExperience({ body });
		}
	};

	// ! UNSAVED HANDLER ======================================================

	const fetchRoles = useFetchRoles();

	const searchCompanyApi = useApi(profileApi.searchCompany, true, true);

	const fetchCompany = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, ___, searchInput] = queryKey;

		const response = await searchCompanyApi.request(searchInput, pageParam, itemsPerPage);
		return response.data;
	};

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	if (editMode && !state) {
		return <Navigate to={routes.experience} />;
	}

	return (
		<>
			<div className={styles.container}>
				<form
					className={styles.form}
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					<InfiniteSearchInput
						label={"Title"}
						required={true}
						queryName={rolesKeys.search}
						queryFn={fetchRoles}
						itemsPerPage={itemsPerPage}
						formatter={defaultFormatter}
						error={errors?.role}
						control={control}
						name="role"
						creatable={true}
					/>

					<div className={styles.react_select_wrapper}>
						<label>
							Employment Type
							<span className="required">*</span>
						</label>

						<CustomSelect
							options={employementTypes}
							placeholder="Select"
							height="35px"
							error={!!errors.employementType}
							control={control}
							name="employementType"
						/>
						{errors?.employementType?.message && (
							<p className={styles.error_message}>
								{errors?.employementType?.message}
							</p>
						)}
					</div>

					<InfiniteSearchInput
						label={"Company Name"}
						required={true}
						queryName={profileKeys.companySearch}
						queryFn={fetchCompany}
						itemsPerPage={itemsPerPage}
						formatter={defaultFormatter}
						error={errors?.companyName}
						control={control}
						name="companyName"
						creatable={true}
					/>

					<div className={styles.info_text}>
						If the company you’re adding has a gaawk profile, a request of verification
						will be sent to them to get approved. In such a case you’ll have the
						verified badge next to your experience.
					</div>

					<InfiniteSearchInput
						queryName={locationKeys.cities}
						queryFn={fetchLocation}
						itemsPerPage={itemsPerPage}
						formatter={locationFormatter}
						label={"Location"}
						placeholder={"Select a city"}
						required={true}
						error={errors?.city}
						control={control}
						name="city"
						openMenuOnClick={true}
					/>

					<div className={styles.react_switch}>
						<Controller
							name="workSwitch"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Switch
									checked={value}
									onColor="#6cc5d1"
									offColor="#a6b1bc"
									handleDiameter={18}
									width={39}
									height={24}
									checkedIcon={false}
									uncheckedIcon={false}
									activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
									onChange={(val) => {
										handleWorkStatus();
										onChange(val);
									}}
								/>
							)}
						/>

						<span>I still work here.</span>
					</div>
					<div className={styles.datePickerWrapper}>
						<div className={styles.datePickerItem}>
							<label>
								Start Date
								<span className="required">*</span>
							</label>
							<DayPicker
								className={`form-start-date ${
									!!errors.startDate ? "error" : undefined
								}`}
								control={control}
								name="startDate"
							/>
							{errors?.startDate?.message && (
								<p className={styles.error_message}>{errors?.startDate?.message}</p>
							)}
						</div>
						<div className={styles.datePickerItem}>
							<label>
								End Date
								<span className="required">*</span>
							</label>
							<DayPicker
								disabled={workSwitch}
								className={`form-end-date ${
									!!errors.endDate && !workSwitch ? "error" : undefined
								}`}
								control={control}
								name="endDate"
							/>
							{errors?.endDate?.message && !workSwitch && (
								<p className={styles.error_message}>{errors?.endDate?.message}</p>
							)}
						</div>
					</div>
					<div className={styles.form_input_wrapper}>
						<label>Headline</label>
						<TextInput {...register("headline")} placeholder="Enter your headline" />
					</div>
					<div className={styles.react_switch}>
						<Switch
							onChange={handleUpdateHeadline}
							checked={headlineSwitch}
							onColor="#6cc5d1"
							offColor="#a6b1bc"
							handleDiameter={18}
							width={39}
							height={24}
							checkedIcon={false}
							uncheckedIcon={false}
							activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
						/>
						<span>Update my headline.</span>
					</div>

					<Controller
						name={"details"}
						control={control}
						render={({ field }) => (
							<TextArea
								className={styles.textarea_wrapper}
								label="Details"
								error={errors.details}
								{...field}
								placeholder="Enter details about the experience"
							/>
						)}
					/>

					{currentPositionsList?.length > 0 && !isFetching && !editMode && (
						<div className={styles.current_positions}>
							<label>Would you like to end your other current positions?</label>
							{currentPositionsList}
						</div>
					)}

					{isFetching && !editMode && <LoadingSpinner />}

					<div className={styles.button_container}>
						<GaawkButton
							type="submit"
							isLoading={isAdding || isUpdating}
							text={"Save"}
						/>
					</div>
				</form>
			</div>

			<PromptModal when={isDirty && !isSubmitSuccessful} />
		</>
	);
};

export default ExperienceEntry;
