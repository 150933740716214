import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import ProductEntry from "components/Profile/ProductsTab/ProductEntry/ProductEntry";
import { AllProductsComponent } from "components/Profile/ProductsTab/ProductsComponents/AllProducts";
import { useProductsSearch } from "components/Profile/ProductsTab/hooks";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import useCurrentUser from "hooks/useCurrentUser";
import ProductsEmpty from "images/box@3x.png";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setModalProduct } from "store/slices/products";
import styles from "./ServiceProducts.module.scss";
import GaawkButton from "components/Utils/Button/GaawkButton";

const ServiceProducts = ({ ownerId, serviceName }) => {
	const dispatch = useDispatch();
	const { serviceId } = useParams();

	const { uuid: userId } = useCurrentUser();

	const { _handleClearSearch, handleSearchInputChange, isFetching, products, ref, searchInput } =
		useProductsSearch({
			ownerIds: [ownerId],
			serviceTags: [serviceId],
		});
	const canEdit = userId === ownerId;

	useEffect(() => {
		trackEvent(eventsDictionary.PRODUCT.PRODUCT_LIST, {
			serviceId: serviceId,
		});
	}, [serviceId]);

	return (
		<div className={styles.container}>
			<div className={styles.search_container}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					classNameInput={styles.search_input}
					onClearClicked={_handleClearSearch}
					border={false}
					placeholder={"Search in Products"}
				/>
				<div className={styles.row}>
					<span className={styles.service_name}>{serviceName}</span>
					{canEdit && (
						<div className={styles.btn_wrapper}>
							<GaawkButton
								text={"+ Add Product"}
								onClick={() => dispatch(setModalProduct(true))}
							/>
						</div>
					)}
				</div>
			</div>

			<AllProductsComponent
				visible={true}
				products={products}
				ref={ref}
				Customitems_wrapper={styles.items_wrapper}
				isViewAll
				product_img_style={styles.product_img}
				canEdit={canEdit}
			/>
			<LoadingSpinner visible={isFetching} />
			<NoResults
				visible={products?.pages[0]?.list?.length === 0}
				text={
					canEdit
						? "Feel free to drop your product right here!"
						: "No products related to your search"
				}
				isSearch={!canEdit}
				image={canEdit && ProductsEmpty}
				button={
					canEdit && (
						<GaawkButton
							severity={"secondary"}
							text={"+ Add Product"}
							onClick={() => dispatch(setModalProduct(true))}
						/>
					)
				}
				customStyle={styles.no_result_container}
				imageStyle={styles.empty_image}
			/>

			<ProductEntry />
		</div>
	);
};

export default ServiceProducts;
