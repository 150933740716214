import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import styles from "./PasswordChange.module.scss";
import { passwordRegex } from "../Utils/General";
import { useForm } from "react-hook-form";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import md5 from "md5";
import useMutate from "../../hooks/useMutate";
import profileApi from "../../api/profile";
import { toast } from "react-toastify";
import GaawkButton from "components/Utils/Button/GaawkButton";

const PasswordChange = () => {
	const schema = Joi.object({
		oldPassword: Joi.string()
			.pattern(passwordRegex)
			.required()
			.label("Current Password")
			.messages({
				"string.pattern.base": "Invalid password",
				"string.empty": "Current password should not be empty",
			}),
		newPassword: Joi.string().pattern(passwordRegex).required().label("New Password").messages({
			"string.pattern.base":
				"Must be at least 8 characters, it should include at least 1 upper case character, 1 number and 1 special character.",
			"string.empty": "Password should not be empty",
		}),
		confirmPassword: Joi.string()
			.required()
			.valid(Joi.ref("newPassword"))
			.label("Confirm password")
			.messages({
				"any.only": "Does not match the password",
			}),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		setError,
		reset,
	} = useForm({ resolver: joiResolver(schema), mode: "onSubmit" });

	const handleSuccess = () => {
		toast.success("Successfully updated password");
		reset();
	};

	const {
		action: { mutate: updatePassword },
	} = useMutate(profileApi.updatePassword, handleSuccess, (error) =>
		setError("oldPassword", {
			message: error.message,
		})
	);

	const handleUpdate = (data) => {
		const { oldPassword, newPassword } = data;
		updatePassword({
			oldPassword: md5(oldPassword),
			newPassword: md5(newPassword),
		});
	};

	return (
		<form onSubmit={handleSubmit((data) => handleUpdate(data))}>
			<div className={styles.form_input_wrapper}>
				<label className="">
					Current Password<span className="required">*</span>
				</label>

				<TextInput
					{...register("oldPassword")}
					error={!!errors.oldPassword}
					placeholder="Enter current password"
					cursor="text"
					isPassword={true}
				/>

				{errors?.oldPassword?.message && <p>{errors?.oldPassword?.message}</p>}
			</div>

			<p className={styles.info}>
				Please enter the new password here…Lorem ipsum dolor sit amet, consetetur sadipscing
				elitr, sed diam nonumy eirmod tempor invidunt ut , sed diam voluptua. At vero eos
				et.
			</p>

			<div className={styles.form_input_wrapper}>
				<label className="">
					New Password<span className="required">*</span>
				</label>

				<TextInput
					{...register("newPassword")}
					error={!!errors.newPassword}
					placeholder="Enter new password"
					cursor="text"
					isPassword={true}
				/>

				{errors?.newPassword?.message && <p>{errors?.newPassword?.message}</p>}
			</div>

			<div className={styles.form_input_wrapper}>
				<label className="">
					Confirm Password<span className="required">*</span>
				</label>

				<TextInput
					{...register("confirmPassword")}
					error={!!errors.confirmPassword}
					placeholder="Enter new password again"
					cursor="text"
					isPassword={true}
				/>

				{errors?.confirmPassword?.message && <p>{errors?.confirmPassword?.message}</p>}
			</div>
			<div className={styles.button_container}>
				<GaawkButton type={"submit"} text={"Change Password"} />
			</div>
		</form>
	);
};

export default PasswordChange;
