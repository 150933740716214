import styles from "./VaultRightSide.module.scss";
// import folderIcon from "images/icon-awesome-folder-open.svg";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import ProfilePic from "../Home/ProfilePic";
import ContentLoader from "react-content-loader";
import useBreadcrumb from "../../hooks/useBreadcrumb";
import { format } from "date-fns";
import { ReactComponent as PlayIcon } from "images/play-button.svg";
import { classNames } from "primereact/utils";
import otherIcon from "images/other_icon.svg";
import { ReactComponent as RemoveIcon } from "images/remove.svg";
import { useState } from "react";
import useMutate from "hooks/useMutate";
import vaultApi from "api/vault";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { vaultKeys } from "queryKeys/vault-key-factory";
import useApi from "hooks/useApi";
import { ReactComponent as FolderIcon } from "images/folder.svg";
import { cssValue } from "components/Utils/General";
import GaawkButton from "components/Utils/Button/GaawkButton";

const VaultDetailsModal = ({ visible, item, onClose }) => {
	const queryClient = useQueryClient();

	const breadcrumb = useBreadcrumb(item);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [unShareProfile, setUnShareProfile] = useState(undefined);

	const getItemInfoApi = useApi(vaultApi.getItemInfo, true, true);

	const getItemInfo = async ({ queryKey }) => {
		const [_, __, itemId] = queryKey;
		const response = await getItemInfoApi.request(itemId);
		return response.data;
	};

	const { data: itemInfo, isLoading } = useQuery({
		queryKey: vaultKeys.shareInfo(item.uuid),
		queryFn: getItemInfo,
		enabled: visible,
	});

	const {
		action: { mutate: withdrawShare },
	} = useMutate(vaultApi.withdrawShare, () => {
		queryClient.invalidateQueries(vaultKeys.shareInfo(item.uuid));
		closeRemoveModal();
	});

	const handleRemoveModal = (person) => {
		setShowRemoveModal(true);
		setUnShareProfile(person);
	};

	const closeRemoveModal = () => {
		setShowRemoveModal(false);
	};

	if (!visible) return null;

	const LoadingView = () => (
		<div className={styles.loader_view}>
			<ContentLoader />
		</div>
	);

	return (
		<>
			{showRemoveModal ? (
				<GaawkModal
					show={visible}
					handleClose={closeRemoveModal}
					defaultModal={false}
					showHeader={true}
					title={`Remove ${unShareProfile.name}`}
					closeAlign={"right"}
					children={
						<div className={styles.unshare_modal_container}>
							<p>
								Are you sure you want to stop sharing{" "}
								{`${item.name} with ${unShareProfile.name}`}? This action CAN NOT be
								undone!
							</p>
							<div className={styles.button_container}>
								<GaawkButton
									onClick={closeRemoveModal}
									severity={"tertiary"}
									text={"Cancel"}
								/>
								<GaawkButton
									onClick={() =>
										withdrawShare({
											itemId: item.uuid,
											profileId: unShareProfile.uuid,
										})
									}
									severity={"danger"}
									text={"Remove"}
								/>
							</div>
						</div>
					}
				/>
			) : (
				<GaawkModal
					show={visible}
					handleClose={onClose}
					defaultModal={false}
					showHeader={true}
					title={"Details"}
					closeAlign={"right"}
					children={
						<div className={styles.details_modal_container}>
							<div className={styles.details_modal_header}>
								<div className={styles.details_icon_container}>
									{item && item.type === "FOLDER" && (
										<FolderIcon
											fill={cssValue("--gray70")}
											width={26}
											height={26}
										/>
									)}
									{item && item.type === "FILE" && (
										<>
											{item.file.previewType === "VIDEO" && (
												<PlayIcon className={styles.play_icon} />
											)}
											<img
												src={
													item.file.previewType === "VIDEO"
														? item.file.thumbnail
														: item.file.previewType === "IMAGE"
														? item.file.customName
														: item.file.fileTypeDTO.url
														? item.file.fileTypeDTO.url
														: otherIcon
												}
												alt={item.file.originalName}
												className={classNames(styles.file_img, {
													[styles.file_icon]:
														item.file.previewType !== "IMAGE" &&
														item.file.previewType !== "VIDEO",
												})}
											/>
										</>
									)}
								</div>
								<div className={styles.details_title_container}>
									{item && item.type === "FOLDER" && (
										<>
											<span>{item.name}</span>
											<span>
												{`${item.subCount} item`}
												{item.subCount > 1 ? "s" : ""}
											</span>
										</>
									)}
									{item && item.type === "FILE" && (
										<>
											<span>{item.name}</span>
											<span>{item.file.extension.toUpperCase()} File</span>
										</>
									)}
								</div>
							</div>

							<div className={styles.details_modal_body}>
								<div className={styles.details_info}>
									<div>
										<span>Created by:</span> <p>{item && item.owner.name}</p>
									</div>
									<div>
										<span>On:</span>{" "}
										{item && (
											<p>
												{format(new Date(item.insertionTime), "dd/MM/yyyy")}
											</p>
										)}
									</div>
									{item && item.updateTime !== 0 && (
										<div>
											<span>Last modified:</span>{" "}
											<p>{format(new Date(item.updateTime), "dd/MM/yyyy")}</p>
										</div>
									)}
									<div>
										<span>Directory:</span> <p>{breadcrumb}</p>
									</div>
								</div>

								{isLoading && <LoadingView />}

								{!isLoading && (
									<>
										<div className={styles.sharedby_container}>
											<div className={styles.separator} />
											<h3>Owner</h3>
											<div className={styles.sharedby}>
												<ProfilePic
													thumbnail={item.owner?.profileImage?.image}
													w={48}
													h={48}
													type={item.owner.type}
													name={item.owner.name}
													createMode={true}
													subdata={item.owner.tagLine}
												/>
											</div>
										</div>

										{itemInfo && itemInfo.sharedBy && (
											<div className={styles.sharedby_container}>
												<div className={styles.separator} />
												<h3>Shared by</h3>
												<div className={styles.sharedby}>
													<ProfilePic
														thumbnail={
															itemInfo.sharedBy?.profileImage?.image
														}
														w={48}
														h={48}
														url={itemInfo.sharedBy.url}
														type={itemInfo.sharedBy.type}
														name={itemInfo.sharedBy.name}
														createMode={true}
														subdataStyle={styles.subdata}
														subdata={`on: ${format(
															new Date(itemInfo.sharedByTime),
															"dd/MM/yyyy"
														)}`}
													/>
												</div>
											</div>
										)}

										{itemInfo && itemInfo.sharedTo && (
											<div className={styles.sharedby_container}>
												<div className={styles.separator} />

												<h3>You shared it with...</h3>

												{itemInfo.sharedTo.map((person) => {
													return (
														<div
															className={styles.sharedby}
															key={person.sharedTo.uuid}
														>
															<ProfilePic
																thumbnail={
																	person.sharedTo?.profileImage
																		?.image
																}
																url={person.sharedTo.url}
																w={48}
																h={48}
																type={person.sharedTo.type}
																name={person.sharedTo.name}
																createMode={true}
																subdataStyle={styles.subdata}
																subdata={`on: ${format(
																	new Date(person.sharedToTime),
																	"dd/MM/yyyy"
																)}`}
															/>

															<button
																onClick={() =>
																	handleRemoveModal(
																		person.sharedTo
																	)
																}
															>
																<RemoveIcon />
															</button>
														</div>
													);
												})}
											</div>
										)}
									</>
								)}
							</div>
						</div>
					}
				/>
			)}
		</>
	);
};

export default VaultDetailsModal;
