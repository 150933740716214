import { useQueryClient } from "@tanstack/react-query";
import { router } from "AppRoutes";
import TabMenu from "components/Utils/SubComs/TabMenu/TabMenu";
import FileSaver from "file-saver";
import useCurrentUser from "hooks/useCurrentUser";
import useVaultThumbCount from "hooks/useVaultThumbCount";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { useEffect, useMemo, useRef, useState } from "react";
import { NavigationType } from "react-router";
import vaultApi from "../../api/vault";
import useApi from "../../hooks/useApi";
import FileViewer from "../Utils/FileViewer/FileViewer";
import ViewerHeader from "../Utils/FileViewer/ViewerHeader";
import FullScreenModal from "../Utils/GaawkModal/FullScreenModal";
import { viewerMapper } from "../Utils/General";
import UserVault from "./UserVault";
import VaultActions from "./VaultActions";
import VaultDeleteModal from "./VaultDeleteModal";
import VaultDetailsModal from "./VaultDetailsModal";
import VaultListItemsModal from "./VaultListItemsModal";
import VaultRenameModal from "./VaultRenameModal";
import styles from "./VaultRightSide.module.scss";
import VaultShareModal from "./VaultShareModal";
import useUrlParams from "hooks/useUrlParams";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import routes from "components/Routing/routing-keys";
import useMutate from "hooks/useMutate";

const VaultRightSide = () => {
	const queryClient = useQueryClient();

	const { uuid: profileId } = useCurrentUser();

	const userVaultRef = useRef();
	const fileviewerRef = useRef();

	// const getRecentsApi = useApi(vaultApi.getRecents);

	// const getItemInfoApi = useApi(vaultApi.getItemInfo);
	// const shareItemsApi = useApi(vaultApi.shareItems);

	// STATES

	const displayCount = useVaultThumbCount();

	// ORDER
	const [meOrder, setMeOrder] = useState(() => localStorage.getItem("meSort") || "NEWEST");

	// LAYOUT
	const [meLayout, setMeLayout] = useState(() => localStorage.getItem("meLayout") || "grid");

	const [renameModal, setRenameModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [detailsModal, setDetailsModal] = useState(false);
	const [shareModal, setShareModal] = useState(false);
	const [copyModal, setCopyModal] = useState(false);
	const [moveModal, setMoveModal] = useState(false);

	const [selectedItem, setSelectedItem] = useState("");
	const [selectedItems, setSelectedItems] = useState([]);

	// !======== TAB CHANGE HANDLER ========

	const paramConfigs = {
		tab: {
			validator: (tab) => ["me", "favorites", "shared", "gaawk"].includes(tab),
			defaultValue: "me",
		},
	};

	const { params, setParams } = useUrlParams(paramConfigs);
	const { tab: vaultTab } = params;

	const handleTabChange = (tab) => {
		setParams({ tab });
	};

	// !================= DATA FETCHING ======================

	const getMeItemsApi = useApi(vaultApi.getMeItems, true, true);
	const getGaawkItemsApi = useApi(vaultApi.getGaawkItems, true, true);
	const getSharedApi = useApi(vaultApi.getSharedItems, true, true);
	const getFavoriteApi = useApi(vaultApi.getFavoriteItems, true, true);

	const fetchItems = async ({ pageParam = 0, queryKey }) => {
		const [_, tab, sortOrder] = queryKey;

		let dynamicEndpoint;

		switch (tab) {
			case "me":
				dynamicEndpoint = getMeItemsApi;
				break;
			case "favorites":
				dynamicEndpoint = getFavoriteApi;
				break;
			case "shared":
				dynamicEndpoint = getSharedApi;
				break;
			case "gaawk":
				dynamicEndpoint = getGaawkItemsApi;
				break;

			default:
				break;
		}

		const response = await dynamicEndpoint.request(pageParam, displayCount, sortOrder);
		return response.data;
	};

	const { items, isLoading, hasNextPage, loadMore } = useInfiniteScroll({
		queryFn: fetchItems,
		queryKey: vaultKeys.vaultItems(vaultTab, meOrder),
		pageSize: displayCount,
	});

	const changeUserItemsOrder = (sort) => {
		localStorage.setItem("meSort", sort);
		setMeOrder(sort);
	};

	// !======== FOLDER CREATION ============

	const {
		action: { mutate: createFolder },
	} = useMutate(
		vaultApi.createFolder,
		() => {
			queryClient.invalidateQueries(vaultKeys.vaultItems(vaultTab, meOrder));
		},
		undefined,
		{
			meta: {
				successMessage: "Folder created!",
			},
		}
	);

	const handleCreateFolder = (name, onRoot, parentId) => {
		createFolder({ name, onRoot, parentId });
	};

	/**
	 * Handlers
	 */

	const handleChangeMeLayout = (layout) => {
		localStorage.setItem("meLayout", layout);
		setMeLayout(layout);
	};

	//! ============ FAVORITE HANDLER ==============

	const {
		action: { mutate: favorite },
	} = useMutate(vaultApi.updateFavorite, () => {
		queryClient.invalidateQueries(vaultKeys.vaultItems(vaultTab, meOrder));
	});

	const handleFavoriteItem = (item) => {
		if (showSlider) {
			const activeFile = filesArray[activeSlideIndex];
			favorite({ itemId: activeFile.uuid, state: !activeFile.favorite });
		} else {
			favorite({ itemId: item.uuid, state: !item.favorite });
		}
	};

	// !=========RENAME HANDLER==========

	const {
		action: { mutate: rename },
	} = useMutate(vaultApi.renameItem, () => {
		queryClient.invalidateQueries(vaultKeys.vaultItems(vaultTab, meOrder));
	});

	const handleRename = (item) => {
		setSelectedItem(item);
		setRenameModal(true);
	};

	const doRenameItem = (item, newTitle) => {
		rename({ itemId: item.uuid, newName: newTitle });
	};

	// !==================

	const handleDetails = (item) => {
		setSelectedItem(item);
		setDetailsModal(true);
	};

	const handleShare = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItems([item]);

		setShareModal(true);
	};

	// const [isMultiShare, setIsMultiShare] = useState(false);

	const handleMultiShare = (selectedItems) => {
		// setIsMultiShare(true);
		handleShare(selectedItems);
	};

	// const doShareItem = (profiles) => {
	// 	shareItemsApi.request(selectedItems, profiles);
	// };

	// ! =========== DELETE / REMOVE HANDLERS =============

	const deleteInvalidation = () => {
		queryClient.invalidateQueries(vaultKeys.vaultItems(vaultTab, meOrder));
		queryClient.invalidateQueries(vaultKeys.storage());
		setSelectedItems([]);
		userVaultRef.current.resetSelect();
	};

	const {
		action: { mutate: removeVaultItems },
	} = useMutate(vaultApi.removeItems, deleteInvalidation, undefined, {
		meta: {
			successMessage: "Item deleted successfully!",
		},
	});

	const {
		action: { mutate: deleteVaultItems },
	} = useMutate(vaultApi.deleteItems, deleteInvalidation, undefined, {
		meta: {
			successMessage: "Item deleted successfully!",
		},
	});

	const handleDelete = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItems([item]);

		setDeleteModal(true);
	};

	const doDeleteItems = () => {
		let deleteItems = [];
		let removeItems = [];

		selectedItems.forEach((item) => {
			const mine = item.owner.uuid === profileId;

			if (mine) deleteItems = [...deleteItems, item];
			else removeItems = [...removeItems, item];
		});

		if (deleteItems.length > 0) deleteVaultItems(deleteItems);

		if (removeItems.length > 0) removeVaultItems(removeItems);
	};

	// ! ======== COPY HANDLERS =========

	const {
		action: { mutate: copy },
	} = useMutate(
		vaultApi.copyItems,
		() => {
			queryClient.invalidateQueries(vaultKeys.vaultItems(vaultTab, meOrder));
			queryClient.invalidateQueries(vaultKeys.storage());
			setSelectedItems([]);
			userVaultRef.current.resetSelect();
		},
		undefined,
		{
			meta: {
				successMessage: "Items copied successfully!",
			},
		}
	);

	const handleCopy = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItems([item]);

		setCopyModal(true);
	};

	const doCopyItems = (toFolder) => {
		copy({
			selectedItems,
			parentFolderId: toFolder?.uuid ?? "",
		});
	};

	// ! ========= MOVE HANDLERS =========

	const {
		action: { mutate: move },
	} = useMutate(
		vaultApi.moveItems,
		() => {
			queryClient.invalidateQueries(vaultKeys.vaultItems(vaultTab, meOrder));
			setSelectedItems([]);
			userVaultRef.current.resetSelect();
		},
		undefined,
		{
			meta: {
				successMessage: "Items moved successfully!",
			},
		}
	);

	const handleMove = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItems([item]);
		setMoveModal(true);
	};

	const doMoveItems = (toFolder) => {
		move({
			selectedItems,
			parentFolderId: toFolder?.uuid ?? "",
		});
	};

	// !======== SLIDER HANDLER ========

	const [showSlider, setShowSlider] = useState(false);
	const filesArray = useMemo(() => viewerMapper(items.filter((item) => item.file)), [items]);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	useEffect(() => {
		if (showSlider && activeSlideIndex > -1) {
			window.history.replaceState(
				null,
				"",
				routes.vaultItem(filesArray[activeSlideIndex].uuid)
			);
		} else if (showSlider && activeSlideIndex === -1) {
			handleCloseSlider();
		}
	}, [activeSlideIndex]);

	const handleShowSlider = (item) => {
		const fileIndex = filesArray.findIndex((file) => file.uuid === item.file.uuid);
		setInitialSlideIndex(fileIndex);
		setShowSlider(true);
		window.history.pushState(null, "", routes.vaultItem(item.uuid));
	};

	const handleCloseSlider = () => {
		setShowSlider(false);
		window.history.replaceState(null, "", routes.vaultFolder());
	};

	useEffect(() => {
		let unlisten;
		if (showSlider) {
			unlisten = router.subscribe((state) => {
				if (state.historyAction === NavigationType.Pop) {
					handleCloseSlider();
				}
			});
		}

		return () => unlisten && unlisten();
	}, [showSlider]);

	const handleSliderDownload = () => {
		FileSaver.saveAs(
			filesArray[activeSlideIndex].url,
			filesArray[activeSlideIndex].originalName
		);
	};

	/**
	 * JSX
	 */

	return (
		<div className={styles.container}>
			<div className={styles.tab_menu_wrapper}>
				<TabMenu
					menuItems={{
						me: "Your files",
						favorites: "Favorites",
						shared: "Shared with you",
						gaawk: "Your Gaawk folders",
					}}
					selectedTab={vaultTab}
					onSelectedTabChanged={handleTabChange}
				/>
			</div>
			<hr className={styles.separator} />

			<UserVault
				ref={userVaultRef}
				items={items}
				order={meOrder}
				layout={meLayout}
				// pageSize={width}
				// isLoading={loadingState}
				isLoading={isLoading}
				perPageCount={displayCount}
				onCreateFolder={handleCreateFolder}
				onChangeOrderAction={changeUserItemsOrder}
				onChangeMeLayout={handleChangeMeLayout}
				onDelete={handleDelete}
				onDetails={handleDetails}
				onShare={handleShare}
				onMultiShare={handleMultiShare}
				onRename={handleRename}
				onMove={handleMove}
				onCopy={handleCopy}
				onFavoriteItem={handleFavoriteItem}
				// onLoadMoreItems={() => getActiveTabItems()}
				onLoadMoreItems={loadMore}
				onClick={handleShowSlider}
				vaultTab={vaultTab}
				onTabChange={handleTabChange}
				hasNextPage={hasNextPage}
			/>

			<VaultDeleteModal
				visible={deleteModal}
				onConfirm={doDeleteItems}
				onClose={() => setDeleteModal(false)}
			/>

			<VaultDetailsModal
				visible={detailsModal}
				item={selectedItem}
				// itemInfo={itemInfo}
				// loading={getItemInfoApi.loading}
				onClose={() => {
					setSelectedItem("");
					setDetailsModal(false);
				}}
			/>

			<VaultShareModal
				show={shareModal}
				onClose={() => {
					setShareModal(false);
					// setIsMultiShare(false);
				}}
				// items={isMultiShare ? selectedItems : selectedItem}
				items={selectedItems}
				onReset={() => {
					// setSelectedItem("");
					setSelectedItems([]);
					// setIsMultiShare(false);
					userVaultRef.current.resetSelect();
				}}
			/>

			{copyModal && (
				<VaultListItemsModal
					visible={copyModal}
					onConfirm={doCopyItems}
					onClose={() => setCopyModal(false)}
					title={selectedItems.length > 1 ? "Copy Items to" : "Copy item to"}
					buttonTitle={"COPY HERE"}
					selectedItems={selectedItems}
				/>
			)}

			{moveModal && (
				<VaultListItemsModal
					visible={moveModal}
					onConfirm={doMoveItems}
					onClose={() => setMoveModal(false)}
					title={"Move Item/s to"}
					isMove={true}
					selectedItems={selectedItems}
					buttonTitle={"MOVE HERE"}
				/>
			)}

			<VaultRenameModal
				visible={renameModal}
				item={selectedItem}
				onConfirm={doRenameItem}
				onClose={() => {
					setSelectedItem("");
					setRenameModal(false);
				}}
			/>

			{showSlider && (
				<FullScreenModal
					fullWidth={true}
					show={showSlider}
					onClose={handleCloseSlider}
					header={
						<ViewerHeader
							onClose={handleCloseSlider}
							leftSide={<div className={styles.parentFolderName}>Your Vault</div>}
							rightSide={
								<VaultActions
									isFavorite={filesArray[activeSlideIndex]?.favorite}
									onFavorite={handleFavoriteItem}
									onDownload={handleSliderDownload}
									onShare={() => handleShare(filesArray[activeSlideIndex])}
									onFullscreen={() => fileviewerRef.current.enterFullscreen()}
								/>
							}
						/>
					}
					children={
						<div className={styles.fileviewer_container}>
							<FileViewer
								ref={fileviewerRef}
								files={filesArray}
								initialSlide={initialSlideIndex}
								onLoadMore={loadMore}
								isLoading={isLoading}
								onActiveSlide={(index) => setActiveSlideIndex(index)}
								text={filesArray[activeSlideIndex].originalName}
								// fsActions={
								//     <VaultFullscreenActions
								//         favorite={filesArray[activeSlideIndex]?.favorite}
								//         onFavorite={handleFavoriteItem}
								//         onDownload={handleSliderDownload}
								//         onShare={() => handleShare(getActiveFile())}
								//     />
								// }
							/>
						</div>
					}
				/>
			)}
		</div>
	);
};

export default VaultRightSide;
