export const PROFILE_MENU_ITEMS = {
	PROFILE_INFO: "profileInfo",
	BIO: "bio",
	CERTIFICATE: "certificate",
	KEY_PEOPLE: "keyPeople",
	LANGUAGE: "language",
	SOFTWARE: "software",
	ROLES: "roles",
	EXPERIENCE: "experience",
	EDUCATION: "education",
	SKILLS: "skills",
	SPECIAL_PROFILE: "specialProfile",
	MOBILE: "mobile",
	EMAIL: "email",
	SOCIAL: "social",
	ADDRESS: "address",
	OTHER: "other",
	PROFILE_PIC: "profilePic",
	COVER_PIC: "coverPic",
};
