import { joiResolver } from "@hookform/resolvers/joi";
import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import { capitalize } from "components/Utils/General";
import PromptModal from "components/Utils/Prompt/PromptModal";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { siteUpdated as companySiteUpdated } from "../../../../../store/slices/company";
import { siteUpdated } from "../../../../../store/slices/user";
import CustomSelect from "../../../../Utils/SubComs/CustomSelect/CustomSelect";
import TextInput from "../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import VisibilitySelector from "../../../../Utils/Visibility/VisibilitySelector";
import emailSchema from "./email-schema";
import styles from "./Email.module.scss";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";

const EmailEntry = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname, state } = useLocation();

	const editMode = pathname === routes.editEmail;

	useEffect(() => {
		trackEvent(eventsDictionary.PROFILE.ADD_EMAIL, { isEditing: editMode });
	}, [editMode]);

	const { uuid: companyId, type: userType } = useCurrentUser();
	const editCompany = userType === "COMPANY";

	const { uuid, description, site, type, visibility: currentVisibility } = state || {};

	const {
		register,
		formState: { errors, isDirty, isSubmitSuccessful },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(emailSchema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					description,
					type: {
						label: capitalize(type), //type?.charAt(0) + type?.slice(1).toLowerCase(),
						value: type,
					},
					site,
			  }
			: {
					description: "",
					type: null,
					site: "",
			  },
	});

	const [visibility, setVisibility] = useState(currentVisibility ?? "WORK_CIRCLE_FRIENDS");

	const handleSuccess = (response, variables) => {
		const { companyId } = variables || {};
		dispatch(companyId ? companySiteUpdated(response.data) : siteUpdated(response.data));
		navigate(routes.email, { replace: true });
	};

	const {
		action: { mutate: addSite, isLoading: isAdding },
	} = useMutate(editCompany ? profileApi.addCompanySite : profileApi.addSite, (response) => {
		trackEvent(eventsDictionary.PROFILE.ADD_EMAIL_DONE);
		handleSuccess(response);
	});

	const {
		action: { mutate: updateSite, isLoading: isUpdating },
	} = useMutate(
		editCompany ? profileApi.updateCompanySite : profileApi.updateSite,
		(response) => {
			trackEvent(eventsDictionary.PROFILE.ADD_EMAIL_DONE, { isEditing: editMode });
			handleSuccess(response);
		}
	);

	const handleSave = (data) => {
		const {
			description,
			site,
			type: { value: type },
		} = data;

		const body = {
			...(editMode && { uuid }),
			description,
			type,
			site,
			visibility,
		};

		if (editMode) {
			updateSite({ ...(editCompany && { companyId }), body });
		} else {
			addSite({ ...(editCompany && { companyId }), body });
		}
	};

	const handleVisibilityChange = (value) => () => {
		setVisibility(value);
	};

	if (editMode && !state) {
		return <Navigate to={routes.email} />;
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.contact_section}>
					<form
						className={styles.add_form}
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<div className={styles.form_input_wrapper}>
							<label>
								Description
								<span className="required">*</span>
							</label>
							<TextInput
								{...register("description")}
								error={!!errors.description}
								placeholder="Description"
							/>
							{errors?.description?.message && (
								<p className={styles.error_message}>
									{errors?.description?.message}
								</p>
							)}
						</div>

						<div className={styles.react_select_wrapper}>
							<label>
								Type
								<span className="required">*</span>
							</label>

							<CustomSelect
								fontSize="14px"
								height="35px"
								options={[
									{
										value: "WEBSITE",
										label: "Website",
									},
									{
										value: "EMAIL",
										label: "Email",
									},
								]}
								isSearchable={false}
								error={!!errors.type}
								control={control}
								name="type"
							/>
							{errors?.type?.message && (
								<p className={styles.error_message}>{errors?.type?.message}</p>
							)}
						</div>

						<div className={styles.form_input_wrapper}>
							<label>
								Email / Website
								<span className="required">*</span>
							</label>
							<TextInput
								{...register("site")}
								error={!!errors.site}
								placeholder="Email / Website"
							/>
							{errors?.site?.message && (
								<p className={styles.error_message}>{errors?.site?.message}</p>
							)}
						</div>

						<div className={styles.visibility_wrapper}>
							<label className={styles.visibility_label}>
								Visibility
								<span className="required">*</span>
							</label>

							<VisibilitySelector
								value={visibility}
								onValueChange={handleVisibilityChange}
							/>
						</div>

						<div className={styles.button_container}>
							<GaawkButton
								type={"submit"}
								isLoading={isAdding || isUpdating}
								text={"Save"}
							/>
						</div>
					</form>
				</div>
			</div>

			<PromptModal when={isDirty && !isSubmitSuccessful} />
		</>
	);
};

export default EmailEntry;
