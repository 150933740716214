import styles from "./ManageSection.module.css";
import GaawkButton from "components/Utils/Button/GaawkButton";

const ManageSection = ({ title, text, btnText, onClick, btnClassName, severity = "primary" }) => {
	return (
		<div className={styles.container}>
			<h4>{title}</h4>
			<p>{text}</p>
			<div className={styles.btn_wrapper}>
				<GaawkButton
					text={btnText}
					severity={severity}
					className={`${styles.btn} ${btnClassName}`}
					onClick={onClick}
				/>
			</div>
		</div>
	);
};

export default ManageSection;
