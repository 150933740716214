import { useDispatch, useSelector } from "react-redux";
import styles from "../ServiceAdd.module.css";
import useCompanyId from "hooks/useCompanyId";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { firstStep } from "../schema";
import ProgressIndicator from "../../ServicesComponent/ProgressIndicator";
import { setStep, updateStep1 } from "store/slices/service";
import infoIcon from "images/icon-info-gray.png";
// import CustomRadioButton from "components/Utils/SubComs/CustomRadioButton/CustomRadioButton";
import GaawkRadioButton from "components/Utils/SubComs/GaawkRadioButton/GaawkRadioButton";
import ServicesTree from "../../ServicesComponent/ServicesTree";
import GaawkButton from "components/Utils/Button/GaawkButton";

const Stage1 = () => {
	const dispatch = useDispatch();

	const companyId = useCompanyId();

	const { step1 = {} } = useSelector((state) => state.service);

	const { hasServices } = useSelector((state) =>
		companyId ? state.company.companyInfo : state.user.profileInfo
	);

	const [accordionParentId, setAccordionParentId] = useState(step1?.accordionParentId || "");

	const createDefaultValues = () => ({
		level: step1.level ? step1?.level : 1,
		category: step1.level
			? step1?.level === 1 || step1?.level === undefined
				? "NEW"
				: "SUB"
			: "NEW",
		subCategory: step1.level ? step1?.parentId : "",
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
		setValue,
		clearErrors,
	} = useForm({
		resolver: joiResolver(firstStep),
		defaultValues: createDefaultValues(),
	});

	const category = watch("category");
	const subCategoryId = watch("subCategory");

	useEffect(() => {
		if (errors?.subCategory) clearErrors();
	}, [category]);

	const handleSave = (data) => {
		const { subCategory, level } = data;
		dispatch(
			updateStep1({
				level,
				parentId: subCategory,
				accordionParentId,
			})
		);
		dispatch(setStep(2));
	};

	//TODO >> not needed if registered with react-hook-form
	// const handleOptionClick = (value) => {
	// 	if (value === "NEW") {
	// 		setValue("category", value);
	// 		setValue("subCategory", "");
	// 		setValue("level", 1);
	// 		clearErrors("subCategory");
	// 	} else {
	// 		setValue("category", "SUB");
	// 	}
	// };

	const ServiceTitle = ({ onInfoClick }) => (
		<div className={styles.title_wrapper}>
			<h3 className={styles.title}>
				What are you creating?<span className="required">*</span>
			</h3>
			<button type="button" onClick={onInfoClick}>
				<img src={infoIcon} alt="" />
			</button>
		</div>
	);
	// const OptionItem = ({
	// 	label,
	// 	value,
	// 	disabled,
	// 	onClick,
	// 	classname,
	// 	text,
	// }) => (
	// 	<button
	// 		type="button"
	// 		className={styles.option_item_wrapper}
	// 		disabled={disabled}
	// 	>
	// 		<div className={`${styles.radio_button_wrapper} ${classname}`}>
	// 			<CustomRadioButton
	// 				{...register("category")}
	// 				value={value}
	// 				id={value}
	// 				readOnly={true}
	// 				onClick={onClick}
	// 				disabled={disabled}
	// 			/>
	// 			<label htmlFor={value}>{label}</label>
	// 		</div>
	// 		<div className={styles.info_text}>{`${text}`}</div>
	// 	</button>
	// );
	const ServiceOptions = () => (
		<>
			{/* <OptionItem
				label="New Category"
				text="Your newly created service will be listed as a first level service."
				value="NEW"
				onClick={() => handleOptionClick("NEW")}
			/>
			<OptionItem
				label="Sub Category"
				value="SUB"
				text="Your newly created service will be listed as a child to the category you select from the list below."
				classname={styles.mt_20}
				onClick={() => handleOptionClick("SUB")}
				disabled={!hasServices}
			/> */}

			<GaawkRadioButton
				{...register("category", { required: true })}
				label={
					<>
						<h4 className={styles.label_title}>New Category</h4>
						<p className={styles.label_text}>
							Your newly created service will be listed as a first level service.
						</p>
					</>
				}
				id="NEW"
				value="NEW"
				name="category"
				customStyle={styles.radio_item}
			/>

			<GaawkRadioButton
				{...register("category", { required: true })}
				label={
					<>
						<h4 className={styles.label_title}>Sub Category</h4>
						<p className={styles.label_text}>
							Your newly created service will be listed as a child to the category you
							select from the list below.
						</p>
					</>
				}
				id="SUB"
				value="SUB"
				name="category"
				disabled={!hasServices}
			/>
		</>
	);

	return (
		<form
			className={styles.form}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			<div className={styles.progress_wrapper}>
				<ProgressIndicator step={1} />
			</div>
			<ServiceTitle /> {/* //TODO onInfoClick={} */}
			<ServiceOptions />
			{hasServices && category === "SUB" && (
				<div className={styles.services_accordion_wrapper}>
					<ServicesTree
						enabled={true}
						radioMode={true}
						accordionParentId={accordionParentId}
						onRadioSelect={(serviceId, level, parentId) => {
							setValue("subCategory", serviceId);
							setValue("level", level);
							setAccordionParentId(parentId);
						}}
						selected={subCategoryId}
					/>
				</div>
			)}
			{errors?.subCategory?.message && (
				<p className={styles.error_message}>{errors?.subCategory?.message}</p>
			)}
			<div className={styles.button_container}>
				<GaawkButton type={"submit"} text={"Next"} />
			</div>
		</form>
	);
};

export default Stage1;
