import useCurrentUser from "hooks/useCurrentUser";
import styles from "./Blocked.module.scss";
import useApi from "hooks/useApi";
import connectionApi from "api/connection";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { connectionsKeys } from "queryKeys/connections-key-factory";
import { useMemo } from "react";
import GroupMember from "components/Chat/GroupMember";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";

const pageSize = 20;

const Blocked = () => {
	const { uuid } = useCurrentUser();

	const getBlockedApi = useApi(connectionApi.getBlocked, true, true);

	const fetchBlocked = async ({ pageParam = 0, signal, queryKey }) => {
		// eslint-disable-next-line no-unused-vars
		const response = await getBlockedApi.request(pageParam, pageSize);
		return response.data;
	};

	const { items, isLoading, isFetchingNextPage, viewRef } = useInfiniteScroll({
		queryKey: connectionsKeys.list(uuid, "blocked"),
		queryFn: fetchBlocked,
		pageSize,
	});

	const blockedList = useMemo(
		() =>
			items.map((block, index) => {
				const isLastItem = index === items.length - 1;
				return (
					<div className={styles.contact_item} key={block.uuid}>
						<GroupMember
							data={block}
							showConnection={false}
							blocked={true}
							redirect={true}
							ref={isLastItem ? viewRef : null}
						/>
						{isLastItem && isFetchingNextPage && <ContactLoader />}
					</div>
				);
			}),
		[isFetchingNextPage, items, viewRef]
	);

	return (
		<div className={styles.container}>
			<h4>Profiles you have blocked:</h4>

			<div className={styles.blocked_container}>
				{isLoading && (
					<>
						<ContactLoader />
						<ContactLoader />
						<ContactLoader />
					</>
				)}

				{blockedList}
			</div>
		</div>
	);
};

export default Blocked;
