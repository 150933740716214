import styles from "./ProfileEdit.module.css";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";

function AddCaption(props) {
	const handleOnChange = (e) => {
		props.onChange(e.target.value);
	};

	return (
		<div className={styles.caption_canvas}>
			<TextArea
				className={styles.textarea_wrapper}
				onChange={handleOnChange}
				value={props.value}
				placeholder="Add a caption..."
			/>
		</div>
	);
}

export default AddCaption;
