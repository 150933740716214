import { useQueryClient } from "@tanstack/react-query";
import specialProfileApi from "api/special-profile";
import routes from "components/Routing/routing-keys";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import useSpecialProfiles from "hooks/useSpecialProfiles";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useEffect, useMemo } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateSpecialProfileCounts } from "store/slices/user";
import styles from "./SpecialProfileEdit.module.scss";
import SpecialProfileItem from "./SpecialProfileItem";
import { trackEvent } from "analytics/amplitude-config";
import { eventsDictionary } from "analytics/events-dictionnary";
import GaawkButton from "components/Utils/Button/GaawkButton";
import { classNames } from "primereact/utils";

const SpecialProfileEdit = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	const { uuid, specialProfileCounts } = useCurrentUser();

	const { data, isLoading } = useSpecialProfiles(uuid);

	const {
		action: { mutate: deleteSpecialProfile },
	} = useMutate(specialProfileApi.deleteSpecialProfile, () => {
		queryClient.invalidateQueries(specialProfileKeys.lists(uuid));
		dispatch(updateSpecialProfileCounts(specialProfileCounts - 1));
	});

	const formattedSpecial = useMemo(
		() =>
			data?.map((specialProfile) => (
				<SpecialProfileItem
					key={specialProfile.uuid}
					canEdit={true}
					item={specialProfile}
					onDelete={() => {
						deleteSpecialProfile(specialProfile.uuid);
					}}
				/>
			)),
		[data]
	);

	useEffect(() => {
		trackEvent(eventsDictionary.SPECIAL_PROFILE.LIST);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.button_wrapper}>
				<GaawkButton
					severity={"secondary"}
					text="+ Add Special Profile"
					onClick={() => navigate(routes.specialProfilesList("/add"))}
				/>
			</div>

			<>
				{isLoading && (
					<div className={styles.loading_container}>
						<Loader type="Oval" color="#6cc5d1" height={40} width={40} />
					</div>
				)}
				{data?.length > 0 && !isLoading && (
					<>
						<h4>Special Profiles you have created</h4>

						<div
							className={classNames(styles.data_section, {
								[styles.align_left]: data?.length > 2,
							})}
						>
							{formattedSpecial}
						</div>
					</>
				)}
			</>
		</div>
	);
};

export default SpecialProfileEdit;
