import routes from "components/Routing/routing-keys";
import CompanyItem from "./CompanyItem";
import styles from "./UserCompanies.module.css";
import { useNavigate } from "react-router-dom";
import GaawkButton from "components/Utils/Button/GaawkButton";

const UserCompanies = ({ title, text, items, owned = false }) => {
	const navigate = useNavigate();
	const itemsList = items?.map((company) => (
		<CompanyItem
			key={company.uuid}
			item={company}
			subdiv={
				<>
					<div className={styles.company_tagLine}>{company.tagLine}</div>
					<div className={styles.company_location}>
						{`${company.locationDTO.name}, ${company.locationDTO.countryDTO.name}`}
					</div>
				</>
			}
			onClick={() => navigate(routes.profile(company.url))}
			border={owned ? false : true}
		/>
	));

	return (
		<div className={styles.container}>
			<h3 className={styles.title}>{title}</h3>
			<p className={styles.paragraph}>{text}</p>
			<div className={`${styles.companies_container} ${owned ? styles.owned : undefined}`}>
				{itemsList}
			</div>

			{owned && (
				<div className={styles.button_wrapper}>
					<GaawkButton
						className={styles.add_button}
						text={"+ Create New Company"}
						onClick={() => navigate(routes.addCompany)}
					/>
				</div>
			)}
		</div>
	);
};

export default UserCompanies;
