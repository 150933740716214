import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import styles from "./ErrorPage.module.scss";
import routes from "components/Routing/routing-keys";
import { Link, useRouteError } from "react-router-dom";
import GaawkButton from "components/Utils/Button/GaawkButton";

const ErrorPage = () => {
	const error = useRouteError();

	const isErrorInstance = error instanceof Error;

	return (
		<div className={styles.container}>
			{!isErrorInstance && (
				<div className={styles.background}>
					<p>{error.code || error.status}</p>
				</div>
			)}

			<div className={styles.content}>
				{isErrorInstance ? (
					<>
						<h1>Something went wrong!</h1>
						<p>
							Oops! It seems we're experiencing technical difficulties. Hang tight
							with Gaawk!
						</p>
					</>
				) : (
					<>
						<h1>
							{error.message ||
								error.statusText ||
								((error.code === 404 || error.status === 404) &&
									"This page doesn't exist")}
						</h1>
					</>
				)}

				<GaawkLink to={routes.root} disableDecoration>
					<GaawkButton text={"Back to Gaawk"} />
				</GaawkLink>

				<Link to={routes.help} className={styles.link}>
					Help & Support
				</Link>
			</div>
		</div>
	);
};

export default ErrorPage;
