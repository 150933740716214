import ProfilePic from "components/Home/ProfilePic";
import GaawkModal from "../GaawkModal/GaawkModal";
import styles from "./ProfileSwitchModal.module.scss";
import GaawkButton from "../Button/GaawkButton";

const ProfileSwitchModal = ({ show, onClose, data, onConfirm }) => {
	const { profileImage, name, type, tagLine } = data || {};

	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={"Profile Switch"}
			closeAlign={"right"}
			children={
				<div className={styles.container}>
					<p>
						This notification is for one of your other profiles. Do you want to switch
						profile?
					</p>

					<div className={styles.profile_wrapper}>
						<ProfilePic
							thumbnail={profileImage?.image}
							w={36}
							h={36}
							type={type}
							// url={redirect && url}
							name={name}
							subdata={tagLine}
						/>
					</div>

					<div className={styles.button_container}>
						<GaawkButton text="No" onClick={onClose} severity={"tertiary"} />
						<GaawkButton text="Yes, please" onClick={onConfirm} />
					</div>
				</div>
			}
		/>
	);
};

export default ProfileSwitchModal;
