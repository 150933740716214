import { joiResolver } from "@hookform/resolvers/joi";
// import { getLocationType } from "components/Utils/General";
import Joi from "joi";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import galleryApi from "../../../api/gallery";
import useApi from "../../../hooks/useApi";
// import LocationModal from "../../Home/LocationModal";
import TextInput from "../../Utils/SubComs/Inputs/TextInput/TextInput";
import VisibilitySelector from "../../Utils/Visibility/VisibilitySelector";
import styles from "./AlbumEntry.module.css";
import routes from "components/Routing/routing-keys";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import useMutate from "hooks/useMutate";
import { useCustomQuery } from "hooks/useCustomQuery";
import { galleryKeys } from "queryKeys/gallery-key-factory";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import DatePicker from "components/Utils/SubComs/PrimeReactDatePicker/DatePicker";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import { profileKeys } from "queryKeys/profile-key-factory";
import useSearchUsers from "hooks/useSearchUsers";
import {
	peopleFormatter,
	unduplicateIds,
} from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import { components } from "react-select";
import ProfilePic from "components/Home/ProfilePic";
import ProfileTag from "components/Utils/SubComs/Tags/ProfileTag";
import GaawkButton from "components/Utils/Button/GaawkButton";

const PAGE_SIZE = 10;

const AlbumEntry = ({ editMode = false }) => {
	const { albumId } = useParams();
	const navigate = useNavigate();

	const getAlbumIdApi = useApi(galleryApi.getAlbumById, true, true);

	const fetchAlbum = async ({ queryKey }) => {
		const [_, albumId] = queryKey;
		const response = await getAlbumIdApi.request(albumId);
		return response.data;
	};

	const { data, isSuccess } = useCustomQuery({
		queryKey: galleryKeys.albumId(albumId),
		queryFn: fetchAlbum,
		enabled: editMode,
		options: {
			select: (fetchedData) => {
				return {
					title: fetchedData.title,
					description: fetchedData.description,
					// location: fetchedData.location, //! TO BE ADDED WHEN BACKEND FIXED
					date: new Date(fetchedData.date),
					visibility: fetchedData.visibility,
					contributors: fetchedData.contributes,
					tagged: fetchedData.tagged,
					uuid: fetchedData.uuid,
					postId: fetchedData.post.uuid,
					gaawkAlbum: fetchedData.gaawkAlbum,
				};
			},
		},
	});

	const {
		action: { mutate: createAlbum, isLoading: isLoadingCreate },
	} = useMutate(galleryApi.addAlbum, ({ data }) => {
		navigate(routes.album(data.uuid), {
			replace: true,
		});
	});

	const {
		action: { mutate: editAlbum, isLoading: isLoadingEdit },
	} = useMutate(galleryApi.editAlbum, ({ data }) => {
		navigate(routes.album(data.uuid), { replace: true });
	});

	const schema = Joi.object({
		uuid: Joi.string().allow(""),
		postId: Joi.string().allow(""),
		title: Joi.string().required().label("Album Title").messages({
			"string.empty": "Album title should not be empty",
		}),
		description: Joi.string().label("Album description").allow(""),
		// location: Joi.object().label("Location").allow(null), //! TO BE ADDED WHEN BACKEND FIXED
		date: Joi.date().label("Date").allow(null),
		visibility: Joi.string().label("Visibility"),
		contributors: Joi.array().min(0).label("Contributors"),
		tagged: Joi.array().min(0).label("Tagged"),
		gaawkAlbum: Joi.boolean().optional(),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		control,
		watch,
		setValue,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onBlur",
		defaultValues: {
			title: "",
			description: "",
			// location: fetchedData.location, //! TO BE ADDED WHEN BACKEND FIXED
			date: null,
			visibility: "PUBLIC",
			contributors: [],
			tagged: [],
			uuid: "",
			postId: "",
			gaawkAlbum: false,
		},
	});

	const contributorsWatcher = watch("contributors");
	const taggedWatcher = watch("tagged");

	useEffect(() => {
		if (isSuccess) {
			reset(data);
		}
	}, [isSuccess]);

	// !========== Location change ===============

	//! TO BE ADDED WHEN BACKEND FIXED
	// const [showLocationModal, setShowLocationModal] = useState(false);

	// const handleLocationClicked = (place) => {
	// 	setValue("location", {
	// 		latitude: place.geometry.location.lat,
	// 		longitude: place.geometry.location.lng,
	// 		title: place.name,
	// 		placeId: place.place_id,
	// 		type: getLocationType(place.types),
	// 	});

	// 	setShowLocationModal(false);
	// };

	// const handleRemoveLocation = () => {
	// 	setValue("location", null);
	// };

	// !======== Delete album ==========

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const {
		action: { mutate: deleteAlbum, isLoading },
	} = useMutate(galleryApi.deleteAlbum, () => {
		navigate(routes.profile());
	});

	// !========== confirm edit ===============

	const handleEditAlbum = (data) => {
		const { uuid, date, contributors, tagged, postId, ...rest } = data;

		const body = {
			// albumId: uuid, //* should be optional if creating album
			// postId, //* should be optional if creating album
			// title,
			// visibility,
			// ...(description && { description }),
			// ...(location && {
			// 	location: {
			// 		latitude: location.latitude,
			// 		longitude: location.longitude,
			// 		title: location.title,
			// 		type: location.type,
			// 	},
			// }),
			// ...(date && { date }),
			// ...(contributors.length > 0 && { contributors }),
			// ...(tagged.length > 0 && { tagged }),
			...(editMode && { albumId: uuid }),
			...(editMode && { postId }),
			...(date && { date: date.getTime() }),
			contributors: contributors?.map((user) => user.value || user.uuid),
			tagged: tagged?.map((user) => user.value || user.uuid),
			...rest,
		};

		if (editMode) {
			editAlbum({ body });
		} else {
			createAlbum({ body });
		}
	};

	const fetchUsers = useSearchUsers(PAGE_SIZE);

	const handleRemoveContributor = (index) => {
		const updatedProfiles = contributorsWatcher.filter((_, i) => {
			return i !== index;
		});
		setValue("contributors", updatedProfiles);
	};

	const contributorsList = useMemo(
		() =>
			contributorsWatcher?.map((profile, index) => (
				<ProfileTag
					key={profile.value || profile.uuid}
					name={profile.label || profile.name}
					type={profile.type}
					image={profile.profileImage?.image}
					onRemove={() => handleRemoveContributor(index)}
					size={40}
				/>
			)),
		[contributorsWatcher]
	);

	const handleRemoveTagged = (index) => {
		const updatedProfiles = taggedWatcher.filter((_, i) => {
			return i !== index;
		});
		setValue("tagged", updatedProfiles);
	};

	const taggedInList = useMemo(
		() =>
			taggedWatcher?.map((profile, index) => (
				<ProfileTag
					key={profile.value || profile.uuid}
					name={profile.label || profile.name}
					type={profile.type}
					image={profile.profileImage?.image}
					onRemove={() => handleRemoveTagged(index)}
					size={40}
				/>
			)),
		[taggedWatcher]
	);

	return (
		<div className={styles.form_container}>
			<form onSubmit={handleSubmit((data) => handleEditAlbum(data))}>
				<InputWrapper
					label="Album Title"
					required={true}
					error={errors?.title}
					className={styles.input_wrapper}
					component={<TextInput {...register("title")} placeholder="Enter Album Title" />}
				/>

				<Controller
					name={"description"}
					control={control}
					render={({ field }) => (
						<TextArea className={styles.text_area} label="Description" {...field} />
					)}
				/>

				{/* //! TO BE ADDED WHEN BACKEND FIXED */}
				{/* <div className={styles.form_input_wrapper}>
					<label className={styles.input_label}>Location</label>

					<Controller
						control={control}
						name="location"
						render={({ field: { value } }) => (
							<>
								<TextInput
									readOnly={true}
									value={value?.title ?? ""}
									placeholder={"Select location"}
									onClick={() => setShowLocationModal(true)}
								/>

								<LocationModal
									closeAlign={"right"}
									show={showLocationModal}
									onClose={() => setShowLocationModal(false)}
									onLocationClicked={handleLocationClicked}
									activeLocation={value}
									onRemove={handleRemoveLocation}
								/>
							</>
						)}
					/>
				</div> */}

				<DatePicker label={"Date"} control={control} name="date" />

				<div className={`${styles.form_input_wrapper} ${styles.visibility_wrapper}`}>
					<label className={styles.input_label}>Visibility</label>

					<Controller
						control={control}
						name="visibility"
						render={({ field: { value, onChange } }) => (
							<VisibilitySelector
								value={value}
								onValueChange={(e) => () => {
									onChange(e);
								}}
							/>
						)}
					/>
				</div>

				<Controller
					control={control}
					name="contributors"
					render={({ field: { onChange } }) => (
						<InfiniteSearchInput
							label={"Contributors"}
							queryName={profileKeys.search}
							onChange={(user) => onChange([...contributorsWatcher, user])}
							unDuplicateFn={(passedData) =>
								unduplicateIds(
									contributorsWatcher?.map((user) => user.value || user.uuid),
									passedData,
									"value"
								)
							}
							queryFn={fetchUsers}
							itemsPerPage={PAGE_SIZE}
							formatter={peopleFormatter}
							option={{
								Option: (props) => {
									const user = props.data;
									return (
										<components.Option {...props}>
											<div className={styles.member_item}>
												<ProfilePic
													className={styles.shrink}
													thumbnail={user?.profileImage?.image}
													w={36}
													h={36}
													type={user.type}
													name={user.label}
													createMode={true}
													subdata={user.tagLine}
												/>
											</div>
										</components.Option>
									);
								},
							}}
						/>
					)}
				/>

				{contributorsWatcher?.length > 0 && (
					<div className={styles.contributors_container}>{contributorsList}</div>
				)}

				<Controller
					control={control}
					name="tagged"
					render={({ field: { onChange } }) => (
						<InfiniteSearchInput
							label={"Tagged In"}
							queryName={profileKeys.search}
							onChange={(user) => onChange([...taggedWatcher, user])}
							unDuplicateFn={(passedData) =>
								unduplicateIds(
									taggedWatcher?.map((user) => user.value || user.uuid),
									passedData,
									"value"
								)
							}
							queryFn={fetchUsers}
							itemsPerPage={PAGE_SIZE}
							formatter={peopleFormatter}
							option={{
								Option: (props) => {
									const user = props.data;
									return (
										<components.Option {...props}>
											<div className={styles.member_item}>
												<ProfilePic
													className={styles.shrink}
													thumbnail={user?.profileImage?.image}
													w={36}
													h={36}
													type={user.type}
													name={user.label}
													createMode={true}
													subdata={user.tagLine}
												/>
											</div>
										</components.Option>
									);
								},
							}}
						/>
					)}
				/>

				{taggedWatcher?.length > 0 && (
					<div className={styles.contributors_container}>{taggedInList}</div>
				)}

				<div className={styles.create_button_wrapper}>
					{!watch("gaawkAlbum") && editMode && (
						<GaawkButton
							severity={"danger"}
							onClick={() => setShowDeleteModal(true)}
							text={"Delete Album"}
						/>
					)}
					<GaawkButton
						type={"submit"}
						disabled={isLoadingCreate || isLoadingEdit}
						text={`${editMode ? "Update" : "Create"} Album`}
					/>
				</div>
			</form>

			<GaawkModal
				show={showDeleteModal}
				handleClose={() => setShowDeleteModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"Delete Album"}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<div className={styles.info_text}>
							Are you sure you want to delete this album? All the posts within this
							album will also be deleted.
						</div>

						<div className={styles.btn_container}>
							<GaawkButton
								text="Cancel"
								severity={"tertiary"}
								isLoading={isLoading}
								onClick={() => {
									setShowDeleteModal(false);
								}}
							/>
							<GaawkButton
								text="Delete"
								severity={"danger"}
								isLoading={isLoading}
								onClick={() => {
									deleteAlbum(albumId);
								}}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default AlbumEntry;
